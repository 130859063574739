import React, {Component} from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Api from '../../Components/Api';
import HeaderSub from '../../Components/Member/HeaderSub';
import numeral from 'numeral';
import ImageUri from '../../Components/ImageUri';
import  moment from 'moment';

UIkit.use(Icons);

export default class InvoiceList extends Component {
  state = {
    list: []
  }
  
  listGet = async () => {
    var _resposne = await Api.member("InvoiceList");


    this.setState({list: _resposne.list || []});
  }

  componentDidMount () {
    this.listGet();
  }

  render() {
    return <div className="uk-padding">
      <HeaderSub title="ใบเสร็จของฉัน" />
      <div className="uk-card uk-card-default uk-padding-small">
        <div className="d-card-header">
          <div className='d-card-header-left'>ใบเสร็จของฉัน</div>
        </div>
        <div className="d-table-container uk-margin-small-top">
          <table className='uk-table uk-table-divider uk-table-small uk-table-striped' id="d-prescription-table">
            <thead>
              <tr>
                <th>วันที่</th>
                <th>ประเภทใบเสร็จ</th>
                <th>ยอดเงิน</th>
                <th>ดาวน์โหลดใบเสร็จ</th>
              </tr>
            </thead>
            <tbody>{
              this.state.list.length === 0 ?
              <tr>
                <td colSpan={'100%'} className="uk-text-center">ไม่พบรายการ</td>
              </tr>
            :
              this.state.list.map((data, index) => {
                return <tr key={`invoiceRow${index}`}>
                  <td>{moment(data.dateadd).format('DD/MM/YY HH:mm')}</td>
                  <td>{data.type}</td>
                  <td>{numeral(data.price).format('0,0.00')}</td>
                  <td><a href={"/invoiceDetail" + data.link} target='_blank'>ดาวน์โหลด</a></td>
                </tr>
              })
            }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  }
};
