import React, { Component } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';

import Login from '../../Pages/Moderator/Login';
import Dashboard from '../../Pages/Moderator/Dashboard';
import Error404 from "../../Pages/Error404";
import moment from "moment";

export default class ModeratorRoute extends Component {

  state = {
    login: false,
    loadingLogin: true
  }

  componentDidMount() {
    this.init();
  }

  componentWillReceiveProps() {
    this.init();
  }

  moderator = null

  async init() {
    
    var result = false;

    try {
      var {result, moderator} = await Api.moderator('ModeratorLoginBySession');

      this.moderator = moderator
    } catch(e) {
      console.error(e);
    }
    this.setState({ login: result === true ? true : false, loadingLogin: false });
  }

  routeRender = () => {
    var routes = {
      "/": Dashboard,
      "/moderator": Dashboard,
    }; 

    var pathname = this.props.location.pathname.split('/').filter(Boolean).join('/');
    var Route = routes[`/${pathname}`];
    if(Route) {
      return <Route {...this.props} qs={Api.queryString} moderator={this.moderator} />;
    }
    else {
      return <Error404 {...this.props} />;
    }
  }

  logout = () => {
    localStorage.setItem('moderatorLogin', '');
    window.location.href="/moderator";

  }


  render() {
    if (this.state.login) {
      return (
        <div>
          <div className="uk-padding-small uk-box-shadow-medium d-background-white" uk-sticky="">
            <div className="uk-grid uk-grid-collapse uk-child-width-1-2 uk-flex-middle">
              <div>
                <img src="/assets/stack-logo-light.png" className="uk-margin-small-right" />
                <img src="/assets/logo_dietz.png" />
              </div>
              
              <div className="uk-text-right uk-flex-middle">
                <div style={{paddingRight: 10}}>
                  <span>
                    <span> 
                      <a className="uk-link-reset icon-arrow-down d-header-menu-more"></a>
                      <div uk-dropdown="mode: click; pos: bottom-right;" className="d-dropdown-card uk-text-left">
                        <a onClick={this.logout}>
                          <span className="icon-power uk-margin-small-right "></span>
                          Logout
                        </a>
                      </div>
                    </span>
                  </span>
                </div>

              </div>
            </div>
          </div>
          <div className='uk-margin-top'>{this.routeRender()}</div>
          <div className='uk-padding uk-text-center'>Copyright © {moment().format('YYYY')} <a className="text-bold-800 grey darken-2" href="https://dietz.asia/" target="_blank">dietz.asia </a></div>
        </div>
      )
    } else {
      return this.state.loadingLogin ?
        <div className="uk-text-center uk-padding-small d-page-loadings-login">
          <span uk-spinner=""></span>
        </div>
        :
        <Login {...this.props} />
    }

  }
};
