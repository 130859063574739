import React, {Component} from 'react';
import Api from './Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import { Link } from "react-router-dom";
import moment from 'moment';

UIkit.use(Icons);

export default class SearchResult extends Component {

  listRender = () => {
    var _list= [];

    var _template = (list) => {
      if(list && list.length > 0) {
        list.forEach((result, index)=> {
          _list.push(<div key={`${result.type}${index}`}  onClick={()=>{
            window.location.href = '/' + (result.type==='chat' ? `chat?listId=${result.chatLast.id}` : `campaign-report?id=${result.id}`);      
          }}>
            <div className="uk-grid-small uk-flex-middle" uk-grid="">
              <div className="uk-width-auto">
                <div className="d-search-profile-image uk-background-cover" style={{backgroundImage: `url(${result.image})`}}></div>
              </div>
              <div className="uk-width-expand">
                <div>{result.name}</div>
                {result.type === 'chat' && <div className="uk-text-meta uk-text-truncate">{result.consultname} <span className="uk-text-meta uk-float-right d-font-mon uk-text-success">{moment(result.dateadd).fromNow()}</span></div> }
              </div>
            </div>
          </div>);
        })
      } else {
        _list.push(<div className="uk-padding uk-text-meta uk-text-center">ไม่พบรายการ</div>);
      }
    };

    _list.push(<div className="d-label">แคมเปญ</div>)
    _template(this.props.campaign);

    _list.push(<div className="d-label">ข้อความ</div>)
    _template(this.props.chat);

    return _list;
  }
  render() {
    return <div id="d-search-result-container" className="d-dropdown-card uk-text-left uk-dropdown uk-open uk-dropdown-bottom-left">
      {this.listRender()}
    </div>
  }
};
