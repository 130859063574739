import React, {Component} from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import moment from 'moment';
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min.js";
import "eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css";
import UserSmartHealthTrendingGraph from "./Member/UserSmartHealthTrendingGraph";
import UserSmartHealthFbsGraph from "./Member/UserSmartHealthFbsGraph";
import $ from 'jquery';
import ImageUri from './ImageUri';
import Gender from './Gender';
import ChatHistory from './ChatHistory';
import numeral from 'numeral';
import Api from './Api';
import VisitNote from './Consult/VisitNote';
import MemberRegisterLocation from './Member/MemberRegisterLocation';
import __ from './Language.js';

UIkit.use(Icons);

export default class MemberProfileChatCard extends Component {

  state = {
    uiUpdate: true,
    showMap : false
  }

  data = {
    atk : null,

    profile : {},
  
    company : {},
  
    companyGroup : [],
  
    memberData : {},
  
    memberId : null,
  
    campaignName : ''
  }


  componentDidMount () {
    this.dataGet(); 
  }

  dataGet = async () => {
    var _r = await Api[this.props.apiType]("MemberProfileChatCardDataGet", {
      memberId : this.props.memberId,
      campaignId: this.props.campaignId
    });

    if(_r.data) {
      var _key = ['atk', 'profile', 'company', 'companyGroup', 'memberData', 'campaignName'];
      for(var k of _key) {
        this.data[k] = _r.data[k];
      }
    }


    this.setState({uiUpdate: !this.state.uiUpdate})
  }

  modalTableContainerRender = () => {
    return (
      this.state.profileLoading !== true && (
        <div>
          <div
            className="uk-padding-small uk-padding-remove-bottom d-text-white"
            style={{ backgroundColor: "#404E67" }}
          >
            คุณ:{" "}
            <b>
              {this.data.nameprefix} {this.data.name}{" "}
              {this.data.lastname}
            </b>{" "}
            องค์กร: <b>{this.company.name}</b>
          </div>
          <div className="uk-margin-small-top">
          <UserSmartHealthTrendingGraph
            typeApi={this.props.apiType}
            memberId={this.props.memberId}
            fullscreen={false}
          /></div>
          <div className="uk-margin-small-top">
          <UserSmartHealthFbsGraph
            typeApi={this.props.apiType}
            memberId={this.props.memberId}
            fullscreen={false}
          />
          </div>
        </div>
      )
    );
  };

  tableMemberDataRender = () => {
    var _type = [
      {
        key: "sys",
        name: "ความดันโลหิต",
      },
      {
        key: "fbs",
        name: "น้ำตาลในเลือด (FBS)",
      },
      {
        key: "bmi",
        name: "BMI",
      },
    ];

    return (
      <table
        style={{ border: "1px solid #E5E5E5" }}
        className="uk-table uk-table-small uk-table-divider"
      >
        <thead>
          <tr>
            <th className="uk-text-center">รายการตรวจที่สำคัญ</th>
            <th colSpan="3" className="uk-text-center">
              ค่าที่ตรวจ 3 ครั้งล่าสุด
            </th>
          </tr>
        </thead>
        <tbody>
          {_type.map((value, index) => {
            return (
              <tr key={`row_${index}`}>
                <td>{value.name}</td>
                <td className="uk-text-center uk-text-small">
                  {this.data.memberData[value.key] != null && this.data.memberData[value.key][0] ? (
                    <div>
                      {value.key === "sys"
                        ? this.data.memberData[value.key][0].value
                        : numeral(this.data.memberData[value.key][0].value).format(
                            "0,0.00"
                          )}
                      <div className="uk-text-meta">
                        {moment(this.data.memberData[value.key][0].dateadd)
                          .add(543, "y")
                          .format("DD/MM/YY HH:mm")}
                      </div>
                      <div className="uk-text-meta">{this.data.memberData[value.key][0].note === '' ? '' : `(${this.data.memberData[value.key][0].note})`}</div>
                    </div>
                  ) : (
                    ""
                  )}
                </td>
                <td className="uk-text-center uk-text-small">
                  {this.data.memberData[value.key] != null && this.data.memberData[value.key][1] ? (
                    <div>
                      {value.key === "sys"
                        ? this.data.memberData[value.key][1].value
                        : numeral(this.data.memberData[value.key][1].value).format(
                            "0,0.00"
                          )}
                      <div className="uk-text-meta">
                        {moment(this.data.memberData[value.key][1].dateadd)
                          .add(543, "y")
                          .format("DD/MM/YY HH:mm")}
                      </div>
                      <div className="uk-text-meta">{this.data.memberData[value.key][1].note === '' ? '' : `(${this.data.memberData[value.key][1].note})`}</div>
                    </div>
                  ) : (
                    ""
                  )}
                </td>
                <td className="uk-text-center uk-text-small">
                  {this.data.memberData[value.key] != null && this.data.memberData[value.key][2] ? (
                    <div>
                      {value.key === "sys"
                        ? this.data.memberData[value.key][2].value
                        : numeral(this.data.memberData[value.key][2].value).format(
                            "0,0.00"
                          )}
                      <div className="uk-text-meta">
                        {moment(this.data.memberData[value.key][2].dateadd)
                          .add(543, "y")
                          .format("DD/MM/YY HH:mm")}
                      </div>
                      <div className="uk-text-meta">{this.data.memberData[value.key][2].note === '' ? '' : `(${this.data.memberData[value.key][2].note})`}</div>
                    </div>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  modalTableContainerRender = () => {
    return (
      this.state.profileLoading !== true && (
        <div>
          <div
            className="uk-padding-small uk-padding-remove-bottom d-text-white"
            style={{ backgroundColor: "#404E67" }}
          >
            คุณ:{" "}
            <b>
              {this.data.profile.nameprefix} {this.data.profile.name}{" "}
              {this.data.profile.lastname}
            </b>{" "}
            องค์กร: <b>{this.data.company.name}</b>
          </div>
          <div className="uk-margin-small-top">
          <UserSmartHealthTrendingGraph
            typeApi={this.props.chatby === "c" ? "consult" : "member"}
            memberId={this.props.memberId}
            fullscreen={false}
          /></div>
          <div className="uk-margin-small-top">
          <UserSmartHealthFbsGraph
            typeApi={this.props.chatby === "c" ? "consult" : "member"}
            memberId={this.props.memberId}
            fullscreen={false}
          />
          </div>
        </div>
      )
    );
  };



  render() {
    return  <div className="uk-grid-small" uk-grid="">
    <div className="uk-width-auto@l uk-width-1-1 uk-text-center">
      <div
        className="uk-background-cover uk-background-muted d-user-profile-card-image uk-display-inline-block"
        style={{
          backgroundImage:
            !this.data.profile || this.data.profile.image === ""
              ? false
              : `url(${ImageUri(this.data.profile.image)})`,
        }}
      ></div>
      <ChatHistory apiType={this.props.apiType} campaignId={this.props.campaignId} memberId={this.props.memberId} />
    </div>
    <div className="uk-width-auto@l uk-width-1-1">
      <div>
        {__('Name')}: {" "}
        <b>
          {this.data.profile.nameprefix} {this.data.profile.name}{" "}
          {this.data.profile.lastname}
        </b>
        {this.data.atk != null && <div title={"ตรวจเมื่อ " + moment(this.data.atk.dateadd, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')} className={"uk-label uk-dispaly-inline-block uk-margin-small-left " + this.data.atk.bg }>ATK {this.data.atk.status}</div>}
      </div>
      <div>
        {__('Company')}: <b>{this.data.company.name}</b>
      </div>
      <div>
        {__('Group')}:{" "}
        {this.data.companyGroup.map((cg) => {
          return (
            <b className="uk-margin-small-right">{cg.name}</b>
          );
        })}
      </div>
      <div>
        {__('Campaign')}: <b>{this.data.campaignName}</b>
      </div>
      <div>
        {__('อายุ')}:{" "}
        <b>
          {this.data.profile.birthday === "0000-00-00"
            ? "-"
            : moment().diff(moment(this.data.profile.birthday), "y")}
        </b>
      </div>
      <div>
        {__('เพศ')}: <b>{Gender[this.data.profile.gender] || ""}</b>
      </div>
      <div>
        {__('โรคประจำตัว')}:{" "}
        <b>{this.data.profile.diabetes === "1" ? "เบาหวาน " : ""}{this.data.profile.congenitaldisease}</b>
      </div>
      <div>
        {__('เลขบัตรประชาชน')}:{" "}<b>{this.data.profile.nid}</b>
        <b></b>
      </div>
      <div>
        {__('หมายเลขโทรศัพท์')}:{" "}<b>{this.data.profile.tel}</b>
        <b></b>
      </div>
      <div>
        {__('ที่อยู่จัดส่งยา')}:{" "}<b>{this.data.profile.address}</b>
        <b></b>
      </div>
      <div>
        {__('แพ้ยา')}:{" "}<b>{this.data.profile.drugallergy}</b>
        <b></b>
      </div>
      <div className="uk-margin-small-top">
        <a
          href="#modal-table-container"
          uk-toggle=""
          style={{ color: "#90A4AE", textDecoration: "underline" }}
        >
          {__('คลิกดูผลตรวจสุขภาพ')}
        </a>
        <div
          id="modal-table-container"
          uk-modal=""
          className="uk-modal-container"
        >
          <div className="uk-modal-dialog uk-modal-body uk-padding-remove">
            {this.modalTableContainerRender()}
          </div>
        </div>
      </div>
      {this.props.apiType === 'consult' && <div className="uk-margin-small-top">
        <a
          href="#modal-visitnote-container"
          uk-toggle=""
          style={{ color: "#90A4AE", textDecoration: "underline" }}
        >
          คลิกดูบันทึกการดูแล
        </a>
        <div
          id="modal-visitnote-container"
          uk-modal=""
          className="uk-modal-container"
        >
          <div className="uk-modal-dialog uk-modal-body uk-padding-remove">
            <VisitNote paddingRemove={true} />
          </div>
        </div>
      </div>}
      <div className="uk-margin-small-top">
        <a
          onClick={()=>this.setState({showMap: true})}
          style={{ color: "#90A4AE", textDecoration: "underline" }}
        >
          คลิกดูแผนที่
        </a>
        {
          this.state.showMap === true && <div className="d-popup-background">
            <div className="d-popup-body">
              <MemberRegisterLocation 
                callback={(data)=>{
                  this.data.profile.lat = data.lat;
                  this.data.profile.lng = data.lng;
                }}
                lat={this.data.profile.lat} 
                lng={this.data.profile.lng} 
                hideAddressName={true} 
                hideAddress={true} 
                hideNote={true} 
                hidePostcode={true} 
                formType="admin" 
              />

              <div className="uk-margin-small-top uk-text-right">
                <button className="uk-button uk-button-small uk-button-primary uk-margin-small-left" onClick={() => {


                  this.setState({ showMap: false });
                }}>ปิด</button>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
    <div className="uk-width-expand@l uk-width-1-1">
      {/*this.state.profileRisk.map((data, index) => {
        return (
          <div
            uk-tooltip={data.label}
            key={`label-risk-${index}`}
            className={"d-chat-profile-risk d-risk-" + data.color}
          >
            <img
              className="d-chat-profile-risk-logo"
              src={`/assets/${data.icon}.png`}
            />
          </div>
        );
      })*/}
      {this.tableMemberDataRender()}
    </div>
    { typeof this.props.scrollToBottom === 'function' && <div className="uk-width-auto@l uk-width-1-1 uk-text-center">
      <button
        className="icon-arrow-down icons uk-border-circle uk-button-secondary"
        style={{ height: 40, width: 40, lineHeight: "40px" }}
        uk-tooltip="ข้อความล่าสุด"
        onClick={this.props.scrollToBottom}
      ></button>
    </div>}
    
  </div>
  }
};
