import React, {Component} from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import HeaderSub from '../../Components/Member/HeaderSub';
import SmartHealthCompanyProfileCard from '../../Components/Member/SmartHealthCompanyProfileCard';
import SmartHealthHealthRisk from '../../Components/Member/SmartHealthHealthRisk';
import SmartHealthTrendingRisk from '../../Components/Member/SmartHealthTrendingRisk';
import SmartHealthTrendingGraph from '../../Components/Member/SmartHealthTrendingGraph';
import SmartHealthUser from '../../Components/Member/SmartHealthUser';
import SmartHealthDepartment from '../../Components/Member/SmartHealthDepartment';
import SmartHealthCampaign from '../../Components/Member/SmartHealthCampaign';
import SmartHealthCriticalRisk from '../../Components/Member/SmartHealthCriticalRisk';
import SmartHealthHealthOverview2 from '../../Components/Member/SmartHealthHealthOverview2';

UIkit.use(Icons);


export default class SmartHealth extends Component {
  state = {
  }

  render() {
    return <div className="uk-padding">
      <HeaderSub title="SMART HEALTH" />
      <div className="uk-margin-small-top">
        <SmartHealthCompanyProfileCard />
      </div>
      <div className="uk-margin-top">
        <div className="uk-child-width-1-4@l uk-child-width-1-2@m uk-child-width-1-1" uk-grid="">
          <div>
            <SmartHealthUser />
          </div>
          <div>
            <SmartHealthDepartment />
          </div>
          <div>
            <SmartHealthCriticalRisk />
          </div>
          <div>
            <SmartHealthCampaign />
          </div>
        </div>
      </div>
      <div className="uk-margin-top">
        <SmartHealthHealthOverview2 />
      </div>
      <div className="uk-margin-top">
        <SmartHealthTrendingGraph />
      </div>
      <div className="uk-margin-top">
        <div className="uk-child-width-1-2@l uk-child-width-1-1" uk-grid="">
          <div>
            <SmartHealthHealthRisk />
          </div>
          <div>
            <SmartHealthTrendingRisk />
          </div>
        </div>
      </div>
    </div>
  }
};
