var Diabates = {
  meal_name: {
    morning: "เช้า",
    day: "กลางวัน",
    evening: "เย็น",
    night: "ก่อนนอน",
  },
  meal: ["morning", "day", "evening", "night"],
  time: ["before", "after"],
  drug: ["clear"],
  food: ["main", "snack"],
  state_init: () => {
    var _state = {
      note: "",
    };

    Diabates.meal.forEach((meal) => {
      Diabates.time.forEach((time) => {
        _state[`${meal}_${time}`] = "";
      });

      Diabates.drug.forEach((drug) => {
        _state[`${meal}_${drug}`] = "";
      });

      Diabates.food.forEach((food) => {
        _state[`${meal}_${food}`] = "";
      });
    });

    return _state;
  },
};

export default Diabates;
