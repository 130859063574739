import React, { Component } from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Api from '../Api';
import FullScreenElement from '../FullScreenElement';
import SortArray from '../SortArray.js';

UIkit.use(Icons);

export default class DashboardDeptTable extends Component {
    state = {
        department: []
    }

    componentDidMount() {
        this.dataGet();
    }

    dataGet = async () => {
        var { department = [] } = await Api.member('DashboardDept');

        if(!Array.isArray(department)) department = [];

        this.setState({ department: SortArray(department, 'value', true) })

    }

    renderTable = () => {
        return <table className="uk-table uk-table-striped">
            <thead>
                <tr>
                    <th>Department</th>
                    <th>Number</th>
                </tr>
            </thead>
            <tbody>
                {
                    this.state.department.length > 0 ? 
                    this.state.department.map((data,index)=>{
                        console.log(data)
                        return <tr key={`row${index}`}>
                            <td>{data.name}</td>
                            <td>{data.value}</td>
                        </tr>
                    })
                    :
                    <tr>
                        <td colSpan="100%" className="uk-text-center">ไม่พบข้อมูล</td>
                    </tr>
                }
            </tbody>
        </table>
    }

    render() {
        return <div className="uk-card uk-card-default uk-padding-small" id="d-dashboard-dept-container">
            <div className="d-card-header">
                <div className="d-card-header-left">
                    DEPARTMENT
                </div>
                <div className="d-card-header-right">
                    <span className='icon-reload' onClick={this.dataGet}></span>
                    <FullScreenElement element="#d-dashboard-dept-container" />
                </div>
            </div>
            <div className="uk-overflow-auto">{this.renderTable()}</div>
        </div>
    }
};
