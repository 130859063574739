import React, { Component } from 'react';
import ConsultStatisticTable from './ConsultStatisticTable';
import HivStatisticTable from './HivStatisticTable';
export default class ConsultStatistic extends Component {

  render() {
    return <div className="uk-padding">
      <div>
        <ConsultStatisticTable />
      </div>
      <div className='uk-margin-top'>
        <HivStatisticTable />
      </div>
    </div>
  }
};
