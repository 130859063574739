import React, { Component } from "react";
import Api from "../../Components/Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import HeaderSub from "../../Components/Member/HeaderSub";
import UserProfileCard2 from "../../Components/Member/UserProfileCard2";
import UserSmartHealthSmartHealthRecord from "../../Components/Member/UserSmartHealthSmartHealthRecord";
import UserSmartHealthRiskMetric from "../../Components/Member/UserSmartHealthRiskMetric";
import UserSmartHealthTrendingRisk from "../../Components/Member/UserSmartHealthTrendingRisk";
import UserSmartHealthTrendingGraph from "../../Components/Member/UserSmartHealthTrendingGraph";
import UserSmartHealthHealtRecordChart from '../../Components/Member/UserSmartHealthHealtRecordChart';
import UserSmartHealthFbsGraph from "../../Components/Member/UserSmartHealthFbsGraph";
import Formular from "../../Components/Formular.js";
import DataType from "../../Components/DataType.js";

UIkit.use(Icons);

export default class UserSmartHealth extends Component {
  componentDidMount() {
    this.dataGet();
  }

  state = {
    data: [],
    loadingData: true,
  };

  async dataGet() {
    var { data } = await Api.member("UserSmartHealthRecord");
    if (!data) data = [];
    var _data = [];

    for (var i = data.length; i-- > 0; ) {
      if (DataType.indexOf(data[i].name) >= 0) {
        data[i].detail = Formular[data[i].name](
          data[i].value,
          data[i].age,
          data[i].gender
        );
        _data.push(data[i]);
      } else {
        _data.push(data[i]);
      }
    }

    this.setState({ data: _data || [], loadingData: false });
  }

  render() {
    return (
      <div className="uk-padding">
        <HeaderSub title="USER" />
        <div>
          <div>
              {this.state.loadingData === false && <UserProfileCard2 data={this.state.data} />}
          </div>
          <div className="uk-margin-top">
            <UserSmartHealthTrendingGraph />
          </div>
          <div className="uk-margin-top">
            <UserSmartHealthFbsGraph />
          </div>
          <div uk-grid="">
            <div className="uk-width-1-1 uk-width-2-5@l">
              <div className="uk-margin-top">
                {this.state.loadingData ? (
                  <div className="uk-text-center uk-padding">
                    <span uk-spinner=""></span>
                  </div>
                ) : (
                  <UserSmartHealthRiskMetric
                    data={this.state.data}
                    dataGet={this.dataGet}
                  />
                )}
              </div>
            </div>
            <div className="uk-width-1-1 uk-width-3-5@l">
              {this.state.loadingData ? (
                <div className="uk-text-center uk-padding">
                  <span uk-spinner=""></span>
                </div>
              ) : (
                <div>
                  <div className="uk-margin-top"></div>
                    <UserSmartHealthTrendingRisk />
                </div>
              )}
            </div>
          </div>
          {
            /**<div className="uk-margin-top">
            <UserSmartHealthHealtRecordChart />
          </div> */
          }
          
        </div>
      </div>
    );
  }
}
