import React, { Component } from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Api from '../../Components/Api';
import Formular from '../Formular';
import GroupReport from '../Formular/GroupReport';
import moment from 'moment';
import numeral from 'numeral';
import FullScreenElement from '../FullScreenElement';
import ReactEcharts from 'echarts-for-react';

UIkit.use(Icons);

export default class UserSmartHealthHealtRecordChart extends Component {

  state = {
    loadingApi: true,
    data: null,
    yearGraph: [],
    dataGraph: [],
  }

  componentDidMount() {
    this.dataGet();
  }

  dataName = {
    'opd' : 'OPD',
    'ipd' : 'IPD',
    'acc' : 'Accident'
  }

  dataRender = () => {

    var data = this.state.data;

    var _dataByName = {}

    var _dataGraphObject = {};

    
    var _symbol = ['circle', 'rect', 'triangle', 'diamond', 'pin', 'arrow'];


    var _index = 0;

    /*Format data */
    for (var i in data) { /** Year */
      for (var i1 in data[i]) { /** Data name */

          if(data[i][i1]) {
            if(!_dataByName[i1]) {
              _dataByName[i1] = {};

              _dataGraphObject[i1] = {
                  name: this.dataName[i1],
                  type: 'line',
                  symbol: _symbol[_index],
                  symbolSize: 14,
                  itemStyle: {
                    borderColor: '#FFFFFF',
                    borderWidth: 2
                  },
                  label: {
                    show: true,
                  },
                  data: []
              };

              _index++;
            }

            if(_dataByName[i1][i] === undefined) _dataByName[i1][i] = data[i][i1];
            
          }

      }
    }


      for(var name in _dataByName) { 
        this.state.yearGraph.forEach((year)=>{
          for(var dateadd in _dataByName[name]) {
            if(dateadd.indexOf(year) >= 0) {
              _dataGraphObject[name].data.push(_dataByName[name][dateadd]);
            } else {
              _dataGraphObject[name].data.push(null);
            }
          }
        })

      }

      var _dataGraph = [];

      for(var i in _dataGraphObject) {
        if(_dataGraphObject[i].data) _dataGraphObject[i].data.reverse();

        _dataGraph.push(_dataGraphObject[i]);
      }

    setTimeout(()=>{       
      this.setState({
        loadingApi: false,
        dataGraph: _dataGraph
      });
    } ,100);
  }

  dataGet = async () => {
    this.setState({ loadingApi: true });
    var { yearGraph, data } = await Api.member('UserSmartHealthHealthRecordChart');

    this.setState({data, yearGraph} , this.dataRender);

  }



  
  renderGraph = () => {
    var _dataName = [];
    this.state.dataGraph.forEach((data)=>{
      _dataName.push(data.name)
    })

    var option = {

      // Setup grid
      grid: {
        x: 40,
        x2: 20,
        y: 35,
        y2: 25
      },

      // Add tooltip
      tooltip: {
        trigger: 'axis'
      },

      // Add legend
      legend: {
        right: 10,
        data: _dataName,
      },

      // Add custom colors
      color: ['#F98E76', '#16D39A', '#2DCEE3', '#FF7588', '#626E82'],

      // Enable drag recalculate
      calculable: true,

      // Hirozontal axis
      xAxis: [{
        type: 'category',
        boundaryGap: false,
        data: this.state.yearGraph.reverse(),
        z: 10,
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      }],

      // Vertical axis
      yAxis: [{
        name : 'User',
        type: 'value',
        minInterval: 1,
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      }],

      // Add series
      // Add series
      series: this.state.dataGraph
    };
    return <ReactEcharts
      option={option}
      notMerge={true}
      lazyUpdate={true}
      style={{height: 400}}
      theme={"theme_name"}
    />
  }

  render() {
    return <div className="uk-card uk-card-default uk-padding-small" id="d-smarth-health-health-record-chart">
      <div className="d-card-header">
        <div className="d-card-header-left">
          HEALTH RECORD CHART
        </div>
        <div className="d-card-header-right">
          <span className='icon-reload' onClick={this.dataGet}></span>
          <FullScreenElement element="#d-smarth-health-health-record-chart" />
        </div>
      </div>
      <div className="uk-margin-small-top">
      {
        this.state.loadingApi === true ? 
        <div className="uk-padding uk-text-center">
          <span uk-spinner=""></span>
        </div>
        :
        this.renderGraph()

      }
      </div>
    </div>
  }
};
