import React, { Component } from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import ReactEcharts from 'echarts-for-react';
import DataTypeSelect from '../DataTypeSelect';
import Api from '../Api';
import Formular from '../Formular';
import FullScreenElement from '../FullScreenElement';
import moment from 'moment';
UIkit.use(Icons);

export default class DashboardHealthRiskByDepartment extends Component {
  state = {
    selectDataType: 'bmi,waistline',
    selectYear: moment().subtract(1, 'year').format('YYYY'),
    departmentGraph: [],
    employeeAmount: [],
    employeeRisk: [],
    loadingApi: false
  }

  componentDidMount() {
    this.dataGet();
  }

  dataGet = () => {
    this.setState({loadingApi: true}, async ()=>{

      var { data } = await Api.member('DashboardHealthRiskByDepartment', { selectDataType: this.state.selectDataType, selectYear: this.state.selectYear  });
      
    

      if(data === null) data = [];
  
      var _departmentGraph = [];
      var _dataAmount = {};
      var _dataRisk = {};
  
      for(var i = data.length; i-->0;) {
        if(data[i] === null) data[i] = [];
        
        for(var i1 = data[i].length; i1-->0;) {

          //create X label
          if(_departmentGraph.indexOf(data[i][i1].position) < 0) 
          _departmentGraph.push(data[i][i1].position);
    
          //Amount
          if(_dataAmount[data[i][i1].position] === undefined) _dataAmount[data[i][i1].position] = [];
    
          if(_dataAmount[data[i][i1].position].indexOf(data[i][i1].memberid) < 0) _dataAmount[data[i][i1].position].push(data[i][i1].memberid);
    
          //Risk
          if(_dataRisk[data[i][i1].position] === undefined) _dataRisk[data[i][i1].position] = [];
    
          var _value = Formular[data[i][i1].name](data[i][i1].value);
    
          if(_value && _value.color !== 'green') {
            if(_dataRisk[data[i][i1].position].indexOf(data[i][i1].memberid) < 0) _dataRisk[data[i][i1].position].push(data[i][i1].memberid);
          }
        }
      };
  
  
      var _employeeRisk = [];
      var _employeeAmount = [];
  
      _departmentGraph.forEach((department)=>{
        _employeeRisk.push(_dataRisk[department] ? _dataRisk[department].length : 0);
        _employeeAmount.push(_dataAmount[department] ? _dataAmount[department].length : 0);
      })
  
      this.setState({
        departmentGraph: _departmentGraph,
        employeeAmount: _employeeAmount,
        employeeRisk: _employeeRisk,
        loadingApi: false
      });

    });

    
  }

  graphRender = () => {

    var option = {

      // Setup grid
      grid: {
        x: 40,
        x2: 20,
        y: 70,
        y2: 30
      },

      // Add tooltip
      tooltip: {
        trigger: 'axis'
      },

      // Add legend
      legend: {
        data: [
          'ความเสี่ยง',
          'จำนวนพนักงาน',
        ]
      },

      // Enable drag recalculate
      calculable: true,

      // Horizontal axis
      xAxis: [{
        type: 'category',
        data: this.state.departmentGraph,
        z: 10,
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      },
      {
        type: 'category',
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: { show: false },
        splitArea: { show: false },
        splitLine: { show: false },
        data: this.state.departmentGraph
      }
      ],

      // Vertical axis
      yAxis: [{
        type: 'value',
        axisLabel: { formatter: '{value}' },
        boundaryGap: [0, 1],
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      }],

      // Add series
      series: [
        {
          name: 'จำนวนพนักงาน',
          type: 'bar',
          itemStyle: { normal: { color: 'rgba(116,116,116,0.4)', bottom: '20px', label: { show: true, position: 'top', formatter:  (p) => {  return p.value > 0 ? (((this.state.employeeRisk[p.dataIndex]/p.value) * 100).toFixed(0) + '%' + '\n' + p.value) + '\n\n' : ''; } } } },
          data: this.state.employeeAmount
        },
        {
          name: 'ความเสี่ยง',
          type: 'bar',
          xAxisIndex: 1,
          itemStyle: { normal: { color: 'rgba(253,57,122,1)', label: { show: true, position: 'top', formatter: function (p) { return p.value > 0 ? (p.value + '\n') : ''; } } } },
          data: this.state.employeeRisk
        },
      ]
    };

    return <ReactEcharts
      option={option}
      style={{height: 400}}
      notMerge={true}
      lazyUpdate={true}
    />
  }

  selectYear = () => {
    var _years = 5;

    var _option = [];

    for(var i=0; i<_years; i++) {
    _option.push(<option key={`year${i}`}>{moment().subtract(i, 'y').format('YYYY')}</option>);
    }

    return <select className="uk-select uk-width-small uk-margin-small-right" value={this.state.selectYear} onChange={(e)=>this.setState({selectYear: e.target.value}, ()=>this.dataGet())}>{_option}</select>
  }

  render() {
    return <div className="uk-card uk-card-default uk-padding-small" id="d-smarth-health-health-risk">
      <div className="d-card-header">
        <div className="d-card-header-left">
          HEALTH RISK BY DEPARTMENT
        </div>
        <div className="d-card-header-right">
          <span className='icon-reload' onClick={this.dataGet}></span>
          <FullScreenElement element="#d-smarth-health-health-risk" />
        </div>
      </div>
      <div className="uk-text-right uk-margin-top uk-margin-bottom">
        {this.selectYear()}
        <DataTypeSelect notSelectCategory={false} value={this.state.selectDataType} callback={(value)=>{
          this.setState({ selectDataType: value }, ()=>this.dataGet());
        }} />
      </div>
      {
        this.state.loadingApi === true ?
        
        <div className="uk-padding uk-text-center"><span uk-spinner=""></span></div>
        :
        this.graphRender()
      }
    </div>
  }
};
