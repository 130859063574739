import React, { Component } from "react";
import Api from "../Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import moment from "moment";
import Thumbnail from "../Thumbnail";
import firebase from "firebase";
import TeleConsultHeader from "./TeleConsultHeader";
import SortArray from "../SortArray";

UIkit.use(Icons);

var Online_trigger_loop = null;

export default class TeleConsult extends Component {
  state = {
    consultList: [],
    consultShowList: [],
    keyword: "",
    consultGetLoading: true,
    companyName: "",
    unreadFilter: "all",
    campaignSelect: "แคมเปญทั้งหมด",
  };

  campaign = [];

  consultGet = async () => {
    this.setState({ consultGetLoading: true });

    var _response = await Api.consult("TeleconsultConsultAllInCompany", {
      consult_type: "exclude_me",
    }); 
    if (!Array.isArray(_response.list)) _response.list = [];

    var _list = {
      unread: [],
      online: [],
      offline: [],
    };

    this.campaign = ["แคมเปญทั้งหมด"];

    _response.list.forEach((data) => {
      data.online =
        moment().diff(moment(data.datelogin), "second") > 300 ? false : true;

      if (parseInt(data.unread) > 0) {
        data.unread_last_dateadd_timestamp = moment(data.dateadd)
          .toDate()
          .getTime();

        _list.unread.push(data);
      } else {
        if (data.online) {
          _list.online.push(data);
        } else {
          _list.offline.push(data);
        }
      }

      if (Array.isArray(data.campaign) === true) {
        for (var campaign of data.campaign) {
          if (this.campaign.includes(campaign.name) !== true) {
            this.campaign.push(campaign.name);
          }
        }
      }
    });

    _list.unread = SortArray(
      _list.unread,
      "unread_last_dateadd_timestamp",
      true
    );
    _list.online = SortArray(_list.online, "name");
    _list.offline = SortArray(_list.offline, "name");

    var _listSort = [];

    _list.unread.forEach((data) => {
      _listSort.push(data);
    });

    _list.online.forEach((data) => {
      _listSort.push(data);
    });

    _list.offline.forEach((data) => {
      _listSort.push(data);
    });

    this.setState({
      consultList: _listSort,
      consultShowList: _listSort,
      consultGetLoading: false,
      companyName:
        Array.isArray(_response.company) === true
          ? _response.company
              .map((company) => {
                return company.name;
              })
              .join(",")
          : "",
    });
  };

  init = () => {
    this.listenUpdate();

    if (Online_trigger_loop == null) {
      Online_trigger_loop = setInterval(() => {
        this.listenUpdate();
      }, 300000);
    }
  };

  componentDidMount() {
    this.init();
  }

  listenUpdate = () => {
    firebase
      .database()
      .ref("teleconsult/" + this.props.consult.id)
      .on("value", (snapshot) => {
        this.consultGet();
      });
  };

  listFilter = () => {
    let _list = this.state.consultList
      .filter((d) => {
        if (this.state.unreadFilter !== "all" && parseInt(d.unread) === 0)
          return false;

        var _r = false;

        if (!this.state.keyword) return true;

        _r =
          d.name.includes(this.state.keyword) ||
          d.lastname.includes(this.state.keyword);

        if (_r === false) {
          if (Array.isArray(d.campaign)) {
            d.campaign.forEach((campaign) => {
              if (_r === true) return false;

              if (
                this.state.keyword &&
                campaign.name
                  .toLowerCase()
                  .includes(this.state.keyword.toLowerCase()) === true
              ) {
                _r = true;
              }
            });
          }
        }

        return _r;
      })
      .filter((d) => {
        if (this.state.campaignSelect === "แคมเปญทั้งหมด") {
          return true;
        } else {
          var _r = false;
          if (Array.isArray(d.campaign)) {
            d.campaign.forEach((campaign) => {
              if (this.state.campaignSelect === campaign.name) {
                _r = true;
              } else {
                _r = false;
              }
            });
          }

          return _r;
        }
      });

    this.setState({
      consultGetLoading: false,
      consultShowList: _list,
    });
  };

  render() {
    return (
      <div className="uk-padding">
        <div className="uk-card uk-card-default uk-padding-small">
          <div className="d-card-header uk-grid-collapse" uk-grid="">
            <div className="d-card-header-left uk-width-1-1 uk-width-1-3@m">
              Teleconsult ระบบปรึกษาระหว่างบุคลากรทางการแพทย์ หน่วยงาน :{" "}
              {this.state.companyName}
            </div>
            <div className="d-card-header-right uk-width-1-1 uk-width-2-3@m">
              <div className="uk-grid-small" uk-grid="">
                <div className=" uk-width-1-1 uk-width-1-3@m">
                  <select
                    className="uk-select"
                    value={this.state.unreadFilter}
                    onChange={(e) => {
                      this.setState({ unreadFilter: e.target.value }, () => {
                        this.listFilter();
                      });
                    }}
                  >
                    <option value="unread">ข้อความที่ยังไม่ได้อ่าน</option>
                    <option value="all">ข้อความทั้งหมด</option>
                  </select>
                </div>
                <div className="uk-width-1-1 uk-width-1-3@m">
                  <input
                    type="text"
                    className="uk-input"
                    placeholder="ค้นหาชื่อแพทย์หรือแคมเปญ"
                    onChange={(e) =>
                      this.setState(
                        { keyword: e.target.value, consultGetLoading: true },
                        this.listFilter
                      )
                    }
                    value={this.state.keyword}
                  />
                </div>
                <div className="uk-width-1-1 uk-width-1-3@m">
                  <select
                    className="uk-select"
                    defaultValue={this.state.campaignSelect}
                    onChange={(e) =>
                      this.setState(
                        {
                          campaignSelect: e.target.value,
                          consultGetLoading: true,
                        },
                        () => {
                          this.listFilter();
                        }
                      )
                    }
                  >
                    {this.campaign.map((campaign, index) => {
                      return (
                        <option value={campaign} key={campaign + index}>
                          {campaign}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {this.state.consultGetLoading === true ? (
            <div>
              <span uk-spinner=""></span>
            </div>
          ) : (
            <div>
              {this.state.consultShowList.map((consult) => {
                return (
                  <TeleConsultHeader
                    data={consult}
                    consult={this.props.consult}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}
