import ImageUri from './ImageUri';
import React, { Component } from 'react';
import $ from 'jquery';

export default class Media extends Component {
  state = {
  }

  render() {
    if(!this.props.file) return null;

    var _ext = this.props.file.split('?')[0].split('.');

    _ext = _ext[_ext.length - 1];

    var _url = ImageUri(this.props.file);

    switch(_ext) {
      case 'png' :
      case 'jpg' :
      case 'jpeg' :
      case 'png' :
      case 'tiff' :

        return  <span uk-lightbox="">
          <a href={_url}>
            <div className="uk-background-cover uk-background-muted" style={{
            height: 50,
            width: 50,
            display: 'inline-block',
            backgroundImage: `url(${_url})`
          }}></div>
          </a>
        </span>
      break;
      case 'mp4' :
        return <span uk-lightbox="">
        <a className='uk-link-reset' href={_url}>
          <div className="uk-background-cover" style={{
          height: 50,
          width: 50,
          display: 'inline-block',
          overflow: 'hidden',
          position: 'relative'
        }}>
          <span uk-icon="play-circle" className='uk-position-center'></span>
          <video src={_url} width="50" height="50" uk-video="autoplay: false"></video>
        </div>
        </a>
      </span>
      break;
      case 'pdf' :
        return <a className='uk-link-reset' href={_url} rel="noopener" target='_blank' >
          <div className="uk-background-cover" style={{
          height: 50,
          width: 50,
          display: 'inline-block',
          overflow: 'hidden',
          position: 'relative',
          backgroundColor: '#EEE'
        }}>
          <span uk-icon="file-pdf" className='uk-position-center'></span>
        </div>
        </a>
      break;
    }
    
  }
    
    

}
