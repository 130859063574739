import NovalueCheck from './NoValueCheck';

export default (value) => {
  if(NovalueCheck(value)) return NovalueCheck(value);

  value = +value;

  if(value >= 160) {
    return { 
      title: 'คลอเลสเตอรอลสูงมาก',
      color: 'red',
      caption: 'คลอเลสเตอรอลสูงมาก',
      suggest: 'ปรับพฤติกรรม พบนักโภชนาการ หรือ นักกำหนดอาหาร ควบคุมอาหารที่มีไขมัน หรือรับประทานยาลดไขมันกลุ่ม statin อย่างเคร่งครัด'
    };
  } else if(value > 130) {
    return { 
      title: 'คลอเลสเตอรอลสูง',
      color: 'orange',
      caption: 'คลอเลสเตอรอลสูง',
      suggest: 'ปรับพฤติกรรม ควบคุมอาหารที่มีไขมัน หรือรับประทานยาลดไขมันกลุ่ม statin'
    };
  } else if(value > 100) {
    return { 
      title: 'คลอเลสเตอรอลเริ่มสูง',
      color: 'yellow',
      caption: 'คลอเลสเตอรอลเริ่มสูง',
      suggest: 'ปรับพฤติกรรม ควบคุมอาหารที่มีไขมัน หรือรับประทานยาลดไขมันกลุ่ม statin'
    };
  } else {
    return { 
      title: 'คลอเลสเตอรอลปกติ',
      color: 'green',
      caption: 'คลอเลสเตอรอลปกติ',
      suggest: 'รับประทานยาอย่างสม่ำเสมอ รับประทานอาหาร และออกกำลังกายตามปกติ'
    };
  } 
}