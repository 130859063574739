import React, { Component } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';

import Setting from '../../Pages/Admin/Setting';
import Company from '../../Pages/Admin/Company';
import Hospital from '../../Pages/Admin/Hospital';
import Member from '../../Pages/Admin/Member';
import Login from '../../Pages/Admin/Login';
import SideMenu from './SideMenu';
import Data from '../../Pages/Admin/Data';
import Drug from '../../Pages/Admin/Drug';
import Campaign from '../../Pages/Admin/Campaign';
import Consult from '../../Pages/Admin/Consult';
import CampaignDetail from '../../Pages/Admin/CampaignDetail';
import Error404 from '../../Pages/Error404';
import Opnion from '../../Pages/Admin/Opnion';
import AppointmentList from '../../Pages/Admin/AppointmentList.js';
import ConsultStatistic from '../../Pages/Admin/ConsultStatistic.js';
import MemberSummary from '../../Pages/Admin/MemberSummary.js';

import moment from 'moment';

export default class AdminRoute extends Component {

  state = {
    login: false,
    loadingLogin: true
  }

  componentDidMount() {
    this.init();
  }

  componentWillReceiveProps() {
    this.init();
  }

  async init() {
    var result = false;
    try {

      var {result} = await Api.admin('AdminLoginBySession');
    } catch(e) {
      console.error(e);
    }
    this.setState({ login: result === true ? true : false, loadingLogin: false });
  }

  routeRender = () => {
    var routes = {
      "/admin/setting": Setting,
      "/admin/company": Company,
      "/admin/campaignDetail": CampaignDetail,
      "/admin/campaign": Campaign,
      "/admin/consult": Consult,
      "/admin/data": Data,
      "/admin/opnion" : Opnion,
      "/admin/hospital" : Hospital,
      "/admin/drug" : Drug,
      "/admin": Member,
      "/admin/appointmentList": AppointmentList,
      "/admin/consultStatistic": ConsultStatistic,
      "/admin/memberSummary": MemberSummary
    };
    
    var pathname = this.props.location.pathname.split('/').filter(Boolean).join('/');
    var Route = routes[`/${pathname}`] || Error404;
    if(Route) {
      return <Route {...this.props} qs={Api.queryString} />;
    }
    else {
      return <Error404 {...this.props} />;
    }
    
  }


  render() {
    if (this.state.login) {
      return (
        <div uk-grid="" className="uk-grid-collapse">
          <div id="d-side" className="uk-width-1-1">
            <SideMenu {...this.props} />
          </div>
          <div id="d-body" className="uk-width-expand uk-width-1-1 ">
            <div className="uk-padding">
              <div className="uk-padding uk-card uk-card-default">
                {this.routeRender()}
              </div>
            </div>
            <div className="d-footer">
            <div uk-grid="" className="uk-child-width-1-1 uk-child-width-1-2@l">
              <div>
                Copyright © {moment().format('YYYY')} <a className="text-bold-800 grey darken-2" href="https://dietz.asia/" target="_blank" rel="noopener">dietz.asia </a>
              </div>
              <div>
              </div>
            </div>
            </div>
          </div>
        </div>
      )
    } else {
      return this.state.loadingLogin ?
        <div className="uk-text-center uk-padding-small d-page-loadings-login">
          <span uk-spinner=""></span>
        </div>
        :
        <Login {...this.props} />
    }

  }
};
