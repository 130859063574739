import React, { Component } from "react";
import UIkit from "uikit";
import HIVKey from "../HIVKey";
import moment from "moment";

class HIV extends Component {
  state = {
    value: {
      symptom: "",

      continuous_cough_2_week: '',
      blood_cough_1_month: '',
      weight_loss_1_month: '',
      unknow_fever_1_month: '',
      unknow_night_sweating_1_month: '',
      body_rash: '',
      dizziness: '',
      tired: '',
      squeamish: '',
      diarrhea: '',
      yellow_body: '',
      sleepless: '',
      nightmare: '',
      urine_pain: '',
      abnormal_leucorrhoea: '',
      genitalia_pain: '',
      genitalia_wound: '',
      genitalia_itch: '',
      genitalia_swell: '',
      symptom_other_abnormal: '',
      symptom_other_abnormal_detail: '',
      sex_with_no_risk: '',
      feel_bore_in_2_week: "",
      feel_depress_in_2_week: "",
      missing_take_medicine_in_30_days: "",
      partner: "",
      stop_because_worsing_symptom: "",
      stop_take_medicine_when_feel_good: "",
      take_medicine_difficult: "",
      use_drug: "",
      virus_check: "",
      virus_check_amount: "",
      child_plan: "",
      covid_vuccine: ""
    }
  };

  dataTemplate = HIVKey

  checkValueUpdate = (key) => {
    this.setState((state)=>{
      state.value[key] = (this.state.value[key] == null || this.state.value[key] == ''  ||  this.state.value[key] === false ? true : false);

      console.log(state.value);

      return {value: state.value};
    });
  }

  radioValueUpdate = (key, value) => {
    this.setState((state)=>{
      state.value[key] = value;

      return {value: state.value};
    });
  }

  inputValueUpdate = (key, value) => {
    this.setState((state)=>{
      state.value[key] = value;
 
      return {value: state.value};
    });
  }

  inputTypeRender = (option) => {
    if(option.type === 'radio') {
      return <input type="radio" className="uk-radio" name={option.name} checked={this.state.value[option.name]===option.value} onChange={(e)=>{
        this.radioValueUpdate(option.name, option.value);
      }} />
    } else if(option.type === 'checkbox') {
      return <input type="checkbox" className="uk-checkbox" checked={this.state.value[option.key] === true} onChange={(e)=>{
        this.checkValueUpdate(option.key);
      }} />
    } else if(option.type === 'text' || option.type === 'number') {
      return <div>
        <span>{option.label_before}</span>
        <input type={option.type || 'text'} className={"uk-input uk-input-small uk-display-inline-block " + option.input_class } min={0} value={this.state.value[option.key] || ''} onChange={(e)=>{
          this.inputValueUpdate(option.key, e.target.value);
        }} />
        <span>{option.label_after}</span>
      </div>
    } else if(option.type === 'custom') {
      return option.dom;
    } else {
      return null;
    }
  }

  inputMapping = (data) => {
    var _input = [];

    if(Array.isArray(data) === true) {
      data.forEach((input, index) => {
        _input.push(
          <div key={'input'+index} className={input.mode === 'column' ? input.column_width : null}>
            {(input.label || input.type) && (input.type === 'checkbox' || input.type === 'radio' ? 
            <label>{this.inputTypeRender(input)} {input.label} {input.label_more}</label> : 
            <div>{this.inputTypeRender(input)} {input.label} {input.label_more}</div>
            ) }

            {
              input.child && <div style={{marginLeft: 20}} className={input.mode === 'grid' ? 'uk-grid uk-flex-top uk-grid-small' : null}>{this.inputMapping(input.child)}</div>
            }
            {input.text_show === true && <hr />}
          </div>
        );
      })
    }

    return _input;
  }

  inputRender = () => {
    return this.inputMapping(this.dataTemplate);
  }

  notiRender = (data) => {
    var _tag = [];

    if(
      data.continuous_cough_2_week === true ||
      data.blood_cough_1_month === true || 
      data.weight_loss_1_month === true ||  
      data.unknow_fever_1_month === true 
    ) {
      _tag.push('tb');
    }

    if(
      data.body_rash === true ||
      data.dizziness === true || 
      data.tired === true ||  
      data.squeamish === true  ||  
      data.diarrhea === true  ||  
      data.yellow_body === true  ||
      data.sleepless === true  ||
      data.nightmare === true 
    ) {
      _tag.push('adr');

    }

    if(
      data.urine_pain === true ||
      data.abnormal_leucorrhoea === true || 
      data.genitalia_pain === true ||  
      data.genitalia_wound === true ||  
      data.genitalia_itch === true ||  
      data.genitalia_swell === true ||
      data.symptom_other_abnormal === true 
    ) {
      _tag.push('sti');
    }


    var _key_check = ['take_medicine_difficult', 'stop_take_medicine_when_feel_good', 'missing_take_medicine_in_30_days', 'stop_because_worsing_symptom'];

    var _amount_check = 0;

    _key_check.forEach((data) => {
      if(data[data] === '1') {
        _amount_check++;
      }
    });

    if(_amount_check >= 2 || data.missing_take_medicine_once === 'more') {
      _tag.push('adherence');
    }


    if(data.use_drug === '1') {
      _tag.push('narcotic');
    }

    var _vl_alert = true;
    
    if(data.virus_check === 'check' && data.virus_check_amount === 'virus_check_less_50') {
      _vl_alert = false;
    }

    if(_vl_alert === true)
      _tag.push('vl');


    if(
      data.feel_depress_in_2_week === '1' ||
      data.feel_bore_in_2_week === '1'
    ) {
      _tag.push('2q');
    }

    if(
      data.sex_more_than_1_person === true ||
      data.sex_with_disease === true ||
      data.sex_with_no_condom === true
    ) {
      _tag.push('unsafe_sex');
    }
    
    if(
      data.partner === 'partner_not_check'
    ) {
      _tag.push('partner');
    }

    if(
      data.child_plan === '1'
    ) {
      _tag.push('preconception');
    }

    if(
      data.covid_vuccine === '0' || data.covid_vuccine_amount === '1'
    ) {
      _tag.push('covid');
    }

    return _tag;
  }

  dataSaveFilter = (value) => {

      var _data_key = ['continuous_cough_2_week', 'blood_cough_1_month', 'weight_loss_1_month', 'unknow_fever_1_month', 'unknow_night_sweating_1_month', 'body_rash', 'dizziness', 'tired', 'squeamish', 'diarrhea', 'yellow_body', 'sleepless', 'nightmare', 'urine_pain', 'abnormal_leucorrhoea', 'genitalia_pain', 'genitalia_wound', 'genitalia_itch', 'genitalia_swell', 'symptom_other_abnormal', 'symptom_other_abnormal_detail'];

      if(value.symptom === '0') {
        _data_key.forEach((key) => {
          if(value[key] != null || value[key] != '') delete value[key];
        });
      } else if(value.symptom === '1') {
        _data_key.forEach((key) => {
          if(value[key] === false || value[key] === '') delete value[key];
        });
      }
      
      var _data_key = ['missing_take_medicine_once', 'missing_because_bore', 'missing_because_no_time', 'missing_because_busy_with_work', 'missing_because_lost', 'missing_because_share','missing_because_no_inspiration','missing_because_dispirited','missing_because_strong','missing_because_other','missing_because_other_detail'];

      if(value.missing_take_medicine_in_30_days === '0') {
        _data_key.forEach((key) => {
          if(value[key] != null || value[key] != '') delete value[key];
        });
      } else if(value.missing_take_medicine_in_30_days === '1') { 
        _data_key.forEach((key) => {
          if(value[key] === false || value[key] === '') delete value[key];
        });
      }

      var _data_key = ['sex_more_than_1_person', 'sex_with_disease', 'sex_with_no_condom', 'sex_with_no_risk', 'sex_with_same_gender', 'sex_with_opposite_gender','sex_with_bi','sex_not_answer'];
      
      _data_key.forEach((key) => {
        if(value[key] === false || value[key] === '') delete value[key];
      });

      if(value.virus_check !== 'check') {
        if(value.virus_check_amount != null || value.virus_check_amount == '') delete value.virus_check_amount;
      }

      if(value.covid_vuccine === '0') {
        if(value.covid_vuccine_amount != null || value.covid_vuccine_amount == '') delete value.covid_vuccine_amount;
      }

    return value;
  }

  callback = () => {
    var _value = {};

    try {

      for(var key in this.state.value) {
        _value[key] = this.state.value[key];
        
      }

      if(_value.take_medicine_difficult == null || _value.take_medicine_difficult == '') {
        throw new Error("โปรดตอบคำถาม ท่านมีความรู้สึกว่าการกินยาต้านไวรัสให้ถูกต้องตามปริมาณและตามเวลาที่แพทย์สั่งเป็นเรื่องยาก");
      }

      if(_value.stop_take_medicine_when_feel_good == null || _value.stop_take_medicine_when_feel_good == '') {
        throw new Error("โปรดตอบคำถาม เมื่อท่านมีอาการดีขึ้นแล้ว ท่านจะหยุดกินยาต้านไวรัสใช่หรือไม่");
      }
  
      if(
        (_value.how_many_tablet_per_day == null || _value.how_many_tablet_per_day == '') 
        ||
        (
          (_value.how_many_tablet_are_left == null || _value.how_many_tablet_are_left == '') &&
          (_value.how_many_day_are_left == null || _value.how_many_day_are_left == '')
        )
      ) {
          throw new Error("โปรดกรอกจำนวนยาต้านไว้รัสที่เหลือ");
      }

      
      
      _value = this.dataSaveFilter(_value);
  
      var _noti = this.notiRender(_value);
  
      if(Array.isArray(_noti)) {
        _noti = _noti.join(',')
      } else {
        _noti = '';
      }
       
  
      if(typeof this.props.callback === 'function') {
        console.log({
          value: _value,
          noti: _noti,
          text: this.textRender(_value) 
        })

        this.props.callback({
          value: _value,
          noti: _noti,
          text: this.textRender(_value) 
        })
      }

    } catch(e) {

      UIkit.notification(e.message);
    }
  }

  textRender = (value) => {
    var _text = '';

    var _add_text = (arr) => {

      arr.forEach((data)=>{
        if(
          data.text_show === true || 
          (data.type === 'radio' && value[data.name] === data.value) || 
          (data.type === 'checkbox' && value[data.key] === true) ||
          ((data.type === 'number' || data.type === 'text') && value[data.key])
        ) {

            var _value = data.label;

            if(data.type === 'number' || data.type === 'text') {
              _value = value[data.key]
            }
          
            _text += `${data.text_prefix || ''} ${_value}${data.text_suffix || ''}
            `;
        }

        if(Array.isArray(data.child) === true) {
          _add_text(data.child);
        }
      });
    }

    _add_text(this.dataTemplate);

    _text = `<div class="d-hiv-consult"><b>ชื่อบุคลากรทางการแพทย์ :</b> ${this.props.consult.name}
    <b>เลขบัตรประชาชน :</b> ${this.props.member.nid}
    <b>ชื่อคนไข้ :</b>  ${this.props.member.firstname}
    <b>นามสกุลคนไข้ :</b>  ${this.props.member.lastname}</div>
    <div class="d-hiv-form"><b><u>แบบประเมินตนเองก่อนเข้าพบแพทย์</u></b>
    ${_text}</div>`


    return _text;
  }

  render() {
    return <div >
      <h1 className="uk-h2 uk-text-center">แบบประเมินตนเองก่อนเข้าพบแพทย์ (For Telehealth Application)</h1>
      <p className="d-text-red uk-text-center">คำชี้แจง คำตอบของท่านจะถูกเก็บเป็นความลับ และใช้ในการดูแลรักษาและพัฒนาระบบบริการเท่านั้น โปรดเลือกคำตอบที่ตรงกับท่านมากที่สุด</p>
      <div className="uk-padding d-border d-form-healthnot">{this.inputRender()}</div>
      <div className="uk-margin-top uk-text-right">
        <button type="button" className="uk-button uk-button-primary" onClick={this.callback} >บันทึก</button>
      </div>
    </div>
  }
}

export default HIV;