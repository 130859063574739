import NovalueCheck from './NoValueCheck';

export default (value) => {
  if(NovalueCheck(value)) return NovalueCheck(value);

  value = +value;

  if(value < 120) {
    return { 
      title: 'การทำงานของตับปกติ',
      color: 'green',
      caption: 'การทำงานของตับปกติ',
      suggest: ''
    };
  } else {
    return { 
      title: 'การทำงานของตับผิดปกติ',
      color: 'orange',
      caption: 'ค่า ALP สูงกว่าปกติ',
      suggest: 'ปรับพฤติกรรม พบนักโภชนาการ หรือ นักกำหนดอาหาร'
    };
  }

}