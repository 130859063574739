import NovalueCheck from './NoValueCheck';

export default (value) => {
  if(NovalueCheck(value)) return NovalueCheck(value);

  if(value.indexOf('*/') >= 0) {
    value = value.split('*/');
  } else {
    value = value.split('/');
  }
  
  value[0] = +value[0];
  value[1] = +value[1];

  if(value[0] >= 140 && value[1] < 90) {
    return { 
      title: 'ความดันสูงเฉพาะค่าตัวบน',
      color: 'orange',
      caption: 'ความดันสูงเฉพาะค่าตัวบน',
      suggest: 'ควรปรึกษาแพทย์เพื่อรักษา หรือรับประทานยาอย่างสม่ำเสมอ'
    };
  } else if(value[0] < 120 && value[1] < 80) {
    return { 
      title: 'ความดันเหมาะสม',
      color: 'green',
      caption: 'ความดันเหมาะสม',
      suggest: 'ควบคุมอาหาร ลดเค็ม และออกกำลังกาย'
    };
  } else if(value[0] < 130 && value[1] < 85) {
    return { 
      title: 'ความดันปกติ',
      color: 'green',
      caption: 'ความดันปกติ',
      suggest: 'ควบคุมอาหาร ลดเค็ม และออกกำลังกาย'
    };
  } else if(value[0] < 140 && value[1] < 90) {
    return { 
      title: 'ความดันเริ่มสูงกว่าปกติ',
      color: 'yellow',
      caption: 'ความดันปกติ',
      suggest: 'ควบคุมอาหาร ลดเค็ม และออกกำลังกาย'
    };
  } else if(value[0] < 160 && value[1] < 100) {
    return { 
      title: 'ความดันสูง',
      color: 'orange',
      caption: 'ความดันสูงกว่าปกติ',
      suggest: 'ควบคุมอาหาร ลดเค็ม และออกกำลังกาย ปรึกษาแพทย์เพื่อรักษา'
    };
  } else if(value[0] < 160 && value[1] < 100) {
    return { 
      title: 'ความดันสูง',
      color: 'orange',
      caption: 'ความดันสูงกว่าปกติ',
      suggest: 'ควบคุมอาหาร ลดเค็ม และออกกำลังกาย ปรึกษาแพทย์เพื่อรักษา'
    };
  } else if(value[0] < 180 && value[1] < 110) {
    return { 
      title: 'ความดันสูงมาก',
      color: 'orange',
      caption: 'ความดันสูงมากอาจเป็นอันตรายหากไม่ได้รับการรักษา',
      suggest: 'ควรปรึกษาแพทย์เพื่อรักษา หรือรับประทานยาอย่างสม่ำเสมอ'
    };
  } else if(value[0] >= 180 && value[1] >= 110) {
    return { 
      title: 'ความดันสูงวิกฤติ',
      color: 'red',
      caption: 'ความดันสูงมากอาจเป็นอันตรายหากไม่ได้รับการรักษา',
      suggest: 'ควรปรึกษาแพทย์เพื่อรักษา หรือรับประทานยาอย่างสม่ำเสมอ'
    };
  }
}