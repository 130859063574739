import React, { Component } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import HeaderSub from '../../Components/Member/HeaderSub';
import UserProfileCard from '../../Components/Member/UserProfileCard';
import UserHealthRecord from '../../Components/Member/UserHealthRecord';
import UserStatistic from '../../Components/Member/UserStatistic';
import UserMeal from '../../Components/Member/UserMeal';
import UserLastestAdviceByDietitian from '../../Components/Member/UserLastestAdviceByDietitian';
import UserSmartHealthSmartHealthRecord from '../../Components/Member/UserSmartHealthSmartHealthRecord';
import UserSmartHealthRiskMetric from '../../Components/Member/UserSmartHealthRiskMetric';
import UserHealthBook from '../../Components/Member/UserHealthBook';
//import UserDataUpload from '../../Components/Member/UserDataUpload';
import MemberCampaignTable from '../../Components/Member/MemberCampaignTable.js';

import Formular from '../../Components/Formular.js';
import DataType from '../../Components/DataType.js';

UIkit.use(Icons);


export default class User extends Component {
  componentDidMount() {
    this.dataGet();
  }

  state = {
    data: [],
    loadingData: true
  }


  async dataGet() {
    var { data, query } = await Api.member('UserSmartHealthRecord');

    if (!data) data = [];
    var _data = [];

    for (var i = data.length; i-- > 0;) {
      if (DataType.indexOf(data[i].name) >= 0) {
        data[i].detail = Formular[data[i].name](data[i].value, data[i].age, data[i].gender);
        _data.push(data[i]);
      } else {
        _data.push(data[i]);
      }
    }

    this.setState({ data: _data || [], loadingData: false });
  }

  userSmartHealthRender = () => {
    return <div className="uk-margin-top">
      {this.state.loadingData ?
        <div className="uk-text-center uk-padding"><span uk-spinner=""></span></div>
        :
        <div>
          <div>
            <UserSmartHealthSmartHealthRecord data={this.state.data} dataGet={this.dataGet} />
          </div>
          {
          <div className="uk-margin-top">
            {/*<UserDataUpload />*/}
          </div>
      }
          <div className="uk-margin-top">
            <UserSmartHealthRiskMetric data={this.state.data} dataGet={this.dataGet} />
          </div>
        </div>
      }
    </div>
  }

  render() {
    return <div className="uk-padding">
      <HeaderSub title="USER" />
      <div className="uk-margin-small-top">
        <div uk-grid="">
          <div className="uk-width-1-1 uk-width-4-5@l">
            <div className="uk-grid-small uk-child-width-1-2@m uk-child-width-1-1" uk-grid="">
              <div>
                <UserProfileCard />
                {this.state.loadingData === false && <div className="uk-margin-top">
                  <UserHealthBook data={this.state.data} />
                </div>
                }
              </div>
              <div>
                <MemberCampaignTable />
                <UserStatistic data={this.state.data} />
              </div>
            </div>
            {this.userSmartHealthRender()}
            <div className="uk-margin-top">
              <UserHealthRecord />
            </div>
            <div className="uk-margin-top">
              <UserMeal />
            </div>
          </div>
          <div className="uk-width-1-1 uk-width-1-5@l">
              <UserLastestAdviceByDietitian />
          </div>
        </div>
      </div>
    </div>
  }
};
