import React, { Component } from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import ReactEcharts from 'echarts-for-react';
import DataTypeSelect from '../DataTypeSelect';
import Api from '../Api';
import Formular from '../Formular';
import FullScreenElement from '../FullScreenElement';

UIkit.use(Icons);

export default class SmartHealthHealthRisk extends Component {
  state = {
    selectDataType: 'bmi',
    yearGraph: [],
    employeeAmount: [],
    employeeRisk: [],
    loadingApi: true,
    user: 0
  }

  componentDidMount() {
    this.dataGet();
  }
  
  userGet = async () => {
    var {user} = await Api.member('SmartHealthUser');
    this.setState({user});
  }

  dataGet = async () => {
    this.setState({loadingApi: true});

    await this.userGet();

    var { data , yearGraph} = await Api.member('SmartHealthHealthRisk', { selectDataType: this.state.selectDataType });

    var _dataGraph = [];
    var _employeeAmount = [];
    var _employeeRisk = [];

    for (var i = data.length; i-- > 0;) {
      if (data[i]) {
        _employeeAmount.push(data[i].length);

        var _riskAmount = 0;
        
        for (var i1 = data[i].length; i1-- > 0;) {
          var _value = Formular[this.state.selectDataType](data[i][i1].value);

          if(_value && _value.color !== 'green') _riskAmount++;
        }

        _employeeRisk.push(_riskAmount);

      } else {
        _employeeAmount.push(0);
        _employeeRisk.push(0);
        
      }
    }

    this.setState({
      yearGraph: yearGraph ? yearGraph.reverse() : [] ,
      employeeAmount: _employeeAmount,
      employeeRisk: _employeeRisk,
      loadingApi: false
    });
  }

  graphRender = () => {

    var option = {

      // Setup grid
      grid: {
        x: 40,
        x2: 20,
        y: 70,
        y2: 30
      },

      // Add tooltip
      tooltip: {
        trigger: 'axis'
      },

      // Add legend
      legend: {
        data: [
          'ความเสี่ยง',
          'จำนวนพนักงานที่ตรวจ',
          'จำนวนพนักงานทั้งหมด'
        ]
      },

      // Enable drag recalculate
      calculable: true,

      // Horizontal axis
      xAxis: [{
        type: 'category',
        data: this.state.yearGraph,
        z: 10,
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      },
      {
        type: 'category',
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: { show: false },
        splitArea: { show: false },
        splitLine: { show: false },
        data: this.state.yearGraph
      },
      {
        type: 'category',
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: { show: false },
        splitArea: { show: false },
        splitLine: { show: false },
        data: this.state.yearGraph
      }
      ],

      // Vertical axis
      yAxis: [{
        type: 'value',
        axisLabel: { formatter: '{value}' },
        boundaryGap: [0, 1],
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      }],

      // Add series
      series: [
        {
          name: 'จำนวนพนักงานทั้งหมด',
          type: 'bar',
          xAxisIndex: 0,
          itemStyle: { normal: { color: 'rgb(0, 181, 184)', label: { show: true, position: 'top' } } },
          data: this.state.yearGraph.map((year)=>this.state.user) //return only user amount
        },
        {
          name: 'จำนวนพนักงานที่ตรวจ',
          type: 'bar',
          xAxisIndex: 1,
          itemStyle: { normal: { color: '#CCC', label: { show: true, position: 'top' } } },
          data: this.state.employeeAmount
        },
        {
          name: 'ความเสี่ยง',
          type: 'bar',
          xAxisIndex: 2,
          itemStyle: { normal: { color: 'rgba(253,57,122,1)', fontWeight:'bold', label: { show: true, position: 'top', formatter: function (p) { return p.value > 0 ? (p.value + '\n') : ''; } } } },
          data: this.state.employeeRisk
        },
      ]
    };

    return   <ReactEcharts
      option={option}
      style={{height: 400}}
      notMerge={true}
      lazyUpdate={true}
    />
  }

  render() {
    return <div className="uk-card uk-card-default uk-padding-small" id="d-smarth-health-health-risk">
      <div className="d-card-header">
        <div className="d-card-header-left">
          HEALTH RISK
        </div>
        <div className="d-card-header-right">
          <span className='icon-reload' onClick={this.dataGet}></span>
          <FullScreenElement element="#d-smarth-health-health-risk" />
        </div>
      </div>
      <div className="uk-text-right uk-margin-top uk-margin-bottom">
        <DataTypeSelect notSelectCategory={true} value={this.state.selectDataType} callback={(value)=>{
          this.setState({ selectDataType: value }, ()=>this.dataGet());

        }} />
      </div>
      {
        this.state.loadingApi === true ?
        <div className="uk-padding uk-text-center"><span uk-spinner=""></span></div>
        :
        this.graphRender()
      }
    </div>
  }
};
