import React, { Component } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import DateTimePicker from "../../Components/DatePicker.js";
import moment from 'moment';
UIkit.use(Icons);

export default class AdminAppointmentList extends Component {
  state = {
    list: [],
    dateStart: moment().subtract(30, 'days'),
    dateStop: moment().add(1, 'days'),
    loadingApi: false,
    companyList : [],
    companySelect: '',
    campaignList: [],
    campaignSelect: ''
  }

  memberById = {} ;

  consultById ={} ;

  listGet = async  () => {
    this.setState({loadingApi: true});

    var {list, consult, member} = await Api.admin("AppointmentList", {
      dateStart: moment(this.state.dateStart).format('YYYY-MM-DD 00:00:00'),
      dateStop: moment(this.state.dateStop).format('YYYY-MM-DD HH:mm:ss'),
      campaignId: this.state.campaignSelect,
      companyId: this.state.companySelect
    });

    if(!list) list = [];
    if(!member) member = [];
    if(!consult) consult = [];

    member.forEach((data) => {
      this.memberById[data.id] = data;
    });

    consult.forEach((data) => {
      this.consultById[data.id] = data;
    });


    this.setState({
      list,
      loadingApi: false
    });
  }

  companySelectRender = () => {
    return <select className="uk-select" value={this.state.companySelect} onChange={(e)=>{
      this.setState({companySelect: e.target.value}, ()=>{
        this.campaignListGet();
      });
    }}>{this.state.companyList.map((data, key)=>{
      return <option key={`company${key}`} value={data.id}>{data.name}</option>
    })}</select>
  }

  companyListGet = async () => {
    var _response = await Api.admin('CompanyList');

    if(!_response.list) _response.list = [];

    _response.list.push({
      id: '',
      name: 'ทั้งหมด'
    });

    this.setState({companyList: _response.list, companySelect: ''}, ()=>{
      this.campaignListGet();
    });
  }


  campaignSelectRender = () => {
    return <select className="uk-select" value={this.state.campaignSelect} onChange={(e)=>{
      this.setState({campaignSelect: e.target.value}, ()=>{
        this.listGet();
      });
    }}>{this.state.campaignList.map((data, key)=>{
      return <option key={`campaign${key}`} value={data.id}>{data.name}</option>
    })}</select>
  }


  campaignListGet = async () => {
    var _response = await Api.admin('CampaignListByCompanyId', {
      companyId: this.state.companySelect
    });

    if(!_response.list) _response.list = [];

    _response.list.push({
      id: "",
      name: "ทั้งหมด"
    });

    this.setState({
      campaignList: _response.list,
      campaignSelect: ""
    }, ()=>{
      this.listGet();
    })
  }

  componentDidMount() {
    this.companyListGet();
  }

  render() {
    return <div>
        <div className="d-card-header uk-margin-bottom">ตารางนัดภาพรวมและของตนเอง</div>
        <div uk-grid="" className="uk-flex-middle uk-grid-small">
          <div className="uk-width-1-5@l uk-width-1-1">
              <DateTimePicker id="dateStart" date={this.state.dateStart} callback={(value)=>this.setState({dateStart: value})}/>
          </div>
          <div className="uk-width-1-5@l uk-width-1-1">
              <DateTimePicker id="dateStop" date={this.state.dateStop} callback={(value)=>this.setState({dateStop: value})} />
            
          </div>
          <div className="uk-width-expand@l uk-width-1-1">
            {this.companySelectRender()}
          </div>
          <div className="uk-width-1-5@l uk-width-1-1">
            {this.campaignSelectRender()}
          </div>
          <div className="uk-width-auto@l uk-width-1-1">
            <button className="uk-button uk-button-primary" onClick={this.listGet}>ค้นหา</button>
          </div>
        </div>

        <hr />
        <div className="uk-overflow-auto">
      
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
            <thead>
              <tr>
                <th>ที่</th>
                <th>วันที่</th>
                <th>เวลา</th>
                <th>ชื่อคนไข้</th>
                <th>นัดหมายเพื่อ</th>
                <th>ผู้นัดหมาย</th>
                <th>โรงพยาบาล</th>
                <th>แคมเปญ</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.loadingApi === true ?
                <tr>
                  <td colSpan="100%">
                    <div className="uk-padding-small uk-text-center"><span uk-spinner=""></span></div>
                  </td>
                </tr>
                :
                this.state.list.length === 0 ? 
                <tr>
                  <td colSpan="100%">
                    <div className="uk-padding-small uk-text-center">ไม่พบข้อมูล</div>
                  </td>
                </tr>
                :

                this.state.list.map((data, key)=>{

                  var _member = this.memberById[data.memberid];

                  if(_member) {
                    _member = `${_member.nameprefix} ${_member.name} ${_member.lastname}`;
                  } else {
                    _member = '-';
                  }

                  var _consult = this.consultById[data.consultid];
                  if(_consult) {
                    _consult = `${_consult.nameprefix} ${_consult.name} ${_consult.lastname}`;
                  } else {
                    _consult = '-';
                  }


                  return <tr key={`row${data.id}`}>
                    <td>{key+1}</td>
                    <td>
                      {moment(data.dateadd).format('DD/MM/YYYY')}
                    </td>
                    <td>
                      {moment(data.dateadd).format('HH:mm น.')}
                    </td>
                    <td>{_member}</td>
                    <td>
                      {data.name}
                    </td>
                    <td>{_consult}</td>
                    <td>{data.companyName}</td>
                    <td>{data.campaignName}</td>
                  </tr>
                })
              }
            </tbody>
          </table>
        </div>
      </div>
  }
};
