import NovalueCheck from './NoValueCheck';

export default (value) => {
  if(NovalueCheck(value)) return NovalueCheck(value);

  value = +value;

  if(value > 500) {
    return { 
      title: 'ไตรกลีเซอไรด์สูงมาก',
      color: 'red',
      caption: 'ไตรกลีเซอไรด์สูงมาก มีความเสี่ยงกลุ่มโรคเมตาบอลิก',
      suggest: 'ปรับพฤติกรรม พบนักโภชนาการ หรือ นักกำหนดอาหาร ควบคุมอาหารกลุ่มคาร์โบไฮเดรต ข้าว แป้ง และน้ำตาล ร่วมกับรับประทานยากลุ่ม fibrate'
    };
  } else if(value > 250) {
    return { 
      title: 'ไตรกลีเซอไรด์สูง',
      color: 'orange',
      caption: 'ไตรกลีเซอไรด์สูง มีความเสี่ยงกลุ่มโรคเมตาบอลิก',
      suggest: 'ปรับพฤติกรรม พบนักกำหนดอาหารควบคุมอาหารกลุ่มคาร์โบไฮเดรต ข้าว แป้ง และน้ำตาล'
    };
  } else if(value > 150) {
    return { 
      title: 'ไตรกลีเซอไรด์เริ่มสูง',
      color: 'yellow',
      caption: 'ไตรกลีเซอไรด์เริ่มสูง มีความเสี่ยงกลุ่มโรคเมตาบอลิก',
      suggest: 'ปรับพฤติกรรม ควบคุมอาหารกลุ่มคาร์โบไฮเดรต ข้าว แป้ง และน้ำตาล'
    };
  } else {
    return { 
      title: 'ไตรกลีเซอไรด์ปกติ',
      color: 'green',
      caption: 'ไตรกลีเซอไรด์ปกติ',
      suggest: 'รับประทานยาอย่างสม่ำเสมอ รับประทานอาหาร และออกกำลังกายตามปกติ'
    };
  }
}