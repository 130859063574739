import React, { Component } from 'react';
import Api from '../Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import moment from 'moment';
import numeral from 'numeral';
import DateTimePicker from '../../Components/DatePicker.js';
import ImageUri from '../ImageUri';
import $ from 'jquery';
import OpenApiSuffix from './SMCOpenApiSuffix.js'
UIkit.use(Icons);

export default class SMCPayment extends Component {
  state = {
    
    dateStart: moment().subtract(1, 'M').startOf('d'),
    dateStop: moment().add(1, 'y'),
    group: 'all',
    list: [],
    listUse: [],
    imagePreview: null,
    paymentStatus: 'รับยอดโอน',
    paymentStatusNote: '0',
    paymentPopupShow: false,
    paymentPopupTitle: '',
    paymentPopupId: null,
    paymentPopupCampaignId: null,

    filterPaymentWait: true,
    filterPaymentReject: true,
    filterPaymentAprove: true,

    filterSendMoneyWait: true,
    filterSendMoneySuccess: true
  }

  loadingUpdateStatus = false

  paymentStatusNote = {
    '0': 'สลิปไม่ถูกต้อง',
    '1': 'ยอดเงินที่โอนไม่ตรงกับยอดแจ้งค่าใช้จ่าย'
  }

  componentDidMount() {
    this.paymentListGet();
  }

  paymentListGet = async () => {
    var _response = await Api.consult('SMCPaymentList', {
      group: this.state.group,
      dateStart: moment(this.state.dateStart).format('YYYY-MM-DD'),
      dateStop: moment(this.state.dateStop).format('YYYY-MM-DD'),
    });

    var _list = _response.list || [];

    for (var list of _list) {
      try {

        list.image = JSON.parse(list.image);
      } catch (e) {
        list.image = [];
      }
    }

    this.setState({ list: _response.list || [] }, () => {
      this.paymentListUseRender()
    });

  }

  paymentListUseRender = () => {
    this.setState({
      listUse: this.state.list.filter((data) => {
        var _use_data = false;

        if (this.state.filterPaymentAprove === true && data.status.includes(`รับยอด`)) _use_data = true;

        if (this.state.filterPaymentReject === true && data.status.includes(`ปฏิเสธ`)) _use_data = true;

        if (this.state.filterPaymentWait === true && data.status.includes(`รอการเงิน`)) _use_data = true;

        var _use_sendmoney = false;

        if (this.state.filterSendMoneySuccess === true && data.invoicestatus == '1') _use_sendmoney = true;

        if (this.state.filterSendMoneyWait === true && data.invoicestatus == '0') _use_sendmoney = true;

        return _use_data && _use_sendmoney;
      })
    })
  }

  paymentStatusUpdate = async () => {
    if(this.loadingUpdateStatus !== false) return;

    this.loadingUpdateStatus = true; 
    var _api = OpenApiSuffix[this.state.paymentPopupCampaignId];


    var _response = await Api.consult('SMCPaymentStatusUpdate', {
      id: this.state.paymentPopupId,
      status: this.state.paymentStatus === 'รับยอดโอน' ? this.state.paymentStatus : `${this.state.paymentStatus} ${this.paymentStatusNote[this.state.paymentStatusNote]}`,
      prefix: _api.prefix,
      version: _api.version
    });

    this.setState({
      paymentPopupId: null,
      paymentPopupShow: false
    }, this.paymentListGet)

    this.loadingUpdateStatus = false;
  }

  invoiceStatusUpdate = async (id, status) => {
    var _response = await Api.consult('SMCPaymentInvoiceStatusUpdate', {
      id: id,
      status: status
    });
  }


  invoiceDatepayUpdate = async (id, value) => {
    var _response = await Api.consult('SMCPaymentDatepayUpdate', {
      id: id,
      datepay: value
    });
  }

  invoiceTimepayUpdate = async (id, value) => {
    var _response = await Api.consult('SMCPaymentTimepayUpdate', {
      id: id,
      timepay: value
    });
  }

  invoicePayamountUpdate = async (id, value) => {
    var _response = await Api.consult('SMCPaymentPayamountUpdate', {
      id: id,
      payamount: value
    });
  }

  rowRender = () => {
    if (this.state.listUse.length === 0) {
      return <tr>
        <td colSpan={'100%'}>
          <div className='uk-padding-small uk-text-center'>ไม่พบรายการ</div>
        </td>
      </tr>
    }
    else {
      return this.state.listUse.map((data, index) => {

        return <tr key={`row${data.id}`} >
          <td>{index + 1}</td>
          <td><div>{data.detail.date}</div><div>เวลา {data.detail.time}</div></td>
          <td>
                {

                  data.paymenttype === 'fee' ?
                  <div><b>ยอดโอนมัดจำ</b></div>
                  :
                  <div><b>ยอดโอนคนไข้</b></div>
                }{
            data.datepay_edit === true ?
              <div>
                <input type="date" className='uk-input' value={data.datepay} onChange={(e) => {
                  data.datepay = e.target.value;

                  this.forceUpdate();
                }} onBlur={() => {

                  this.invoiceDatepayUpdate(data.id, data.datepay);

                  data.datepay_edit = false;

                  this.forceUpdate();
                }} />
              </div>
              :
              <div>
                {moment(data.datepay, "YYYY-MM-DD").format('DD/MM/YYYY')} <a uk-icon="pencil" onClick={() => {
                  data.datepay_edit = true;
                  this.forceUpdate();
                }}></a>
              </div>
          }

          </td>
          <td>{
            data.timepay_edit === true ?
              <div>

                <input type="time" className='uk-input' value={data.timepay} onChange={(e) => {
                  data.timepay = e.target.value;

                  this.forceUpdate();
                }} onBlur={() => {
                  this.invoiceTimepayUpdate(data.id, data.timepay);

                  data.timepay_edit = false;

                  this.forceUpdate();
                }} />
              </div>
              :
              <div>
                {data.timepay} <a uk-icon="pencil" onClick={() => {
                  data.timepay_edit = true;
                  this.forceUpdate();
                }}></a>
              </div>
          }
          </td>
          <td>{data.image.map((path) => {
            return <a onClick={() => this.setState({ imagePreview: ImageUri(path) })}>
              <img src={ImageUri(path)} width={100} uk-img="" />
            </a>
          })}</td>
          <td>{
            data.paymenttype === 'fee' ?
              numeral(data.price).format('0,0.00')
              :
              data.payamount_edit === true ?
                <div>
                  <input type="number" className='uk-input' value={data.payamount} onChange={(e) => {
                    data.payamount = e.target.value;

                    this.forceUpdate();
                  }} onBlur={() => {
                    this.invoicePayamountUpdate(data.smcid, data.payamount);

                    data.payamount_edit = false;

                    this.forceUpdate();
                  }} />
                </div>
                :
                <div>
                  {numeral(data.payamount).format('0,0.00')} <a uk-icon="pencil" onClick={() => {
                    data.payamount_edit = true;
                    this.forceUpdate();
                  }}></a>
                </div>
          }</td>
          <td>{data.detail.patient}</td>
          <td>{data.token.hn}</td>
          <td>{data.status === 'รอการเงินดำเนินการ' ? <a onClick={() => this.setState({
            paymentPopupTitle: `คนไข้ ${data.detail.patient} วันที่ ${moment(data.datepay, "YYYY-MM-DD").format('DD/MM/YYYY')} ยอด ${numeral(data.payamount).format('0,0.00')}`,
            paymentPopupCampaignId: data.campaignid,
            paymentPopupId: data.id,
            paymentPopupShow: true
          })}>{data.status}</a> : data.status}</td>
          <td className='uk-text-left'>
            <div><label><input type="radio" checked={data.invoicestatus == '0'} onChange={() => {
              data.invoicestatus = '0';
              this.invoiceStatusUpdate(data.id, '0')
              this.forceUpdate();
            }} /> ยังไม่ส่งการเงิน</label></div>
            <div><label><input type="radio" checked={data.invoicestatus == '1'} onChange={() => {
              data.invoicestatus = '1';

              this.invoiceStatusUpdate(data.id, '1')
              this.forceUpdate();
            }} /> ส่งการเงินออกใบเสร็จแล้ว</label></div>
          </td>
        </tr>
      })

    }
  }

  CsvExport = () => {
    var _csv = [];

    _csv.push(`"ลำดับ","วันที่พบแพทย์","วันที่โอนเงิน","เวลาที่โอนเงิน","จำนวนเงินที่โอน","ชื่อ-สกุล คนไข้","HN","การดำเนินงาน","สถานะการส่งเงิน"`);

    if (this.state.listUse.length) {
      this.state.listUse.forEach((data, index) => {
        _csv.push(`"${index + 1}","${data.detail.date} เวลา ${data.detail.time}","${moment(data.datepay, "YYYY-MM-DD").format('DD/MM/YYYY')}","${data.timepay}","${numeral(data.payamount).format('0,0.00')}","${data.detail.patient}","${data.token.hn}","${data.status}","${data.invoicestatus == '1' ? 'ส่งการเงินออกใบเสร็จแล้ว' : 'ยังไม่ส่งการเงิน'}"`);
      })
    }


    var a = window.document.createElement('a');
    a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent("\uFEFF" + _csv.join('\r\n')));
    a.setAttribute('download', 'report.csv');
    window.document.body.appendChild(a);
    a.click();
  }

  Print = () => {
    console.log(this.state.listUse)
    $("#d-print-container").remove();

    var _row = [];

    this.state.listUse.forEach(function (data) {

      _row.push(`<div style="break-after:page; font-size: 24px;">
        <div><b>วันที่พบแพทย์ :</b> ${data.detail.date} เวลา ${data.detail.time} </div>
        <div><b>ชื่อ :</b> ${data.detail.patient} [HN : ${data.token.hn}, UID : ${data.detail.uid}]</div>
        <br />
        <div>${data.image.map(function (path) {
        return `<img src=${ImageUri(path)} width="400" />`
      }).join('')}</div>
      </div>`);
    });

    $("#root").prepend(
      $('<div id="d-print-container">').html(_row.join(''))
    );

    window.print();

    $("#d-print-container").remove();
  }

  render() {
    return <div>
      {this.state.paymentPopupShow && <div className="d-popup-background">
        <div className="d-popup-body d-popup-body-small">
          <span className='uk-float-right' uk-close="" onClick={() => this.setState({ paymentPopupShow: false })}></span>
          <h3 className='uk-margin-remove'>การดำเนินงาน</h3>
          <div className='d-border uk-margin-small-top uk-padding-small'>
            <p>{this.state.paymentPopupTitle}</p>
            <div className='uk-margin-small-top'>
              <label>
                <input type="radio" name="payment_confirm" checked={this.state.paymentStatus === 'รับยอดโอน'} onChange={(e) => this.setState({ paymentStatus: 'รับยอดโอน' })} />
                <span> รับยอดโอน</span>
              </label>
            </div>
            <div className='uk-margin-top'>
              <div>
                <label>
                  <input type="radio" name="payment_confirm" checked={this.state.paymentStatus === 'ปฏิเสธยอดโอน'} onChange={(e) => this.setState({ paymentStatus: 'ปฏิเสธยอดโอน' })} /> <span> ปฏิเสธยอดโอน ระบุ</span>
                </label>
              </div>
              {
                this.state.paymentStatus === 'ปฏิเสธยอดโอน' &&
                <div className="uk-margin-left">
                  <div>
                    <label>
                      <input type="radio" name="payment_confirm_note" checked={this.state.paymentStatusNote === '0'} onChange={(e) => this.setState({ paymentStatusNote: '0' })} /> {this.paymentStatusNote['0']}
                    </label>
                  </div>
                  <div>
                    <label>
                      <input type="radio" name="payment_confirm_note" checked={this.state.paymentStatusNote === '1'} onChange={(e) => this.setState({ paymentStatusNote: '1' })} /> {this.paymentStatusNote['1']}
                    </label>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className='uk-margin-top uk-text-right'>
            <a className='uk-margin-right' onClick={() => this.setState({ paymentPopupShow: false })}>ยกเลิก</a>
            <button className='uk-button uk-button-primary' onClick={() => this.paymentStatusUpdate()} >ยืนยัน</button>
          </div>
        </div>
      </div>}
      <div className="uk-card uk-card-default uk-padding-small">
        <div className="d-card-header">
          <div uk-grid="">
            <div className='uk-width-1-2'>รายงานการเงิน</div>
            <div className='uk-width-1-2 uk-text-right'>
              <button className='uk-button d-text-white uk-button-primary' onClick={this.Print}><span uk-icon="print"></span> พิมพ์</button>
              <button className='uk-button d-text-white uk-button-primary' onClick={this.CsvExport}><span uk-icon="download"></span> Export CSV</button>
            </div>
          </div>
        </div>
        <div uk-grid="" className="uk-flex-middle">
          <div className="uk-width-expand@m uk-width-1-1">
            <span className='uk-margin-right'>การดำเนินงาน</span>
            <label className='uk-margin-small-right'>
              <input type="checkbox" className='uk-checkbox' checked={this.state.filterPaymentWait} onChange={e => this.setState({ filterPaymentWait: !this.state.filterPaymentWait }, () => { this.paymentListUseRender() })} /> รอการเงินดำเนินการ
            </label>
            <label className='uk-margin-small-right'>
              <input type="checkbox" className='uk-checkbox' checked={this.state.filterPaymentReject} onChange={e => this.setState({ filterPaymentReject: !this.state.filterPaymentReject }, () => { this.paymentListUseRender() })} /> ปฏิเสธยอดโอน
            </label>
            <label className='uk-margin-small-right'>
              <input type="checkbox" className='uk-checkbox' checked={this.state.filterPaymentAprove} onChange={e => this.setState({ filterPaymentAprove: !this.state.filterPaymentAprove }, () => { this.paymentListUseRender() })} /> รับยอดโอน
            </label>
          </div>
          <div className="uk-width-expand@m uk-width-1-1">

            <span className='uk-margin-right'>การส่งเงิน</span>
            <label className='uk-margin-small-right'>
              <input type="checkbox" className='uk-checkbox' checked={this.state.filterSendMoneyWait} onChange={e => this.setState({ filterSendMoneyWait: !this.state.filterSendMoneyWait }, () => { this.paymentListUseRender() })} /> ยังไม่ส่งการเงิน
            </label>
            <label className='uk-margin-small-right'>
              <input type="checkbox" className='uk-checkbox' checked={this.state.filterSendMoneySuccess} onChange={e => this.setState({ filterSendMoneySuccess: !this.state.filterSendMoneySuccess }, () => { this.paymentListUseRender() })} /> ส่งการเงินออกใบเสร็จแล้ว
            </label>
          </div>
          <div className='uk-width-auto'>ค้นหาจากวันที่</div>
          <div className="uk-width-1-6@m uk-width-1-1">
            <DateTimePicker id="dateStart" date={this.state.dateStart} callback={(value) => this.setState({ dateStart: value })} format={'DD/MM/YYYY'} />
          </div>
          <div className='uk-width-auto'>ถึง</div>
          <div className="uk-width-1-6@m uk-width-1-1">
            <DateTimePicker id="dateStop" date={this.state.dateStop} callback={(value) => this.setState({ dateStop: value })} format={'DD/MM/YYYY'} />
          </div>
          <div className="uk-width-auto@m uk-width-1-1">
            <label className="uk-margin-small-right">
              <input type="radio" className="uk-radio" name="group_select" checked={this.state.group === 'self'} onChange={() => this.setState({ group: 'self' })} /> เฉพาะตนเอง
            </label>
            <label>
              <input type="radio" className="uk-radio" name="group_select" checked={this.state.group === 'all'} onChange={() => this.setState({ group: 'all' })} /> รวมทุกคน
            </label>
          </div>
          <div className="uk-width-auto@m uk-width-1-1 uk-text-right">
            <button className="uk-button uk-button-primary" onClick={this.paymentListGet}>ค้นหา</button>
          </div>
        </div>
        <div className='uk-overflow-auto uk-margin-top'>
          <table className='uk-table uk-table-divider uk-table-striped d-report-table'>
            <thead>
              <tr>
                <th>ลำดับ</th>
                <th>วันที่พบแพทย์</th>
                <th>วันที่โอนเงิน</th>
                <th>เวลา</th>
                <th>ภาพสลิป</th>
                <th>จำนวนเงินที่โอน (บาท)</th>
                <th>ชื่อ-สกุล คนไข้</th>
                <th>HN</th>
                <th>การดำเนินงาน</th>
                <th>สถานะการส่งเงิน</th>
              </tr>
            </thead>
            <tbody>
              {this.rowRender()}
            </tbody>
          </table>
        </div>
      </div>
      {
        this.state.imagePreview && <div className="d-popup-background">
          <div className="d-popup-body uk-width-large">
            <img src={this.state.imagePreview} uk-img="" />
            <div className='uk-margin-top uk-text-right'>
              <button className='uk-button uk-button-secondary' onClick={() => this.setState({ imagePreview: null })}>ปิดภาพ</button>
            </div>
          </div>
        </div>
      }

    </div>
  }
};
