import React, { Component } from 'react';
import Api from '../Api';
import moment from 'moment';
import numeral from 'numeral';

export default class ConsultService extends Component {

  state = {
    editIndex: null,
    service: 'chat',
    price: '0',
    condition: '',
    list: [],
    editShow: false
  }

  componentDidMount() {
    var _list = [];

    try {
      _list = JSON.parse(this.props.list);

    } catch (e) { console.error(e); }

    this.setState({ list: _list || [] })
  }

  formEdit = () => {
    return <div className="uk-padding-small uk-background-muted">
      <div>การให้บริการ</div>
      <div>
        <label className="uk-margin-small-right"><input type="radio" className="uk-radio" name="serviceType" checked={this.state.service === 'chat' ? true : false} value="chat" onChange={e => this.setState({ service: 'chat' })} /> แชท</label>
        <label className="uk-margin-small-right"><input type="radio" className="uk-radio" name="serviceType" checked={this.state.service === 'voice' ? true : false} value="voice" onChange={e => this.setState({ service: 'voice' })} /> วอยส์คอล</label>
        <label className="uk-margin-small-right"><input type="radio" className="uk-radio" name="serviceType" checked={this.state.service === 'video' ? true : false} value="video" onChange={e => this.setState({ service: 'video' })} /> วีดีโอคอล</label>
      </div>
      <div className="uk-margin-small-top">
        <label>เงื่อนไขการให้บริการ</label>
        <textarea className="uk-textarea uk-form-small" value={this.state.condition} onChange={e => this.setState({ condition: e.target.value })} ></textarea>
      </div>
      <div className="uk-margin-small-top">
        <label>ค่าใช้จ่ายต่อครั้ง</label>
        <input type="text" className="uk-input uk-form-small" value={this.state.price} onChange={e => this.setState({ price: e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1') })} />
      </div>
      <div className="uk-margin-small-top uk-text-right">
        <a onClick={()=>this.setState({
          editShow: false,
          service: '',
          price: '0',
          condition: '',
          editIndex: null
        })}>ยกเลิก</a>

        <button className="uk-button uk-button-small uk-button-secondary uk-margin-left" onClick={() => {
          var _list = this.state.list;
          var _data = {
            service: this.state.service,
            price: this.state.price,
            condition: this.state.condition
          };

          if (this.state.editIndex !== null) {
            _list[this.state.editIndex] = _data;
          } else {
            _list.push(_data);
          }
          if(this.props.callback) this.props.callback(JSON.stringify(_list));
          this.setState({ list: _list, editIndex: null, editShow: false });
        }}>บันทึก</button>
      </div>
    </div>
  }

  listRender = () => {
    return this.state.list.map((data, index) => {
      var _service = '';
      switch (data.service) {
        case 'chat':
          _service = 'ให้คำแนะนำผ่านแชท';
          break;
        case 'voice':
          _service = 'วอยส์คอล';
          break;
        case 'video':
          _service = 'วีดีโอคอล';
          break;
        default:
      }
      return <div className="uk-padding-small" style={{ borderBottom: '1px solid #CCC' }} key={'rowcondition' + index}>
        <div className="uk-text-right">
          <a className="uk-text-small uk-margin-left-small" uk-icon="icon: pencil" onClick={() => {
            this.setState({
              service: data.service,
              price: data.price,
              condition: data.condition,
              editIndex: index,
              editShow: true
            });
          }}></a>
          <a className="uk-text-small uk-margin-small-left" uk-icon="icon: trash" onClick={() => {
            if (window.confirm('ยืนยันการลบ?')) {
              delete this.state.list[index];
              var _list =  this.state.list.filter(Boolean);
              if(this.props.callback) this.props.callback(JSON.stringify(_list));
              this.setState({ list: _list })
            }

          }}></a>
        </div>
        <b>{_service}</b> <span dangerouslySetInnerHTML={{ __html: data.condition.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></span> <div className="uk-margin-small-top">{data.price == '0' ? 'ไม่มีค่าใช้จ่าย' : `ค่าใช้จ่ายครั้งละ ${numeral(data.price).format('0,0.00')} บาท`}</div></div>
    });
  }

  render() {
    return <div>
      {
        this.state.editShow ?
          <div>
            {this.formEdit()}
          </div>
          :
          <div className="uk-margin-top">
            <label>รายการการบริการ</label>
            <div className="uk-margin-small-bottom uk-margin-small-top">
              <a onClick={()=>this.setState({
                editShow: true,
                service: '',
                price: '0',
                condition: '',
                editIndex: null
              })}><i uk-icon="icon: plus-circle"></i> เพิ่มรายการ</a>
            </div>
            <hr />
            {this.listRender()}
          </div>
      }


    </div>

  }
};
