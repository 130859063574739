import React, {Component} from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import ChatBox from '../../Components/ChatBox';

UIkit.use(Icons);

export default class Chat extends Component {
  render() {
    return <div>
      <ChatBox admin="true" chatby="m" {...this.props} />
    </div>
  }
};
