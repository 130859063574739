import React, { Component } from 'react';
import Api from '../Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import { Link } from "react-router-dom";
import UserProfileCardChangePassword from '../UserProfileCardChangePassword';
import ProfileOverview  from './ProfileOverview';
import UploadFirebase from '../UploadFirebase';
import ImageUri from '../ImageUri';

UIkit.use(Icons);

export default class UserProfileCard extends Component {
  state = {
    profile: {},
    company: [],
    show: '',
  }

  componentDidMount() {
    this.profileGet();
  }

  profileGet = async () => {
    var { profile, company } = await Api.consult('ConsultProfileCard');

    this.setState({
      profile: profile ? profile : {},
      company: company ? company : [],
    });

  }

  linkList = [
    {
      icon: 'layers',
      label: 'Profile Overview',
      before: 'ProfileOverviewRender',
      click: () => {
        this.setState({show: 'ProfileOverviewRender'});
      }
    },
    {
      icon: 'note',
      label: 'Change Password',
      before: 'userProfileCardChangePasswordRender',
      click: () => {
        this.setState({show: 'userProfileCardChangePasswordRender'});
      }
    },
  ]

  ProfileOverviewRender = () => {
    return  this.state.show === 'ProfileOverviewRender' && <ProfileOverview profile={this.state.profile} callback={(profile)=>this.setState({profile: {...this.state.profile, ...profile} })} />
  }

  userProfileCardChangePasswordRender = () => {
    return this.state.show === 'userProfileCardChangePasswordRender' && <UserProfileCardChangePassword type='c' id={this.state.profile.id} />
  }

  render() {
    return <div className="uk-card uk-card-default">
      <div uk-grid="" className="uk-grid-small uk-padding-small">
        <div className="uk-width-1-2@s uk-width-1-1 uk-text-left@s uk-text-center">
          <div className="uk-inline">
          <span style={{backgroundColor: '#16d39a ', width: 20, height: 20, borderRadius: 10, position: 'absolute', right: 10, top: 10, border: '2px solid #FFF', zIndex: 1, display: 'inline-block'}}></span>
          <div className="uk-background-cover uk-inline uk-background-muted uk-display-inline-block d-user-profile-card-image uk-transition-toggle" style={{ backgroundImage: this.state.profile.image === '' ? false : `url(${ImageUri(this.state.profile.image)})` }}>
            
            <label className="uk-position-center uk-transition-fade" style={{backgroundColor: `rgba(255,255,255,0.8)`, padding: '5px 10px', cursor: 'pointer'}}>
              เลือก
              <UploadFirebase cb={(media) => {
                this.setState(state=>{
                  state.profile.image = media;
                  return {profile: state.profile};
                });

                Api.consult('ConsultProfileImageUpdate', {image: media});
              }} />
            </label>
          </div>
          </div>
        </div>
        <div className="uk-width-1-2@s uk-width-1-1 uk-text-left@s uk-text-center">
          <div className="uk-margin-small-top">Name: <b>{this.state.profile.nameprefix} {this.state.profile.name} {this.state.profile.lastname}</b></div>
          <div className="uk-margin-small-top">
            <div className="uk-button-group">
              <Link to="/consult/chat" className="uk-button uk-button-small uk-button-primary uk-margin-small-right">Chat</Link>
            </div>
          </div>
        </div>
      </div>
      <div uk-grid="" className="uk-grid-small uk-padding-small uk-padding-remove-top uk-margin-remove-top">
        <div className="uk-width-1-3">
          <div>Email: </div>
          <div>Phone: </div>
          <div>Company: </div>
        </div>
        <div className="uk-width-2-3 uk-text-right">
          <div><a href={"mailto:" + this.state.profile.email}>{this.state.profile.email}</a>&nbsp;</div>
          <div>{this.state.profile.tel}&nbsp;</div>
          <div>
            {this.state.company.map(data=>(<div key={`company${data.name}`}>{data.name}</div>))}&nbsp;
          </div>
        </div>
      </div>
      <div className="d-user-profile-card">
        {
          this.linkList.map((data, index) => (
            <div key={'link'+index}>
              {data.before && this[data.before]() }
              <a className={'d-user-profile-card-link ' + (this.state.show === data.before ? 'd-link-active' : '')} onClick={()=>{
                if(data.click) {
                  data.click();
                }
              }}><i className={`icon-${data.icon}`}></i> {data.label}</a>
            </div>
          ))
        }
      </div>
    </div>
  }
};
