import React, {Component} from 'react';
import Api from './Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import moment from 'moment';

UIkit.use(Icons);

export default class CampaignInputSetting extends Component {
  
  state = {
    member_chat_button: []
  }
  

  type = [
    {
      label: 'รักษาแผล',
      value: 'wound'
    },
    {
      label: 'อาการโควิด-19',
      value: 'covid19'
    },
    {
      label: 'ความดันโลหิต',
      value: 'bloodpressure'
    },
    {
      label: 'วัดน้ำตาล',
      value: 'sugar'
    },
    {
      label: 'น้ำหนัก/รอบเอว',
      value: 'bmi'
    },
    {
      label: 'อาหาร',
      value: 'food'
    },
    {
      label: 'เฉพาะผู้ป่วยเบาหวาน',
      value: 'diabetes'
    },
    {
      label: 'ตารางน้ำตาลเบาหวานชนิดที่ 1',
      value: 'diabetestype1'
    },
    {
      label: 'ตารางน้ำตาลเบาหวานชนิดที่ 2',
      value: 'diabetestype2'
    },
    {
      label: 'ตารางน้ำตาลเบาหวานตั้งครรภ์',
      value: 'diabetespregnant'
    },
    {
      label: 'น้ำยาล้างไต',
      value: 'dialysis'
    },
    {
      label: 'ข้อมูลคนไข้ก่อนพบแพทย์',
      value: 'patientprofile'
    },
    {
      label: 'ที่อยู่จัดส่งยา',
      value: 'address'
    },
    {
      label: 'สร้างเสริมสุขภาพ',
      value: 'hiv'
    },
    {
      label: 'คู่มือวีดีโอใช้งานสร้างเสริมสุขภาพ',
      value: 'hiv_manual'
    },
    {
      label: 'แบบประเมินตนเองก่อนพบแพทย์ (HIV V.2)',
      value: 'hiv_form_v2'
    },
    {
      label: 'SOS',
      value: 'sos'
    },
    {
      label: 'ส่งแบบสำรวจความพึงพอใจ',
      value: 'survey'
    },
    {
      label: 'ส่งชุดความรู้',
      value: 'knowledge'
    },
    {
      label: 'ส่งแบบประเมินตีตราตนเอง',
      value: 'self-stigma'
    },
    {
      label: 'ดูชุดความรู้แนะนำ',
      value: 'knowledge_suggest'
    },
    {
      label: 'ทำแบบประเมินความพึงพอใจ',
      value: 'survey_link'
    },
  ]

  callback = () => {
    if(typeof this.props.callback === 'function') {
      this.props.callback(this.state.member_chat_button);
    }
  }

  campaignSave = async () => {
    var _response = await Api[this.props.apiType || 'consult']("CampaignMemberChatButtonUpdate", { data: JSON.stringify(this.state.member_chat_button), id: this.props.campaign.id });

    UIkit.notification(_response.responseText);
  }

  updateKey = (key) => {
    
    this.setState((state)=>{
      if(state.member_chat_button.includes(key)) {
        state.member_chat_button.splice(state.member_chat_button.indexOf(key), 1);
      } else {
        state.member_chat_button.push(key);
      }

      return {member_chat_button: state.member_chat_button}
    }, ()=>{
      this.campaignSave();
    })
  }

  button_load = () => {
    var _data = this.type.map((type)=>type.value);

    try {
      _data = JSON.parse(this.props.campaign.memberchatbutton);
    } catch(e) {
      console.error(e);
    }

    this.setState({member_chat_button: _data});
  }

  componentDidMount() {
    this.button_load();
  }

  render() {
    return <div className=" uk-card uk-card-default uk-margin-top uk-padding-small">
      <div className="d-card-header">
        <div className="d-card-header-left">ตั้งค่าปุ่มบันทึกข้อมูลคนไข้</div>
        <div className="d-card-header-right"></div>
      </div>
      <div>
        <div uk-grid="" className="uk-grid-small uk-child-width-1-3@m uk-width-1-1">
          {this.type.map((data) => {

            return <div key={`checkbox${data.value}`}>
              <label>
                <input type="checkbox" className="uk-checkbox uk-margin-small-right" checked={(this.state.member_chat_button.includes(data.value))} onChange={()=>this.updateKey(data.value)} />
                {data.label}
              </label>

            </div>
          })}
        </div>
      </div>
    </div>
  }
};
