import React, { Component } from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import ReactEcharts from 'echarts-for-react';
import DataType from '../DataType';
import Api from '../Api';
import Formular from '../Formular';
import FullScreenElement from '../FullScreenElement';

UIkit.use(Icons);

export default class DashboardUserHealthOpnion extends Component {
    state = {
        opnion: {},
        selectDataType: 'all',
        position: []
    }

    componentDidMount() {
        this.dataGet();
    }

    dataGet = async () => {
        var { opnion = [], position } = await Api.member('DashboardUserHealthOpnion', { position: this.state.selectDataType });

        this.setState({ opnion: opnion || {}, position: position.map(data => (data.position)) || [] });

    }

    renderChart = () => {
        var chartOptions = {

            // Add tooltip
            tooltip: {
                trigger: 'axis'
            },

            // Add legend
            legend: {
                x: 'center',
                data: ['User Health Opinion'],
            },

            // Add polar
            polar: [
                {
                    indicator: [
                        { text: 'Health Facility', max: 100 },
                        { text: 'Satisfies', max: 100 },
                        { text: 'Insurance', max: 100 },
                        { text: 'Health Banefits', max: 100 },
                        { text: 'Health Activity', max: 100 },
                        { text: 'Health Budget', max: 100 }
                    ],
                    radius: 150
                }
            ],

            // Add custom colors
            color: ['#2f94f3', '#FF7588'],

            // Enable drag recalculate
            calculable: true,

            // Add series
            series: [
                {
                    name: 'Players fully live data',
                    type: 'radar',
                    itemStyle: {
                        normal: {
                            areaStyle: {
                                type: 'default'
                            }
                        }
                    },
                    data: [
                        {
                            value: [
                                this.state.opnion.healthfacility,
                                this.state.opnion.satisfies,
                                this.state.opnion.insurance,
                                this.state.opnion.healthbenefits,
                                this.state.opnion.healthactivity,
                                this.state.opnion.healthbudget,
                            ],
                            name: 'User Health Opinion'
                        }
                    ]
                }
            ]
        };;

        return <ReactEcharts
            option={chartOptions}
            style={{ height: 400 }}
            notMerge={true}
            lazyUpdate={true}
        />
    }

    render() {
        return <div className="uk-card uk-card-default uk-padding-small" id="d-dashboard-health-opnion">
            <div className="d-card-header">
                <div className="d-card-header-left">
                    USER HEALTH OPINION
                </div>
                <div className="d-card-header-right">
                    <span className='icon-reload' onClick={this.dataGet}></span>
                    <FullScreenElement element="#d-dashboard-health-opnion" />
                </div>
            </div>
            <div className="uk-text-right uk-margin-top uk-margin-bottom">
                <select className="uk-select uk-width-small" value={this.state.selectDataType} onChange={(e) => this.setState({ selectDataType: e.target.value }, () => this.dataGet())} >
                    <option value="all">ทั้งหมด</option>
                    {
                        this.state.position.map(data => {
                            return <option key={`option${data}`} value={data}>{data}</option>
                        })
                    }
                </select>
            </div>
            {this.renderChart()}
        </div>
    }
};
