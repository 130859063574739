import React, { Component } from "react";
import Api from "../../Components/Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import moment from "moment";
import DateTimePicker from "../../Components/DatePicker.js";
import numeral from "numeral";
import SortArray from "../../Components/SortArray.js";

UIkit.use(Icons);

export default class AdminConsultStatistic extends Component {
  state = {
    list: [],
    dateStart: moment().subtract(30, 'days').startOf("d"),
    dateStop: moment().add(1, "d"),
    campaignSelect: null,
    campaignList: [],
    sortBy: "chat_amount",
    sortType: "desc",
    companyList: [],
    companySelect: "",
    loadingList: false
  };

  listGet = async () => {
    this.setState({loadingList: true});

    var { list } = await Api.admin("ConsultStatisticList", {
      campaignId: this.state.campaignSelect,
      companyId: this.state.companySelect,
      dateStart: moment(this.state.dateStart).format("YYYY-MM-DD HH:mm:ss"),
      dateStop: moment(this.state.dateStop).format("YYYY-MM-DD HH:mm:ss"),
    });

    if (!list) list = [];

    list.forEach((data) => {
      if (!data.videocall) data.videocall = 0;
      if (!data.chat_amount) data.chat_amount = 0;
      data.chat_percent = +data.chat_amount > 0 ? numeral(
        (+data.chat_in_minute / +data.m_chat_amount) * 100
      ).format("0,0.00") : '0.00';
    });

    list = SortArray(
      list,
      this.state.sortBy,
      this.state.sortType === "desc" ? true : false
    );


    this.setState({ list, loadingList: false });
  };

  sortRender = (by) => {
    var _list = this.state.list;
    var _sortType = this.state.sortType;
    var _sortBy = this.state.sortBy;

    if (by === this.state.sortBy) {
      _sortType = this.state.sortType === "desc" ? "asc" : "desc";
    } else {
      _sortBy = by;
    }

    _list = SortArray(_list, _sortBy, _sortType === "desc" ? true : false);

    this.setState({ list: _list, sortBy: _sortBy, sortType: _sortType });
  };

  companySelectRender = () => {
    return (
      <select
        className="uk-select"
        value={this.state.companySelect}
        onChange={(e) => {
          this.setState({ companySelect: e.target.value }, () => {
            this.campaignListGet();
          });
        }}
      >
        {this.state.companyList.map((data, key) => {
          return (
            <option key={`company${key}`} value={data.id}>
              {data.name}
            </option>
          );
        })}
      </select>
    );
  };

  companyListGet = async () => {
    var _response = await Api.admin("CompanyList");

    if (!_response.list) _response.list = [];

    _response.list.push({
      id: "",
      name: "ทั้งหมด"
    });

    this.setState(
      {
        companyList: _response.list
      },
      () => {
        this.campaignListGet();
      }
    );
  };

  campaignSelectRender = () => {
    return (
      <select
        className="uk-select"
        value={this.state.campaignSelect}
        onChange={(e) => {
          this.setState({ campaignSelect: e.target.value }, () => {
            this.listGet();
          });
        }}
      >
        {this.state.campaignList.map((data, key) => {
          return (
            <option key={`campaign${key}`} value={data.id}>
              {data.name}
            </option>
          );
        })}
      </select>
    );
  };

  campaignListGet = async () => {
    var _response = await Api.admin("CampaignListByCompanyId", {
      companyId: this.state.companySelect,
    });

    if (!_response.list) _response.list = [];

    _response.list.push({
      id: "",
      name: "ทั้งหมด",
    });

    this.setState(
      {
        campaignList: _response.list,
        campaignSelect: "",
      },
    );
  };

  componentDidMount() {
    this.companyListGet();
  }

  render() {
    return (
      <div>
        <div className="d-card-header uk-margin-bottom">
          ตารางสรุปการดำเนินงาน
        </div>
        <div uk-grid="" className="uk-grid-small">
          <div className="uk-width-auto@m uk-width-1-1">
            <DateTimePicker
              id="dateStart"
              date={this.state.dateStart}
              callback={(value) => this.setState({ dateStart: value })}
            />
          </div>
          <div className="uk-width-1-5@m uk-width-1-1">
            <DateTimePicker
              id="dateStop"
              date={this.state.dateStop}
              callback={(value) => this.setState({ dateStop: value })}
            />
          </div>
          <div className="uk-width-expand@m uk-width-1-1">
            {this.companySelectRender()}
          </div>
          <div className="uk-width-1-5@m uk-width-1-1">
            {this.campaignSelectRender()}
          </div>
          <div className="uk-width-auto@m uk-width-1-1">
            <button
              className="uk-button uk-button-primary uk-width-auto@m uk-width-1-1"
              onClick={this.listGet}
            >
              ค้นหา
            </button>
          </div>
        </div>
        <hr />
        <div className="uk-overflow-auto">
          <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
            <thead>
              <tr>
                <th>ที่</th>
                <th>ชื่อบุคลากร</th>
                <th onClick={() => this.sortRender("chat_amount")}>
                  จำนวนการแชท
                </th>
                <th onClick={() => this.sortRender("videocall")}>
                  จำนวนการวีดีโอคอล
                </th>
                <th onClick={() => this.sortRender("chat_percent")}>
                  % การแชทภายใน 1 ชั่วโมง หลังจากคนไข้ส่งมา
                </th>
                  <th>โรงพยาบาล</th>
                  <th>แคมเปญ</th>
              </tr>
            </thead>
            <tbody>
              {this.state.list.length === 0 ? (
                <tr>
                  <td colSpan="100%">
                    <div className="uk-padding-small uk-text-center">
                      {
                        this.state.loadingList ? 
                        <span uk-spinner=""></span>
                        :
                        <span>ไม่พบข้อมูล</span>
                      }
                    </div>
                  </td>
                </tr>
              ) : (
                this.state.list.map((data, index) => {
                  return (
                    <tr key={`row${index}`}>
                      <td>{index + 1}</td>
                      <td>
                        {data.nameprefix} {data.name} {data.lastname}
                      </td>
                      <td>{data.chat_amount}</td>
                      <td>{data.videocall}</td>
                      <td>{data.chat_percent} %</td>
                      <td>{Array.isArray(data.company) ? data.company.map((company)=>{
                        return <span className="uk-label uk-margin-small-right uk-text-small">{company.name}</span>
                      }) : ''}</td>
                      <td>{Array.isArray(data.campaign) ? data.campaign.map((campaign)=>{
                        return <span className="uk-label uk-margin-small-right uk-text-small">{campaign.name}</span>;
                      }) : ''}</td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
