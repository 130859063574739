import $ from 'jquery';
import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import UIkit from 'uikit';
import Api from '../../Components/Api';
import UploadFirebase from '../../Components/UploadFirebase';
import ImageUri from '../../Components/ImageUri';

class AdminConsultAddForm extends Component {

  field = [
    { key: 'nameprefix', label: 'คำนำหน้าชื่อ', require: true },
    { key: 'name', label: 'ชื่อ', require: true },
    { key: 'lastname', label: 'นามสกุล', require: true },
    { key: 'username', label: 'ชื่อผู้ใช้', require: true },
    { key: 'password', label: 'รหัสผ่าน', require: true },
    { key: 'email', label: 'อีเมล', type: 'email', require: true },
    { key: 'tel', label: 'โทร', require: true },
    { key: 'gender', label: 'เพศ', type: 'select', option: [{ value: '1', label: 'ชาย' }, { value: '0', label: 'หญิง' }], require: true },
    { key: 'nid', label: 'เลขบัตรประชาชน / เลขบัตรประกันสังคม / passport สำหรับชาวต่างชาติ', require: true },
    { key: 'birthday', label: 'วันเกิด', type: 'date', require: true },
    { key: 'typeconsult', label: 'ประเภท', type: 'select', option: [{ value: 'แพทย์', label: 'แพทย์' }, { value: 'เภสัชกร', label: 'เภสัชกร' }, { value: 'นักกำหนดอาหาร', label: 'นักกำหนดอาหาร' }, { value: 'พยาบาลวิชาชีพ', label: 'พยาบาลวิชาชีพ' }, { value: 'พยาบาล', label: 'พยาบาล' }, { value: 'นักวิชาการสาธารณสุข', label: 'นักวิชาการสาธารณสุข' }, {value: 'ผู้ให้ความรู้โรคเบาหวาน', label:'ผู้ให้ความรู้โรคเบาหวาน'}, {value: 'ผู้เชี่ยวชาญด้านการออกกำลังกาย', label: 'ผู้เชี่ยวชาญด้านการออกกำลังกาย'}, {value: 'นักกายภาพบำบัด', label:'นักกายภาพบำบัด'}, {label: 'เจ้าหน้าที่อื่น', value: 'เจ้าหน้าที่อื่น'}, {label: 'ฯลฯ', value: 'ฯลฯ'}], require: true },
    { key: 'typebranch', label: 'สาขา', require: true },
    { key: 'hospitalhistory', label: 'โรงพยาบาลวิชาชีพ', type: 'textarea', require: true },
    { key: 'education', label: 'การศึกษา', type: 'textarea', require: true },
    { key: 'speciallist', label: 'ความชำนาญเฉพาะทาง', type: 'textarea', require: true },
    { key: 'history', label: 'ประวัติ', type: 'textarea', require: true },
    { key: 'language', label: 'ใช้ภาษา', type: 'checkbox', change: (e)=>{
      

      var _language = this.state.language;
      var _value = e.target.value;
      var _index = this.state.language.indexOf(_value);
      if(_index < 0) {
        _language.push(_value);
      } else {
        delete _language[_index];
        _language = _language.filter(Boolean);
      }

      this.setState({language: _language});

    
    }, option: [{ value: 'th', label: 'ไทย' }, { value: 'en', label: 'อังกฤษ' }, { value: 'ch', label: 'จีน' },], require: true },
    
    { key: 'menulevel', label: 'ระดับเมนู', type: 'select', option: [
      {
        value: '',
        label: 'ทั้งหมด (อบจ)'
      },
      {
        value: 'healthbag',
        label: 'กระเป๋าสุขภาพ'
      },
      {
        value: 'ncds',
        label: 'NCDS'
      },
      {
        value: 'smc',
        label: 'SMC'
      },
      {
        value: 'healthbag',
        label: 'กระเป๋าสุขภาพ+Station'
      },
      {
        value: 'clinic',
        label: 'CLINIC'
      },
      {
        value: 'teleConsult',
        label: 'TELECONSULT'
      },
      {
        value: 'm2',
        label: 'มหาชัย'
      },
    ]},
  ]

  state = {
    company: [],
    consultCompany: [],
    hospital: [],
    consultHospital: [],
    image: '',
    loadingApi: false
  }
 
  componentWillReceiveProps() {
    this.init();
  }

  init = async () => {
    this.setState({ loadingApi: true });
    this.setState((state) => {
      var stateInit = {};
      if (this.props.consult) {
        this.field.map(data => { stateInit[data.key] = data.key === 'password' ? '' : this.props.consult[data.key]; });
        stateInit.consultCompany = this.props.consult.consultCompany || [];
        stateInit.consultHospital = this.props.consult.consultHospital || [];
        stateInit.language = this.props.consult.language ? this.props.consult.language.split(',') : [];
        stateInit.image = this.props.consult.image || '';
      } else {
        this.field.map(data => { stateInit[data.key] = ''; });
        stateInit.consultCompany = [];
        stateInit.consultHospital = [];
        stateInit.language = [];
        stateInit.image = '';
      }

      return stateInit;
    });

    var { list } = await Api.admin('CompanyList');
    this.setState({ company: list });

    var { list } = await Api.admin('HospitalList');
    this.setState({ hospital: list });

    if (this.props.show && UIkit && UIkit.modal('#modal-form')) {
      UIkit.modal('#modal-form').show();
    } else if (UIkit && UIkit.modal('#modal-form')) {
      UIkit.modal('#modal-form').hide();
    }

    this.setState({ loadingApi: false });
  }

  consultAdd = async (e) => {
    e.preventDefault();

    if (this.state.loadingApi) return false;

    var data = this.state;
    if (this.props.consult) {
      data.id = this.props.consult.id;
    }

    data.consultCompany = this.state.consultCompany;
    data.consultHospital = this.state.consultHospital;


    if (!data.gender) data.gender = '1';
    try {
      if (data.language) {
        data.language = data.language.join(',');
      } else {
        data.language = '';
      }

    } catch(e) {
      console.log(data);
      console.log(e)
    }

    if (!data.typeconsult) data.typeconsult = 'แพทย์';

    var _error = false;
    this.field.forEach(f => {

      if (f.require && !data[f.key]) {
        if (!(f.key === 'password' && this.props.consult))
          _error = true;
        return false;

      }
    })

    if (_error) {

      UIkit.notification({ message: 'โปรดกรอกข้อมูลให้ครบ', status: 'danger' });
    } else {


      this.setState({ loadingApi: true });


      if(data.hospital != null) delete data.hospital;
      if(data.company !=null ) delete data.company;
      

      var { result, responseText } = await Api.admin(this.props.consult ? 'ConsultUpdate' : 'ConsultAdd', data);
      UIkit.notification({ message: responseText, status: result ? 'success' : 'danger' });

      if (result) {
        this.props.callback();
        UIkit.modal('#modal-form').hide();
      }
    }

    this.setState({ loadingApi: false });

  }

  updateValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  consultCompanyAdd = () => this.setState((state) => {

    var hasCompany = state.consultCompany.map(data => (data.id));

    if (hasCompany.indexOf($("#select-company option:selected").val()) < 0) {
      state.consultCompany.push({
        id: $("#select-company option:selected").val(),
        name: $("#select-company option:selected").attr('data-name'),
        level: '',
        position: '',
        active: '0'
      })
    }

    return { consultCompany: state.consultCompany };
  })

  consultCompanyRemove = (id) => this.setState((state) => {
    for (var i = state.consultCompany.length; i-- > 0;) {
      if (state.consultCompany[i].id == id) delete state.consultCompany[i];
    }

    return { consultCompany: state.consultCompany.filter(Boolean) };
  })

  consultCompanyRender = () => {
    return <div className="uk-margin-small-top">
      <label>บริษัท</label>
      <div className="uk-button-group uk-width-1-1">
        <select className="uk-select" id="select-company">
          {Array.isArray(this.state.company) && this.state.company.map((data, key) => (<option key={`company${key}`} value={data.companyid} data-name={data.name}>{data.name}</option>))}
        </select>
        <button type="button" className="uk-button uk-button-secondary" onClick={this.consultCompanyAdd}><span uk-icon="icon: plus"></span></button>
      </div>
      <div className="uk-padding-small uk-background-muted uk-margin-top">
        {
          this.state.consultCompany && this.state.consultCompany.length > 0 ?
            <table className="uk-table uk-table-small uk-table-divider">
              <thead>
                <tr className="uk-child-width-1-4">
                  <th>ชื่อบริษัท</th>
                  <th>ลบ</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.consultCompany.map((data, key) => (<tr key={`consultCompany${key}`}>
                    <td>
                      {data.name}
                    </td>
                    <td>
                      <button type="button" className="uk-button uk-button-default uk-button-small" onClick={() => this.consultCompanyRemove(data.id)}>
                        <span uk-icon="icon: trash; ratio: 0.8"></span>
                      </button>
                    </td>
                  </tr>))
                }
              </tbody>
            </table>
            :
            <div className="uk-text-center">ไม่มีรายการ โปรดเลือกบริษัท</div>
        }
      </div>
    </div>
  }

  consultHospitalAdd = () => this.setState((state) => {

    var hasHospital = state.consultHospital.map(data => (data.id));

    if (hasHospital.indexOf($("#select-hospital option:selected").val()) < 0) {
      state.consultHospital.push({
        id: $("#select-hospital option:selected").val(),
        name: $("#select-hospital option:selected").attr('data-name')
      })
    }

    return { consultHospital: state.consultHospital };
  })

  consultHospitalRemove = (id) => this.setState((state) => {
    for (var i = state.consultHospital.length; i-- > 0;) {
      if (state.consultHospital[i].id == id) delete state.consultHospital[i];
    }

    return { consultHospital: state.consultHospital.filter(Boolean) };
  })

  consultHospitalRender = () => {
    return <div className="uk-margin-small-top">
      <label>โรงพยาบาลวิชาชีพ</label>
      <div className="uk-button-group uk-width-1-1">
        <select className="uk-select" id="select-hospital">
          {Array.isArray(this.state.hospital) && this.state.hospital.map((data, key) => (<option key={`hospital${key}`} value={data.id} data-name={data.name}>{data.name}</option>))}
        </select>
        <button type="button" className="uk-button uk-button-secondary" onClick={this.consultHospitalAdd}><span uk-icon="icon: plus"></span></button>
      </div>
      <div className="uk-padding-small uk-background-muted uk-margin-top">
        {
          this.state.consultHospital && this.state.consultHospital.length > 0 ?
            <table className="uk-table uk-table-small uk-table-divider">
              <thead>
                <tr className="uk-child-width-1-4">
                  <th>ชื่อโรงพยาบาลวิชาชีพ</th>
                  <th>ลบ</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.consultHospital.map((data, key) => (<tr key={`consultHospital${key}`}>
                    <td>
                      {data.name}
                    </td>
                    <td>
                      <button type="button" className="uk-button uk-button-default uk-button-small" onClick={() => this.consultHospitalRemove(data.id)}>
                        <span uk-icon="icon: trash; ratio: 0.8"></span>
                      </button>
                    </td>
                  </tr>))
                }
              </tbody>
            </table>
            :
            <div className="uk-text-center">ไม่มีรายการ โปรดเลือกโรงพยาบาลวิชาชีพ</div>
        }
      </div>
    </div>
  }

  render() {
    return <div id="modal-form" className="uk-modal-full" uk-modal="bg-close: false; esc-close: false;">
      <div className="uk-modal-dialog uk-modal-body">
        <button className="uk-modal-close-default" type="button" uk-close=""></button>
        <h2 className="uk-modal-title">จัดการข้อมูลที่ปรึกษา</h2>
        <form onSubmit={this.consultAdd}>
          <div className="uk-margin-small-bottom">
            <label className="uk-button uk-button-default">
              เลือกรูปภาพ
              <UploadFirebase cb={(image) => {
                this.setState({ image })
              }} />
            </label>
            {this.state.image !== '' && <div className="uk-margin-small"><img src={ImageUri(this.state.image)} uk-img="" width="200" /></div>}
          </div>
          <div className="uk-grid-small uk-child-width-1-1 uk-child-width-1-3@s" uk-grid="">
            {
              this.field.map(data => {
                var _input = null;
                switch (data.type) {
                  case 'select':
                    _input = <select className="uk-select" name={data.key} onChange={this.updateValue} value={this.state[data.key]}>
                      {data.option.map(o => <option value={o.value} key={o.key}>{o.label}</option>)}

                    </select>
                    break;
                  case 'textarea':

                    _input = <textarea className="uk-textarea" rows="5" name={data.key} onChange={this.updateValue} value={this.state[data.key]} />
                    break;
                  case 'checkbox':
                  _input = data.option.map(o=> <div><label><input type="checkbox"  onChange={e=>{ if(data.change) data.change(e); }} checked={this.state.language && this.state.language.indexOf(o.value) >= 0 ? true: false} className="uk-checkbox" value={o.value} /> {o.label}</label></div>)
                    break;
                  default:
                    _input = <input type={data.type || 'text'} className="uk-input" name={data.key} onChange={this.updateValue} value={this.state[data.key]} />
                }

                return <div key={data.key}>
                  <label>{data.label} {data.require ? <span className="uk-text-danger">*</span> : null}</label>
                  {_input}
                </div>
              })
            }
          </div>
          {this.consultCompanyRender()}
          {this.consultHospitalRender()}
          <div className="uk-text-right uk-width-1-1 uk-button-group uk-margin-top">
            <button className="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button">ยกเลิก</button>
            {
              this.state.loadingApi ?
                <span uk-spinner=""></span>
                :
                <button className="uk-input uk-button-primary" type="submit">{this.props.consult ? 'บันทึก' : 'เพิ่ม'}</button>
            }

          </div>

        </form>
      </div>
    </div>
  }
}

export default class AdminConsult extends Component {
  state = {
    consultList: [],
    formShow: false,
    consult: false,
    pageCount: 1,
    pageNow: 1,
    loadingApi: false,
    keywordName: '',
    keywordBranch: '',
    keywordCompany: '',
    keywordHospital: '',
    keywordTypeConsult: ['แพทย์','นักกำหนดอาหาร','เภสัชกร','พยาบาล','พยาบาลวิชาชีพ','นักวิชาการสาธารณสุข','ผู้ให้ความรู้โรคเบาหวาน','ผู้เชี่ยวชาญด้านการออกกำลังกาย','นักกายภาพบำบัด','ฯลฯ','']
  }

  perPage = 100

  componentDidMount() {
    this.init();
  }

  componentWillReceiveProps() {
    this.init();
    this.setState({ pageNow: 1 });
  }

  init() {
    this.consultListGet();
  }

  consultListGet = async () => {
    this.setState({ loadingApi: true });
    var { list, amount} = await Api.admin('ConsultList', {
      perPage: this.perPage,
      pageNow: this.state.pageNow,
      keywordBranch: this.state.keywordBranch,
      keywordCompany: this.state.keywordCompany,
      keywordName: this.state.keywordName,
      keywordTypeConsult: this.state.keywordTypeConsult.map(d=>`'${d}'`).join(','),
      keywordHospital: this.state.keywordHospital
    });
    this.setState({ consultList: list || [], pageCount: +amount / this.perPage, loadingApi: false }, () => this.setState({ formShow: false }));
  }

  handlePageClick = (page) => {
    this.setState({ pageNow: page.selected + 1 }, () => this.consultListGet())
  }

  paginateRender = () => {
    return <div className="uk-text-right">
      <ReactPaginate
        previousLabel={'previous'}
        nextLabel={'next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={this.state.pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={this.handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      />
    </div>
  }

  typeConsult = [{label: 'แพทย์', value: 'แพทย์'}, {label: 'นักกำหนดอาหาร', value: 'นักกำหนดอาหาร'}, { value: 'เภสัชกร', label: 'เภสัชกร' }, { value: 'พยาบาล', label: 'พยาบาล' }, { value: 'พยาบาลวิชาชีพ', label: 'พยาบาลวิชาชีพ' }, { value: 'นักวิชาการสาธารณสุข', label: 'นักวิชาการสาธารณสุข' }, {value: 'ผู้ให้ความรู้โรคเบาหวาน', label:'ผู้ให้ความรู้โรคเบาหวาน'}, {value: 'ผู้เชี่ยวชาญด้านการออกกำลังกาย', label: 'ผู้เชี่ยวชาญด้านการออกกำลังกาย'},{value: 'นักกายภาพบำบัด', label:'นักกายภาพบำบัด'}, {label: 'เจ้าหน้าที่อื่น', value: 'เจ้าหน้าที่อื่น'}, {label: 'ฯลฯ', value: 'ฯลฯ'}];

  keywordTypeConsultRender = (data) => {
    var _k = this.state.keywordTypeConsult;
    if(_k.indexOf(data) >= 0) {
      delete _k[_k.indexOf(data)];
    } else {
      _k.push(data);
    }

    this.setState({keywordTypeConsult: _k.filter(d=>( typeof d === 'string' ? true : false))})
  }

  render() {
    return <div>
      <div className="d-admin-header-row uk-text-right">
        <button className="uk-button uk-button-small uk-button-secondary" onClick={() => this.setState({ consult: false }, () => this.setState({ formShow: true }))} >เพิ่มที่ปรึกษา</button>
      </div>
      <div>
        <div uk-grid="" className="uk-grid-small uk-flex-middle">
          <div className="uk-width-auto">ค้นหาจาก ชื่อ-สกุล</div>
          <div className="uk-width-1-4@l uk-width-1-1">
            <input type="text" className="uk-input" value={this.state.keywordName} onChange={e => this.setState({ keywordName: e.target.value })} />
          </div>
          <div className="uk-width-auto">ประเภท</div>
          <div className="uk-width-1-4@l uk-width-1-1">
            {this.typeConsult.map(t => <label className="uk-margin-small-right"><input type="checkbox" className="uk-checkbox" onClick={()=>this.keywordTypeConsultRender(t.value)} value={t.value} checked={this.state.keywordTypeConsult.indexOf(t.value) >= 0 ? true: false} /> {t.label}</label>)}
          </div>
        </div>
        <div uk-grid="" className="uk-grid-small uk-flex-middle">
          <div className="uk-width-auto">สาขาแพทย์</div>
          <div className="uk-width-1-5@l uk-width-1-1">
            <input type="text" className="uk-input" value={this.state.keywordBranch} onChange={e => this.setState({ keywordBranch: e.target.value })} />
          </div>
          <div className="uk-width-auto">โรงพยาบาลวิชาชีพ</div>
          <div className="uk-width-1-5@l uk-width-1-1">
            <input type="text" className="uk-input" value={this.state.keywordHospital} onChange={e => this.setState({ keywordHospital: e.target.value })} />
          </div>
          <div className="uk-width-auto">บริษัทที่ดูแล</div>
          <div className="uk-width-1-5@l uk-width-1-1">
            <input type="text" className="uk-input" value={this.state.keywordCompany} onChange={e => this.setState({ keywordCompany: e.target.value })} />
          </div>
          <div className="uk-width-expand">
            <button className="uk-button uk-button-secondary" onClick={() => this.consultListGet()}>ค้นหา</button>
          </div>
        </div>
      </div>
      <AdminConsultAddForm {...this.props} callback={()=>{
        this.setState({formShow: false}, ()=>{
          this.consultListGet();
        });

        
      }} show={this.state.formShow} consult={this.state.consult} />
        {this.paginateRender()}
      <div className="uk-overflow-auto">
        <table className="uk-table uk-table-small uk-table-divider">
          <thead>
            <tr>
              <th>ลำดับ</th>
              <th>รหัสที่ปรึกษา</th>
              <th>ชื่อผู้ใช้</th>
              <th>ชื่อ-สกุล</th>
              <th>ประเภท</th>
              <th>สาขา</th>
              <th>โรงพยาบาลวิชาชีพ</th>
              <th>บริษัท</th>
              <th>แคมเปญ</th>
              <th>แก้ไข</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.loadingApi ?
                <tr><td colSpan="100%">
                  <div className="uk-padding uk-text-center"><div uk-spinner=""></div></div>
                </td></tr>
                :
                this.state.consultList && this.state.consultList.length ? this.state.consultList.map((data, key) => {
                  return <tr key={key}>
                    <td>{key + 1}</td>
                    <td>{data.id}</td>
                    <td>{data.username}</td>
                    <td>
                      {data.nameprefix} {data.name} {data.lastname}
                    </td>
                    <td>{data.typeconsult}</td>
                    <td>{data.typebranch}</td>
                    <td>{data.consultHospital && data.consultHospital.map(c=><div>{c.name}</div>)}</td>
                    <td>{data.consultCompany && data.consultCompany.map(c=><div>{c.name}</div>)}</td>
                    <td>{data.consultCampaign && data.consultCampaign.map(c=><span className='uk-label uk-margin-small-right uk-margin-small-bottom'>{c.name}</span>)}</td>
                    <td>
                      <a onClick={() => this.setState({ consult: data }, () => this.setState({ formShow: true }))} >
                        <i uk-icon="icon: pencil"></i>
                      </a>
                    </td>
                  </tr>
                })
                :
                <tr><td colSpan="100%">
                  <div className="uk-padding uk-text-center">ไม่มีรายการ</div>
                </td></tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  }
};
