import HeartCase from './HeartCase';


export default (data) => {
  var user = {
    sys: +data['sys/dias'].value.split('/')[0],
    chol: +data.chol.value,
    gender: +data.chol.gender,
    smoking: +data.chol.smoking,
    age: +data.chol.age,
    diabetes: +data.chol.diabetes
  };

  var color = HeartCase(user);
  var title = '';
  var caption = '';
  var suggest = '';

  switch (color) {
    case 'green':
      title = 'ความเสี่ยงต่ำ';
      caption = 'ความเสี่ยงต่ำ';
      suggest = 'หยุดสูบบุหรี่หรือไม่เริ่มสูบ (รวมทั้งยาสูบในรูปแบบอื่น) และไม่สูดดมควันบุหรี่เป็นประจํา ลดอาหารไขมันสูง ลดอาหารเค็มหรือมีเกลือโซเดียมสูง ลดอาหารและเครื่องดื่มที่มีรสหวานหรือนํ้าตาลสูง  เพิ่มผักสดและผลไม้ที่ไม่หวานจัด เดินเร็ว อย่างน้อย 30 นาทีต่อวัน ลดหรืองดการบริโภคแอลกอฮอล์ ควบคุมน้ำหนัก ความดันโลหิตให้อยู่ในเกณฑ์ที่เหมาะสม ในผู้ป่วยเบาหวานให้ควบคุมระดับน้ำตาลในเลือดให้อยู่ในระดับที่เหมาะสม';
      break;
    case 'yellow':
      title = 'ความเสี่ยงปานกลาง';
      caption = 'ความเสี่ยงปานกลาง';
      suggest = 'หยุดสูบบุหรี่หรือไม่เริ่มสูบ (รวมทั้งยาสูบในรูปแบบอื่น) และไม่สูดดมควันบุหรี่เป็นประจํา ลดอาหารไขมันสูง ลดอาหารเค็มหรือมีเกลือโซเดียมสูง ลดอาหารและเครื่องดื่มที่มีรสหวานหรือนํ้าตาลสูง  เพิ่มผักสดและผลไม้ที่ไม่หวานจัด เดินเร็ว อย่างน้อย 30 นาทีต่อวัน  ลดหรืองดการบริโภคแอลกอฮอล์ ควบคุมน้ำหนัก ความดันโลหิตให้อยู่ในเกณฑ์ที่เหมาะสม ในผู้ป่วยเบาหวานให้ควบคุมระดับน้ำตาลในเลือดให้อยู่ในระดับที่เหมาะสม';
      break;
    case 'orange':
      title = 'ความเสี่ยงสูง';
      caption = 'ความเสี่ยงสูง';
      suggest = 'หยุดสูบบุหรี่หรือไม่เริ่มสูบ (รวมทั้งยาสูบในรูปแบบอื่น) และไม่สูดดมควันบุหรี่เป็นประจํา ลดอาหารไขมันสูง ลดอาหารเค็มหรือมีเกลือโซเดียมสูง ลดอาหารและเครื่องดื่มที่มีรสหวานหรือนํ้าตาลสูง  เพิ่มผักสดและผลไม้ที่ไม่หวานจัด เดินเร็ว อย่างน้อย 30 นาทีต่อวัน  ลดหรืองดการบริโภคแอลกอฮอล์ ควบคุมน้ำหนัก ความดันโลหิตให้อยู่ในเกณฑ์ที่เหมาะสม ในผู้ป่วยเบาหวานให้ควบคุมระดับน้ำตาลในเลือดให้อยู่ในระดับที่เหมาะสม';
      break;
    case 'red':
      title = 'ความเสี่ยงสูงมาก';
      caption = 'ความเสี่ยงสูงมาก';
      suggest = 'หยุดสูบบุหรี่หรือไม่เริ่มสูบ (รวมทั้งยาสูบในรูปแบบอื่น) และไม่สูดดมควันบุหรี่เป็นประจํา ลดอาหารไขมันสูง ลดอาหารเค็มหรือมีเกลือโซเดียมสูง ลดอาหารและเครื่องดื่มที่มีรสหวานหรือนํ้าตาลสูง  เพิ่มผักสดและผลไม้ที่ไม่หวานจัด เดินเร็ว อย่างน้อย 30 นาทีต่อวัน  ลดหรืองดการบริโภคแอลกอฮอล์ ควบคุมน้ำหนัก ความดันโลหิตให้อยู่ในเกณฑ์ที่เหมาะสม ในผู้ป่วยเบาหวานให้ควบคุมระดับน้ำตาลในเลือดให้อยู่ในระดับที่เหมาะสม';
      break;
    case 'reder':
      title = 'ความเสี่ยงสูงอันตราย';
      caption = 'ความเสี่ยงสูงอันตราย';
      suggest = 'หยุดสูบบุหรี่หรือไม่เริ่มสูบ (รวมทั้งยาสูบในรูปแบบอื่น) และไม่สูดดมควันบุหรี่เป็นประจํา ลดอาหารไขมันสูง ลดอาหารเค็มหรือมีเกลือโซเดียมสูง ลดอาหารและเครื่องดื่มที่มีรสหวานหรือนํ้าตาลสูง  เพิ่มผักสดและผลไม้ที่ไม่หวานจัด เดินเร็ว อย่างน้อย 30 นาทีต่อวัน  ลดหรืองดการบริโภคแอลกอฮอล์ ควบคุมน้ำหนัก ความดันโลหิตให้อยู่ในเกณฑ์ที่เหมาะสม ในผู้ป่วยเบาหวานให้ควบคุมระดับน้ำตาลในเลือดให้อยู่ในระดับที่เหมาะสม';
      break;
  }

  return {
    name: 'หัวใจ',
    value: title,
    detail: {
      title,
      color, 
      caption,
      suggest
    }
  }

}