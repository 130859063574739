import React, { Component } from "react";
import Api from "../Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import moment from "moment";
import Select from 'react-select'
import UploadFirebase from "../UploadFirebase";
import MemberProfileChatCard from '../MemberProfileChatCard';
import Thumbnail from '../Thumbnail';
import ImageUri from '../ImageUri';
import Media from "../Media";
import firebase from "firebase";
import TeleConsultHeader from './TeleConsultHeader';

UIkit.use(Icons);

export default class TeleConsultRoom extends Component {

  formTemplate = {
    id: false,
    to: this.props.qs.receiveId,
    detail: '',
    memberref: [],
    file: []
  }

  state = {
    dateStart: moment().subtract(1, 'month').format('YYYY-MM-DD'),
    dateStop: moment().format('YYYY-MM-DD'),
    member: [],
    memberLoading: false,
    formShow: false,
    form: {
      ...this.formTemplate
    },
    saveLoading: false,
    list: [],
    listShow: [],
    listLoading: true,
    keywordPatient: '',
    campaignModalShow: false,
    memberCampaignList: [],
    memberCampaignSelect: '',
    memberIdSelect: null,
    fileRemove: []
  };

  memberById = {}

  user = {}

  meId = null

  roomKey = null

  memberAllInCompay = async () => {
    this.setState({ memberLoading: true });

    var _response = await Api.consult("TeleconsultMemberInCompany");

    var _member = [];
    if(_response.list != null && _response.list.length) {
      _member = _response.list.map((data)=>{
        var _label = `${data.nameprefix} ${data.name} ${data.lastname}`;

        this.memberById[data.id] = _label;
        return {
          label: _label,
          value: data.id
        }
      });
    }

    this.setState({
      memberLoading: false,
      member: _member
    });
  }

  MemberCampaignGet = async (memberId) => {
    UIkit.notification('กำลังโหลดข้อมูล');

    var _response = await Api.consult("CampaignListByMember", {
      memberId
    });

    UIkit.notification.closeAll();

    if(_response.list == null || _response.list.length === 0) {
      UIkit.notification('ไม่พบแคมเปญ โปรดเลือกแคมเปญให้กับคนไข้');

      this.setState({memberCampaignList: []});
    } else {
      this.setState({memberCampaignList: _response.list, memberCampaignSelect: _response.list[0].id, memberIdSelect: memberId, campaignModalShow: true});

    }
  }

  MemberDetailGet = async (memberId) => {
    this.MemberCampaignGet(memberId);
  }

  listShowRender = () => {

    if(this.state.keywordPatient == '') {
      this.setState({listShow: this.state.list});
    } else {
      this.setState({
        listShow: this.state.list.filter((data)=>{
          if(data.memberref == null) return false;
          
          var _is_name_in_keyword = false;

          data.memberref.map((memberId) => {
            if(this.memberById[memberId] != null && this.memberById[memberId].includes(this.state.keywordPatient) === true) {
              _is_name_in_keyword = true;
            }
          });

          return _is_name_in_keyword;
        })
      })
    }
  }

  listGet = async () => {
    this.setState({listLoading: true});

    var _response = await Api.consult("TeleconsultList", {to: this.state.form.to, dateStart: this.state.dateStart, dateStop: this.state.dateStop});

    if(_response.consult) this.meId = _response.consult.id;

    if(_response.receiver) {
      _response.receiver.online = (moment().diff(moment(_response.receiver.datelogin), 'second') > 300 ? false : true)

      this.user[_response.receiver.id] = _response.receiver;
    }


    if(_response.sender) {
      _response.sender.online = (moment().diff(moment(_response.sender.datelogin), 'second') > 300 ? false : true)

      this.user[_response.sender.id] = _response.sender;
    }

    this.setState({
      list: _response.list || [],
      listLoading: false
    }, this.listShowRender);
  }

  listenUpdate = () => {
    firebase.database().ref('teleconsultroom/' + this.roomKey).on('value', (snapshot) => {
      this.listGet();

      this.readUpdate();

    });
  }

  readUpdate = async () => {
    var _response = await Api.consult("TeleconsultReadUpdate", {to: this.state.form.to});
  }

  roomkeySet = () => {
    
    if(+this.props.consult.id < +this.state.form.to) {
      this.roomKey = `${this.props.consult.id}_${this.state.form.to}`;
    } else {
      this.roomKey = `${this.state.form.to}_${this.props.consult.id}`;
    }
  }

  save = async () => {
    try {

      this.setState({saveLoading: true});

      var _response = await Api.consult("TeleconsultAdd", {
        ...this.state.form,
        title: this.state.form.detail.split('\n')[0].substring(0, 100)
      });

      if(_response.result === true) {
        
        for(var file of this.state.fileRemove) {
          firebase.storage().ref(decodeURIComponent(file).split("?")[0]).delete()
          .then(() => {
          })
          .catch((error) => {
            console.error(error)
          });
        }
        
        
        this.setState({formShow: false, fileRemove: [], form: {
          ...this.formTemplate
        }});

        firebase.database().ref('teleconsultroom/' + this.roomKey).set(+new Date);
        firebase.database().ref('teleconsult/' + this.state.form.to).set(+new Date);
      }

      throw new Error(_response.responseText);

    } catch(e) {
      UIkit.notification(e.message);
    }

  }

  formValueUpdate = (key, value) => {
    this.setState((state) => {
      state.form[key] = value;
      return {form: state.form}
    })
  }
  
  init = () => {
    this.roomkeySet();
    this.memberAllInCompay();
    this.listenUpdate();
  }

  componentDidMount() {
    this.init();
  }

  filePreviewRender = (file) => {

    return file.map((url)=>{
      return <span className="uk-margin-small-right" key={encodeURIComponent(url)}><Media file={url} /> </span>
    })
  }

  fileRemove = (file) =>{
    if(window.confirm("ยืนยันการลบไฟล์นี้ ?") === true) {
      this.setState((state)=>{
        var _index = this.state.form.file.indexOf(file);
  
        if(_index >= 0) {
          delete state.form.file[_index];

            state.form.file = state.form.file.filter(Boolean);

            state.fileRemove.push(file);
        }
  
        return {
          form: state.form
          , fileRemove: state.fileRemove
        }
      })

    }
  }

  render() {
    return ( <div className="uk-padding">
      {
        this.state.formShow === true && <div className="d-popup-background">
          <div className="d-popup-body">
            <h4 className="uk-text-bold">ส่งข้อความใหม่ <button className="uk-float-right" uk-close="" onClick={()=>this.setState({
              formShow: false,
              form: {...this.formTemplate}
              })}></button></h4>
            <div>
              <div className="uk-margin-small-bottom">เนื้อหา</div>
              <textarea placeholder="เนื้อหา" className="uk-textarea" rows={5} value={this.state.form.detail} onChange={(e)=>{
                this.formValueUpdate('detail', e.target.value);
              }}></textarea>
            </div>
            <div className="uk-margin-small-top">
              <div className="uk-margin-small-bottom">อ้างถึงคนไข้ (ถ้ามี)</div>
              <div>
                {this.state.memberLoading === false && <Select defaultValue={this.state.member.filter((member)=>{
                  return this.state.form.memberref.includes(member.value);
                })} isMulti={true} options={this.state.member} onChange={(value)=>{
                  this.formValueUpdate('memberref', value.map((data)=>{
                    return data.value;
                  }))
                }} />}
              </div>
            </div>
            <div className="uk-margin-small-top">
              <div className="uk-margin-small-bottom">แนบไฟล์หรือรูปภาพ <span className="uk-text-meta">(รองรับไฟล์รูปภาพนามสกุล jpg, jpeg, png, gif, tiff รองรับไฟล์วีดีโอ mp4 และเอกสารนามสกุล pdf)</span></div>
              <div>{this.state.form.file.map((file)=>{

                return <span className="uk-margin-right uk-inline">
                  <a className="d-teleconsult-media-delete-button" uk-icon="icon: trash; ratio: 0.5" onClick={()=>this.fileRemove(file)}></a>
                  {<Media file={file} />}
                </span>
              })}</div>
              <label className="uk-margin-small-top uk-button uk-button-default uk-width-1-1">
                <span uk-icon="upload"></span> อัพโหลดไฟล์
                <UploadFirebase
                  maxWidth={100}
                  maxHeight={100}
                  accept="image/jpg,image/jpeg,image/tiff,image/gif,image/png,application/pdf,video/mp4"
                  cb={(file) => {
                    this.setState((state)=>{
                      state.form.file.push(file);

                      return {form: state.form}
                    })
                  }}
                />
              </label>
            </div>
            <div className="uk-margin-top uk-text-right">
              <button className="uk-button-default uk-button uk-margin-right" onClick={()=>this.setState({formShow
                : false})}>ยกเลิก</button>
              <button className="uk-button-primary uk-button" onClick={this.save}>บันทึก</button>
            </div>
          </div>
        </div>
      }
      {
        this.state.campaignModalShow === true && 
        <div className="d-popup-background">
          <div className="d-popup-body d-popup-body-small">
            <h4>โปรดเลือกแคมเปญ
              <button className="uk-float-right" uk-close="" onClick={()=>this.setState({campaignModalShow: false})}></button>
            </h4>
            <div>
              <select className="uk-select" value={this.state.memberCampaignSelect} onChange={(e)=>this.setState({memberCampaignSelect: e.target.value})}>{
                this.state.memberCampaignList.map((campaign) => {
                  return <option value={campaign.id}>{campaign.name}</option>
                })
              }</select>
            </div>
            <div className="uk-text-right uk-margin-top">
              <button className="uk-button uk-button-default" onClick={()=>this.setState({campaignModalShow: false})}>ยกเลิก</button>
              <button className="uk-button uk-button-primary uk-margin-small-left" onClick={()=>{
                this.setState({
                  memberProfileCardShow: true,
                  campaignModalShow: false
                })
              }} >เลือก</button>
            </div>
          </div>
        </div>
      }
      
        {
          this.state.memberProfileCardShow === true &&
          this.state.memberCampaignSelect && <div className="d-popup-background">
          <div className="d-popup-body">
            <div className="uk-text-right uk-margin-small-bottom">
              <button uk-close="" onClick={()=>this.setState({memberProfileCardShow: false})}></button>
            </div>
            <MemberProfileChatCard
              memberId = {this.state.memberIdSelect}
              campaignId = {this.state.memberCampaignSelect}
              apiType = 'consult'
            />
            </div>
          </div>
        }
        {this.state.listLoading === false && this.user[this.props.qs.receiveId] != null && <div className="uk-card uk-card-default uk-padding-small uk-margin-bottom">
          <div className="d-card-header">
            <div className="d-card-header-left">
              ส่งข้อความถึง
            </div>
          </div>
          <TeleConsultHeader data={this.user[this.props.qs.receiveId]} consult={this.props.consult} hideMessageButton={true} hideNotiIcon={true} hideLastMessage={true} />
        </div>}
        <div className="uk-card uk-card-default uk-padding-small">
          <div className="d-card-header">
            <div className="d-card-header-left">
              ข้อความสนทนา
            </div>
            <div className="d-card-header-right">
              <button className="uk-button uk-button-primary uk-text-center" onClick={()=>this.setState({formShow
              : true})}><i uk-icon="plus-circle"></i><span className="uk-visible@s"> ส่งข้อความใหม่</span></button>
            </div>
          </div>
          <div className="uk-grid uk-grid-small uk-flex-middle" >
            <div className="uk-width-expand@s uk-width-1-1 uk-margin-small-bottom">
              <div className="uk-grid uk-grid-small uk-flex-middle">
                <div className="uk-width-1-6">
                  วันที่
                </div>
                <div className="uk-width-expand">
                  <input type="date" className="uk-input" value={this.state.dateStart} onChange={(e)=>this.setState({dateStart: e.target.value})} />
                </div>
              </div>
            </div>
            <div className="uk-width-expand@s uk-width-1-1 uk-margin-small-bottom">
              <div className="uk-grid uk-grid-small uk-flex-middle">
                <div className="uk-width-1-6">
                  ถึง
                </div>
                <div className="uk-width-expand">
                  <input type="date" className="uk-input" value={this.state.dateStop} onChange={(e)=>this.setState({dateStop: e.target.value})} />
                </div>
              </div>
            </div>
            <div className="uk-width-auto@s uk-width-1-1 uk-margin-small-bottom uk-text-right">
              <button className="uk-button uk-button-primary" onClick={this.listGet}>ค้นหา</button>
            </div>
          </div>
          <div className="uk-width-expand@s uk-width-1-1 uk-margin-small-bottom">
            <div className="uk-grid uk-grid-small uk-flex-middle">
              <div className="uk-width-expand">
                <input type="text" className="uk-input" placeholder="ค้นหาชื่อคนไข้" defaultValue={this.state.keywordPatient} onChange={(e)=>this.setState({keywordPatient: e.target.value}, this.listShowRender)} />
              </div>
            </div>
          </div>
          
          <div className="uk-padding-small">
              {
                this.state.listLoading === true ? 
                <div className="uk-text-center"><span uk-spinner=""></span></div>
                :
                (this.state.listShow.length === 0 ? 
                <div className="uk-padding uk-text-center">ไม่พบข้อมูล</div>
                :
                <div>
                  {
                    this.state.listShow.map((data) => {
                      var _user = this.user[data.senderid] || {};

                      return <div key={'row'+data.id}>
                        <div className="uk-grid-small uk-margin-bottom" uk-grid="">
                          <div className="uk-width-auto">
                            <div className="uk-margin-top">
                              <Thumbnail image={_user.image ? _user.image : ''} online={_user.online} />
                            </div>
                          </div>
                          <div className="uk-width-expand@s uk-width-1-1">
                              <div><b>{_user.nameprefix} {_user.name} {_user.lastname}</b></div>
                              <div className="uk-text-meta uk-text-small">
                                {moment(data.dateadd).format('วันที่ DD/MM/YYYY เวลา HH:mm น.')} 
                              </div>
                            <div className="d-teleconsult-message-container uk-overflow-auto">
                              <span className="d-teleconsult-message-arrow"></span>
                              {data.senderid == this.meId && <a uk-icon="pencil" className="uk-float-right" onClick={()=>{
                                this.setState((state) => {
                                  var _form = {...this.formTemplate};

                                  for(var k in _form) {
                                    if(data[k] != null)
                                      _form[k] = data[k];
                                  }

                                  state.form = _form;

                                  return {form: state.form, formShow: true}
                                })
                              }}></a>}
                              <div className="uk-margin-small-bottom" dangerouslySetInnerHTML={{__html: data.detail}}></div>
                              <div>
                                {this.filePreviewRender(data.file)}
                              </div>
                              {
                                data.memberref != null && data.memberref.length ? 
                                <div>
                                  <div className="d-teleconsult-line" ></div>
                                  <div className="uk-text-small"><b>อ้างถึงคนไข้ : </b>
                                  {
                                    data.memberref.map((memberId) => {
                                      return <span key={'memberref'+ data.id + '_' + memberId} className="uk-label uk-margin-small-left d-background-gray d-teleconsult-member-label" onClick={()=>this.MemberDetailGet(memberId)}><span uk-icon="user"></span> {this.memberById[memberId]}</span>
                                    })
                                  }
                                  </div>
                                </div>
                                :
                                null
                              }
                              </div>
                            </div>
                          </div>
                      </div>
                    })
                  }
                </div>)
              }
          </div>
        </div>
      </div>
    );
  }
}
