import React, { Component } from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Api from '../../Components/Api';
import Formular from '../Formular';
import GroupReport from '../Formular/GroupReport';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import numeral from 'numeral';
import FullScreenElement from '../FullScreenElement';

UIkit.use(Icons);


var dataIcon = {};

export default class UserSmartHealthTrendingRisk extends Component {

  state = {
    data2Year: [],
    data5Year: [],
    data10Year: [],
    loadingApi: false
  }

  componentDidMount() {
    this.dataGet();
  }

  dataGet = async () => {
    if(this.state.loadingApi === true) return false;

    this.setState({ loadingApi: true });

    var { yearGraph, data } = await Api.member('UserSmartHealthTrendingRisk');

    var _member = {};

    var _dataTypeAmount = {};

    /*Format data */
    for (var i in data) { /** Year */
      for (var i1 in data[i]) { /** Data name */
        _dataTypeAmount[i1] = data[i][i1].length;
        for (var i2 = data[i][i1].length; i2-- > 0;) {
          if (data[i][i1][i2]) {
            for (var i3 = data[i][i1][i2].length; i3-- > 0;) {
              var _memberData = data[i][i1][i2][i3];

              if (!_member[_memberData.memberid]) _member[_memberData.memberid] = {};
              if (!_member[_memberData.memberid][i]) _member[_memberData.memberid][i] = {};

              if (Formular[i1]) {
                _member[_memberData.memberid][i][i1] = {
                  ..._memberData,
                  detail: Formular[i1](_memberData.value, _memberData.age, _memberData.gender)
                }
              }
            }
          }

        }
      }
    }

    /** find summary of risk */
    var _dataByYear = {};

    for (var i in _member) { /** member id */
      for (var i1 in _member[i]) { /** year */

        var _groupReport = GroupReport(_member[i][i1]);

        if (!_dataByYear[i1]) _dataByYear[i1] = {};

        for (var g = _groupReport.length; g-- > 0;) {
          if (!_dataByYear[i1][_groupReport[g].label]) _dataByYear[i1][_groupReport[g].label] = {
            green: 0,
            yellow: 0,
            orange: 0,
            red: 0
          };

          _dataByYear[i1][_groupReport[g].label][_groupReport[g].color]++;

          if (!dataIcon[_groupReport[g].label]) dataIcon[_groupReport[g].label] = _groupReport[g].icon;
        }

      }
    };

    var _yearRiskData = {
      '2': {},
      '5': {},
      '10': {}
    };


    //
    var _yearRiskRender = (numberOfYear) => {
      var _data = [];

      yearGraph.forEach((year, key)=>{
        if(key < numberOfYear) {
          _data.push(_dataByYear[year]);
        } else {
          return false;
        }
      });
      _data.reverse();

      if(_data[0] === undefined) {
        return false;
      } else {
        _data.forEach((dataOfyear, key) => {
          var _amoutYear = _data.length-key;
          if(dataOfyear) {
            for(var i in dataOfyear) {
  
              if(!_yearRiskData[numberOfYear][i]) _yearRiskData[numberOfYear][i] = {
                year: 0,
                color: 'green'
              };
  
              if(dataOfyear[i].green === 0) { //มีความเสี่ยง เพราะจำนวนที่ปกติเป็น 0
                _yearRiskData[numberOfYear][i].year = _amoutYear;
                
                if(key === 0) { //ใช้สีของความเสี่ยงของปีที่เลือก(numberOfYear) เท่านั้น (key === 0 คือปีที่เลือก ไม่ใช่ปีล่าสุด)
                  for(var i1 in dataOfyear[i]) {
                    if(dataOfyear[i][i1] > 0)
                      _yearRiskData[numberOfYear][i].color = i1;
                  }
                }
                
              }
              
            }
          }
        })
      }
    }

    _yearRiskRender(2);
    _yearRiskRender(5);
    _yearRiskRender(10);



    /*** setting for data in table */

    var _dataTableRender = (_data) => {
      var _dataTable = [];


      for (var i in _data) {
        _dataTable.push({
          label: i,
          icon: dataIcon[i],
          year: _data[i].year,
          color: _data[i].color
        })
      }

      return _dataTable.reverse();
    }


    this.setState({
      data2year: _dataTableRender(_yearRiskData['2']),
      data5year: _dataTableRender(_yearRiskData['5']),
      data10year: _dataTableRender(_yearRiskData['10']),
      
    }, ()=>{
      setTimeout(() => {
        this.setState({loadingApi: false});
      }, 100)
    });


  }

  badge = (color) => {
    var _label = '';
    switch (color) {
      case 'green':
        _label = 'Normal';
        break;
      case 'yellow':
        _label = 'Low Risk';
        break;
      case 'orange':
        _label = 'Risk';
        break;
      case 'red':
        _label = 'High Risk';
        break;
    }

    return <span className={`d-risk-label d-risk-${color}`}>{_label}</span>
  }

  columns = [
    {
      name: '',
      cell: row => <div className="d-table-icon-container">{row.icon === '' ? '' : <div className={`d-risk-logo-container d-risk-${row.color}`} ><img className="d-risk-logo" src={`/assets/${row.icon}.png`} /></div>}</div>,
      sortable: false
    },
    {
      name: 'Health Risk',
      cell: row => <div>
        <div>{row.label}</div>
        <div>{row.color === 'green' ? 'ปกติ' : 'ผิดปกติ'}</div>
      </div>,
      sortable: false
    },
    {
      name: 'Status',
      cell: row => <div>{this.badge(row.color) || ''}</div>,
      sortable: false
    },
    {
      name: 'จำนวนปีที่เสี่ยง',
      cell: row => <div>{row.year}</div>,
      sortable: false
    }
  ];

  render() {
    return <div className="uk-card uk-card-default uk-padding-small" id="d-smarth-health-trending-risk">
      <div className="d-card-header">
        <div className="d-card-header-left">
          YOUR TRENDING RISK
        </div>
        <div className="d-card-header-right">
          <span className='icon-reload' onClick={this.dataGet}></span>
          <FullScreenElement element="#d-smarth-health-trending-risk" />
        </div>
      </div>
      {
        this.state.loadingApi ?
          <div className="uk-paddin uk-text-center">
            <span uk-spinner=""></span>
          </div>
          :
          <div className="uk-margin-small-top">
            <ul uk-tab="">
              <li><a href="#">2 Year</a></li>
              <li><a href="#">5 Year</a></li>
              <li><a href="#">10 Year</a></li>
            </ul>
            <ul className="uk-switcher">
              <li>
                <DataTable
                  noHeader={true}
                  columns={this.columns}
                  data={this.state.data2year}
                />
              </li>
              <li>
                <DataTable
                  noHeader={true}
                  columns={this.columns}
                  data={this.state.data5year}
                />
              </li><li>
                <DataTable
                  noHeader={true}
                  columns={this.columns}
                  data={this.state.data10year}
                />
              </li>
            </ul>
          </div>
      }


    </div>
  }
};
