import Bmi from './Bmi';
import NovalueCheck from './NoValueCheck';

export default (value, age, gender) => {
  if(gender == '1') gender = 'male';
  if(gender == '0') gender = 'female';

  if(NovalueCheck(value)) return NovalueCheck(value);

  value = +value;
  var maxValue = 32;
  if(gender == 'male') { /** ผู้ชาย */
    maxValue = 36;
  }

  if(value < maxValue) {
    return { 
      title: 'เส้นรอบเอวปกติ',
      color: 'green',
      caption: 'เส้นรอบเอวปกติ',
      suggest: 'รับประทานอาหารและออกกำลังกายตามปกติ'
    };
  } else {
    return Bmi(value);
  }
}