import React, { Component } from "react";

export default class UserHealthRecord extends Component {
  dataType = [
    {
      name: "โรคอ้วนลงพุง",
      value: "bmi,waistline",
      type: "category",
    },
    {
      name: "bmi",
      value: "bmi",
      type: "data",
    },
    {
      name: "Waist Line",
      value: "waistline",
      type: "data",
    },
    {
      name: "โรคความดันโลหิตสูง",
      value: "sys/dias",
      type: "category",
    },
    {
      name: "sys/dias",
      value: "sys/dias",
      type: "data",
    },
    {
      name: "โรคไขมันในเลือดสูง",
      value: "chol,tg,hdl,ldl",
      type: "category",
    },
    {
      name: "Cho (คลอเรสเตอรอล)",
      value: "chol",
      type: "data",
    },
    {
      name: "Tg (ไตรกลีเซอไรด์)",
      value: "tg",
      type: "data",
    },
    {
      name: "HDL",
      value: "hdl",
      type: "data",
    },
    {
      name: "LDL",
      value: "ldl",
      type: "data",
    },
    {
      name: "โรคเบาหวาน",
      value: "fbs/fpg,hba1c",
      type: "category",
    },
    {
      name: "FBS หรือ FPG ระดับน้ำตาลในเลือด",
      value: "fbs/fpg",
      type: "data",
    },
    {
      name: "HbA1C ระดับน้ำตาลเฉลี่ยสะสม",
      value: "hba1c",
      type: "data",
    },
    {
      name: "โรคไต",
      value: "bun,creatinine,egfr",
      type: "category",
    },
    {
      name: "BUN",
      value: "bun",
      type: "data",
    },
    {
      name: "Creatinine",
      value: "creatinine",
      type: "data",
    },
    {
      name: "e gfr",
      value: "efgr",
      type: "data",
    },
    {
      name: "กรดยูริค",
      value: "uric",
      type: "category",
    },
    {
      name: "uric",
      value: "uric",
      type: "data",
    },
    {
      name: "การทำงานของตับ",
      value: "sgpt,sgot,alp",
      type: "category",
    },
    {
      name: "SGPT",
      value: "sgpt",
      type: "data",
    },
    {
      name: "SGOT",
      value: "sgot",
      type: "data",
    },
    {
      name: "ALP",
      value: "alp",
      type: "data",
    },
    {
      name: "เอกซ์เรย์ปอด",
      value: "lung",
      type: "category",
    },
    {
      name: "Lung",
      value: "lung",
      type: "data",
    },
  ];

  render() {
    return (
      <select
        className="uk-select uk-width-small@s uk-width-1-1"
        value={this.props.dataType}
        onChange={(e) => {
          if (this.props.callback) this.props.callback(e.target.value);
        }}
      >
        {this.props.onlyCategory === true
          ? this.dataType.map((data, index) => {
              if (data.type === "category")
                return (
                  <option value={data.value} key={"option_cat" + index}>
                    {data.name}
                  </option>
                );
              else return null;
            })
          : this.dataType.map((data, index) => {
              if (data.type === "data") {
                return (
                  <option value={data.value} key={"option" + index}>
                    - {data.name}
                  </option>
                );
              } else if (data.type === "category") {
                return (
                  <option
                    disabled={this.props.notSelectCategory ? true : false}
                    value={data.value}
                    key={"option_cat" + index}
                  >
                    {data.name}
                  </option>
                );
              }
            })}
      </select>
    );
  }
}
