import React, { Component } from 'react';
import SMCPayment from '../../Components/Consult/SMCPayment';
import SMCReport from '../../Components/Consult/SMCReport';
export default class ConsultStatistic extends Component {

  render() {
    return <div className="uk-padding">
      <div className='uk-margin-top'>
        <SMCPayment {...this.props} />
      </div>
    </div>
  }
};
