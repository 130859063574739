import React, {Component} from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import IconCard from '../../Components/Member/IconCard';
import Api from '../../Components/Api';

UIkit.use(Icons);

export default class DashboardConsultant extends Component {

  state = {
    consultant: <span uk-spinner=""></span>
  }

  componentDidMount() {
    this.userGet();
  }

  async userGet() {
    var {consultant} = await Api.member('DashboardConsultant');
    this.setState({consultant: '+ ' + consultant});
  }

  render() {
    return <IconCard option={{
      backgroundColorLeft: '#11a578',
      backgroundColorRight: '#16d39a',
      icon: 'people',
      title: 'Consultant',
      caption: this.state.consultant || 0
    }} />
  }
};
