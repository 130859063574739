import React, {Component} from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import IconCard from '../../Components/Member/IconCard';
import Api from '../../Components/Api';

UIkit.use(Icons);

export default class CampaignReportUser extends Component {

  state = {
    user: <span uk-spinner=""></span>
  }

  componentDidMount() {
    this.userGet();
  }

  async userGet() {
    var {user} = await Api.member('CampaignReportUser', {campaignId: this.props.id});
    this.setState({user});
  }

  render() {
    return <IconCard option={{
      backgroundColorLeft: '#008385',
      backgroundColorRight: '#00b5b8',
      icon: 'people',
      title: 'User',
      caption: '',
      value: this.state.user || 0
    }} />
  }
};
