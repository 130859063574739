import React, { Component } from "react";
import Api from "../Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import numeral from "numeral";
import moment from "moment";
UIkit.use(Icons);

export default class ConsultCampaignTable extends Component {
  state = {
    list: [],
    expireStatus: 'all',
    keyword: '',
    listMember: [],
    search: false
  };

  componentDidMount() {
    this.init();
  }

  init = () => {
    this.listGet();
  };

  csvGet = async (token) => {
    var _response = await Api.consult("ConsultCampaignMemberTableCsv", {token});

    if(_response.result !== true) {
      UIkit.notification(_response.responseText);
    } else {
      
      window.open("https://server.dietz.asia/download.php?token=" + _response.token);
    }

  }

  listGet = async () => {
    var _response = await Api.consult("ConsultCampaignTableGet");

    this.setState({ list: _response.list || [] });
  };

  memberListGet = async () => {
    if(!this.state.keyword) {
      UIkit.notification('โปรดใส่คำค้นหา');

      return;
    }

    var _response = await Api.consult("ConsultCampaignMemberTableGet", {
      keyword: this.state.keyword,
      expireStatus: this.state.expireStatus
    });

    this.setState({listMember: _response.list, search: true});
  }

  memberListRender = () => {
    if(this.state.keyword.length === 0 )
      return [];
    else if(this.state.search === true)
      return <div className="uk-overflow-auto">
          <div className="uk-card uk-card-default uk-margin-top-small uk-margin-bottom uk-padding-small">
          <div className="d-card-header">
            <div className="d-card-header-left">ตารางคนไข้ในแคมเปญ</div>
            <div className="d-card-header-right"></div>
          </div>
          <div className="uk-overflow-auto">
            <table className="uk-table uk-table-divider uk-table-small">
              <thead>
                <tr>
                  <th>ชื่อแคมเปญ</th>
                  <th>ชื่อคนไข้</th>
                  <th>ว/ด/ป เกิด</th>
                  <th>อายุ</th>
                  <th>ระยะเวลาแคมเปญ (วัน)</th>
                  <th>วันที่เริ่ม</th>
                  <th>วันที่สิ้นสุด</th>
                  <th>ค่าใช้จ่าย</th>
                  <th>สถานะ</th>
                </tr>
              </thead>
              <tbody>
                {this.state.listMember.length == 0 ? (
                  <tr>
                    <td colSpan={"100%"}>
                      <div className="uk-padding uk-text-center">ไม่มีรายการ</div>
                    </td>
                  </tr>
                ) : (
                  this.state.listMember.map((data, index) => {
                    return (
                      <tr key={`rowList${index}`}>
                        <td>{data.campaign_name}</td>
                        <td>
                          {data.member_name}
                        </td>
                        <td>{moment(data.birthday,'YYYY-MM-DD').format('DD/MM/YYYY')}</td>
                        <td>{moment().diff(moment(data.birthday,'YYYY-MM-DD'), 'year')}</td>
                        <td>{data.day}</td>
                        <td>{moment(data.datestart).format('DD/MM/YYYY')}</td>
                        <td>{moment(data.datestop).format('DD/MM/YYYY')}</td>
                        <td>{numeral(data.price).format("0,0.00")}</td>
                        <td>
                          {data.expire_status}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
  }

  render() {
    return <div className="uk-card uk-card-default uk-margin-top uk-padding-small">
      <div className="d-card-header">
        <div className="d-card-header-left">ตารางคนไข้ในแคมเปญ</div>
        <div className="d-card-header-right"></div>
      </div>
      <div className="uk-margin-bottom" uk-grid="">
        <div className="uk-width-1-1 uk-width-expand@s">
          <input type="text" className="uk-input" placeholder="ค้นหาคนไข้จากชื่อ" value={this.state.keyword} onChange={e=>this.setState({keyword: e.target.value})} />
        </div>
        <div className="uk-width-1-1 uk-width-auto@s">
          <select className="uk-select" value={this.state.expireStatus} onChange={e=>this.setState({expireStatus: e.target.value})}>
            <option value="all">ทั้งหมด</option>
            <option value="expire">หมดอายุ</option>
            <option value="noexpire">ไม่หมดอายุ</option>
          </select>
        </div>
        <div className="uk-width-1-1 uk-width-auto@s">
          <button className="uk-button uk-button-primary" onClick={this.memberListGet}>ค้นหา</button>
        </div>
      </div>
      {this.memberListRender()}
      <div className="uk-overflow-auto">
        <table className="uk-table uk-table-divider uk-table-small">
          <thead>
            <tr>
              <th>ชื่อแคมเปญ</th>
              <th>จำนวนคนไข้ในแคมเปญ</th>
              <th>หมดอายุ</th>
              <th>ไม่หมดอายุ</th>
              <th>ส่งออก</th>
            </tr>
          </thead>
          <tbody>{
          this.state.list.length > 0 ? 
          this.state.list.map((value, index)=>{
            value.member_amount = +value.member_amount;
            value.member_amount_expire = +value.member_amount_expire;

            return <tr key={`consultCampaignTable${index}`}>
              <td>{value.name}</td>
              <td>{value.member_amount}</td>
              <td>{value.member_amount_expire}</td>
              <td>{(value.member_amount - value.member_amount_expire)}</td>
              <td><a uk-icon="download" onClick={()=>{
                this.csvGet(value.token);
              }}></a></td>
            </tr>
          })
          :
          <tr><td colSpan={'100%'} className="uk-text-center uk-padding">ไม่พบรายการ</td></tr>
          }</tbody>
        </table>
      </div>
    </div>
  }
  
}
