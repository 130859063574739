
import React, { Component } from 'react';
import ImageUri from '../ImageUri';
import Api from "../Api"
import firebase from "firebase";
import SoundAlertFile from '../../alertcall.mp3';
var SoundAlert = new Audio(SoundAlertFile);

export default class VideoCallBox extends Component {

  componentDidMount() {
    
    SoundAlert.pause();

    SoundAlert.volume = 0.02;
          
    SoundAlert.currentTime = 0;
    

    SoundAlert.play();
  }

  roomKeyVideoCallRender = () => {
    if(+this.props.fromConsultId > +this.props.consult.id) {
      return `${this.props.consult.id}/${this.props.fromConsultId}`;
    } else {
      return `${this.props.fromConsultId}/${this.props.consult.id}`;
    }
  }

  render() {
    return  <div className="d-popup-background">
      <div className="d-popup-body d-popup-body-small uk-text-center d-videocall-card">
        <div className='uk-text-right uk-margin-bottom'>
          <a uk-close="" onClick={()=>{
            this.props.closeCallback();

            SoundAlert.pause();
          }}></a>
        </div>
        <div>
          <div class="uk-background-muted uk-background-cover d-videocall-person-image" style={{"background-image": "url(" + ImageUri(this.props.image) + ")"} }></div>
        </div>
        <div className='uk-margin-top d-videocall-title'>สายเรียกเข้าจาก<div>{this.props.name}</div></div>
        <div className='uk-margin-small-bottom d-videocall-caption uk-text-meta'>มีการเรียกระบบวีดีโอคอล<div>กดปุ่มเพื่อเข้าสู่หน้าวีดีโอคอล</div></div>
        <div className='uk-margin-top'>
          <a href={`https://con.defence-innovation.com/teleconsult${this.roomKeyVideoCallRender()}?openExternalBrowser=1`} target="_blank" className="d-videocall-button uk-display-block" onClick={async ()=>{

            SoundAlert.pause();

            this.props.closeCallback();
            
          }} >
            เข้าสู่หน้าวีดีโอคอล
            <span className="d-videocall-button-icon icon-camrecorder"></span>
          </a>
        </div>
      </div>
    </div>
  }
    

}
