import React, { Component } from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Api from '../Api';
import ImageUri from '../ImageUri';

UIkit.use(Icons);

export default class DashboardCompanyLogo extends Component {
  state = {
    image: ''
  }

  componentDidMount() {
    this.dataGet();
  }

  dataGet = async () => {
    var {image} = await Api.member('DashboardCompanyLogo');

    this.setState({image: image || ''})

  }


  render() {
    return <div className="uk-card uk-card-default uk-padding-small uk-text-center">
      <div style={{height: 160}}>
        {this.state.image !== '' && <img src={ImageUri(this.state.image)} uk-img="" style={{maxHeight: 160}} /> }
      </div>
    </div>
  }
};
