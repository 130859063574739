import React, { Component } from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import ReactEcharts from 'echarts-for-react';
import Api from '../../Components/Api';
import SelectMemberCompanygroup from '../../Components/Member/SelectMemberCompanygroup';
import moment from 'moment';
import numeral from 'numeral';
import FullScreenElement from '../FullScreenElement';
UIkit.use(Icons);

export default class CampaignActiveUser extends Component {
  state = {
    selectMemberCompanyGroup: 'all',
    avgActiveUserGraph: [],
    todayActiveUserGraph: [],
    dayGraph: [],
    amountUser: 0,
  }

  componentDidMount() {
    this.dataGet();
  }

  dataGet = async () => {
    var response = await Api.member('CampaignReportActiveUser', { campaignId: this.props.id, groupId: this.state.selectMemberCompanyGroup });

    this.setState({
      avgActiveUserGraph: response.avgActiveUserGraph ? response.avgActiveUserGraph.reverse() : [],
      todayActiveUserGraph: response.todayActiveUserGraph ? response.todayActiveUserGraph.reverse() : [],
      dayGraph: response.dayGraph ? response.dayGraph.reverse() : [],
      amountUser: response.amountUser || 0
    })
  }

  graphRender = () => {
    var option = {

      // Setup grid
      grid: {
        x: 40,
        x2: 20,
        y: 35,
        y2: 25
      },

      // Add tooltip
      tooltip: {
        trigger: 'axis'
      },

      // Add legend
      legend: {
        data: ['Today Active User', 'Avg Active User']
      },

      // Add custom colors
      color: ['#FF7588', '#16D39A', '#2DCEE3', '#FF7588', '#626E82'],

      // Enable drag recalculate
      calculable: true,

      // Hirozontal axis
      xAxis: [{
        type: 'category',
        boundaryGap: false,
        data: this.state.dayGraph.map(data => (moment(data).format('DD MMM'))),
        z: 10,
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      }],

      // Vertical axis
      yAxis: [{
        type: 'value',
        boundaryGap: [0, 1],
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      }],

      // Add series
      // Add series
      series: [
        {
          name: 'Today Active User',
          type: 'line',
          symbol: 'circle',
          stack: 'Total',
          data: this.state.todayActiveUserGraph
        },
        {
          name: 'Avg Active User',
          type: 'line',
          symbol: 'circle',
          stack: 'Total',
          data: this.state.avgActiveUserGraph
        }
      ]
    };

    return <ReactEcharts
      option={option}
      notMerge={true}
      lazyUpdate={true}
      theme={"theme_name"}
    />
  }
  
  getPercent = () => {
    return ((this.state.avgActiveUserGraph[this.state.avgActiveUserGraph.length-1]/+this.state.amountUser)*100) || 0;
  }

  render() {
    return <div className="uk-card uk-card-default uk-padding-small" id="d-campaign-report-active-user">
      <div className="d-card-header">
        <div className="d-card-header-left">
          ACTIVE USER
        </div>
        <div className="d-card-header-right">
          <span className='icon-reload' onClick={this.dataGet}></span>
          <FullScreenElement element="#d-campaign-report-active-user" />
        </div>
      </div>
      <div className="uk-text-right uk-margin-top uk-margin-bottom">
        <SelectMemberCompanygroup id={this.props.id} callback={(result) => this.setState({ SelectMemberCompanygroup: result }, () => {
          this.dataGet();
        })} />
      </div>
      <div>
        <table className="uk-table uk-table-divider">
          <thead>
            
          </thead>
          <tbody>
            <tr className="d-active-user-row-header">
              <td>Today Active User</td>
              <td>Date</td>
            </tr>
            <tr className="d-active-user-row-data">
              <td className="d-active-user-row-data-today-active-user">
                <span className='icon-people'></span> {numeral(Math.ceil(this.state.todayActiveUserGraph[this.state.todayActiveUserGraph.length-1])).format('0,0') || 0}
              </td>
              <td className="d-active-user-row-data-today-active-user-date">
                {moment().format('DD MMM YYYY')}
              </td>
            </tr>
            <tr className="d-active-user-row-header">
              <td>
                Avg Active User
              </td>
              <td>
                % Active User
              </td>
            </tr>
            <tr className="d-active-user-row-data">
              <td className="d-active-user-row-data-avg-active-user">
                <span className='icon-people'></span> {numeral(Math.ceil(this.state.avgActiveUserGraph[this.state.avgActiveUserGraph.length-1])).format('0,0') || 0}
              </td>
              <td>
                <span className="uk-text-small">{numeral(this.getPercent()).format('0,0.00')} %</span>
                <div className="d-progress uk-margin-small-top">
                  <div className="d-progress-inner" style={{ width: this.getPercent() + '%', backgroundColor: '#16d39a' }}></div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {this.graphRender()}
    </div>
  }
};
