import NovalueCheck from './NoValueCheck';

export default (value) => {
  if(NovalueCheck(value)) return NovalueCheck(value);

  value = +value;

  if(value > 20) {
    return { 
      title: 'ค่ามากผิดปกติ',
      color: 'orange',
      caption: 'ค่ามากผิดปกติ',
      suggest: ''
    };
  } else if(value < 6) {
    return { 
      title: 'ค่าน้อยผิดปกติ',
      color: 'orange',
      caption: 'ค่าน้อยผิดปกติ',
      suggest: ''
    };
  } else {
    return { 
      title: 'อยู่ในเกณฑ์ปกติ',
      color: 'green',
      caption: 'อยู่ในเกณฑ์ปกติ',
      suggest: ''
    };
  }
}