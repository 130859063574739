import React, { Component } from 'react';
import Api from '../Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import ConsultService from './ConsultService';
import { Link } from "react-router-dom";
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min.js';
import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css';
import moment from 'moment';
import numeral from 'numeral';
import Gender from '../Gender.js';

UIkit.use(Icons);

export default class ProfileOverview extends Component {
  form = [
    {
      key: 'nameprefix',
      value: '',
      label: 'คำนำหน้าชื่อ'
    },
    {
      key: 'name',
      value: '',
      label: 'ชื่อ'
    },
    {
      key: 'lastname',
      value: '',
      label: 'นามสกุล'
    },
    {
      key: 'email',
      value: '',
      label: 'อีเมล'
    },
    {
      key: 'tel',
      value: '',
      label: 'เบอร์ติดต่อ'
    },
    {
      key: 'gender',
      value: '',
      label: 'เพศ',
      type: 'select',
      option: [
        { value: '1', label: 'ชาย' },
        { value: '0', label: 'หญิง' },
      ]
    },
    {
      key: 'birthday',
      value: '',
      label: 'วันเกิด',
      type: 'date'
    },
    {
      key: 'nid',
      value: '',
      label: 'รหัสบัตรประชาชน'
    },
    {
      key: 'language',
      value: [],
      label: 'ภาษาที่ใช้',
      type: 'checkbox',
      option: [
        { value: 'th', label: 'ไทย' },
        { value: 'en', label: 'อังกฤษ' }, ,
        { value: 'ch', label: 'จีน' },
      ],
      change: (e) => {
        var _language = this.state.form.language;
        var _value = e.target.value;
        var _index = this.state.form.language.indexOf(_value);
        if (_index < 0) {
          _language.push(_value);
        } else {
          delete _language[_index];
          _language = _language.filter(Boolean);
        }

        this.formUpdate('language', _language);
      }
    },
    {
      key: 'typeconsult',
      value: '',
      label: 'ประเภท',
      type: 'select',
      option: 
      [{ value: 'แพทย์', label: 'แพทย์' }, { value: 'เภสัชกร', label: 'เภสัชกร' }, { value: 'นักกำหนดอาหาร', label: 'นักกำหนดอาหาร' }, { value: 'พยาบาลวิชาชีพ', label: 'พยาบาลวิชาชีพ' }, { value: 'พยาบาล', label: 'พยาบาล' }, { value: 'นักวิชาการสาธารณสุข', label: 'นักวิชาการสาธารณสุข' }, {value: 'ผู้ให้ความรู้โรคเบาหวาน', label:'ผู้ให้ความรู้โรคเบาหวาน'}, {value: 'ผู้เชี่ยวชาญด้านการออกกำลังกาย', label: 'ผู้เชี่ยวชาญด้านการออกกำลังกาย'}, {value: 'นักกายภาพบำบัด', label:'นักกายภาพบำบัด'}, {label: 'เจ้าหน้าที่อื่น', value: 'เจ้าหน้าที่อื่น'}, {label: 'ฯลฯ', value: 'ฯลฯ'}]
    },
    {
      key: 'typebranch',
      value: '',
      label: 'สาขาประเภท'
    },
    {
      key: 'speciallist',
      type: 'textarea',
      value: '',
      label: 'ความชำนาญเฉพาะทาง'
    },
    {
      key: 'hospitalhistory',
      type: 'textarea',
      value: '',
      label: 'โรงพยาบาล'
    },
    {
      key: 'education',
      type: 'textarea',
      value: '',
      label: 'การศึกษา'
    },
    {
      key: 'history',
      type: 'textarea',
      value: '',
      label: 'ประวัติ'
    },
  ]

  formStateInit = () => {
    var _r = {};
    this.form.forEach(d => {

      _r[d.key] = this.props.profile && this.props.profile[d.key] ? this.props.profile[d.key] : d.value;
    })

    return _r;
  }

  state = {
    form: { ...this.formStateInit(), service: this.props.profile ? this.props.profile.service || '' : '' },
    editing: false,
    loadingApi: false
  }


  componentDidMount() {
  }


  formUpdate = (key, value) => {
    this.setState(state => {
      state.form[key] = value;

      if (this.props.callback) { this.props.callback(state.form); };

      return { form: state.form };
    });
  }

  save = async () => {
    this.setState({loadingApi: true});

    var _data = { ...this.state.form };
    _data.language = _data.language.join(',');

    var { result, responseText, query } = await Api.consult('ConsultProfileUpdate', _data);
    UIkit.notification({ message: responseText });

    this.setState({ editing: false, loadingApi: false });
  }


  formRender = () => {
    var _formRender = (d) => {
      switch (d.type) {
        case 'date':
          setTimeout(() => {
            this.datepickerGet(d.key, this.state.form[d.key])
          }, 400)
          return <div>
            <div className="d-button-group uk-width-1-1 input-group uk-button-group date" id={'datepicker' + d.key}>
              <input type="text" className="uk-input uk-form-small" placeholder="วันที่/เวลา" />
              <span className="uk-button input-group-addon" style={{ width: 35, height: 30, marginLeft: 0, textAlign: 'center', paddingLeft: 0 }}>
                <span className="glyphicon glyphicon-calendar" style={{ marginLeft: -8, marginTop: 7, position: 'absolute' }}></span>
              </span></div>
          </div>
          break;
        case 'select':
          return <select className="uk-select uk-form-small" value={this.state.form[d.key]} placeholder={d.label} onChange={e => this.formUpdate(d.key, e.target.value)}>
            {d.option.map(g => <option key={g.value} value={g.value}>{g.label}</option>)}
          </select>
          break;
        case 'textarea':
          return <textarea className="uk-textarea uk-form-small" type="text" value={this.state.form[d.key]} placeholder={d.label} onChange={e => this.formUpdate(d.key, e.target.value)} />
          break;
        case 'checkbox':
          return d.option.map(o => <div><label><input type="checkbox" onChange={e => {

            if (d.change) d.change(e);

          }} checked={this.state.form.language && this.state.form.language.indexOf(o.value) >= 0 ? true : false} className="uk-checkbox" value={o.value} /> {o.label}</label></div>)
          break;
        default:
          return <input className="uk-input uk-form-small" type="text" value={this.state.form[d.key]} placeholder={d.label} onChange={e => this.formUpdate(d.key, e.target.value)} />
          break;
      }
    }
    var _r = [];


    this.form.forEach(d => {
      _r.push(<div key={d.key} className="uk-margin-small-top">
        <label>{d.label}</label>
        {_formRender(d)}
      </div>)
    });

    _r.push(
      <div className="uk-margin-small-top" key="service"><ConsultService list={this.state.form.service} callback={(data) => {
        this.formUpdate('service', data);
      }} /></div>
    );


    _r.push(
      <div className="uk-text-right uk-margin-small-top" key="save">
        {this.state.loadingApi ?
          <div className="uk-padding-small uk-text-center"><span uk-spinner=""></span></div>
          :
          <button className="uk-button uk-button-primary" onClick={this.save}>บันทึก</button>
        }

      </div>
    );

    return _r;

  }


  datepickerGet = (key, value) => {
    var _this = this;

    $('#datepicker' + key)
      .on('dp.change', function (e) {
        _this.formUpdate(key, e.date.format('YYYY-MM-DD'));
      })
      .datetimepicker({
        format: "DD/MM/YYYY",
        defaultDate: value && value !== '0000-00-00' ? moment(value, 'YYYY-MM-DD') : moment(),
      });
  }

  viewRender = () => {
    var _d = this.state.form;

    var _lang = [];

    if (typeof _d.language === 'string')
      _d.language = _d.language.split(',').filter(Boolean);
    _d.language.forEach(l => {
      switch (l) {
        case 'en':
          _lang.push('อังกฤษ');
          break;
        case 'th':
          _lang.push('ไทย');
          break;
        case 'ch':
          _lang.push('จีน');
          break;
        default:
      }
    });

    _lang = _lang.join(',');


    var _service = [];

    try {
      _service = JSON.parse(_d.service);
    } catch (e) {
      console.error(e);
      _service = [];
    }

    return <div id="d-consult-profile-overview">
      <div className="uk-text-right">
        <a onClick={() => this.setState({ editing: true })}><i className="icon-note"></i> แก้ไข</a>
      </div>
      <div><b>ชื่อ: </b> {_d.nameprefix}</div>
      <div><b>อีเมล: </b> {_d.email}</div>
      <div><b>เบอร์ติดต่อ: </b> {_d.tel}</div>
      <div><b>เพศ: </b> {Gender[_d.gender] || ''}</div>
      <div><b>วันเกิด: </b> {moment(_d.birthday).format('DD/MM/YYYY')}</div>
      <div><b>รหัสบัตรประชาชน: </b> {_d.nid}</div>
      <div><b>ภาษา: </b> {_lang}</div>
      <div><b>ประเภท: </b> {_d.typeconsult} {_d.typebranch}</div>
      <div><b>ความชำนาญเฉพาะทาง: </b> <div dangerouslySetInnerHTML={{ __html: _d.speciallist.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></div></div>
      <div><b>โรงพยาบาล: </b> <div dangerouslySetInnerHTML={{ __html: _d.hospitalhistory.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></div></div>
      <div><b>การศึกษา: </b> <div dangerouslySetInnerHTML={{ __html: _d.education.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></div></div>
      <div><b>ประวัติ: </b> <div dangerouslySetInnerHTML={{ __html: _d.history.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></div></div>
      <div>
        <b>การบริการ</b>
        <ul className="uk-list uk-list-divider uk-padding-remove uk-margin-small-left uk-margin-remove-top">
          {_service.map(data => {
            var _name = '';
            switch (data.service) {
              case 'chat':
                _name = 'ให้คำแนะนำผ่านแชท';
                break;
              case 'voice':
                _name = 'วอยส์คอล';
                break;
              case 'video':
                _name = 'วีดีโอคอล';
                break;
              default:
            }

            return <li><b>{_name}</b> <span dangerouslySetInnerHTML={{ __html: data.condition.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></span> <div className="uk-text-meta">{data.price == '0' ? 'ไม่มีค่าใช้จ่าย' : `ค่าใช้จ่ายครั้งละ ${numeral(data.price).format('0,0.00')} บาท`}</div></li>
          })}
        </ul>
      </div>
    </div>
  }

  render() {
    return <div className="uk-padding-small">
      {
        this.state.editing ?
          <div>{this.formRender()}</div>
          :
          <div>{this.viewRender()}</div>
      }
    </div>
  }
};
