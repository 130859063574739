import ImageUri from './ImageUri';
import React, { Component } from 'react';
import $ from 'jquery';

export default class Thumbnail extends Component {
  state = {
  }

  render() {
    return  <div className={"uk-background-cover uk-border-circle" + (this.props.online === true ? ' d-chatbox-chatlist-person-image-online' : '') } style={{
      height: 50,
      width: 50,
      display: 'inline-block',
      backgroundColor: '#CCC',
      backgroundImage: `url(${ImageUri(this.props.image)})`
    }}></div>
  }
}
