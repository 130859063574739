var KeyPage = {
  '1' : {
    title: 'ความรู้เบื้องต้นเกี่ยวกับเอชไอวีและเอดส์',
    url: 'https://knowledge.dietz.asia/1.jpg'
  },
  '2' : {
    title: 'การตรวจเลือดหาการติดเชื้อเอชไอวี',
    url: 'https://knowledge.dietz.asia/2.jpg'
  },
  '3' : {
    title: 'ความแตกต่างของเอชไอวีและเอดส์',
    url: 'https://knowledge.dietz.asia/3.jpg'
  },
  '4' : {
    title: 'ความหมายและประโยชน์ของการตรวจ ซีดี4 (CD4)',
    url: 'https://knowledge.dietz.asia/4.jpg'
  },
  '5' : {
    title: 'ปริมาณไวรัสในเลือด',
    url: 'https://knowledge.dietz.asia/5.jpg'
  },
  '6' : {
    title: 'โรคติดต่อเชื้อฉวยโอกาสที่พบบ่อยและระดับ ซีดี4',
    url: 'https://knowledge.dietz.asia/6.jpg'
  },
  '7' : {
    title: 'การถ่ายทอดเชื้อเอชไอวีจากแม่สู่ลูก',
    url: 'https://knowledge.dietz.asia/7.jpg'
  },
  '8' : {
    title: 'Index testing',
    url: 'https://knowledge.dietz.asia/8.jpg'
  },
  '9' : {
    title: 'ปัจจัยที่มีผลต่อการถ่ายทอดเชื้อเอชไอวีไปสู่คู่นอน',
    url: 'https://knowledge.dietz.asia/9.jpg'
  },
  '10' : {
    title: 'การป้องกันการติดเชื้อเอชไอวีแบบผสมผสาน',
    url: 'https://knowledge.dietz.asia/10.jpg'
  },
  '11' : {
    title: 'เพร็พ (PrEP)',
    url: 'https://knowledge.dietz.asia/11.jpg'
  },
  '12' : {
    title: 'เป็ป (PEP)',
    url: 'https://knowledge.dietz.asia/12.jpg'
  },
  '13' : {
    title: 'ความสำคัญของการเริ่มยาต้านเอชไอวีโดยเร็วที่สุด',
    url: 'https://knowledge.dietz.asia/13.jpg'
  },
  '14' : {
    title: 'U=U',
    url: 'https://knowledge.dietz.asia/14.jpg'
  },
  '15' : {
    title: 'วัณโรค',
    url: 'https://knowledge.dietz.asia/15.jpg'
  },
  '16' : {
    title: 'วัณโรคระยะแฝง',
    url: 'https://knowledge.dietz.asia/16.jpg'
  },
  '17' : {
    title: 'โรคไวรัสตับอักเสบบี',
    url: 'https://knowledge.dietz.asia/17.jpg'
  },
  '18' : {
    title: 'โรคไวรัสตับอักเสบซี',
    url: 'https://knowledge.dietz.asia/18.jpg'
  },
  '19' : {
    title: 'โรคติดต่อทางเพศสัมพันธ์',
    url: 'https://knowledge.dietz.asia/19.jpg'
  },
  '20' : {
    title: 'โควิด-19',
    url: 'https://knowledge.dietz.asia/20.jpg'
  },
  '21' : {
    title: 'การใช้ฮอร์โมนและการดูแลสุขภาพในกลุ่มสตรีข้ามเพศที่ติดเชื้อเอชไอวี',
    url: 'https://knowledge.dietz.asia/21.jpg'
  },
  '22' : {
    title: 'การเตรียมความพร้อมวัยรุ่นเพื่อส่งต่อคลินิกผู้ใหญ่',
    url: 'https://knowledge.dietz.asia/22.jpg'
  },
  '23' : {
    title: 'แอลกอฮอล์และสารเสพติด',
    url: 'https://knowledge.dietz.asia/23.jpg'
  },
  '24' : {
    title: 'การใช้สารเสพติดเพื่อการมีเพศสัมพันธ์ในกลุ่มผู้ติดเชื้อเอชไอวี (Chem Sex)',
    url: 'https://knowledge.dietz.asia/24.jpg'
  },
  '25' : {
    title: 'โภชนาการสำหรับผู้ติดเชื้อเอชไอวี',
    url: 'https://knowledge.dietz.asia/25.jpg'
  },
  '26' : {
    title: 'ผลิตภัณฑ์อาหารเสริม',
    url: 'https://knowledge.dietz.asia/26.jpg'
  },
  '27' : {
    title: 'การตรวจคัดกรองมะเร็งปากมดลูกสำหรับผู้หญิงที่ติดเชื้อเอชไอวี',
    url: 'https://knowledge.dietz.asia/27.jpg'
  },
  '28' : {
    title: 'ทางเลือกการมีบุตรของผู้ติดเชื้อเอชไอวี',
    url: 'https://knowledge.dietz.asia/28.jpg'
  },
  '29' : {
    title: 'การคุมกำเนิดสำหรับผู้ติดเชื้อเอชไอวี',
    url: 'https://knowledge.dietz.asia/29.jpg'
  },
  '30' : {
    title: 'การจัดการอารมณ์ในผู้ติดเชื้อเอชไอวี',
    url: 'https://knowledge.dietz.asia/30.jpg'
  },
  '31' : {
    title: 'สิทธิในการเข้าถึงบริการสุขภาพในสถานบริการของรัฐ',
    url: 'https://knowledge.dietz.asia/31.jpg'
  },
  '32' : {
    title: 'การตีตราและการเลือกปฏิบัติ',
    url: 'https://knowledge.dietz.asia/32.jpg'
  },
  'ontime' : {
    title: 'ทำไมต้องกินยาตรงเวลาและต่อเนื่องทุกวัน?',
    url: 'https://knowledge.dietz.asia/ontime.jpg'
  },
}

var KeyVideo = {
  '33' : {
    title: 'การรับประทานยาต้านไวรัสเอชไอวีชนิดรวมเม็ด สูตร TLD',
    url: 'https://www.youtube.com/watch?v=rziFv_l-9No',
    cover_url: 'https://knowledge.dietz.asia/33.png'
  },
  '34' : {
    title: 'ความรู้ทั่วไปเกี่ยวกับเอชไอวี',
    url: 'https://www.youtube.com/watch?v=dbJV7ul-uTE',
    cover_url: 'https://knowledge.dietz.asia/34.png'
  },
  '35' : {
    title: 'ความรู้โรคฉวยโอกาส (Opportunity, OI) รู้ก่อนป้องกันได้ ตอนที่ 1 ผู้ติดเชื้อเอชไอวี กับ วัณโรคระยะแฝง',
    url: 'https://www.youtube.com/watch?v=bNt1zVwjFUY',
    cover_url: 'https://knowledge.dietz.asia/35.png'
  },
  '36' : {
    title: 'ความรู้โรคฉวยโอกาส (Opportunity, OI) รู้ก่อนป้องกันได้ ตอนที่ 2 ผู้ติดเชื้อเอชไอวี กับ ไวรัสตับอักเสบบี และ ไวรัสตับอักเสบซี',
    url: 'https://www.youtube.com/watch?v=vbRYSMZsCC4',
    cover_url: 'https://knowledge.dietz.asia/36.png'
  },
  '37' : {
    title: 'ความรู้โรคฉวยโอกาส (Opportunity, OI) รู้ก่อนป้องกันได้ ตอนที่ 3 ผู้ติดเชื้อเอชไอวี กับ ภาวะโรคเมตาบอลิค',
    url: 'https://www.youtube.com/watch?v=Q1dWWICPXuI',
    cover_url: 'https://knowledge.dietz.asia/37.png'
  }
}

var NotiData = (tag, mode) => {
  var _d = {};
  var _media = {};

  if(tag.length > 0) {
    if(tag.includes('TB')) {
      _d[`page_15`] = true;
      _media['15'] = KeyPage['15'];
    }
    if(tag.includes('STIs')) {
      _d[`page_19`] = true;
      _media['19'] = KeyPage['19'];
    }
    if(tag.includes('Adherence')) {
      _d[`page_ontime`] = true;

      _media['ontime'] = KeyPage['ontime'];
    }
    if(tag.includes('Alcohol and substance use') || tag.includes('Alcohol+and+substance+use')) {
      _d[`page_23`] = true;

      _media['23'] = KeyPage['23'];
    }
    if(tag.includes('VL')) {
      _d[`page_5`] = true;
      _d[`page_14`] = true;
      
      _media['5'] = KeyPage['5'];
      _media['14'] = KeyPage['14'];
    }
    if(tag.includes('2Q')) {
      _d[`page_30`] = true;
      
      _media['30'] = KeyPage['30'];
    }
    if(tag.includes('Unsafe sex') || tag.includes('Unsafe+sex')) {
      _d[`page_9`] = true;
      _d[`page_10`] = true;

      
      _media['9'] = KeyPage['9'];
      _media['10'] = KeyPage['10'];
    }
    if(tag.includes('Unscreened partner for HIV') || tag.includes('Unscreened+partner+for+HIV')) {
      _d[`page_8`] = true;
      
      _media['8'] = KeyPage['8'];
    }
    if(tag.includes('Family planning') || tag.includes('Family+planning')) {
      _d[`page_28`] = true;
      _d[`page_29`] = true;

      _media['28'] = KeyPage['28'];
      _media['29'] = KeyPage['29'];
    }
    if(tag.includes('Self-stigma')) {
      _d[`page_32`] = true;

      _media['32'] = KeyPage['32'];
    }

  }

  if(mode === 'media') {
    return _media;

  } else {
    return _d;
    
  }
}


export {
  KeyPage,
  KeyVideo,
  NotiData
}
