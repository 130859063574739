import Api from './Api';
import moment from 'moment';

export default async (knowledgekey, campaignid, channel) => {
    var _r = await Api.member("KnowledgeLogAdd", {
        dateadd: moment().format('YYYY-MM') + '-01',
        campaignId: campaignid,
        knowledgekey: knowledgekey,
        channel: channel
    });

}