import NovalueCheck from './NoValueCheck';

export default (value) => {
  if(NovalueCheck(value)) return NovalueCheck(value);

  value = +value;

  if(value <= 7) {
    return { 
      title: 'ยูริคในเลือดปกติ',
      color: 'green',
      caption: 'ยูริคในเลือดปกติ',
      suggest: ''
    };
  } else {
    return { 
      title: 'ยูริคในเลือดสูงกว่าปกติ',
      color: 'orange',
      caption: 'ยูริคในเลือดสูงกว่าปกติ',
      suggest: 'ปรับพฤติกรรม พบนักโภชนาการ หรือ นักกำหนดอาหาร'
    };
  }

}