import NovalueCheck from './NoValueCheck';

export default (value, age, gender) => {

  if(gender == '1') gender = 'male';
  if(gender == '0') gender = 'female';

  if(NovalueCheck(value)) return NovalueCheck(value);

  value = +value;
  age = +age;
  var max = 0, min = 0;

  if(age >= 20) {
    if(gender === 'male') {
      max = 1.2;
      min = 0.5;
    } else if(gender === 'female') {
      max = 1.1;
      min = 0.5;
    }
  } else if( age >= 10) {
    max = 1.0;
    min = 0.5;
  } else {
    max = 0.7;
    min = 0.3;
  }

  if(value > max) {
    return { 
      title: 'ค่ามากผิดปกติ',
      color: 'orange',
      caption: 'ค่ามากผิดปกติ',
      suggest: ''
    };
  } else if(value < min) {
    return { 
      title: 'ค่าน้อยผิดปกติ',
      color: 'orange',
      caption: 'ค่าน้อยผิดปกติ',
      suggest: ''
    };
  } else {
    return { 
      title: 'อยู่ในเกณฑ์ปกติ',
      color: 'green',
      caption: 'อยู่ในเกณฑ์ปกติ',
      suggest: ''
    };
  }
}