import React, { Component } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import ConsultProfileCard from '../../Components/Consult/ConsultProfileCard';
import ConsultAppointmentToday from '../../Components/Consult/ConsultAppointmentToday';
import ConsultMemberSummary from '../../Components/Consult/ConsultMemberSummary.js';
import ConsultCampaignTable from '../../Components/Consult/ConsultCampaignTable.js';

UIkit.use(Icons);

export default class ConsultDashboard extends Component {

  render() {
    return <div className="uk-padding">
      <div uk-grid="">
        <div className="uk-width-1-1 uk-width-1-3@m">
          <ConsultProfileCard />
          <ConsultMemberSummary />
        </div>
        <div className="uk-width-1-1 uk-width-2-3@m">
          <ConsultAppointmentToday />
          <ConsultCampaignTable />
        </div>
      </div>
    </div>
  }
};
