import React, { Component } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import ReactPaginate from 'react-paginate';

var Field = [
  { key: 'memberid', label: 'รหัสสมาชิก' },
  { key: 'companyid', label: 'รหัสบริษัท' },
  { key: 'healthfacility', label: 'health facility' },
  { key: 'healthbudget', label: 'health budget' },
  { key: 'healthactivity', label: 'health activity' },
  { key: 'healthbenefits', label: 'health benefits' },
  { key: 'insurance', label: 'insurance' },
  { key: 'satisfies', label: 'satisfies' },
];

class AdminOpnionAddForm extends Component {

  state = {
  }

  componentDidMount() {
    this.init();
  }

  componentWillReceiveProps() {
    this.init();
  }

  init = async () => {
    this.setState((state) => {
      var stateInit = {};
      if (this.props.data) {
        Field.map(data => { stateInit[data.key] = this.props.data[data.key]; });
      } else {
        Field.map(data => { stateInit[data.key] = ''; });
      }

      return stateInit;
    });

    if (this.props.show) {
      UIkit.modal('#modal-form-data').show();
    } else {
      UIkit.modal('#modal-form-data').hide();
    }
  }


  OpnionAdd = async (e) => {
    e.preventDefault();
    var data = this.state;
    if (this.props.data) {
      data.id = this.props.data.id;
    }

    var { result, responseText } = await Api.admin(this.props.data ? 'OpnionUpdate' : 'OpnionAdd', data);

    UIkit.notification({ message: responseText, status: result ? 'success' : 'danger' })
    this.props.callback();
    UIkit.modal('#modal-form-data').hide();
  }


  updateValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return <div id="modal-form-data" uk-modal="bg-close: false; esc-close: false;">
      <div className="uk-modal-dialog uk-modal-body">
        <button className="uk-modal-close-default" type="button" uk-close=""></button>
        <h2 className="uk-modal-title">จัดการข้อมูล</h2>
        <form onSubmit={this.OpnionAdd} className="uk-width-1-1">
          {
            Field.map(data => {
              return <div>
                <label>{data.label}</label>
                <input type="text" className="uk-input" name={data.key} onChange={this.updateValue} value={this.state[data.key]} />
              </div>
            })
          }
          <button className="uk-input uk-button-primary uk-margin-small-top" type="submit">{this.props.data ? 'บันทึก' : 'เพิ่ม'}</button>
        </form>
      </div>
    </div>
  }
}

export default class AdminData extends Component {

  state = {
    dataList: [],
    formShow: false,
    data: false,
    pageCount: 0,
    pageNow: 1,
  }

  perPage = 100

  componentDidMount() {
    this.init();
  }

  componentWillReceiveProps() {
    this.init();
  }

  init = async () => {
    this.dataListGet();
  }

  dataListGet = async () => {
    var { list, amount } = await Api.admin('OpnionList', {
      perPage : this.perPage,
      pageNow : this.state.pageNow
    });
    this.setState({ dataList: list || [], formShow: false, pageCount: +amount/this.perPage }, () => this.setState({ formShow: false }));
  }

  handlePageClick = (page) => {
    this.setState({pageNow: page.selected+1}, ()=>this.dataListGet())
  }

  paginateRender = () => {
    return <div className="uk-text-right">
            <ReactPaginate
              previousLabel={'previous'}
              nextLabel={'next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          </div>
  }


  render() {
    return <div>
      <div className="d-admin-header-row uk-text-right">
        <button className="uk-button uk-button-small uk-button-secondary" onClick={() => this.setState({ data: false, formShow: true }, () => this.setState({ formShow: true }))} >เพิ่มข้อมูล</button>
        <AdminOpnionAddForm  {...this.props} callback={this.dataListGet} show={this.state.formShow} data={this.state.data} />
      </div>
      {this.paginateRender()}
      <table className="uk-table uk-table-small uk-table-divider">
        <thead>
          <tr>
            <th>ลำดับ</th>
            {
              Field.map(data => {
                return <th key={data.key}>{data.label}</th>
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            this.state.dataList.map((data, key) => {
              return <tr key={`Data${data.id}`}>
                <td>{key + 1}</td>
                {
                  Field.map(field => {
                    return <td key={`row`+field.key}>{data[field.key]}</td>
                  })
                }
                <td>
                  <button className="uk-button uk-button-small" onClick={() => this.setState({ data: data, formShow: true }, () => this.setState({ formShow: true }))} >
                    <i uk-icon="icon: pencil"></i>
                  </button>
                </td>
              </tr>
            })
          }
        </tbody>
      </table>
      {this.paginateRender()}
    </div>
  }
};
