import React, { Component } from "react";
import firebase from "firebase";
import ImageUriReduce from './ImageUriReduce';
import moment from 'moment';
import UIkit from "uikit";
import { v4 as uuidv4 } from 'uuid';


class UploadFirebase extends Component {
  state = {
    username: "",
    avatar: "",
    isUploading: false,
    progress: 0,
    avatarURL: ""
  };

  fileRef = null


  storage = firebase.storage();


  handleUploadStart = (file) => {
    try {
      if (file.size > 209715200) {
        throw new Error("จำกัดขนาดสูงสุด 200 Mb")
      } else if (
        ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/gif', 'video/mp4', 'application/pdf'].includes(file.type) !== true
      ) {
        throw new Error("ไฟล์ไม่สนับสนุน")

      }

      this.setState({ isUploading: true, progress: 0 });

    } catch (e) {
      UIkit.notification(e.message);
    }
  }
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
  };
  handleUploadSuccess = filename => {
    this.setState({ avatar: filename, progress: 100, isUploading: false });

    firebase
      .storage()
      .ref("images/" + moment().format('YYYY_MM_DD'))
      .child(filename)
      .getDownloadURL()
      .then(url => {
        this.setState({ avatarURL: url })
        if (this.props.cb) this.props.cb(ImageUriReduce(url));
      });
  };

  render() {
    return <div className="uk-text-center">
      {
        this.state.isUploading ?
          <div><span uk-spinner=""></span> {this.state.progress} %</div>
          :
          <input ref={ref => (this.fileRef = ref)} type="file" className="uk-hidden" accept={this.props.accept || "image/*"} name={this.props.name || "avatar"} onChange={(e) => {


            if (this.fileRef.files[0] != null) {
              try {
                if (this.fileRef.files[0].size > 209715200) {
                  throw new Error("จำกัดขนาดสูงสุด 200 Mb")
                } else if (
                  ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/gif', 'video/mp4', 'application/pdf'].includes(this.fileRef.files[0].type) !== true
                ) {
                  throw new Error("ไฟล์ไม่สนับสนุน")

                }

                this.setState({ isUploading: true, progress: 0 });



                var _file_name = this.fileRef.files[0].name.split('.');

                _file_name = uuidv4() + '.' + _file_name[_file_name.length - 1];


                var _upload = firebase.storage().ref().child(`image/${moment().format('YYYY_MM_DD')}/${_file_name}`).put(this.fileRef.files[0], {
                  contentType: this.fileRef.files[0].type
                });

                _upload.on(firebase.storage.TaskEvent.STATE_CHANGED,
                  (snapshot) => {
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                    this.setState({ progress })
                  },
                  (error) => {
                    console.log(error)
                    this.setState({ isUploading: false });
                  },
                  () => {

                    this.setState({ avatar: _file_name, progress: 100, isUploading: false });

                    _upload.snapshot.ref.getDownloadURL().then((downloadURL) => {
                      this.setState({ avatarURL: downloadURL })
                      if (this.props.cb) this.props.cb(ImageUriReduce(downloadURL));
                      console.log(downloadURL)
                    });
                  }
                );
              } catch (e) {
                UIkit.notification(e.message);
              }
            }


          }} />
      }
    </div>
  }
}

export default UploadFirebase;