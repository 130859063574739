import React, {Component} from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Api from '../../Components/Api';
import HeaderSub from '../../Components/Member/HeaderSub';
import numeral from 'numeral';
import ImageUri from '../../Components/ImageUri';
import  moment from 'moment';
import THBText from 'thai-baht-text';
UIkit.use(Icons);

export default class InvoiceDetail extends Component {
  state = {
    list: [],
    customerEmail: '',
    customerAddress: '',
    customerName: '',
    loadingData: true,
    found: false
  }

  componentDidMount () {
    this.init();
  }

  init = () => {
    this.detailGet();
  }

  detailGet = async () => {
    var _response = await Api.member('InvoiceDetail', {
      documentType: Api.queryString.type,
      id: Api.queryString.id
    });

    console.log(_response)

    if(_response.data.customerName != null) {
      this.setState({
        ..._response.data,
        found: true,
        loadingData: false
      }, () => {
        setTimeout(()=>{ 
          window.print();
          window.close();

        }, 1000);
      });

      
 
    } else {
      this.setState({
        found: false,
        loadingData: false
      });
    }


  }

  prescriptionRender = (page) => {
    return <div className='d-background-white' style={{position: 'relative'}}>
            <div style={{
              width: 0,
              height: 0,
              borderStyle: 'solid',
              borderWidth: '0 100px 100px 0',
              borderColor: 'transparent #2e99d8 transparent transparent',
              position: 'absolute',
              right: 0,
              top: 0,
              textAlign: 'right',
              zIndex: 2
            }}>
            </div>
              <span className='d-text-white' style={{fontSize: 30, position: 'absolute', right: 15, top: 15, zIndex: 3}}>{page}</span>
            <table cellPadding={0} cellSpacing={0} border={0} width={'100%'}>
              <tbody>
              <tr>
                <td width={'40%'}>
                  <img src="/assets/logo_dietz-l.png" width="140" />
                  <div style={{marginTop: 20}}>บริษัท พรีซีชั่นไดเอทซ์ จำกัด (สำนักงานใหญ่)</div>
                  <div>209/5 ถนนเจริญเวียง แขวงสีลม เขตบางรัก กรุงเทพมหานคร</div>
                  <div>เลขประจำตัวผู้เสียภาษี 0115563023698</div>
                  <div>โทร. 027458089</div>
                  <div>เบอร์มือถือ 0813186700</div>
                  <div>โทรสาร 027458090</div>
                  <div>www.dietz.asia</div>
            
                  <div style={{marginTop: 20}} className="d-text-blue-light">ลูกค้า</div>
                  <div>{this.state.customerName}</div>
                  <div>{this.state.customerAddress}</div>
                  <div>เลขประจำตัวผู้เสียภาษี {this.state.customerTaxId}</div>
                </td>
                <td width={'10%'}>&nbsp;</td>
                <td width={'40%'}>
                  <div style={{textAlign: 'center'}}>
                    <div className="d-text-blue-light" style={{fontSize: 20}}>ใบกำกับภาษี/ใบเสร็จรับเงิน</div>
                    <div className="d-text-blue-light" style={{fontSize: 16}}>{page == 1 ? 'ต้นฉบับ' : 'สำเนา'} 
                    {" "}(เอกสารออกเป็นชุด)</div>
                  </div>
                  <hr style={{marginTop: 5, marginBottom: 5, paddingTop: 0, paddingBottom: 0}} />
                  <table width={'100%'}>
                    <tr>
                      <td className="d-text-blue-light">เลขที่</td>
                      <td>{this.state.id}</td>
                    </tr>
                    <tr>
                      <td className="d-text-blue-light">วันที่</td>
                      <td>{moment(this.state.date).format('DD/MM/YYYY')}</td>
                    </tr>
                    <tr>
                      <td className="d-text-blue-light">ผู้ขาย</td>
                      <td>พงษ์ชัย เพชรสังหาร</td>
                    </tr>
                    <tr>
                      <td className="d-text-blue-light">อ้างอิง</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <hr style={{marginTop: 5, marginBottom: 5, paddingTop: 0, paddingBottom: 0}} />
                      </td>
                    </tr>
                    <tr>
                      <td className="d-text-blue-light">อีเมล</td>
                      <td>{this.state.customerEmail}</td>
                    </tr>
                  </table>
                </td>
                <td width={'10%'}>&nbsp;</td>
              </tr>
              </tbody>
            </table>
            <table width={'100%'} border={0} cellPadding={'10'} cellSpacing={0} style={{marginBottom: 20, marginTop: 20}} >
              <thead>
              <tr style={{
                borderTop: '1px solid #CCC',
                borderBottom: '1px solid #CCC'
              }}>
                <th>#</th>
                <th>รายละเอียด</th>
                <th>จำนวน</th>
                <th>ราคาต่อหน่วย</th>
                <th>ยอดรวม</th>
              </tr>
              </thead>
              <tbody>
                {
                  this.state.list.length ? 
                  this.state.list.map((data, index)=>{
                    return <tr key={`list${index}`} style={{
                      borderBottom: '1px solid #CCC'
                    }}>
                      <td>{index+1}</td>
                      <td>{data.detail}</td>
                      <td>{numeral(data.amount).format('0,0')}</td>
                      <td>{numeral(data.pricePerUnit).format('0,0.00')}</td>
                      <td>{numeral(data.priceSum).format('0,0.00')}</td>
                    </tr>
                  })
                  :
                  <tr>
                    <td colSpan={"100%"} className="uk-text-center uk-padding">ไม่พบรายการ</td>
                  </tr>
                }
              </tbody>
            </table>
            <div>
              <table width={'100%'} style={{textAlign: 'right'}}>
                <tbody>
                  <tr>
                    <td></td>
                    <td className="d-text-blue-light">รวมเป็นเงิน</td>
                    <td>{numeral(this.state.priceSum).format('0,0.00')} บาท</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="d-text-blue-light">ค่าส่งและค่าดำเนินการอื่นๆ</td>
                    <td>{numeral(this.state.priceOther).format('0,0.00')} บาท</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="d-text-blue-light">ส่วนลด</td>
                    <td>{numeral(this.state.priceDiscount).format('0,0.00')} บาท</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="d-text-blue-light">ราคาหลังหักส่วนลด</td>
                    <td>{numeral(this.state.priceAfterDiscount).format('0,0.00')} บาท</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="d-text-blue-light">ภาษีมูลค่าเพิ่ม 7%</td>
                    <td>{numeral(this.state.priceVat).format('0,0.00')} บาท</td>
                  </tr>
                  <tr>
                    <td width={'60%'} style={{textAlign: 'left'}}>
                      <div>({THBText((+this.state.priceWithVat).toFixed(2))})</div>
                    </td>
                    <td width={'20%'} className="d-text-blue-light">จำนวนเงินรวมทั้งสิ้น</td>
                    <td>{numeral(this.state.priceWithVat).format('0,0.00')} บาท</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style={{marginTop: 20}}>
              <div className="d-text-blue-light">หมายเหตุ</div>
              <div>
                1. ระบบชำระเงินออนไลน์ 5% ของยอด transection
              </div>
            </div>
            
            <div style={{marginTop: 20}}>
              <span style={{marginRight: 20}}>การชำระเงินจะสมบูรณ์เมื่อบริษัทได้รับเงินเรียบร้อยแล้ว</span>
              <span style={{marginRight: 10}}><input type="checkbox" checked={true} /> เงินสด</span>
              <span style={{marginRight: 10}}><input type="checkbox" checked={false} /> โอนเงิน</span>
              <span><input type="checkbox" checked={false} /> บัตรเครดิต</span>
            </div>
            <table width={'100%'} cellPadding={0} cellSpacing={0} border={0} style={{marginTop: 30}}>
              <tbody>
              <tr>
                <td width={'40%'}>
                  <div>ในนาม {this.state.customerName}</div>
                  <div>
                    <table style={{textAlign: 'center'}} width={'100%'} cellPadding={10} cellSpacing={5} border={0}>
                      <tr>
                        <td width={'50%'}>
                          <div style={{height: 50, marginBottom: 5, borderBottom: '1px solid #CCC'}}></div>
                          <div>ผู้จ่ายเงิน</div>
                        </td>
                        <td width={'50%'}>
                          <div style={{height: 50, marginBottom: 5, borderBottom: '1px solid #CCC'}}></div>
                          <div>วันที่</div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
                <td width={'20%'}>
                  <img src={'/assets/company_stamp.jpg'} width="120" style={{marginTop: 30}} />
                </td>
                <td width={'40%'}>
                  <div style={{textAlign: 'right'}}>ในนาม บริษัท พรีซีชั่นไดเอทซ์ จำกัด</div>
                  <div>
                    <table style={{textAlign: 'center'}} width={'100%'} cellPadding={10} cellSpacing={5} border={0}>
                      <tbody>
                      <tr>
                        <td width={'50%'}>
                          <div style={{height: 50, marginBottom: 5, borderBottom: '1px solid #CCC', position: 'relative'}}><img src={'/assets/signature.jpg'} width={100} style={{position: 'absolute', bottom: 10, left: '50%', transform: 'translateX(-50%)'}} /></div>
                          <div>ผู้รับเงิน</div>
                        </td>
                        <td width={'50%'}>
                          <div style={{height: 50, marginBottom: 5, borderBottom: '1px solid #CCC', position: 'relative'}}>
                            <div style={{position: 'absolute', bottom: 10, left: '50%', transform: 'translateX(-50%)'}}>{moment(this.state.date).format('DD/MM/YYYY')}</div>
                            </div>
                          <div>วันที่</div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
  }

  render() {
    return <div>
      {
        this.state.loadingData === true ? 
        <div><span uk-spinner=""></span></div>
        :
        (
          this.state.found === true ?
          <div>
            {this.prescriptionRender(1)}
            <div style={{pageBreakAfter : 'always'}}></div>
            {this.prescriptionRender(2)}
          </div>

          :

          <div>ไม่พบข้อมูล</div>
        )
      }
    </div>
  }
};
