import React, { Component } from "react";
import Api from "../Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import MemberRegisterLocation from "../Member/MemberRegisterLocation";
import "../../prescription.css";

import moment from "moment";

import numeral from "numeral";

UIkit.use(Icons);

export default class ConsultPrescription extends Component {
  state = {
    show: true,
    loading: false,
    printLink: '#',
    loadingShippingPrice : false
  };

  memberGet = async () => {
    var _response = await Api.consult("MemberGet", {
      memberId: this.props.memberId,
    });

    this.member = _response.data || {};

    this.option.customer_name = `${this.member.nameprefix} ${this.member.name} ${this.member.lastname}`.replace(/(?:\r\n|\r|\n)/g, '');

    this.option.customer_address = this.member.address.replace(/(?:\r\n|\r|\n)/g, ' ');
    this.option.customer_postcode = this.member.postcode.replace(/(?:\r\n|\r|\n)/g, ' ');
    this.option.customer_id = this.member.id;
    this.option.lat = this.member.lat;
    this.option.lng = this.member.lng;

    this.listUpdate();
  };

  consultGet = async () => {
    var _response = await Api.consult("ConsultLoginBySession");

    this.option.admin_name = `${_response.consult.nameprefix} ${_response.consult.name} ${_response.consult.lastname}`;

    this.listUpdate();

    this.consult = _response.consult;
  };

  componentDidMount() {
    this.init();
    
  }

  componentWillReceiveProps() {
    this.init();
  }

  init = () => {

    this.consultGet();

    this.memberGet();

    if (this.props.id) {
      this.id = this.props.id;
    }

    this.prescription_load();

    this.printLinkGet();
  }

  consult = {};

  member = {};

  campaignId = this.props.campaignId || null;

  option = {
    id: moment().format("YYYYMMDD-HHmmss"),
    number: "",
    visit_number: "",
    hospital_number: "",
    vat7: "vat7",
    customer_id: "",
    customer_name: "",
    customer_address: "",
    customer_postcode: "",
    customer_tax_id: "",
    customer_branch: "",
    date: moment().format("YYYY-MM-DD"),
    credit: "0",
    credit_until_date: moment().format("YYYY-MM-DD"),
    admin_name: "",
    ref_number: "",
    note: "",
    note_company: "",
    discount: 0.0,
    pay_tax: "",
    signature: "",
    price: 0.0,
    price_discount: 0.0,
    price_after_discount: 0.0,
    price_summary: 0.0,
    price_with_vat7: 0.0,
    status: "รอคนไข้ยืนยัน",
    com_online: 0,
    width: '',
    weight: '',
    shipping: '',
    thaipostprice: '',
    flashprice: '',
    scgprice: '',
    sktprice: '',
    shippingDealer: 'flash',
    //temp: '3',
    lat: '',
    lng: ''
  };
  /*
  tempOption = [
    {label: 'ควบคุมความเย็น', value: '3'},
    {label: 'แช่แข็ง', value: '4'},
  ];
  */

  shippingPriceGet = async () => {
    if(!this.option.width || !this.option.weight ) {
      UIkit.notification('โปรดใส่ขนาดและน้ำหนักพัสดุ')

      return;
    }

    this.setState({loadingShippingPrice: true});
    
    var _response = await Api.consult('ShippingPriceGetV3', {
      width: this.option.width,
      weight: this.option.weight,
      campaignId: this.campaignId,
      memberId: this.member.id,
      customerTel: this.member.tel,
      customerName: this.option.customer_name,
      customerAddress: this.option.customer_address,
      customerPostcode: this.option.customer_postcode,
      lat: this.option.lat,
      lng: this.option.lng
    });

    if(_response.result === true) {
      this.option.shippingDealer = 'flash';
      this.option.sktprice = numeral(+_response.sktprice).format('0.00');
      this.option.flashprice = numeral(+_response.flashprice).format('0.00');
      this.option.scgprice = +_response.scgprice > 0 ? numeral(+_response.scgprice).format('0.00') : '-';
      this.option.thaipostprice = numeral(+_response.thaipostprice).format('0.00');

      this.listUpdate();
    }

    this.setState({loadingShippingPrice: false});

  }

  printLinkGet = async () => {
    
    var _response = await Api.consult('PrescriptionGetToken', {id: this.id});

    this.setState({printLink: "/prescription-file?consult=true&print=true&token=" + _response.token, file_token: _response.token});
  }

  list = [{
    name: "ค่าธรรมเนียมแพทย์",
    detail: "",
    amount: 1,
    unit: "ครั้ง",
    priceperunit: 0,
    price: 0,
  }];

  listUpdate = () => {
    this.priceRender(() => {
      this.forceUpdate();
    });
  };

  listAdd = () => {
    this.list.push({
      name: "",
      detail: "",
      amount: 1,
      unit: "",
      priceperunit: 0,
      price: 0,
    });

    this.listUpdate();
  };

  id = null;

  prescription_save = async () => {

    if(this.option.id == "") {
      UIkit.notification({ message: "โปรดระบุเลขที่ใบสั่งยา" });

      return false;
    }

    var _this = this;
    
    var _data = {
      content: JSON.stringify({
        list: _this.list,
        option: _this.option,
      }),
      memberId: _this.member.id,
      campaignId: _this.campaignId,
      id: _this.id,
    };

    if(window.confirm("ยืนยันการบันทึกใบสั่งยา?")) {
      var _response = await Api.consult("PrescriptionAdd", _data);

      if(_response.result === true && _response.id) {
        _this.id = _response.id;
        
        if(this.id != _this.id) this.id = _this.id; //Debug
    
        UIkit.notification({ message: _response.responseText });

        await _this.printLinkGet();
        
        setTimeout(()=>{
          _this.shareToChat();
          _this.closeCallback();

        }, 100);
      } else {
        UIkit.notification({ message: 'ไม่สามารถบันทึกข้อมูลได้' });
      }

      
    }

    
  };

  prescription_load = async () => {
    if (this.id) {
      this.setState({ loading: true });

      var _response = await Api.consult("PrescriptionLoad", {
        id: this.id,
      });

      if (_response.result === true && _response.data) {
        try {
          var _content = JSON.parse(_response.data.content);

          this.campaignId = _response.data.campaignid;

          this.option = _content.option;

          this.list = _content.list;

          this.listUpdate();

          this.setState({ loading: false });
        } catch (e) {}
      }
    }
  };

  closeCallback = this.props.closeCallback;

  priceRender = (callback) => {
    this.option.price = 0;

    this.list.forEach((data) => {
      this.option.price += +data.price;
    });

    this.option.price_after_discount = this.option.price;

    if (+this.option.discount > 0) {
      this.option.price_discount = +this.option.price * (+this.option.discount/100);

      this.option.price_after_discount = (this.option.price - this.option.price_discount
      ).toFixed(2);
    }

    if(this.option.shipping === '1' && this.option.shippingDealer) {
      this.option.price_after_discount += +this.option[`${this.option.shippingDealer}price`];
    }

    this.option.com_online = this.option.price_after_discount * 0.05;

    this.option.price_summary = +this.option.price_after_discount + +this.option.com_online;

    if (this.option.vat7 === "vat7") {
      this.option.price_with_vat7 = this.option.price_summary * 0.07;

      this.option.price_summary = (+this.option.price_summary + +this.option.price_with_vat7).toFixed(2);
    } else {
      this.option.price_with_vat7 = 0.0;
    }
    
    callback();
  };

  shareToChat = async () => {
    
    var _option = '';
    if(this.option.status === 'รอคนไข้ยืนยัน' || this.option.status === 'คนไข้ยันยันแล้ว รอการชำระเงิน') {
      _option = `<div data-prescription="${this.option.status}" data-prescription-id="${this.id}"></div>`;
    }

    
    var _message = `ใบสั่งยาเลขที่ : <a target="_blank" rel="noopenner" href="/prescription-file?print=true&token=${this.state.file_token}"><b>${
        this.option.id
      }</b></a>\nสถานะใบสั่งยา : <b>${this.option.status}</b> \nวันที่ : ${moment().format(
        "DD/MM/YYYY HH:mm"
      )}${_option}`;

    var _response = await Api.consult("ChatMessageAdd", {
      consultId: this.consult.id,
      memberId: this.member.id,
      campaignId: this.campaignId,
      typeMessage: "text",
      memberRead: 0,
      consultRead: 1,
      message : _message,
      chatby: "c",
    });

    if(_response.result === true) {
      UIkit.notification({message: "แชร์ใบสั่งยาไปยังแชทแล้ว"});

      if(typeof this.props.chatCallback === 'function') this.props.chatCallback();
    }
      
  }

  listRender = () => {
    return this.list.length > 0 ? (
      this.list.map((data, index) => {
        return (
          <tr key={`list_${index}`}>
            <td>{index + 1}</td>
            <td>
              <div className="d-prescription-input-group">
                <input
                  type="text"
                  value={data.name}
                  placeholder="ชื่อยา"
                  onChange={(e) => {
                    data.name = e.target.value;
                    this.listUpdate();
                  }}
                />
                <textarea
                  rows={1}
                  placeholder="รายละเอียดการใช้ยา"
                  value={data.detail}
                  onChange={(e) => {
                    data.detail = e.target.value.replace(/(?:\r\n|\r|\n)/g, '');
                    this.listUpdate();
                  }}
                >
                  {data.detail}
                </textarea>
              </div>
            </td>
            <td>
              <div className="d-prescription-input-group-h">
                <input
                  className="d-width-80 uk-text-right"
                  type="text"
                  value={data.amount}
                  onChange={(e) => {
                    data.amount = parseInt(e.target.value.replaceAll(',', ''));

                    if (+data.amount < 1) data.amount = 1;

                    data.price = data.amount * +data.priceperunit;
                    this.listUpdate();
                  }}
                  onBlur={() => {
                    data.amount = numeral(data.amount).format(
                      "0,0"
                    );
  
                    this.listUpdate();
                  }}
                />
                <input
                  className="d-width-80"
                  type="text"
                  value={data.unit}
                  onChange={(e) => {
                    data.unit = e.target.value;
                    this.listUpdate();
                  }}
                />
              </div>
            </td>
            <td>
              <input
                type="text"
                className="uk-text-right d-width-120"
                value={data.priceperunit}
                onChange={(e) => {
                  data.priceperunit = parseFloat(e.target.value.replaceAll(',', ''));

                  data.price = parseInt(data.amount.toString().replaceAll(',', '')) * +data.priceperunit;

                  this.listUpdate();
                }}
                onBlur={() => {
                  data.priceperunit = numeral(data.priceperunit).format(
                    "0,0.00"
                  );

                  this.listUpdate();
                }}
              />
            </td>
            <td>
              <div className="d-prescription-row-price">
                {numeral(data.price).format("0,0.00")}
              </div>
            </td>
            <td className="uk-text-center">
              <a
                className="d-prescription-remove-list-button"
                onClick={() => {
                  if (window.confirm("ยืนยันการลบรายการ " + data.name + " ?")) {
                    delete this.list[index];

                    this.list = this.list.filter(Boolean);

                    this.listUpdate();
                  }
                }}
              >
                <span uk-icon="trash"></span>
              </a>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="100%">
          <div className="uk-padding-small uk-text-center">ไม่พบรายการ</div>
        </td>
      </tr>
    );
  };

  
  geoData = {
    lat: null,
    lng: null
  }

  render() {
    return (
      this.state.show === true && (
        <div>
          <div id="d-prescription-background">
            <div id="d-prescription-container">
              {this.state.loading === true ? (
                <div className="uk-padding uk-text-center">
                  <span uk-spinner=""></span>
                </div>
              ) : (
                <div>
                  <div>
                    <h1>สร้างใบสั่งยา</h1>
                  </div>
                  <div uk-grid="" className="uk-child-width-1-3@m uk-child-width-1-1">
                    <div>
                      <input
                        type="text"
                        placeholder="เลขที่"
                        value={this.option.id}
                        onChange={(e) => {
                          this.option.id = e.target.value;
                          this.listUpdate();
                        }}
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="visit number"
                        value={this.option.visit_number}
                        onChange={(e) => {
                          this.option.visit_number = e.target.value;
                          this.listUpdate();
                        }}
                      />
                    </div>
                    <div className="uk-text-right">
                      <button
                        id="d-prescription-close-button"
                        onClick={this.closeCallback}
                      >
                        ปิดหน้าต่าง
                      </button>
                      <button
                        id="d-prescription-save-button"
                        onClick={this.prescription_save}
                      >
                        บันทึกเอกสาร
                      </button>
                    </div>
                  </div>
                  <div id="d-prescription-card-container">
                    <div
                      uk-grid="uk-grid-small"
                    >
                      <div className="uk-width-1-3@m uk-width-1-1">
                        <div className="uk-margin-small-bottom">
                          <label>ชื่อลูกค้า</label>
                          <input
                            type="text"
                            placeholder="ชื่อลูกค้า"
                            value={this.option.customer_name}
                            onChange={(e) => {
                              this.option.customer_name = e.target.value;
                              this.listUpdate();
                            }}
                          />
                        </div>
                        <div>
                          <label>รายละเอียด <u className="d-pointer" onClick={()=>this.setState({showMap: true})}>คลิกเพื่อดูแผนที่</u></label>
                          <div className="d-prescription-input-group">
                            <textarea
                              placeholder="รายละเอียดที่อยู่"
                              value={this.option.customer_address}
                              onChange={(e) => {
                                this.option.customer_address = e.target.value.replace(/(?:\r\n|\r|\n)/g, '');
                                this.listUpdate();
                              }}
                            ></textarea>
                            <input
                              type="text"
                              placeholder="รหัสไปรษณีย์"
                              value={this.option.customer_postcode}
                              onChange={(e) => {
                                this.option.customer_postcode = e.target.value;
                                this.listUpdate();
                              }}
                            />
                            {
                              this.state.showMap === true && <div className="d-popup-background">
                                <div className="d-popup-body">
                                  <MemberRegisterLocation 
                                    callback={(data)=>{
                                      this.geoData.lat = data.lat;
                                      this.geoData.lng = data.lng;
                                    }}
                                    lat={this.option.lat} 
                                    lng={this.option.lng} 
                                    hideAddressName={true} 
                                    hideAddress={true} 
                                    hideNote={true} 
                                    hidePostcode={true} 
                                    formType="admin" 
                                  />

                                  <div className="uk-margin-small-top uk-text-right">
                                    <button
                                      className="uk-button uk-button-small uk-button-default"
                                      onClick={() => {
                                        this.setState({ showMap: false });
                                      }}
                                    >
                                      ยกเลิก
                                    </button>
                                    <button className="uk-button uk-button-small uk-button-primary uk-margin-small-left" onClick={() => {
                                      
                                      this.option.lat = this.geoData.lat;
                                      this.option.lng = this.geoData.lng;
                                      
                                      this.listUpdate();


                                      this.setState({ showMap: false });
                                    }}>บันทึก</button>
                                  </div>
                                </div>
                              </div>
                            }
                            
                            <input
                              type="text"
                              placeholder="เลขประจำตัวผู้เสียภาษี"
                              value={this.option.customer_tax_id}
                              onChange={(e) => {
                                this.option.customer_tax_id = e.target.value;
                                this.listUpdate();
                              }}
                            />
                            <input
                              type="text"
                              placeholder="สำนักงาน/สาขาเลขที่"
                              value={this.option.customer_branch}
                              onChange={(e) => {
                                this.option.customer_branch = e.target.value;
                                this.listUpdate();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="uk-width-1-3@m uk-width-1-1"></div>
                      <div className="uk-width-1-3@m uk-width-1-1">
                        <div className="uk-text-right">
                          {this.id && <div>
                            <a className="uk-margin-right uk-link-reset uk-display-inline-block" onClick={this.shareToChat}>
                            <div uk-icon="forward"></div>
                            <div className="uk-text-meta">แชร์</div>
                          </a>
                          
                          <a className="uk-link-reset uk-display-inline-block" target="_blank" href={this.state.printLink} rel="noopenner">
                            {
                              this.state.printLoad === true ? <span uk-spinner=""></span>
                              :
                              <div>
                            
                              </div>
                            }
                            <div uk-icon="print"></div>
                            <div className="uk-text-meta">พิมพ์</div>
                          </a>
                          </div>
                         }
                        </div>
                        <label>จำนวนเงินรวมทั้งสิ้น</label>
                        <div id="d-prescription-price-summary-container">
                          {numeral(this.option.price_summary).format("0,0.00")}
                        </div>
                        <div uk-grid="" className="uk-grid-small">
                          <div className="uk-width-1-3 d-prescription-description-container">
                            <div>
                              <label>วันที่ :</label>
                            </div>
                            <div>
                              <label>เครดิต (วัน) :</label>
                            </div>
                            <div>
                              <label>ครบกำหนด : </label>
                            </div>
                            <div>
                              <label>ผู้ให้บริการ :</label>
                            </div>
                          </div>
                          <div className="uk-width-2-3">
                            <div className="d-prescription-input-group">
                              <input
                                type="date"
                                value={this.option.date}
                                onChange={(e) => {
                                  this.option.date = e.target.value;
                                  this.listUpdate();
                                }}
                              />
                              <input
                                type="text"
                                value={this.option.credit}
                                onChange={(e) => {
                                  this.option.credit = e.target.value;

                                  this.option.credit_until_date = moment(
                                    this.option.date,
                                    "YYYY-MM-DD"
                                  )
                                    .add(this.option.credit, "days")
                                    .format("YYYY-MM-DD");

                                  this.listUpdate();
                                }}
                              />
                              <input
                                type="date"
                                value={this.option.credit_until_date}
                                onChange={(e) => {
                                  this.option.credit_until_date =
                                    e.target.value;

                                  this.option.credit = moment(
                                    e.target.value,
                                    "YYYY-MM-DD"
                                  ).diff(
                                    moment(this.option.date, "YYYY-MM-DD"),
                                    "days"
                                  );

                                  this.listUpdate();
                                }}
                              />
                              <input
                                type="text"
                                placeholder="ชื่อ-สกุล"
                                value={this.option.admin_name}
                                onChange={(e) => {
                                  this.option.admin_name = e.target.value;
                                  this.listUpdate();
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div uk-grid="" className="uk-flex-middle">
                      <div className="uk-width-auto@m uk-width-1-1">
                        <label>
                          ใบสั่งยาให้คุณ {this.option.customer_name}
                        </label>
                      </div>
                      <div className="uk-width-expand@m uk-width-1-1">
                        <div
                          uk-grid=""
                          className="uk-grid-small uk-flex-middle"
                        >
                          <div className="uk-width-auto@m uk-width-1-1">
                            <label>เลขที่โรงพยาบาล/คลินิก</label>
                          </div>
                          <div className="uk-width-expand@m uk-width-1-1">
                            <input
                              type="text"
                              value={this.option.hospital_number}
                              onChange={(e) => {
                                this.option.hospital_number = e.target.value;
                                this.listUpdate();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          uk-grid=""
                          className="uk-grid-small uk-flex-middle"
                        >
                          <div className="uk-width-auto@m uk-width-1-1">
                            <label>เลขที่อ้างอิง</label>
                          </div>
                          <div className="uk-width-expand@m uk-width-1-1">
                            <input
                              type="text"
                              value={this.option.ref_number}
                              onChange={(e) => {
                                this.option.ref_number = e.target.value;
                                this.listUpdate();
                              }}
                            />
                          </div>
                          <div className="uk-width-auto@m uk-width-1-1">
                            <label>ราคา</label>
                          </div>
                          <div className="uk-width-expand@m uk-width-1-1">
                            <select
                              value={this.option.vat7}
                              onChange={(e) => {
                                this.option.vat7 = e.target.value;
                                this.listUpdate();
                              }}
                            >
                              <option value="">ราคาไม่รวมภาษี</option>
                              <option value="vat7">ราคารวมภาษี</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="uk-overflow-auto">
                      <table
                        className="uk-table uk-table-small uk-table-divider"
                        id="d-prescription-medicine-table"
                        border="1"
                      >
                        <thead>
                          <tr>
                            <th>ลำดับ</th>
                            <th>ชื่อสินค้า/รายละเอียด</th>
                            <th>
                              <div className="uk-display-inline-block d-width-80">
                                จำนวน
                              </div>
                              <div className="uk-display-inline-block d-width-80">
                                หน่วย
                              </div>
                            </th>
                            <th className="uk-text-right">ราคาต่อหน่วย</th>
                            <th className="uk-text-right">ราคารวม</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody id="d-prescription-list-container">
                          {this.listRender()}
                        </tbody>
                      </table>
                    </div>
                    <div className="uk-margin-small-top">
                      <button
                        id="d-prescription-add-button"
                        onClick={this.listAdd}
                      >
                        เพิ่มแถวรายการ
                      </button>
                    </div>
                    <div className="uk-margin-small-top">
                      <label>
                        <input
                          type="checkbox"
                          checked={this.option.signature === "1" ? true : false}
                          onChange={(e) => {
                            this.option.signature =
                              e.target.checked === true ? "1" : "";
                            this.listUpdate();
                          }}
                        />{" "}
                        ลายเซ็นอิเล็กทรอนิกส์และตรายาง
                      </label>
                    </div>
                    <div
                      uk-grid=""
                      className="uk-width-1-1 uk-margin-small-top"
                    >
                      <div className="uk-width-1-2@m uk-width-1-1">
                        <div
                          uk-grid=""
                          className="uk-child-width-1-2@m uk-child-width-1-1"
                        >
                          <div>
                            <label>หมายเหตุ:</label>
                            <textarea
                              value={this.option.note}
                              onChange={(e) => {
                                this.option.note = e.target.value.replace(/(?:\r\n|\r|\n)/g, '');
                                this.listUpdate();
                              }}
                            ></textarea>
                          </div>
                          <div>
                            <label>โน๊ตสำหรับใช้ภายในโรงพยาบาล:</label>
                            <textarea
                              value={this.option.note_company}
                              onChange={(e) => {
                                this.option.note_company = e.target.value.replace(/(?:\r\n|\r|\n)/g, '');
                                this.listUpdate();
                              }}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="uk-width-1-2@m uk-width-1-1">
                        <div
                          uk-grid=""
                          className="uk-child-width-1-1 uk-child-width-1-2@s uk-grid-small"
                        >
                          <div className="d-prescription-text-blue">
                            รวมเป็นเงิน
                          </div>
                          <div className="uk-text-right">
                            {numeral(this.option.price).format("0,0.00")}
                          </div>
                          <div>
                            <label className="d-prescription-text-blue">
                              <input
                                type="checkbox"
                                checked={
                                  this.option.shipping === "1" ? true : false
                                }
                                onChange={(e) => {

                                  if(!this.option.customer_postcode || !this.option.customer_address) {
                                    UIkit.notification('โปรดระบุที่อยู่และรหัสไปรษณีย์')
                                    return;
                                  }

                                  this.option.shipping =
                                    e.target.checked === true ? "1" : "";
                                  this.listUpdate();
                                }}
                              />{" "}
                            การขนส่ง (ถ้ามี)
                            
                            </label>
                          </div>
                          <div className="uk-text-right">
                          </div>
                          {
                            this.option.shipping === '1' && [
                              <div className="d-prescription-text-blue">
                                ระบุน้ำหนักโดยประมาณ*
                              </div>,
                              <div className="uk-text-right">
                                <input
                                  className="d-input-discount"
                                  type="number"
                                  min={1}
                                  value={this.option.weight}
                                  onChange={(e) => {
                                    this.option.weight = e.target.value;

                                    if(this.option.weight < 1) {
                                      this.option.weight = 1;
                                    }

                                    this.listUpdate();
                                  }}
                                  onBlur={()=>{
                                    if(this.option.width) this.shippingPriceGet();
                                  }}
                                />{" "}
                                <span>กรัม</span>
                              </div>,
                              <div className="d-prescription-text-blue">
                                ขนาดกล่องพัสดุ*
                              </div>,
                              <div className="uk-text-right">
                                <select
                                  className="uk-select"
                                  value={this.option.width}
                                  onChange={(e) => {
                                    this.option.width = e.target.value;

                                    this.shippingPriceGet();
                                  }}
                                >
                                  <option value="">โปรดเลือกขนาดพัสดุ</option>
                                  <option value="12x18x10">12.0 x 18.0 x 10.0 ซม</option>
                                  <option value="15x24x11">15.0 x 24.0 x 11.0 ซม</option>
                                  <option value="19.7x27x13.1">19.7 x 27 x 13.1 ซม</option>
                                  <option value="25.5x35x20.6">25.5 x 35 x 20.6 ซม</option>
                                  <option value="31.2x43.1x25.7">31.2 x 43.1 x 25.7 ซม</option>
                                  <option value="38x51x31">38 x 51 x 31 ซม</option>
                                  <option value="40.1x60.1x39.8">40.1 x 60.1 x 39.8 ซม</option>
                                </select>
                              </div>
                            ]
                          }
                          
                          {this.state.loadingShippingPrice ? 
                          [
                            <div className="d-prescription-text-blue"></div>,
                            <div className="uk-text-right">
                              <div uk-spinner=""></div>
                            </div>
                          ]
                          :
                          this.option.width && this.option.weight && [
                            <div className="d-prescription-text-blue">
                              {this.option.flashprice && <label>
                                <input type="radio" name="shipping-dealer" checked={this.option.shippingDealer==='flash' ? true : false} onChange={(e) => {
                                    this.option.shippingDealer = 'flash';

                                    this.listUpdate();
                                  }} />
                                ขนส่ง Flash (มารับที่สถานที่)
                              </label>}
                              
                            </div>,
                            <div className="uk-text-right">{" "}
                              {this.option.flashprice || '-'} <span>บาท</span>
                            </div>, 
                            <div className="d-prescription-text-blue">
                              {
                                this.option.thaipostprice && <label>
                                <input type="radio" name="shipping-dealer" checked={this.option.shippingDealer==='thaipost' ? true : false} onChange={(e) => {
                                  this.option.shippingDealer = 'thaipost';

                                  this.listUpdate();
                                }} />
                              ไปรษณีย์ไทย (ต้องเอาพัสดุไปส่งจุด Dropoff)
                              </label>
                              }
                              
                            </div>,
                            <div className="uk-text-right">{" "}
                              {this.option.thaipostprice || '-'} <span>บาท</span>
                            </div>,
                           <div className="d-prescription-text-blue">
                             {
                               this.option.scgprice && <label>
                               {+this.option.scgprice > 0 && <input type="radio" name="shipping-dealer" checked={this.option.shippingDealer==='scg' ? true : false} onChange={(e) => {
                                   this.option.shippingDealer = 'scg';

                                   this.listUpdate();
                                 }} />}
                               
                             SCG (ยาแช่เย็น)
                             </label>
                             }
                              
                                </div>,
                            <div className="uk-text-right">
                              {this.option.scgprice || '-'} <span>บาท</span> 
                            </div>,
                            <div className="d-prescription-text-blue">
                              {
                                this.option.sktprice && <label>
                                <input type="radio" name="shipping-dealer" checked={this.option.shippingDealer==='skt' ? true : false} onChange={(e) => {
                                  this.option.shippingDealer = 'skt';

                                  this.listUpdate();
                                }} />
                              ขนส่ง Skootar (พนักงานจัดส่งมารับและจัดส่ง)
                              </label>
                              }
                              
                            </div>,
                            <div className="uk-text-right">{" "}
                              {this.option.sktprice || '-'} <span>บาท</span>
                            </div>,
                          ]
                          }
                          
                          <div>
                            <span className="d-prescription-text-blue">
                              ส่วนลด
                            </span>{" "}
                            <input
                              className="d-input-discount"
                              type="number"
                              value={this.option.discount}
                              onChange={(e) => {
                                this.option.discount = e.target.value;

                                this.listUpdate();
                              }}
                            />{" "}
                            <span className="d-prescription-text-blue">%</span>
                          </div>
                          <div className="uk-text-right">
                            {numeral(this.option.price_discount).format(
                              "0,0.00"
                            )}
                          </div>
                          <div className="d-prescription-text-blue">
                            ราคาหลังหักส่วนลด
                          </div>
                          <div className="uk-text-right">
                            {numeral(this.option.price_after_discount).format(
                              "0,0.00"
                            )}
                          </div>
                          <div className="d-prescription-text-blue">
                          ค่าธรรมเนียมออนไลน์
                          </div>
                          <div className="uk-text-right">
                            {numeral(this.option.com_online).format(
                              "0,0.00"
                            )}
                          </div>
                          <div>
                            <label className="d-prescription-text-blue">
                              <input
                                type="checkbox"
                                checked={
                                  this.option.vat7 === "vat7" ? true : false
                                }
                                onChange={(e) => {
                                  this.option.vat7 =
                                    e.target.checked === true ? "vat7" : "";
                                  this.listUpdate();
                                }}
                              />{" "}
                              ภาษีมูลค่าเพิ่ม 7%
                            </label>
                          </div>
                          <div className="uk-text-right">
                            {numeral(this.option.price_with_vat7).format(
                              "0,0.00"
                            )}
                          </div>
                          <div className="d-prescription-text-blue">
                            จำนวนเงินรวมทั้งสิ้น
                          </div>
                          <div className="uk-text-right">
                            {numeral(this.option.price_summary).format(
                              "0,0.00"
                            )}
                          </div>
                        </div>
                        {/*
                        <hr />
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              checked={this.option.pay_tax === "1" ? true : false}
                              onChange={(e) => {
                                this.option.pay_tax =
                                  e.target.checked === true ? "1" : "";
                                this.listUpdate();
                              }}
                            />{" "}
                            หักภาษี ณ ที่จ่าย
                          </label>
                        </div>
                        */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )
    );
  }
}
