import React, { Component } from "react";

export default  [
  {
    label: '1. ท่านมีอาการผิดปกติเหล่านี้หรือไม่ ',
    text_show: true,
    child : [
      {
        label: 'มีอาการผิดปกติ',
        label_more: '(เลือกได้มากกว่า 1 ข้อ)',
        type: 'radio',
        name: 'symptom',
        value: '1',
        mode: 'grid',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-',
        child: [
          {
            mode: 'column',
            column_width: 'uk-width-1-1 uk-width-1-2@s uk-width-1-4@m',
            child: [
              {
                label: 'ไอทุกวันเกิน 2 สัปดาห์',
                key: 'continuous_cough_2_week',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'ไอเป็นเลือดใน 1 เดือนที่ผ่านมา',
                key: 'blood_cough_1_month',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'น้ำหนักลดโดยไม่ทราบสาเหตุใน 1 เดือนที่ผ่านมา',
                key: 'weight_loss_1_month',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'มีไข้ไม่ทราบสาเหตุ ใน 1 เดือนที่ผ่านมา',
                key: 'unknow_fever_1_month',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'เหงื่อออกมากผิดปกติตอนกลางคืน ภายใน 1 เดือนที่ผ่านมา',
                key: 'unknow_night_sweating_1_month',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
            ]
          },
          {
            mode: 'column',
            column_width: 'uk-width-1-1 uk-width-1-2@s uk-width-1-4@m',
            child: [
              {
                label: 'ผื่นขึ้นตามตัว',
                key: 'body_rash',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'เวียนศีรษะ ',
                key: 'dizziness',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'ซีด/ อ่อนเพลีย',
                key: 'tired',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'คลื่นไส้/ อาเจียน/ กินอาหารไม่ได้/ เบื่ออาหาร',
                key: 'squeamish',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'ท้องเสีย/ ปวดท้อง',
                key: 'diarrhea',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'ตัวเหลือง ตาเหลือง',
                key: 'yellow_body',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'นอนไม่หลับ',
                key: 'sleepless',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'ฝันร้าย',
                key: 'nightmare',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
            ]
          },
          {
            mode: 'column',
            column_width: 'uk-width-1-1 uk-width-1-2@s uk-width-1-4@m',
            child: [
              {
                label: 'มีปัสสาวะแสบขัด มีหนองใส หรือขุ่นจากท่อปัสสาวะ/ อวัยวะเพศ',
                key: 'urine_pain',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'มีตกขาวผิดปกติ',
                key: 'abnormal_leucorrhoea',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'ปวดท้องน้อย ปวดอวัยวะเพศ ',
                key: 'genitalia_pain',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'มีแผลที่อวัยวะเพศ (แผล หูด ตุ่มน้ำใส ก้อน ติ่งผิดปกติ)',
                key: 'genitalia_wound',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'คันบริเวณอวัยวะเพศ',
                key: 'genitalia_itch',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'มีบวมที่อวัยวะเพศ',
                key: 'genitalia_swell',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
            ]
          },
          {
            mode: 'column',
            column_width: 'uk-width-1-1 uk-width-1-2@s uk-width-1-4@m',
            label: 'มีอาการผิดปกติ อื่นๆ',
            key: 'symptom_other_abnormal',
            type: 'checkbox',
            text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--',
            child: [
              {
                label: '',
                show_on_column: true,
                key: 'symptom_other_abnormal_detail',
                type: 'text',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;มีอาการผิดปกติอื่นๆ : '
              }  
            ]
            
          },
        ]
      },
      {
        label: 'ไม่มีอาการผิดปกติ',
        name: 'symptom',
        type: 'radio',
        value: '0',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-',
      }
    ]
  },
  {
    label: '2. ท่านมีความรู้สึกว่าการกินยาต้านไวรัสให้ถูกต้องตามปริมาณและตามเวลาที่แพทย์สั่งเป็นเรื่องยาก',
    text_show: true,
    child: [
      {
        label: 'ใช่',
        name: 'take_medicine_difficult',
        type: 'radio',
        value: '1',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-',
      },
      {
        label: 'ไม่ใช่',
        name: 'take_medicine_difficult',
        type: 'radio',
        value: '0',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-',
      },
    ]
  },
  {
    label: '3. เมื่อท่านมีอาการดีขึ้นแล้ว ท่านจะหยุดกินยาต้านไวรัสใช่หรือไม่',
    text_show: true,
    child: [
      {
        label: 'ใช่',
        name: 'stop_take_medicine_when_feel_good',
        type: 'radio',
        value: '1',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-',
      },
      {
        label: 'ไม่ใช่',
        name: 'stop_take_medicine_when_feel_good',
        type: 'radio',
        value: '0',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-',
      },
    ]
  },
  {
    label: '4. ในช่วง 30 วันที่ผ่านมา ท่านเคยขาดยาต้านไวรัสใช่หรือไม่',
    text_show: true,
    child: [
      {
        label: 'ใช่',
        name: 'missing_take_medicine_in_30_days',
        type: 'radio',
        value: '1',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-',
      },
      {
        label: '',
        child: [
          {
            label: '4.1 จำนวนครั้งที่ท่านขาดยาต้านไวรัส',
            child: [
              {
                label: 'ขาดยาต้านไวรัส 1 ครั้ง',
                name: 'missing_take_medicine_once',
                type: 'radio',
                value: '1',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--',
              },
              {
                label: 'ขาดยาต้านไวรัส มากกว่า 1 ครั้ง',
                name: 'missing_take_medicine_once',
                type: 'radio',
                value: 'more',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--',
              },
            ]
          },
          {
            label: '4.2 สาเหตุที่ขาดยาต้านไวรัส',
            label_more: '(เลือกได้มากกว่า 1 ข้อ)',
            child: [
              {
                label: 'เบื่อ ไม่อยากกินยาต้านไวรัส',
                key: 'missing_because_bore',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'ไม่มีเวลากินยาที่เหมาะสม',
                key: 'missing_because_no_time',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'เวลาทำงานไม่แน่นอน ยุ่งกับงาน',
                key: 'missing_because_busy_with_work',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'ทำยาหาย',
                key: 'missing_because_lost',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'ยาหมดก่อนนัดเนื่องจากแบ่งให้คู่/เพื่อน/คนอื่น',
                key: 'missing_because_share',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'ขาดแรงจูงใจในการกินยา',
                key: 'missing_because_no_inspiration',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'ท้อแท้ โดดเดี่ยว ซึมเศร้า หมดอาลัยตายอยาก',
                key: 'missing_because_dispirited',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'ร่างกายแข็งแรงดี ยังไม่ป่วย/ ไม่มีอาการผิดปกติมาก',
                key: 'missing_because_strong',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'คลินิกนัดรับยาถี่เกินไป ไม่สะดวกรับยา',
                key: 'missing_because_too_often',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: 'อื่นๆ',
                key: 'missing_because_other',
                type: 'checkbox',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
              },
              {
                label: '',
                show_on_column: true,
                type: 'text',
                key: 'missing_because_other_detail',
                text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; อื่นๆ : '
              },
            ]
          },
        ]
      },
      {
        label: 'ไม่ใช่',
        name: 'missing_take_medicine_in_30_days',
        type: 'radio',
        value: '0',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-',
      },
    ]
  },
  {
    label: '5. บางครั้งท่านมีอาการแย่ลงหลังจากกินยาต้านไวรัส ท่านได้หยุดกินยาใช่หรือไม่',
    text_show: true,
    child: [
      {
        label: 'ใช่',
        name: 'stop_because_worsing_symptom',
        type: 'radio',
        value: '1',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-',
      },
      {
        label: 'ไม่ใช่',
        name: 'stop_because_worsing_symptom',
        type: 'radio',
        value: '0',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-',
      },
    ]
  },
  {
    label: '6. ท่านมียาต้านไวรัสเหลือ (โดยประมาณ) ',
    text_show: true,
    child: [
      {
        label_before: 'จำนวน ',
        label_after: ' เม็ด',
        key: 'how_many_tablet_are_left',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- จำนวนเม็ดที่เหลือ',
        type: 'number',
        text_suffix: ' เม็ด',
        input_class: 'uk-width-small uk-margin-small-bottom'
      },
      {
        label_before: 'จำนวน ',
        label_after: ' วัน',
        key: 'how_many_day_are_left',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- จำนวนวันที่เหลือ',
        type: 'number',
        text_suffix: ' วัน',
        input_class: 'uk-width-small uk-margin-small-bottom'
      },
      {
        label_before: 'ท่านกินยาต้านไวรัสจำนวน ',
        label_after: ' เม็ด/วัน',
        key: 'how_many_tablet_per_day',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- จำนวนที่ทาน',
        type: 'number',
        text_suffix: ' เม็ด/วัน',
        input_class: 'uk-width-small'
      }
    ]
  },
  {
    label: '7. ท่านดื่มสุรา* หรือใช้สารเสพติดอื่นๆ หรือไม่ ',
    text_show: true,
    child: [
      {
        label: 'ใช่',
        name: 'use_drug',
        type: 'radio',
        value: '1',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-',
      },
      {
        label: 'ไม่ใช่',
        name: 'use_drug',
        type: 'radio',
        value: '0',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-',
      },
      {
        type: 'custom',
        dom: <div className="uk-text-small uk-margin-small-bottom uk-margin-small-top">
          <div>* ดิ่มสุราในที่นี้ หมายถึง ผู้ชาย 	&gt; 4 ดื่มมาตรฐาน/วัน และ ผู้หญิง &gt; 2 ดื่มมาตรฐาน/วัน</div>
          <div className="d-border uk-padding-small uk-display-inline-block uk-margin-small-top">
            <div>1 ดื่มมาตรฐาน เท่ากับ</div>
            <ul className="uk-margin-remove">
              <li>เบียร์ หรือ เครื่องดื่มที่มีแอลกอฮอล์ 5% : 1 กระป๋อง หรือ 1 ขวดเล็ก หรือ 330 มิลลิลิตร</li>
              <li>วิสกี้ หรือ วอดก้าที่มีแอลกอฮอล์ 40-43% : 3 ฝา หรือ 30 มล.</li>
              <li>ไวน์ ที่มีแอลกอฮอล์ 11-13% : 1 แก้ว หรือ 100 มล.</li>
            </ul>
          </div>
        </div>
      }
    ]
  },
  {
    label: '8. ในช่วง 12 เดือนที่ผ่านมา ท่านได้ตรวจปริมาณไวรัสในเลือดหรือไม่',
    text_show: true,
    child: [
      {
        label: 'ตรวจ',
        key: 'virus_check',
        name: 'virus_check',
        type: 'radio',
        value: 'check',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-',
        child: [
          {
            label: 'ตรวจไม่พบปริมาณไวรัสหรือ ระดับไวรัสต่ำกว่า 50 copies/ml',
            value: 'virus_check_less_50',
            name: 'virus_check_amount',
            type: 'radio',
            text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
          },
          {
            label: 'ปริมาณไวรัส 50 - < 1,000 copies/ml',
            value: 'virus_check_less_1000',
            name: 'virus_check_amount',
            type: 'radio',
            text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
          },
          {
            label: 'ปริมาณไวรัส ≥ 1,000 copies/ml',
            value: 'virus_check_more_1000',
            name: 'virus_check_amount',
            type: 'radio',
            text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
          },
          {
            label: 'ไม่ทราบปริมาณไวรัส ',
            value: 'virus_check_unknow',
            name: 'virus_check_amount',
            type: 'radio',
            text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
          },
        ]
      },
      {
        label: 'ไม่ตรวจ',
        key: 'virus_uncheck',
        name: 'virus_check',
        type: 'radio',
        value: 'uncheck',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-',
      },
      {
        label: 'จำไม่ได้',
        key: 'virus_not_remember',
        name: 'virus_check',
        type: 'radio',
        value: 'not_remember',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-',
      },
    ]
  },
  {
    label: '9. ในช่วง 2 สัปดาห์ที่ผ่านมา ท่านมีความรู้สึกหดหู่ เศร้า หรือท้อแท้สิ้นหวัง',
    text_show: true,
    child: [
      {
        label: 'ใช่',
        name: 'feel_depress_in_2_week',
        type: 'radio',
        value: '1',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-',
      },
      {
        label: 'ไม่ใช่',
        name: 'feel_depress_in_2_week',
        type: 'radio',
        value: '0',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-',
      },
    ]
  },
  {
    label: '10. ในช่วง 2 สัปดาห์ที่ผ่านมา ท่านรู้สึกเบื่อ ทำอะไรก็ไม่เพลิดเพลิน',
    text_show: true,
    child: [
      {
        label: 'ใช่',
        name: 'feel_bore_in_2_week',
        type: 'radio',
        value: '1',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-',
      },
      {
        label: 'ไม่ใช่',
        name: 'feel_bore_in_2_week',
        type: 'radio',
        value: '0',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-',
      },
    ]
  },
  {
    label: '11. การมีเพศสัมพันธ์ในช่วง 3 เดือนที่ผ่านมา',
    label_more: '(เลือกได้มากกว่า 1 ข้อ)',
    text_show: true,
    child: [
      {
        label: 'มีคู่เพศสัมพันธ์มากกว่า 1 คน ',
        key: 'sex_more_than_1_person',
        type: 'checkbox',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-'
      },
      {
        label: 'มีคู่เพศสัมพันธ์ที่ป่วยเป็นโรคติดต่อทางเพศสัมพันธ์ ',
        key: 'sex_with_disease',
        type: 'checkbox',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-'
      },
      {
        label: 'มีเพศสัมพันธ์โดยไม่ใช้ถุงยางอนามัย หรือถุงยางอนามัย แตก รั่ว หลุด (ทุกช่องทางที่ใช้ในการมีเพศสัมพันธ์)',
        key: 'sex_with_no_condom',
        type: 'checkbox',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-'
      },
      {
        label: 'มีเพศสัมพันธ์กับผู้ขายบริการทางเพศ ',
        key: 'sex_with_prostitute',
        type: 'checkbox',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-'
      },
      {
        label: 'ไม่มีความเสี่ยงเกี่ยวกับการมีเพศสัมพันธ์ ',
        key: 'sex_with_no_risk',
        type: 'checkbox',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-'
      },
      {
        label: 'มีเพศสัมพันธ์กับเพศเดียวกัน',
        key: 'sex_with_same_gender',
        type: 'checkbox',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-'
      },
      {
        label: 'มีเพศสัมพันธ์กับเพศตรงข้าม',
        key: 'sex_with_opposite_gender',
        type: 'checkbox',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-'
      },
      {
        label: 'มีเพศสัมพันธ์ได้กับทั้งสองเพศ',
        key: 'sex_with_bi',
        type: 'checkbox',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-'
      },
      {
        label: 'ไม่ขอตอบคำถามนี้',
        key: 'sex_not_answer',
        type: 'checkbox',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-'
      }
    ]
  },
  {
    label: '12. คู่นอนของท่านเคยเข้ารับการตรวจเพื่อหาเชื้อ HIV หรือไม่',
    text_show: true,
    child: [
      {
        label: 'เคย',
        name: 'partner',
        type: 'radio',
        value: 'partner_check',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-'
      },
      {
        label: 'ไม่เคย',
        value: 'partner_not_check',
        name: 'partner',
        type: 'radio',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-'
      },
      {
        label: 'ไม่ขอตอบคำถามนี้',
        value: 'partner_not_answer',
        name: 'partner',
        type: 'radio',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-'
      },
    ]
  },
  {
    label: '13. ท่านกำลังวางแผนจะมีบุตร หรือไม่',
    text_show: true,
    child: [
      {
        label: 'ใช่',
        key: 'child_plan',
        name: 'child_plan',
        type: 'radio',
        value: '1',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-'
      },
      {
        label: 'ไม่ใช่',
        key: 'child_plan',
        name: 'child_plan',
        type: 'radio',
        value: '0',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-'
      },
    ]
  },
  {
    label: '14. ท่านเคยได้รับการฉีดวัคซีนโควิด 19 หรือไม่',
    text_show: true,
    child: [
      {
        label: 'ไม่เคยได้รับการฉีด',
        name: 'covid_vuccine',
        type: 'radio',
        value: '0',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-'
      },
      {
        label: 'เคยได้รับการฉีด',
        name: 'covid_vuccine',
        type: 'radio',
        value: '1',
        text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-',
        child: [
          {
            label: 'จำนวน 1 เข็ม',
            key: 'covid_vuccine_1',
            name: 'covid_vuccine_amount',
            type: 'radio',
            value: '1',
            text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
          },
          {
            label: 'จำนวน 2 เข็ม',
            key: 'covid_vuccine_2',
            name: 'covid_vuccine_amount',
            type: 'radio',
            value: '2',
            text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
          },
          {
            label: 'จำนวน 3 เข็ม',
            key: 'covid_vuccine_3',
            name: 'covid_vuccine_amount',
            type: 'radio',
            value: '3',
            text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
          },
          {
            label: 'จำนวนมากกว่าเท่ากับ 4 เข็ม',
            key: 'covid_vuccine_4',
            name: 'covid_vuccine_amount',
            type: 'radio',
            value: '4',
            text_prefix : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--'
          },

        ]
      },
    ]
  },
];