import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Api from '../../Components/Api.js';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import numeral from 'numeral';
import ReactPaginate from 'react-paginate';

UIkit.use(Icons);

export default class ArticleList extends Component {
  state = {
    list: [],
    loadingApi: false,
    pageNow: 1,
    pageAmount: 1,
    perPage: 10
  }

  listGet = async  () => {
    this.setState({loadingApi: true});

    var _response = await Api.consult("M2ArticleList", {
      page: this.state.pageNow
    });
    
    this.setState({
      list: _response.list || [],
      loadingApi: false,
      pageAmount: _response.pageAmount || 1
    });
  }

  
  handlePageClick = (page) => {
    this.setState({pageNow: page.selected + 1}, ()=> this.listGet())
  }

  paginateRender = () => {
    return <div className="uk-text-right">
      <ReactPaginate
        className={'uk-padding-remove'}
        previousLabel={'previous'}
        nextLabel={'next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={this.state.pageAmount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={this.handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      />
    </div>
  }

  componentDidMount() {
    this.listGet();
  } 

  render() {
    return <div className="uk-padding">
      <div className="uk-card uk-card-default uk-padding-small">
        <div className="d-card-header">
          <div uk-grid="">
            <div className='uk-width-1-2@m uk-width-1-1'>รายการบทความ</div>
            <div className='uk-width-1-2@m uk-width-1-1 uk-text-right'>
              <a href={'/consult/articleAdd'} >
              <button onClick={()=>this.articleAdd} className='uk-button uk-button-primary'>เพิ่มบทความ</button>
              </a>
            </div>
          </div>
        </div>
        <div className="uk-overflow-auto">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
            <thead>
              <tr>
                <th>ที่</th>
                <th>ชื่อบทความ</th> 
                <th>ใช้งาน</th>
                <th>แก้ไข</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.loadingApi === true ?
                <tr>
                  <td colSpan="100%">
                    <div className="uk-padding-small uk-text-center"><span uk-spinner=""></span></div>
                  </td>
                </tr>
                :
                this.state.list.length === 0 ? 
                <tr>
                  <td colSpan="100%">
                    <div className="uk-padding-small uk-text-center">ไม่พบข้อมูล</div>
                  </td>
                </tr>
                :

                this.state.list.map((data, key)=>{

                  return <tr key={`row${data.id}`}>
                    <td>{(this.state.perPage * (this.state.pageNow-1 ) + key + 1)}</td>
                    <td>
                      {data.title}
                    </td> 
                    <td>{data.publish === "1" ? <span uk-icon="icon:check"></span> : ''}
                    </td>
                    <td>
                      <a target="_blank" href={`/consult/articleAdd?id=${data.id}`}><span uk-icon="pencil"></span></a>
                    </td>
                  </tr>
                })
              }
            </tbody>
          </table>
          <div>
            {this.paginateRender()}
          </div>
          </div>
      </div>
    </div>
  }
};
