import React, { Component } from "react";
import moment from "moment";
import $ from "jquery";

import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min.js";
import "eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css";

export default class DiabetesTypeForm extends Component {
  state = {
    lowsugar : '0',
    eatsweet: '0',
    forgot: '0',
    confident: '0',
    hurt: '0'
  }

  componentDidMount() {
  }
  

  render() {
    return <div className="uk-padding d-border">
      <h2 className="uk-text-center">ข้อมูลก่อนพบแพทย์สำหรับเบาหวานชนิดที่ 2 หรือ เบาหวานชนิดที่ 1</h2>

      <div className="uk-margin-top">1. ใน 1 เดือนที่ผ่านมาท่านมีอาการน้ำตาลต่ำ ได้แก่ เหงื่อออกมาก กระสับกระส่าย ใจสั่น หิว อ่อนเพลีย เวียนหัว มึนงงหรือไม่</div>

      <div className="uk-margin-small-top">
        <label className="uk-margin-right">
          <input type="radio" className="uk-radio" checked={this.state.lowsugar == '1'} onChange={(e)=>this.setState({lowsugar: '1'})} /> ใช่
        </label>
        <label className="uk-margin-right">
          <input type="radio" className="uk-radio" checked={this.state.lowsugar == '0'} onChange={(e)=>this.setState({lowsugar: '0'})} /> ไม่ใช่
        </label>
      </div>

      
      <div className="uk-margin-top">2. ท่านมีอาการกังวล กลัวน้ำตาลต่ำ จนต้องกินน้ำหวานหรือขนมบ่อยๆ เพื่อป้องกันอาการน้ำตาลต่ำหรือไม่</div>
      
      <div className="uk-margin-small-top">
        <label className="uk-margin-right">
          <input type="radio" className="uk-radio" checked={this.state.eatsweet == '1'} onChange={(e)=>this.setState({eatsweet: '1'})} /> ใช่
        </label>
        <label className="uk-margin-right">
          <input type="radio" className="uk-radio" checked={this.state.eatsweet == '0'} onChange={(e)=>this.setState({eatsweet: '0'})} /> ไม่ใช่
        </label>
      </div>

      <div className="uk-margin-top">3. ใน 1 เดือนที่ผ่านมา ท่านลืมฉีดยาเบาหวานหรือไม่</div>

      <div className="uk-margin-small-top">
        <label className="uk-margin-right">
          <input type="radio" className="uk-radio" checked={this.state.forgot == '0'} onChange={(e)=>this.setState({forgot: '0'})} /> ไม่ลืม
        </label>
        <label className="uk-margin-right">
          <input type="radio" className="uk-radio" checked={this.state.forgot == '1'} onChange={(e)=>this.setState({forgot: '1'})} /> ลืมเล็กน้อย (น้อยกว่า 2 ครั้ง)
        </label>
        <label className="uk-margin-right">
          <input type="radio" className="uk-radio" checked={this.state.forgot == '2'} onChange={(e)=>this.setState({forgot: '2'})} /> ลืมบ่อย (มากกว่า 3 ครั้ง)
        </label>
      </div>

      
      <div className="uk-margin-top">4. ท่านมั่นใจในเทคนิคการฉีดยาเบาหวานของท่านหรือไม่</div>
      
      <div className="uk-margin-small-top">
        <label className="uk-margin-right">
          <input type="radio" className="uk-radio" checked={this.state.confident == '0'} onChange={(e)=>this.setState({confident: '0'})} /> มั่นใจ
        </label>
        <label className="uk-margin-right">
          <input type="radio" className="uk-radio" checked={this.state.confident == '1'} onChange={(e)=>this.setState({confident: '1'})} /> ไม่มั่นใจ
        </label>
      </div>

      
      <div className="uk-margin-top">5. หลังฉีดยาเบาหวานท่านมีเลือดออก หรือเจ็บตำแหน่งที่ฉีดบ่อยๆ หรือไม่</div>
      
      <div className="uk-margin-small-top">
        <label className="uk-margin-right">
          <input type="radio" className="uk-radio" checked={this.state.hurt == '0'} onChange={(e)=>this.setState({hurt: '0'})} /> ใช่
        </label>
        <label className="uk-margin-right">
          <input type="radio" className="uk-radio" checked={this.state.hurt == '1'} onChange={(e)=>this.setState({hurt: '1'})} /> ไม่ใช่
        </label>
      </div>

      <div className="uk-margin-top">
        <button className="uk-button uk-button-primary uk-width-1-1" onClick={()=>{
          var _alert_tag = [];

          if(this.state.lowsugar == '1') {
            _alert_tag.push('symptomatic hypoglycemia');
          }

          if(this.state.eatsweet == '1') {
            _alert_tag.push('fear hypoglycemia');
          }

          if(this.state.forgot != '0') {
            _alert_tag.push('compliance');
          }

          if(this.state.confident == '1' || this.state.hurt == '0') {
            _alert_tag.push('injection problem');
          }

          this.props.callback({
            alert_tag: _alert_tag.join(','),
            text : `<div><b><u>ข้อมูลก่อนพบแพทย์สำหรับเบาหวานชนิดที่ 2 หรือ เบาหวานชนิดที่ 1</u></b></div>
            <div><b>1. ใน 1 เดือนที่ผ่านมาท่านมีอาการน้ำตาลต่ำ ได้แก่ เหงื่อออกมาก กระสับกระส่าย ใจสั่น หิว อ่อนเพลีย เวียนหัว มึนงงหรือไม่</b></div><div> - ${this.state.lowsugar == '1' ? 'ใช่' : 'ไม่'} </div>
            <div><b>2. ท่านมีอาการกังวล กลัวน้ำตาลต่ำ จนต้องกินน้ำหวานหรือขนมบ่อยๆ เพื่อป้องกันอาการน้ำตาลต่ำหรือไม่</b></div><div> - ${this.state.eatsweet == '1' ? 'ใช่' : 'ไม่'} </div>
            <div><b>3. ใน 1 เดือนที่ผ่านมา ท่านลืมฉีดยาเบาหวานหรือไม่</b></div><div> - ${this.state.forgot == '0' ? 'ไม่ลืม' : ( this.state.forgot == '1' ? 'ลืมเล็กน้อย (น้อยกว่า 2 ครั้ง)' : 'ลืมบ่อย (มากกว่า 3 ครั้ง)')} </div>
            <div><b>4. ท่านมั่นใจในเทคนิคการฉีดยาเบาหวานของท่านหรือไม่</b></div><div> - ${this.state.confident == '0' ? 'มั่นใจ' : 'ไม่มั่นใจ'} </div>
            <div><b>5. หลังฉีดยาเบาหวานท่านมีเลือดออก หรือเจ็บตำแหน่งที่ฉีดบ่อยๆ หรือไม่</b></div><div> - ${this.state.hurt == '0' ? 'ใช่' : 'ไม่ใช่'} </div>
            `
          });
        }}>ส่งข้อมูล</button>
      </div>
      
    </div>
  }
}