export default [
    {
      title: '1. ท่านมีอาการผิดปกติเหล่านี้ในช่วง 1 เดือนที่ผ่านมาหรือไม่',
      child: [
        {
          title: 'มีอาการผิดปกติ',
          key: 'choose_abnormal_1',
          child: [
            {
              title: 'ไอต่อเนื่อง',
              key: 'continuous_cough'
            },
            {
              title: 'ไอเป็นเลือด',
              key: 'blood_cough'
            },
            {
              title: 'น้ำหนักลด',
              key: 'weight_loss'
            },
            {
              title: 'ไข้',
              key: 'fever'
            },
            {
              title: 'เหงื่อออกมากผิดปกติ',
              key: 'unknow_sweating'
            },
            {
              title: 'ผื่น',
              key: 'rash'
            },
            {
              title: 'เวียนหัว',
              key: 'dizziness'
            },
            {
              title: 'อ่อนเพลีย',
              key: 'tired'
            },
            {
              title: 'คลื่นไส้ อาเจียน',
              key: 'squeamish'
            },
            {
              title: 'เบื่ออาหาร',
              key: 'bored_food'
            },
            {
              title: 'ถ่ายเหลว',
              key: 'diarrhea'
            },
            {
              title: 'ตัวเหลือง ตาเหลือง',
              key: 'yellow_body'
            },
            {
              title: 'นอนไม่หลับ',
              key: 'sleepless'
            },
            {
              title: 'มีปัสสาวะแสบขัด',
              key: 'urine_pain'
            },
            {
              title: 'มีหนองออกจากทางเดินปัสสาวะ',
              key: 'urine_purulent'
            },
            {
              title: 'มีตกขาวผิดปกติ',
              key: 'abnormal_leucorrhoea'
            },
            {
              title: 'คัน บวม หรือเป็นแผลบริเวณอวัยวะเพศ',
              key: 'genitalia_wound_itch_swell'
            },
            {
              title: 'อื่นๆ',
              key: 'symptom_other_abnormal'
            },
          ]
        },
        {
          title: 'ไม่มีอาการผิดปกติ',
          key: 'choose_abnormal_0'
        },
  
      ]
    },
    {
      title : '2. ในช่วง 30 วันที่ผ่านมา ท่านเคยขาดยาต้านไวรัสใช่หรือไม่',
      child: [
        {
          title: 'ใช่',
          key: 'missing_medicine_1',
          child: [
            {
              title: "2.1 จำนวนครั้งที่ท่านขาดยาต้านไวรัส",
              child: [
                {
                  title: 'ขาดยาต้านไวรัส 1 ครั้ง',
                  key: 'missing_take_medicine_once_1'
                },
                {
                  title: 'ขาดยาต้านไวรัส มากกว่า 1 ครั้ง',
                  key: 'missing_take_medicine_once_more'
                },
              ]
            },
            {
              title: "2.2 สาเหตุที่ขาดยาต้านไวรัส (เลือกได้มากกว่า 1 ข้อ)",
              child: [
                {
                  title: "เบื่อไม่อยากกินยาต้านไวรัส",
                  key: "missing_because_bore"
                },
                {
                  title: "ไม่มีเวลากินยาที่เหมาะสม",
                  key: "missing_because_no_time"
                },
                {
                  title: "เวลาทำงานไม่แน่นอน ยุ่งกับงาน",
                  key: "missing_because_busy_with_work"
                },
                {
                  title: "ทำยาหาย",
                  key: "missing_because_lost"
                },
                {
                  title: "ยาหมดก่อนนัดเนื่องจากแบ่งให้คู่/เพื่อน/คนอื่น",
                  key: "missing_because_share"
                },
                {
                  title: "ขาดแรงจูงใจในการกินยา",
                  key: "missing_because_no_inspiration"
                },
                {
                  title: "ท้อแท้ โดดเดี่ยว หมดอาลัยตายอยาก",
                  key: "missing_because_dispirited"
                },
                {
                  title: "ร่างกายแข็งแรงดี ยังไม่ป่วย/ไม่มีอาการผิดปกติมาก",
                  key: "missing_because_strong"
                },
                {
                  title: "คลินิกนัดรับยาถี่เกินไป ไม่สะดวกรับยา",
                  key: "missing_because_too_often"
                },
                {
                  title: "อื่นๆ",
                  key: "missing_because_other"
                },
              ]
            }
          ]
        },
        {
          title: 'ไม่ใช่',
          key: 'missing_medicine_0',
        }
      ]
    },
    {
      title: '3. ท่านดื่มสุรา หรือใช้สารเสพติดอื่นๆ หรือไม่',
      child: [
        {
          title: 'ใช่',
          key: 'check_drink_alcohol_1'
        },
        {
          title: 'ไม่ใช่',
          key: 'check_drink_alcohol_0'
        },
      ]
    },
    {
      title: '4. ในช่วง 12 เดือนที่ผ่านมา ท่านได้ตรวจปริมาณไวรัสในเลือดหรือไม่',
      child: [
        {
          title: 'ใช่',
          key: 'virus_check_ตรวจ',
          child: [
            {
              title: 'ตรวจไม่พบปริมาณไวรัสหรือ ระดับไวรัสต่ำกว่า 50 copies/ml',
              key: 'virus_check_amount_<50'
            },
            {
              title: 'ปริมาณไวรัส 50-200 copies/ml',
              key: 'virus_check_amount_<200'
            },
            {
              title: '>200-<1000 copies/ml',
              key: 'virus_check_amount_<1000'
            },
            {
              title: 'ปริมาณไวรัส ≥ 1000 copies/ml',
              key: 'virus_check_amount_>1000'
            },
            {
              title: 'ไม่ทราบปริมาณไวรัส',
              key: 'virus_check_amount_unknown'
            },
          ]
        },
        {
          title: 'ไม่ตรวจ',
          key: 'virus_check_ไม่ตรวจ'
        },
        {
          title: 'จำไม่ได้',
          key: 'virus_check_จำไม่ได้'
        },
      ]
    },
    {
      title: '5. ในช่วง 2 สัปดาห์ที่ผ่านมา ท่านมีความรู้สึกหดหู่ เศร้า หรือ ท้อแท้สิ้นหวัง',
      child: [
        {
          title: 'ใช่',
          key: 'feel_depress_in_2_week_1'
        },
        {
          title: 'ไม่ใช่',
          key: 'feel_depress_in_2_week_0'
        },
      ]
    },
    {
      title: '6. ในช่วง 2 สัปดาห์ที่ผ่านมา ท่านรู้สึกเบื่อ ทำอะไรก็ไม่เพลิดเพลิน',
      child: [
        {
          title: 'ใช่',
          key: 'feel_bore_in_2_week_1'
        },
        {
          title: 'ไม่ใช่',
          key: 'feel_bore_in_2_week_0'
        },
      ]
    },
    {
      title: '7. การมีเพศสัมพันธ์ในช่วง 3 เดือนที่ผ่านมา (เลือกได้มากกว่า 1 ข้อ)',
      child: [
        {
          title: 'มีเพศสัมพันธ์มากกว่า 1 คน',
          key: 'sex_more_than_1_person'
        },
        {
          title: 'มีคู่เพศสัมพันธ์ที่เป็นโรคติดต่อทางเพศสัมพันธ์',
          key: 'sex_with_disease'
        },
        {
          title: 'มีเพศสัมพันธ์โดยไม่ใช้ถุงยางอนามัย หรือถุงยางอนามัย แตก รั่ว หลุด (ทุกช่องทางที่ใช้ในการมีเพศสัมพันธ์)',
          key: 'sex_with_no_condom'
        },
        {
          title: 'มีเพศสัมพันธ์กับผู้ขายบริการทางเพศ',
          key: 'sex_with_prostitute'
        },
        {
          title: 'ไม่มีความเสี่ยงเกี่ยวกับการมีเพศสัมพันธ์',
          key: 'sex_with_no_risk'
        },
        {
          title: 'ไม่ขอตอบคำถามนี้',
          key: 'sex_not_answer'
        },
      ]
    },
    {
      title: '8. คู่นอนของท่านเคยเข้ารับการตรวจเพื่อหาเชื้อ HIV หรือไม่',
      child: [
        {
          title: 'เคย',
          key: 'partner_check_เคย'
        },
        {
          title: 'ไม่เคย',
          key: 'partner_check_ไม่เคย'
        },
        {
          title: 'ไม่มีคู่นอน',
          key: 'partner_check_ไม่มีคู่นอน'
        },
        {
          title: 'ไม่ขอตอบคำถามนี้',
          key: 'partner_check_ไม่ขอตอบคำถามนี้'
        },
      ]
    },
    {
      title: '9. ท่านกำลังวางแผนจะมีบุตร หรือไม่',
      child: [
        {
          title: 'ใช่',
          key: 'child_plan_ใช่'
        },
        {
          title: 'ไม่ใช่ / ไม่เกี่ยวข้อง',
          key: 'child_plan_ไม่ใช่'
        },
        {
          title: 'ไม่ขอตอบ',
          key: 'child_plan_ไม่ขอตอบ'
        },
      ]
    },
  ];
