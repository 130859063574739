import React, { Component } from "react";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import $ from "jquery";
import Api from "../Api";
import moment from "moment";
import numeral from "numeral";

UIkit.use(Icons);

export default class UserHealthBook extends Component {
  state = {
    data: {},
    profile: {},
    bmi: "",
    company: {},
    group: [],
    //image: '',
    //imageOpen: false
  };

  componentDidMount() {
    var _date_lab = null;

    var _data = {};
    if (this.props.data == null) this.props.data = [];

    this.props.data.forEach((data) => {
      if (_data[data.name] === undefined)
        _data[data.name] = {
          dateadd: data.dateadd,
          value: data.value,
        };

      if (_data.age === undefined) _data.age = { value: data.age };

      if (
        _data.dateLab === undefined ||
        moment(_data.dateLab.value) < moment(data.dateadd)
      )
        _data.dateLab = { value: data.dateadd };
        

      if (_data.congenitaldisease === undefined)
        _data.congenitaldisease = { value: `${data.congenitaldisease}${data.diabetes === "0" ? "" : " เบาหวาน"}` };
    });

    if (_data.dateLab !== undefined)
      _data.dateLab.value = moment(_data.dateLab.value)
        .add(543, "y")
        .format("DD/MM/YY");

    if (_data["weight/height"] !== undefined) {
      var _data_tmp = _data["weight/height"].value.split("/");

      if (
        _data.weight &&
        moment(_data.weight.dateadd) < moment(_data["weight/height"]).dateadd
      ) {
        _data.weight.dateadd = _data["weight/height"].dateadd;
        _data.weight.value = _data_tmp[0];
      }

      if (
        _data.height &&
        moment(_data.height.dateadd) < moment(_data["weight/height"]).dateadd
      ) {
        _data.height.dateadd = _data["weight/height"].dateadd;
        _data.height.value = _data_tmp[1];
      }
    }

    if (_data["fbs/fpg"] !== undefined && _data.fbs !== undefined) {
      if (moment(_data["fbs/fpg"].dateadd) < moment(_data.fbs).dateadd) {
        _data["fbs/fpg"] = _data.fbs;
      }
    }

    if (_data["fbs/fpg"] === undefined && _data.fbs !== undefined) {
      _data["fbs/fpg"] = _data.fbs;
    }

    (async () => {
      var { data, datacheck } = await Api.member("UserSmartHealthRecord", {
        dataType: [
          "weight", //16
          "height", //17
          "bmi", //18
          "bp-sys", //21
          "bp-dias", //24
          "pulse", //27
          "waist", //30
          "whr", //33
          "conclusion", //36***
          "binocular-vision", //37
          "visual-acuity", //39
          "color-blindness", //41
          "stereo-depth", //43
          "vertical-phoria", //45
          "lateral-phoria", //47
          "horizontal-visual-field-left", //49
          "horizontal-visual-field-right", //51
          "close-visual-left", //53
          "close-visual-right", //55
          "chest-x-ray", //57
          "ekg", //59
          "general-physical-examination", //61
          "mammogragraphy", //63
          "thin-prep-pap-test", //65
          "psa", //67
          "cea", //69
          "hb", //71***
          "hct", //74
          "rbc-count", //77
          "rdw", //80
          "rbc-morph", //83
          "mcv", //85***
          "mch", //88
          "mchc", //91
          "platelets", //94
          "mpv", //97
          "wbc", //100
          "neutrophil-ratio", //103
          "neutrophil-count", //106
          "lymphocyte-ratio", //109
          "lymphocyte-count", //112
          "eosinophil-ratio", //115
          "eosinophil-count", //118
          "monocyte-ratio", //121
          "monocyte-count", //124
          "basophil-ratio", //127
          "basophil-count", //130
          "blast-ratio", //133
          "blast-count", //136
          "kidney", //139
          "bun", //141****
          "creatinine", //144
          "uric", //147
          "fbs/fpg", //150
          "liver", //153 ***
          "sgot", //155 ***
          "sgpt", //158
          "alk.phosphatase", //161
          "t.bilirubin", //164
          "d.bilirubin", //167
          "fat", //170 ***
          "chol", //172 ***
          "tg", //175
          "hdl", //178
          "ldl", //181
          "hbsag", //184 ***
          "anti-hbs", //186 ***
          "anti-hbc", //189
          "anti-hav", //192
          "stool-examination", //195 ***
          "fecal-occult-blood", //197
          "ua-color", //199
          "ua-apperance", //201
          "ua-spgr", //203 ***
          "ua-ph", //206
          "ua-wbc", //209
          "ua-rbc", //212
          "ua-erythocyt", //215 ***
          "ua-glucose", //217
          "ua-protein", //219
          "ua-ketone", //221
          "ua-bilirubin", //223
          "ua-squa-epi", //225 ***
          "ua-recommend", //228 ***
          "audiogram-l-500", //230 ***
          "audiogram-l-1000", //233
          "audiogram-l-2000", //236
          "audiogram-l-3000", //239
          "audiogram-l-4000", //242
          "audiogram-l-6000", //245
          "audiogram-l", //248 **
          "audiogram-r-500", //250 ***
          "audiogram-r-1000", //253
          "audiogram-r-2000", //256
          "audiogram-r-3000", //259
          "audiogram-r-4000", //262
          "audiogram-r-6000", //265
          "audiogram-r", //268 **
          "audiogram", //270
          "fvc", //272 ***
          "fev1", //275
          "fev1/fvc%", //278
          "fef25-75%", //281
          "pft", //284 **
          "benzene", //286 ***
          "toluene", //289
          "toluene-in-blood", //292
          "toluene-in-urine", //295
          "xylene", //298
          "styrene", //301
          "hexane", //304
          "methanol", //307
          "arsenic", //310
          "inorganic-arsenic-plus-methylated-metabolites", //313
          "mercury", //316
          "chromium-in-urine", //319
          "chromium-in-blood", //322
          "confined-work", //325**
          "gramma-work", //327
          "diving-work", //329

          "hbsab",
          "other-list-1",
          "other-value-1",
          "other-normal-1",
          "other-result-1",
          "other-list-2",
          "other-value-2",
          "other-normal-2",
          "other-result-2",
          "other-list-3",
          "other-value-3",
          "other-normal-3",
          "other-result-3",

          "stool-color-result",
          "stool-color-normal",
          "stool-appearance-result",
          "stool-appearance-normal",
          "stool-wbc-result",
          "stool-rbc-result",
          "stool-parasite-result",
          "stool-parasite-normal",
          "stool-examination-note",

          "fbs/fpg",
          "fbs/fpg-result",
          "hba1c",
          "hba1c-result",
          "exercise-per-minute",
          "sleep-per-hour",
          "smoke-per-day",
          "alcohol-per-day",
          "depress-2-week",
          "bored-2-week",
          "pv-pap-smear",
          "pv-pap-smear-note",
          "mamogram",
          "mamogram-note",
          "ultrasound-place",
          "ultrasound",
          "ultrasound-note",
          "exercise-stres",
          "exercise-stress-note",
          "vaccine-name-1",
          "vaccine-date-1",
          "vaccine-name-2",
          "vaccine-date-2",
          "vaccine-name-3",
          "vaccine-date-3",
          "vaccine-name-4",
          "vaccine-date-4",
          "antenatal-age-1",
          "antenatal-place-1",
          "antenatal-date-1",
          "antenatal-age-2",
          "antenatal-place-2",
          "antenatal-date-2",
          "antenatal-age-3",
          "antenatal-place-3",
          "antenatal-date-3",
          "antenatal-age-4",
          "antenatal-place-4",
          "antenatal-date-4",
          "globulin",
          "globulin-result",
          "albumin",
          "albumin-result",
          "ammonia",
          "ammonia-result",
          "walking-amount",
          "food-amount",
          "drink-amount"
        ],
      });

      if (!datacheck) datacheck = {};

      if (data) {
        data.forEach(function (d) {
          //update some value
          if (d.name === "bp-sys") {
            if (_data["sys/dias"]) {
              _data["sys/dias"].dateadd = d.dateadd;
              _data["sys/dias"].value = _data["sys/dias"].value.split("/");
              _data["sys/dias"].value[0] = d.value;

              _data["sys/dias"].value = _data["sys/dias"].value.join("/");
            } else {
              _data["sys/dias"] = {
                dateadd: d.dateadd,
                value: `${d.value}/0`,
              };
            }
          } else if (d.name === "bp-dias") {
            if (_data["sys/dias"]) {
              _data["sys/dias"].dateadd = d.dateadd;
              _data["sys/dias"].value = _data["sys/dias"].value.split("/");
              _data["sys/dias"].value[1] = d.value;

              _data["sys/dias"].value = _data["sys/dias"].value.join("/");
            } else {
              _data["sys/dias"] = {
                dateadd: d.dateadd,
                value: `0/${d.value}`,
              };
            }
          }

          _data[d.name] = d;
        });
      }

      this.setState({ data: _data }, () => {
        this.profileGet();
      });
    })();
  }

  profileGet = async () => {
    var { profile, bmi, company, group } = await Api.member("UserProfileCard", {
      memberId: this.props.memberId || false,
    });

    this.setState(
      {
        bmi: profile.bmi ? profile.bmi : "-",
        profile: profile ? profile : {},
        company: company ? company : {},
        group: group || [],
      },
      () => {
        if (this.props.printPrompt === true) {
          this.printPrompt();
        }
        
      
        
      }
    );
  };

  printPrompt = () => {
    
    $("#d-print-container").remove();

    $("#root").prepend(
      $('<div id="d-print-container">').html(
        $("#d-print-user-health-book-container").html()
      )
    );

    window.print();
    
    
    $("#d-print-container").css({display: 'none'});
    /*
    $("#d-print-container").remove();

    $("#d-print-user-health-book-container").css("display", "inline-block");
    
    html2canvas(
      document.querySelector("#d-print-user-health-book-container")
    ).then((canvas) => {
      canvas.setAttribute("id", "canvas-pdf");
      document.body.appendChild(canvas);
      
      var _image = document.getElementById("canvas-pdf").toDataURL("image/jpg");


      
      
      this.setState({image: _image, imageOpen: true}, ()=>{
        //$("#canvas-pdf").remove();
        //$("#d-print-user-health-book-container").removeAttr("style");
      });
      
    });
    */
  };

  buttonPrintRender = () => {
    if (this.props.printPrompt === true) {
      return null;
    } else {
      return (
          <div>
            <button
              className="uk-button uk-button-primary uk-width-1-1"
              onClick={this.printPrompt}
            >
              พิมพ์สมุดสุขภาพ
            </button>
          </div>
      );
    }
  };

  getData = (name) => {
    if (this.state.data[name]) return this.state.data[name].value;
    else return "";
  };

  getCheckResult = (name, color_row) => {
    if (this.state.data[name]) {
      if (color_row === true) {
        return this.state.data[name].checkresult === "ผิดปกติ" ? (
          <span class="d-text-red">{this.state.data[name].checkresult}</span>
        ) : (
          this.state.data[name].checkresult
        );
      } else {
        return name.toUpperCase() + " " + this.state.data[name].checkresult;
      }
    } else return "";
  };

  getCheckNote = (name) => {
    if (this.state.data[name]) return this.state.data[name].checknote;
    else return "";
  };
  /*
  formularRender = (name) => {
    if(!this.getData(name)) return {
      value: '',
      status: ''
    }

    var _value = +this.getData(name);

    var _result = {
      value: _value,
      status: 'ปกติ'
    };

    switch(name) {
      case 'fbs/fpg':
        if(!(_value >= 70 && _value <= 99)) {
          _result = {
            value: <span className="d-text-red">{_value}</span>,
            status: <span className="d-text-red">{'ผิดปกติ'}</span>
          }
        }
        break;
        case 'chol':
          if(!(_value >= 0 && _value <= 200)) {
            _result = {
              value: <span className="d-text-red">{_value}</span>,
              status: <span className="d-text-red">{'ผิดปกติ'}</span>
            }
          }
        break;
        case 'tg':
          if(!(_value >= 0 && _value <= 160)) {
            _result = {
              value: <span className="d-text-red">{_value}</span>,
              status: <span className="d-text-red">{'ผิดปกติ'}</span>
            }
          }
        break;
        case 'hdl':
          if(!(_value < 45)) {
            _result = {
              value: <span className="d-text-red">{_value}</span>,
              status: <span className="d-text-red">{'ผิดปกติ'}</span>
            }
          }
        break;
        case 'ldl':
          if(!(_value >= 0 && _value <= 160)) {
            _result = {
              value: <span className="d-text-red">{_value}</span>,
              status: <span className="d-text-red">{'ผิดปกติ'}</span>
            }
          }
        break;
        case 'sgot': case 'sgpt':
          if(!(_value >= 0 && _value <= 40)) {
            _result = {
              value: <span className="d-text-red">{_value}</span>,
              status: <span className="d-text-red">{'ผิดปกติ'}</span>
            }
          }
        break;
        case 'bun':
          if(!(_value >= 7.8 && _value <= 20.3)) {
            _result = {
              value: <span className="d-text-red">{_value}</span>,
              status: <span className="d-text-red">{'ผิดปกติ'}</span>
            }
          }
        break;
        case 'creatinine':
          if(!(_value >= 0.7 && _value <= 1.5)) {
            _result = {
              value: <span className="d-text-red">{_value}</span>,
              status: <span className="d-text-red">{'ผิดปกติ'}</span>
            }
          }
        break;
        case 'uric':
          if(!(_value >= 2.3 && _value <= 8.2)) {
            _result = {
              value: <span className="d-text-red">{_value}</span>,
              status: <span className="d-text-red">{'ผิดปกติ'}</span>
            }
          }
        break;
      default:
    }

    return _result;
  }
  */

  render() {
    return (
      <div>
        {/*
        <div style={{
          position: 'fixed',
          background: 'rgba(0,0,0,.5)',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflowY: 'scroll',
          minHeight: 200,
          zIndex: 1200,
          textAlign: 'center',
          padding: 30,
          display: this.state.imageOpen === true ? 'block' : 'none'
        }} onClick={()=>this.setState({imageOpen: false})}>{this.state.image}<img src={this.state.image} onClick={(e)=>{
          e.stopPropagation();
        }} onLoad={(e)=>{
          e.target.src = this.state.image;
        }} style={{maxWidth: '90%'}} /></div>
      */}
        {this.buttonPrintRender()}
        <div id="d-print-user-health-book-container">
          <div id="d-print-user-health-book">
            {/**  */}
            <div
              className="d-user-health-book-container"
              style={{ minHeight: 740 }}
            >
              <div className="d-text-blue uk-text-center">
                [ สรุปผลการตรวจและข้อแนะนำในการปฏิบัติตนประจำปี{" "}
                {moment(this.getData("dateLab"), "DD/MM/YY").subtract(43, 'years').format('YYYY')} ]
              </div>
              <div>- ผลการตรวจร่างกายทั่วไปโดยแพทย์ </div>
              <div>* ผลตรวจภาพรังสีทรวงอก </div>
              <div>* ผลตรวจนับเม็ดเลือดแบบสมบูรณ์ </div>
              <div>* ผลตรวจปัสสาวะ </div>
              <div>* ผลตรวจระดับน้ำตาลในเลือด </div>
              <div>* ผลตรวจระดับไขมันในเลือด </div>
              <div>* ผลตรวจระดับไขมันไตรกลีเซอไรด์ในเลือด </div>
              <div>* ผลตรวจไขมันชนิดดี(HDL) </div>
              <div>* ผลตรวจไขมันเลว(LDL) </div>
              <div>* ผลตรวจเอนไซม์ตับ </div>
              <div>* ผลตรวจการทํางานของไต </div>
              <div>* ผลตรวจระดับกรดยูริคในเลือด </div>
              <div>* ผลตรวจเลือดไม่พบเชื้อไวรัสตับอักเสบบี </div>
              <div>* ผลตรวจเลือดไม่พบเชื้อไวรัสตับอักเสบบี </div>
              <div>* ผลตรวจคลื่นไฟฟ้าหัวใจ (EKG)</div>
            </div>
            <div className="d-page-break"></div>

            {/**  */}
            <div className="d-user-health-book-container">
              <div className="d-text-blue uk-text-center">
                [ รายงานผลการตรวจร่างกายเบื้องต้น (Physical Examination) ]
              </div>
              <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                <tr>
                  <td>
                    <span className="d-text-blue">น้่ำหนัก (Weight)</span>
                    <span
                      className="uk-text-center"
                      style={{ display: "inline-block", width: 45 }}
                    >
                      {this.getData("weight")}
                    </span>
                    <span className="d-text-blue">กิโลกรัม</span>
                  </td>
                  <td>
                    <span className="d-text-blue">ส่วนสูง (Height)</span>
                    <span
                      className="uk-text-center"
                      style={{ display: "inline-block", width: 45 }}
                    >
                      {this.getData("height")}
                    </span>
                    <span className="d-text-blue">เซนติเมตร</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <span className="d-text-blue">ดัชนีมวลกาย(BMI)</span>
                    <span
                      className="uk-text-center"
                      style={{ display: "inline-block", width: 85 }}
                    >
                      {this.state.bmi
                        ? numeral(this.state.bmi).format("0.00")
                        : ""}
                    </span>
                    <span className="d-text-blue">
                      กก./ม<sup>2</sup>.
                    </span>{" "}
                    <span className="d-text-pink">
                      [ค่าปกติ:18.5-24.9 กก./ม<sup>2</sup>.]
                    </span>
                  </td>
                </tr>
              </table>
              <div className="d-note-border-bottom">
                {this.getCheckResult("bmi")}
              </div>
              <div className="d-note-border-bottom">
                {this.getCheckNote("bmi")}
              </div>
              <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                <tr>
                  <td>
                    <span className="d-text-blue">ความดันโลหิต (BP)</span>
                    <span
                      className="uk-text-center"
                      style={{ display: "inline-block", width: 65 }}
                    >
                      {this.getData("sys/dias")}
                    </span>
                    <span className="d-text-blue">ม.ม.ปรอท</span>
                  </td>
                  <td>
                    <span className="d-text-blue">ชีพจร (Pulse)</span>
                    <span
                      className="uk-text-center"
                      style={{ display: "inline-block", width: 45 }}
                    >
                      {this.getData("pulse")}
                    </span>
                    <span className="d-text-blue">ครั้ง/นาที</span>
                  </td>
                </tr>
              </table>
              <div className="d-note-border-bottom">
                {this.getCheckResult("bp-sys")} {this.getCheckNote("bp-sys")}
              </div>
              <div className="d-note-border-bottom">
                {this.getCheckResult("bp-dias")} {this.getCheckNote("bp-dias")}
              </div>
              <div className="d-note-border-bottom">
                {this.getCheckResult("pulse")} {this.getCheckNote("pulse")}
              </div>
              <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                <tr>
                  <td>
                    <div className="d-text-blue uk-text-center">
                      โรคประจำตัว
                    </div>
                    <div className="d-note-border-bottom">
                      {this.getData("diabetes")}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="d-text-blue uk-text-center">
                      ประวัติแพ้ยา,แพ้อาหาร
                    </div>
                    <div className="d-note-border-bottom"></div>
                  </td>
                </tr>
              </table>
              <div>
                <div className="uk-text-center">
                  <span className="d-text-blue">
                    <u>รายละเอียดของผู้ที่เข้ารับการตรวจ</u>
                  </span>
                </div>
                <div>
                  <span className="d-text-blue">บริษัท : </span>{" "}
                  {this.state.company.name}
                  <span className="d-text-purple">
                    <span
                      className="uk-text-center"
                      style={{ display: "inline-block", width: 65 }}
                    ></span>
                  </span>
                </div>
                <div>
                  <span className="d-text-blue">ชื่อ-นามสกุล : </span>{" "}
                  <span>
                    {this.state.profile.nameprefix} {this.state.profile.name}{" "}
                    {this.state.profile.lastname}
                  </span>
                </div>
                <div>
                  <span className="d-text-blue">แผนก/ตำแหน่ง : </span>{" "}
                  <span>{this.state.company.position}</span>
                </div>
                <div>
                  <span className="d-text-blue">รหัส : </span>
                  <span
                    className="uk-text-center"
                    style={{ display: "inline-block", width: 65 }}
                  ></span>
                  <span className="d-text-blue uk-margin-left">
                    ลำดับที่ :{" "}
                  </span>{" "}
                  <span></span>
                </div>
                <div>
                  <span className="d-text-blue">อายุ : </span>
                  <span
                    className="uk-text-center"
                    style={{ display: "inline-block", width: 65 }}
                  >
                    {this.getData("age")}
                  </span>
                  <span className="d-text-blue">ปี</span>
                  <span className="d-text-blue uk-margin-left">
                    ตรวจวันที่ :{" "}
                  </span>{" "}
                  {this.getData("dateLab")}
                  <span></span>
                </div>
              </div>
              <div>
                <div className="uk-text-center">
                  <span className="d-text-blue">
                    <u>
                      สรุปผลการตรวจร่างกายเบื้องต้นและตรวจร่างกายทั่วไปโดยแพทย์
                    </u>
                  </span>
                </div>
                <div className="d-note-border-bottom">
                  - ผลตรวจร่างกายทั่วไปโดยแพทย์
                </div>
                <div className="d-note-border-bottom">
                  {this.getData("conclusion")}
                </div>
              </div>
            </div>

            <div className="d-page-break"></div>

            {/** */}
            <div className="d-user-health-book-container">
              <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                <tr>
                  <td>
                    <div className="d-text-blue uk-text-center">
                      [ สรุปผลการตรวจภาพรังสีทรวงอก (Chest X-Ray) ]
                    </div>
                    <div className="d-note-border-bottom">
                      * ผลตรวจภาพรังสีทรวงอก{" "}
                    </div>
                    <div className="d-note-border-bottom">
                      {this.getData("chest-x-ray")}
                    </div>
                  </td>
                </tr>
              </table>
              <table
                border="1"
                cellPadding="10"
                cellSpacing="0"
                width="100%"
                className="uk-margin-small-top"
              >
                <tr>
                  <td>
                    <div className="d-text-blue uk-text-center">
                      [ สรุปผลตรวจคลื่นไฟฟ้าหัวใจ (Electrocardiogram-EKG) ]
                    </div>
                    <div className="d-note-border-bottom">
                      * ผลตรวจคลื่นไฟฟ้าหัวใจ (EKG){" "}
                    </div>
                    <div className="d-note-border-bottom">
                      {this.getData("ekg")}
                    </div>
                  </td>
                </tr>
              </table>
              <table
                border="1"
                cellPadding="10"
                cellSpacing="0"
                width="100%"
                className="uk-margin-small-top"
              >
                <tr>
                  <td>
                    <div className="d-text-blue uk-text-center">
                      [ ผลตรวจสายตาอาชีวอนามัย (Occupation Vision Tests) ]
                    </div>
                    <table
                      border="1"
                      cellPadding="10"
                      cellSpacing="0"
                      width="90%"
                      className="uk-margin-small-top"
                      style={{ margin: "auto" }}
                    >
                      <tr>
                        <td className="d-text-purple">ประเภทการทดสอบ</td>
                        <td
                          className="d-text-purple uk-text-center"
                          width="30%"
                        >
                          ผลตรวจ
                        </td>
                      </tr>
                      <tr>
                        <td className="d-text-blue">ทดสอบการมองระยะไกล</td>
                        <td>{this.getData("visual-acuity")}</td>
                      </tr>
                      <tr>
                        <td className="d-text-blue">ทดสอบการมองระยะใกล้</td>
                        <td>
                          {" "}
                          L : {this.getData("close-visual-left")} R :{" "}
                          {this.getData("close-visual-right")}
                        </td>
                      </tr>
                      <tr>
                        <td className="d-text-blue">
                          ทดสอบการมองชัดลึก (ภาพ 3 มิติ)
                        </td>
                        <td>{this.getData("stereo-depth")}</td>
                      </tr>
                      <tr>
                        <td className="d-text-blue">ทดสอบการแยกสี</td>
                        <td>{this.getData("color-blindness")}</td>
                      </tr>
                      <tr>
                        <td className="d-text-blue">
                          ทดสอบความสมดุลกล้ามเนื้อตา
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td className="d-text-blue">ทดสอบลานสายตา</td>
                        <td>
                          L : {this.getData("horizontal-visual-field-left")} R :{" "}
                          {this.getData("horizontal-visual-field-right")}
                        </td>
                      </tr>
                    </table>
                    <div className="d-note-border-bottom"></div>
                    <div className="d-note-border-bottom"></div>
                    <div className="d-note-border-bottom"></div>
                  </td>
                </tr>
              </table>
            </div>

            <div className="d-page-break"></div>

            {/** */}

            <div className="d-user-health-book-container">
              <div className="d-text-blue uk-text-center">
                [ ผลการตรวจปัสสาวะ (Urinalysis) ]
              </div>
              <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                <tr>
                  <td width="40%">สีปัสสาวะ (Color)</td>
                  <td colSpan="2">{this.getData("ua-color")}</td>
                </tr>
                <tr>
                  <td>ความขุ่นใส (Appearance)</td>
                  <td colSpan="2">{this.getData("ua-apperance")}</td>
                </tr>
                <tr>
                  <td className="d-text-blue">Urine Strip</td>
                  <td className="d-text-blue uk-text-center">ผลตรวจ</td>
                  <td className="d-text-blue uk-text-center" width="150">
                    ค่าปกติ
                  </td>
                </tr>
                <tr>
                  <td>ความถ่วงจําเพาะ (Sp.gr.)</td>
                  <td className="uk-text-center">{this.getData("ua-spgr")}</td>
                  <td className="d-text-blue uk-text-center">1.005-1.030</td>
                </tr>
                <tr>
                  <td>ค่าความเป็นกรด, ด่าง (pH)</td>
                  <td className="uk-text-center">{this.getData("ua-ph")}</td>
                  <td className="d-text-blue uk-text-center">5.0 - 8.0</td>
                </tr>
                <tr>
                  <td>น้ําตาลในปัสสาวะ (Glucose)</td>
                  <td className="uk-text-center">
                    {this.getData("ua-glucose")}
                  </td>
                  <td className="d-text-blue uk-text-center">Negative</td>
                </tr>
                <tr>
                  <td>โปรตีนในปัสสาวะ (Protein)</td>
                  <td className="uk-text-center">
                    {this.getData("ua-protein")}
                  </td>
                  <td className="d-text-blue uk-text-center">Negative</td>
                </tr>
                <tr>
                  <td>เลือดในปัสสาวะ (Blood)</td>
                  <td className="uk-text-center"></td>
                  <td className="d-text-blue uk-text-center">Negative</td>
                </tr>
                <tr>
                  <td className="d-text-blue" colSpan="3">
                    Urine Microscopy
                  </td>
                </tr>
                <tr>
                  <td>เม็ดเลือดขาว (WBC)</td>
                  <td className="uk-text-center">{this.getData("ua-wbc")}</td>
                  <td className="d-text-blue uk-text-center">0 - 5 HPF</td>
                </tr>
                <tr>
                  <td>เม็ดเลือดแดง (RBC)</td>
                  <td className="uk-text-center">{this.getData("ua-rbc")}</td>
                  <td className="d-text-blue uk-text-center">0 - 3 HPF</td>
                </tr>
                <tr>
                  <td>เซลล์เยื่อบุ (Epithelial Cell)</td>
                  <td className="uk-text-center">{this.getData("ua-epi")}</td>
                  <td className="d-text-blue uk-text-center">0 - 5 HPF</td>
                </tr>
                <tr>
                  <td>แบคทีเรีย (Bacteria)</td>
                  <td className="uk-text-center" colSpan="2"></td>
                </tr>
              </table>
            </div>

            <div className="d-page-break"></div>

            {/** */}
            <div className="d-user-health-book-container">
              <div className="d-text-blue uk-text-center">
                [ ผลตรวจนับเม็ดเลือดแบบสมบูรณ์ (Complete Blood Count) ]
              </div>
              <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                <tr>
                  <td className="d-text-purple uk-text-center" width="40%">
                    รายละเอียดการตรวจ
                  </td>
                  <td className="d-text-purple uk-text-center" width="100">
                    ผลตรวจ
                  </td>
                  <td className="d-text-purple uk-text-center">ค่าปกติ</td>
                </tr>
                <tr>
                  <td>ฮีโมโกลบิน (Hb)</td>
                  <td className="uk-text-center">{this.getData("hb")}</td>
                  <td className="d-text-blue uk-text-center">
                    M13-18,F 12-16 g/dl
                  </td>
                </tr>
                <tr>
                  <td>ฮีมาโตคริต (Hct)</td>
                  <td className="uk-text-center">{this.getData("hct")}</td>
                  <td className="d-text-blue uk-text-center">
                    M 37-54 % , F 34-48 %
                  </td>
                </tr>
                <tr>
                  <td>จํานวนเม็ดเลือดแดง (RBC)</td>
                  <td className="uk-text-center">
                    {this.getData("rbc-count")}
                  </td>
                  <td className="d-text-blue uk-text-center">
                    4-6x10<sup>6</sup> cells/mm<sup>3</sup>
                  </td>
                </tr>
                <tr>
                  <td>จํานวนเม็ดเลือดขาว (WBC)</td>
                  <td className="uk-text-center">{this.getData("wbc")}</td>
                  <td className="d-text-blue uk-text-center">
                    4,500 - 11,000 cells/mm<sup>2</sup>
                  </td>
                </tr>
                <tr>
                  <td>- นิวโตรฟิล (Neutrophil)</td>
                  <td className="uk-text-center">
                    {this.getData("neutrophil-ratio")}
                  </td>
                  <td className="d-text-blue uk-text-center">40 - 75 %</td>
                </tr>
                <tr>
                  <td>- ลิมโฟไซท์ (Lymphocyte)</td>
                  <td className="uk-text-center">
                    {this.getData("lymphocyte-ratio")}
                  </td>
                  <td className="d-text-blue uk-text-center">20 - 45 %</td>
                </tr>
                <tr>
                  <td>- โมโนไซท์ (Monocyte) </td>
                  <td className="uk-text-center">
                    {this.getData("monocyte-ratio")}
                  </td>
                  <td className="d-text-blue uk-text-center">2 - 10 %</td>
                </tr>
                <tr>
                  <td>- อีโอสิโนฟิล (Eosinophil)</td>
                  <td className="uk-text-center">
                    {this.getData("eosinophil-ratio")}
                  </td>
                  <td className="d-text-blue uk-text-center">0 - 8 %</td>
                </tr>
                <tr>
                  <td>จํานวนเกล็ดเลือด (Platelet)</td>
                  <td className="uk-text-center">
                    {this.getData("platelets")}
                  </td>
                  <td className="d-text-blue uk-text-center">
                    140,000-450,000 cell/mm
                  </td>
                </tr>
                <tr>
                  <td>RBC Index</td>
                  <td colSpan="2">
                    <div>
                      <span className="d-text-blue" style={{ marginRight: 20 }}>
                        - MCV
                      </span>
                      <span style={{ width: 40, display: "inline-block" }}>
                        {this.getData("mcv")}
                      </span>
                      <span className="d-text-blue">fl. ( 80 - 100 fl. )</span>
                    </div>
                    <div>
                      <span className="d-text-blue" style={{ marginRight: 20 }}>
                        - MCH
                      </span>
                      <span style={{ width: 40, display: "inline-block" }}>
                        {this.getData("mch")}
                      </span>
                      <span className="d-text-blue">pg. ( 27 - 33 pg. )</span>
                    </div>
                    <div>
                      <span className="d-text-blue" style={{ marginRight: 20 }}>
                        - MCHC
                      </span>
                      <span style={{ width: 40, display: "inline-block" }}>
                        {this.getData("mchc")}
                      </span>
                      <span className="d-text-blue">g/dl ( 31 - 35 g/dl )</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>ลักษณะรูปร่างเม็ดเลือดแดง (RBC Morphology)</td>
                  <td colSpan="2">
                    <div style={{ minHeight: 50 }}>
                      {this.getData("rbc-morph")}
                    </div>
                  </td>
                </tr>
              </table>

              <div className="d-text-blue uk-text-center">
                [ สรุปผลตรวจนับเม็ดเลือดแบบสมบูรณ์ (Complete Blood Count) ]
              </div>
              <div className="d-note-border-bottom">
                * ผลตรวจนับเม็ดเลือดแบบสมบูรณ์
              </div>
              <div className="d-note-border-bottom"></div>
              <div className="d-note-border-bottom"></div>
              <div className="d-note-border-bottom"></div>
            </div>
            <div className="d-page-break"></div>
            {/** */}
            <div className="d-user-health-book-container">
              <div className="d-text-blue uk-text-center">
                [ ผลตรวจสมรรถภาพการได้ยิน (Audiometry) ]
              </div>
              <div className="uk-overflow-auto">
              <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                <tr>
                  <td className="d-text-purple uk-text-center">
                    ความถี่ (เฮิร์ตซ์)
                  </td>
                  <td className="d-text-blue uk-text-center">500</td>
                  <td className="d-text-blue uk-text-center">1000</td>
                  <td className="d-text-blue uk-text-center">2000</td>
                  <td className="d-text-blue uk-text-center">3000</td>
                  <td className="d-text-blue uk-text-center">4000</td>
                  <td className="d-text-blue uk-text-center">6000</td>
                  <td className="d-text-blue uk-text-center">8000</td>
                </tr>
                <tr>
                  <td className="d-text-purple uk-text-center">หูขวา</td>
                  <td>{this.getData("audiogram-r-500")}</td>
                  <td>{this.getData("audiogram-r-1000")}</td>
                  <td>{this.getData("audiogram-r-2000")}</td>
                  <td>{this.getData("audiogram-r-3000")}</td>
                  <td>{this.getData("audiogram-r-4000")}</td>
                  <td>{this.getData("audiogram-r-6000")}</td>
                  <td></td>
                </tr>
                <tr>
                  <td className="d-text-purple uk-text-center">หูซ้าย</td>
                  <td>{this.getData("audiogram-l-500")}</td>
                  <td>{this.getData("audiogram-l-1000")}</td>
                  <td>{this.getData("audiogram-l-2000")}</td>
                  <td>{this.getData("audiogram-l-3000")}</td>
                  <td>{this.getData("audiogram-l-4000")}</td>
                  <td>{this.getData("audiogram-l-6000")}</td>
                  <td></td>
                </tr>
              </table>
              </div>
              <div
                className="d-text-purple uk-text-center"
                style={{ padding: 10 }}
              >
                ค่าปกติ : ระดับการได้ยินไม่เกิน 25 dB
              </div>
              <div className="d-text-blue uk-text-center">
                <u className="d-text-blue">
                  สรุปผลตรวจสมรรถภาพการได้ยิน (Audiometry)
                </u>
              </div>
              <div className="d-note-border-bottom">
                {this.getData("audiogram")}
              </div>
              <div className="d-note-border-bottom"></div>
              <div className="d-note-border-bottom"></div>
              <div className="d-note-border-bottom"></div>
              <div className="d-note-border-bottom"></div>
            </div>
            <div className="d-user-health-book-container">
              <div className="d-text-blue uk-text-center">
                [ ผลตรวจสมรรถภาพปอด (Spirometry) ]
              </div>
              <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                <tr>
                  <td className="d-text-blue uk-text-center">FVC (L)</td>
                  <td className="d-text-blue uk-text-center">FEV1 (L)</td>
                  <td className="d-text-blue uk-text-center">FEV1/FVC (%)</td>
                  <td className="d-text-blue uk-text-center">ผลตรวจ</td>
                </tr>
                <tr>
                  <td>
                    <div style={{ minHeight: 50 }}>{this.getData("fvc")}</div>
                  </td>
                  <td>{this.getData("fev1")}</td>
                  <td>{this.getData("fev1/fvc%")}</td>
                  <td></td>
                </tr>
              </table>
              <div
                className="d-text-pink uk-padding-small uk-text-center"
                style={{ fontSize: 12 }}
              >{`หมายเหตุ : ค่า %FEV1/FVC อายุ < 50 ปี ใช้เกณฑ์ 75 % และ อายุ ≥ 50 ปี ใช้เกณฑ์ 70 %`}</div>

              <div className="d-text-blue uk-text-center">
                [ ผลตรวจสมรรถภาพปอด (Spirometry) ]
              </div>
              <div className="d-note-border-bottom">{this.getData("pft")}</div>
              <div className="d-note-border-bottom"></div>
              <div className="d-note-border-bottom"></div>
            </div>

            <div className="d-page-break"></div>

            {/** */}
            <div className="d-user-health-book-container">
              <div className="d-text-blue uk-text-center">
                [ ผลตรวจทางห้องปฏิบัติการ (Laboratory) ]
              </div>
              <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                <tr>
                  <td className="d-text-blue uk-text-center">รายการตรวจ</td>
                  <td className="d-text-blue uk-text-center">ผลตรวจ</td>
                  <td className="d-text-blue uk-text-center">ค่าปกติ</td>
                  <td className="d-text-blue uk-text-center">สรุปผล</td>
                </tr>
                <tr>
                  <td colSpan="4" className="d-text-purple">
                    ตรวจระดับน้ําตาลในเลือด
                  </td>
                </tr>
                <tr>
                  <td>Fasting Blood Sugar (FBS)</td>
                  <td className="uk-text-center">{this.getData("fbs/fpg")}</td>
                  <td className="d-text-blue">70-99 mg/dl</td>
                  <td className="uk-text-center">
                    {this.getCheckResult("fbs/fpg", true)}
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" className="d-text-purple">
                    ตรวจหาระดับไขมันในเลือด
                  </td>
                </tr>
                <tr>
                  <td>Cholesterol</td>
                  <td className="uk-text-center">{this.getData("chol")}</td>
                  <td className="d-text-blue">0-200 mg/dl</td>
                  <td className="uk-text-center">
                    {this.getCheckResult("chol", true)}
                  </td>
                </tr>
                <tr>
                  <td>Triglyceride</td>
                  <td className="uk-text-center">{this.getData("tg")}</td>
                  <td className="d-text-blue">0-160 mg/dl</td>
                  <td className="uk-text-center">
                    {this.getCheckResult("tg", true)}
                  </td>
                </tr>
                <tr>
                  <td>HDL-Cholesterol</td>
                  <td className="uk-text-center">{this.getData("hdl")}</td>
                  <td className="d-text-blue">{`>45 mg/dl`}</td>
                  <td className="uk-text-center">
                    {this.getCheckResult("hdl", true)}
                  </td>
                </tr>
                <tr>
                  <td>LDL-Cholesterol</td>
                  <td className="uk-text-center">{this.getData("ldl")}</td>
                  <td className="d-text-blue">0-160 mg/dl</td>
                  <td className="uk-text-center">
                    {this.getCheckResult("ldl", true)}
                  </td>
                </tr>
                  <tr>
                    <td>VLDL-Cholesterol</td>
                    <td className="uk-text-center">{this.getData("vldl-chol")}</td>
                    <td className="d-text-blue">7-32 mg/dl</td>
                    <td className="uk-text-center">
                      {this.getData("vldl-chol-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>VLDL-Cholesterol (cal)</td>
                    <td className="uk-text-center"></td>
                    <td className="d-text-blue">7-32 mg/dl</td>
                    <td className="uk-text-center">
                    </td>
                  </tr>
                  <tr>
                    <td>Chylomicron</td>
                    <td className="uk-text-center">{this.getData("chylomicron")}</td>
                    <td className="d-text-blue">{`< 200 mg/dl`}</td>
                    <td className="uk-text-center">
                    </td>
                  </tr>
                <tr>
                  <td colSpan="4" className="d-text-purple">
                    ตรวจการทํางานของตับ
                  </td>
                </tr>
                <tr>
                  <td>Total protein</td>
                  <td className="uk-text-center"></td>
                  <td className="d-text-blue">6.6-8.7 g/dl</td>
                  <td className="uk-text-center"></td>
                </tr>
                <tr>
                  <td>Total Bilirubin</td>
                  <td className="uk-text-center">
                    {this.getData("t.bilirubin")}
                  </td>
                  <td className="d-text-blue">0.3-1.2 mg/dl</td>
                  <td className="uk-text-center">
                    {this.getCheckResult("t.bilirubin", true)}
                  </td>
                </tr>
                <tr>
                  <td>SGOT (AST)</td>
                  <td className="uk-text-center">{this.getData("sgot")}</td>
                  <td className="d-text-blue">10-40 U/L</td>
                  <td className="uk-text-center">
                    {this.getCheckResult("sgot", true)}
                  </td>
                </tr>
                <tr>
                  <td>SGPT (ALT)</td>
                  <td className="uk-text-center">{this.getData("sgpt")}</td>
                  <td className="d-text-blue">10-40 U/L</td>
                  <td className="uk-text-center">
                    {this.getCheckResult("sgpt", true)}
                  </td>
                </tr>
                  <tr>
                    <td>Ammonia</td>
                    <td className="uk-text-center">
                      {this.getData("ammonia")}
                    </td>
                    <td className="d-text-blue">25-94 mg%</td>
                    <td className="uk-text-center">
                      {this.getData("ammonia-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>Albumin</td>
                    <td className="uk-text-center">
                      {this.getData("albumin")}
                    </td>
                    <td className="d-text-blue">35-55 g/dl</td>
                    <td className="uk-text-center">
                      {this.getData("albumin-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>Globulin</td>
                    <td className="uk-text-center">
                      {this.getData("globulin")}
                    </td>
                    <td className="d-text-blue">3.5-5.0 g/dl</td>
                    <td className="uk-text-center">
                      {this.getData("globulin-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>Globulin (cal)</td>
                    <td className="uk-text-center">
                    {this.getData("t.protein") && this.getData("albumin") ?  parseFloat(this.getData("t.protein")) - parseFloat(this.getData("albumin")) : ''}
                    </td>
                    <td className="d-text-blue">1.3-3.2 g/dl</td>
                    <td className="uk-text-center">
                    </td>
                  </tr>
                  <tr>
                    <td>Direct Bilirubin</td>
                    <td className="uk-text-center">{this.getData("direct-bilirubin")}</td>
                    <td className="d-text-blue">{`< 0.3 mg/dl`}</td>
                    <td className="uk-text-center">
                    </td>
                  </tr>
                <tr>
                  <td>Alk. Phosphatase</td>
                  <td className="uk-text-center">
                    {this.getData("alk.phosphatase")}
                  </td>
                  <td className="d-text-blue">38-126 U/L</td>
                  <td className="uk-text-center">
                    {this.getCheckResult("alk.phosphatase", true)}
                  </td>
                </tr>
                  <tr>
                    <td>Globulin (cal)</td>
                    <td className="uk-text-center">
                      {this.getData("tg") ?  parseFloat(this.getData("tg"))/5 : ''}
                    </td>
                    <td className="d-text-blue">7-32 mg/dl</td>
                    <td className="uk-text-center">
                    </td>
                  </tr>
                  <tr>
                    <td>Direct Bilirubin</td>
                    <td className="uk-text-center">{this.getData("direct-bilirubin")}</td>
                    <td className="d-text-blue">{`< 200 mg/dl`}</td>
                    <td className="uk-text-center">
                    </td>
                  </tr>
                <tr>
                  <td colSpan="4" className="d-text-purple">
                    ตรวจการทํางานของไต
                  </td>
                </tr>
                <tr>
                  <td>BUN</td>
                  <td className="uk-text-center">{this.getData("bun")}</td>
                  <td className="d-text-blue">7.8-20.3 mg/dl</td>
                  <td className="uk-text-center">
                    {this.getCheckResult("bun", true)}
                  </td>
                </tr>
                <tr>
                  <td>Creatinine</td>
                  <td className="uk-text-center">
                    {this.getData("creatinine")}
                  </td>
                  <td className="d-text-blue">0.7-1.5 mg/dl</td>
                  <td className="uk-text-center">
                    {this.getCheckResult("creatinine", true)}
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" className="d-text-purple">
                    ตรวจระดับกรดยูริคในเลือด
                  </td>
                </tr>
                <tr>
                  <td>Uric Acid</td>
                  <td className="uk-text-center">{this.getData("uric")}</td>
                  <td className="d-text-blue">2.3-8.2 mg/dl</td>
                  <td className="uk-text-center">
                    {this.getCheckResult("uric", true)}
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" className="d-text-purple">
                    ตรวจไวรัสตับอักเสบ บี
                  </td>
                </tr>
                <tr>
                  <td>HBs Ag (เชื้อไวรัสตับอักเสบบี)</td>
                  <td>{this.getData("hbsag")}</td>
                  <td></td>
                  <td>{this.getCheckResult("hbsag", true)}</td>
                </tr>
                <tr>
                  <td>HBs Ab (ภูมิคุ้มกัน)</td>
                  <td>{this.getData("hbsab")}</td>
                  <td></td>
                  <td>{this.getCheckResult("hbsab", true)}</td>
                </tr>
              </table>
            </div>

            <div className="d-page-break"></div>

            {/** */}
            <div className="d-user-health-book-container">
              <div className="d-text-blue uk-text-center">
                [ ผลตรวจรายการอื่นๆ(Other) และสารพิเศษ (Toxic) ]
              </div>
              <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                <tr>
                  <td className="d-text-blue uk-text-center">รายการตรวจ</td>
                  <td className="d-text-blue uk-text-center">ผลตรวจ</td>
                  <td className="d-text-blue uk-text-center">ค่าปกติ</td>
                  <td className="d-text-blue uk-text-center">สรุปผล</td>
                </tr>
                <tr>
                  <td>
                    <div style={{ minHeight: 50 }}>{this.getData("other-list-1")}</div>
                  </td>
                  <td>{this.getData("other-value-1")}</td>
                  <td>{this.getData("other-normal-1")}</td>
                  <td>{this.getData("other-result-1")}</td>
                </tr>
                <tr>
                  <td>
                    <div style={{ minHeight: 50 }}>{this.getData("other-list-2")}</div>
                  </td>
                  <td>{this.getData("other-value-2")}</td>
                  <td>{this.getData("other-normal-2")}</td>
                  <td>{this.getData("other-result-2")}</td>
                </tr>
                <tr>
                  <td>
                    <div style={{ minHeight: 50 }}>{this.getData("other-list-3")}</div>
                  </td>
                  <td>{this.getData("other-value-3")}</td>
                  <td>{this.getData("other-normal-3")}</td>
                  <td>{this.getData("other-result-3")}</td>
                </tr>
              </table>

              <div className="d-text-blue uk-text-center uk-margin-small-top">
                [ ผลตรวจอุจจาระ (Stool Examination) ]
              </div>
              <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                <tr>
                  <td className="d-text-blue uk-text-center">
                    รายละเอียดการตรวจ
                  </td>
                  <td className="d-text-blue uk-text-center">ผลตรวจ</td>
                  <td className="d-text-blue uk-text-center">ค่าปกติ</td>
                </tr>
                <tr>
                  <td>สีของอุจจาระ (Color)</td>
                  <td>{this.getData("stool-color-result")}</td>
                  <td className="d-text-green uk-text-center"></td>
                </tr>
                <tr>
                  <td>ลักษณะทั่วไป (Appearance)</td>
                  <td>{this.getData("stool-appearance-result")}</td>
                  <td>{this.getData("stool-color-normal")}</td>
                </tr>
                <tr>
                  <td>เม็ดเลือดขาว (WBC)</td>
                  <td>{this.getData("stool-wbc-result")}</td>
                  <td className="d-text-green uk-text-center">0 - 5 HPF</td>
                </tr>
                <tr>
                  <td>เม็ดเลือดแดง (RBC)</td>
                  <td>{this.getData("stool-rbc-result")}</td>
                  <td className="d-text-green uk-text-center">0 - 5 HPF</td>
                </tr>
                <tr>
                  <td>พยาธิ,ไข่พยาธิ (parasite,Ova)</td>
                  <td>{this.getData("stool-parasite-result")}</td>
                  <td className="d-text-green uk-text-center"></td>
                </tr>
              </table>
              <div className="d-note-border-bottom">
                {this.getData("stool-examination")}
              </div>
              <div className="d-note-border-bottom">
                {this.getCheckResult("stool-examination")}
              </div>
            </div>

            <div className="d-user-health-book-container">
              <div className="d-text-blue uk-text-center">
                [ ผลตรวจเพาะเชื้อก่อโรคระบบทางเดินอาหาร ( Stool Culture ) ]
              </div>
              <div className="d-note-border-bottom">
                <span className="d-text-blue">ผลตรวจ</span>
              </div>
              <div>{this.getData("stool-culture-value")}</div>
              <div className="d-note-border-bottom">
                <span className="d-text-blue">สรุปผลตรวจ</span>
              </div>
              <div>{this.getData("stool-culture-note")}</div>
            </div>

            <div className="d-page-break"></div>
            
            <div className="d-user-health-book-container">
              <div className="d-text-blue uk-text-center uk-margin-bottom">
                [ ข้อมูลอื่นๆ ]
              </div>
              <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                <tr>
                  <td className="d-text-blue uk-text-center">
                    รายละเอียดการตรวจ
                  </td>
                  <td className="d-text-blue uk-text-center">ผลตรวจ</td>
                  <td className="d-text-blue uk-text-center">หน่วย</td>
                </tr>
                <tr>
                  <td>ออกกำลังกาย</td>
                  <td>{this.getData("exercise-per-minute")}</td>
                  <td className="d-text-green uk-text-center">นาที/วัน</td>
                </tr>
                <tr>
                  <td>การพักผ่อน</td>
                  <td>{this.getData("sleep-per-hour")}</td>
                  <td className="d-text-green uk-text-center">ชั่วโมง/วัน</td>
                </tr>
                <tr>
                  <td>การสูบบุหรี่</td>
                  <td>{this.getData("smoke-per-day")}</td>
                  <td className="d-text-green uk-text-center">มวน/วัน</td>
                </tr>
                <tr>
                  <td>การดื่มแอลกอฮอล์</td>
                  <td>{this.getData("alcohol-per-day")}</td>
                  <td className="d-text-green uk-text-center">แก้ว/วัน</td>
                </tr>
                <tr>
                  <td>การดื่มแอลกอฮอล์</td>
                  <td>{this.getData("alcohol-per-day")}</td>
                  <td className="d-text-green uk-text-center">แก้ว/วัน</td>
                </tr>
                <tr>
                  <td>จำนวนก้าว</td>
                  <td>{this.getData("walking-amount")}</td>
                  <td className="d-text-green uk-text-center">ก้าว/วัน</td>
                </tr>
                <tr>
                  <td>การรับประทานอาหาร</td>
                  <td>{this.getData("food-amount")}</td>
                  <td className="d-text-green uk-text-center">ที่รับประทาน/วัน</td>
                </tr>
                <tr>
                  <td>การดื่มน้ำ</td>
                  <td>{this.getData("drink-amount")}</td>
                  <td className="d-text-green uk-text-center">แก้ว/วัน</td>
                </tr>
              </table>
            </div>
              
            
            <div className="d-user-health-book-container">
              <div className="d-text-blue uk-text-center uk-margin-bottom">
                [ แบบคัดกรองโรคซึมเศร้า 2 คำถาม (2Q) ]
              </div>
              <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                <tr>
                  <td className="d-text-blue uk-text-center">
                    คำถาม
                  </td>
                  <td className="d-text-blue uk-text-center">มี</td>
                  <td className="d-text-blue uk-text-center">ไม่มี</td>
                </tr>
                <tr>
                  <td>1. ใน 2 สัปดาห์ที่ผ่านมา รวมวันนี้ ท่านรู้สึก หดหู่ เศร้า หรือท้อแท้สิ้นหวัง หรือไม่</td>
                  <td className="uk-text-center">
                    {
                      this.getData("depress-2-week") == '1' ? '/' : ''
                    }
                  </td>
                  <td className="uk-text-center">
                    {
                      this.getData("depress-2-week") == '0' ? '/' : ''
                    }
                  </td>
                </tr>
                <tr>
                  <td>2. ใน 2 สัปดาห์ที่ผ่านมา รวมวันนี้ท่านรู้สึก เบื่อ ทำอะไรก็ไม่เพลิดเพลิน หรือไม่</td>
                  <td className="uk-text-center">
                    {
                      this.getData("bored-2-week") == '1' ? '/' : ''
                    }
                  </td>
                  <td className="uk-text-center">
                    {
                      this.getData("bored-2-week") == '0' ? '/' : ''
                    }
                  </td>
                </tr>
              </table>
              {
                this.getData("bored-2-week") != null && this.getData("depress-2-week") != null ?
                (this.getData("bored-2-week") == '1' || this.getData("depress-2-week") == '1' ?
                <div className="uk-margin-top">* ท่านมีแนวโน้มที่จะเป็นโรคซึมเศร้า</div>
                :
                '')

                : ''
              }
            </div>            


            <div className="d-user-health-book-container">
                <div className="d-text-blue uk-text-center uk-margin-bottom">
                  [ PV & Pap smear ]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td className="d-text-blue uk-text-center">
                      ปกติ
                    </td>
                    <td className="d-text-blue uk-text-center">ผิดปกติ</td>
                    <td className="d-text-blue uk-text-center">หมายเหตุ</td>
                  </tr>
                  <tr>
                    <td className="uk-text-center">
                      {this.getData("pv-pap-smear") == '0' ? '/' : ''}
                    </td>
                    <td className="uk-text-center">
                      {this.getData("pv-pap-smear") == '1' ? '/' : ''}
                    </td>
                    <td>
                      {this.getData("pv-pap-smear-note")}
                    </td>
                  </tr>
                </table>
              </div>

              <div className="d-user-health-book-container">
                <div className="d-text-blue uk-text-center uk-margin-bottom">
                  [ Mamogram ]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td className="d-text-blue uk-text-center">
                      ปกติ
                    </td>
                    <td className="d-text-blue uk-text-center">ผิดปกติ</td>
                    <td className="d-text-blue uk-text-center">หมายเหตุ</td>
                  </tr>
                  <tr>
                    <td className="uk-text-center">
                      {this.getData("mamogram") == '0' ? '/' : ''}
                    </td>
                    <td className="uk-text-center">
                      {this.getData("mamogram") == '1' ? '/' : ''}
                    </td>
                    <td>
                      {this.getData("mamogram-note")}
                    </td>
                  </tr>
                </table>
              </div>

              <div className="d-user-health-book-container">
                <div className="d-text-blue uk-text-center uk-margin-bottom">
                  [ Ultrasound ]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td className="d-text-blue uk-text-center">บริเวณที่ Ultrasound</td>
                    <td className="d-text-blue uk-text-center">
                      ปกติ
                    </td>
                    <td className="d-text-blue uk-text-center">ผิดปกติ</td>
                    <td className="d-text-blue uk-text-center">หมายเหตุ</td>
                  </tr>
                  <tr>
                    <td>
                      {this.getData("ultrasound-place")}
                    </td>
                    <td className="uk-text-center">
                      {this.getData("ultrasound") == '0' ? '/' : ''}
                    </td>
                    <td className="uk-text-center">
                      {this.getData("ultrasound") == '1' ? '/' : ''}
                    </td>
                    <td>
                      {this.getData("ultrasound-note")}
                    </td>
                  </tr>
                </table>
              </div>
              
              <div className="d-user-health-book-container">
                <div className="d-text-blue uk-text-center uk-margin-bottom">
                  [ Exercise stress ]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td className="d-text-blue uk-text-center">
                      ปกติ
                    </td>
                    <td className="d-text-blue uk-text-center">ผิดปกติ</td>
                    <td className="d-text-blue uk-text-center">หมายเหตุ</td>
                  </tr>
                  <tr>
                    <td className="uk-text-center">
                      {this.getData("exercise-stress") == '0' ? '/' : ''}
                    </td>
                    <td className="uk-text-center">
                      {this.getData("exercise-stress") == '1' ? '/' : ''}
                    </td>
                    <td>
                      {this.getData("exercise-stress-note")}
                    </td>
                  </tr>
                </table>
              </div>
               
            <div className="d-page-break"></div>
              <div className="d-user-health-book-container">
                <div className="d-text-blue uk-text-center uk-margin-bottom">
                  [ ประวัติการได้รับวัคซีน ]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td className="d-text-blue uk-text-center">
                      ที่
                    </td>
                    <td className="d-text-blue uk-text-center">ชื่อวัคซีน</td>
                    <td className="d-text-blue uk-text-center">ได้รับเมื่อวันที่</td>
                  </tr>
                  <tr>
                    <td className="uk-text-center">1</td>
                    <td>{this.getData("vaccine-name-1")}</td>
                    <td>{this.getData("vaccine-date-1")}</td>
                  </tr>
                  <tr>
                    <td className="uk-text-center">2</td>
                    <td>{this.getData("vaccine-name-2")}</td>
                    <td>{this.getData("vaccine-date-2")}</td>
                  </tr>
                  <tr>
                    <td className="uk-text-center">3</td>
                    <td>{this.getData("vaccine-name-3")}</td>
                    <td>{this.getData("vaccine-date-3")}</td>
                  </tr>
                  <tr>
                    <td className="uk-text-center">4</td>
                    <td>{this.getData("vaccine-name-4")}</td>
                    <td>{this.getData("vaccine-date-4")}</td>
                  </tr>
                </table>
              </div>
              
              <div className="d-user-health-book-container">
                <div className="d-text-blue uk-text-center uk-margin-bottom">
                  [ ประวัติการฝากครรภ์ ]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td className="d-text-blue uk-text-center">
                      ที่
                    </td>
                    <td className="d-text-blue uk-text-center">อายุครรภ์ (สัปดาห์)</td>
                    <td className="d-text-blue uk-text-center">ฝากครรภ์ที่</td>
                    <td className="d-text-blue uk-text-center">ฝากครรภ์วันที่</td>
                  </tr>
                  <tr>
                    <td className="uk-text-center">1</td>
                    <td>{this.getData("antenatal-age-1")}</td>
                    <td>{this.getData("antenatal-place-1")}</td>
                    <td>{this.getData("antenatal-date-1")}</td>
                  </tr>
                  <tr>
                    <td className="uk-text-center">2</td>
                    <td>{this.getData("antenatal-age-2")}</td>
                    <td>{this.getData("antenatal-place-2")}</td>
                    <td>{this.getData("antenatal-date-2")}</td>
                  </tr>
                  <tr>
                    <td className="uk-text-center">3</td>
                    <td>{this.getData("antenatal-age-3")}</td>
                    <td>{this.getData("antenatal-place-3")}</td>
                    <td>{this.getData("antenatal-date-3")}</td>
                  </tr>
                  <tr>
                    <td className="uk-text-center">4</td>
                    <td>{this.getData("antenatal-age-4")}</td>
                    <td>{this.getData("antenatal-place-4")}</td>
                    <td>{this.getData("antenatal-date-4")}</td>
                  </tr>
                </table>
              </div>


          </div>
        </div>
      </div>
    );
  }
}
