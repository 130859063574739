import React, { Component } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import $ from 'jquery';
import UploadFirebase from '../../Components/UploadFirebase';
import ImageUri from '../../Components/ImageUri';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import numeral from 'numeral';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min.js';
import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css';

class AdminMemberAddForm extends Component {

  field = [
    { key: 'nameprefix', label: 'คำนำหน้าชื่อ', require: true },
    { key: 'name', label: 'ชื่อ', require: true },
    { key: 'lastname', label: 'นามสกุล', require: true },
    { key: 'username', label: 'ชื่อผู้ใช้', require: true },
    { key: 'password', label: 'รหัสผ่าน', require: true },
    { key: 'email', label: 'อีเมล', type: 'email' },
    { key: 'tel', label: 'โทร', require: true },
    { key: 'gender', label: 'เพศ', type: 'select', option: [{value: '1', label: 'ชาย'}, {value: '0', label: 'หญิง'}] },
    { key: 'nid', label: 'เลขบัตรประชาชน / เลขบัตรประกันสังคม / passport สำหรับชาวต่างชาติ', require: true },
    { key: 'birthday', label: 'วันเกิด' },
    { key: 'lineuid', label: 'uid Line application' },
  ]

  taiyim = {
    use: false,
    hospital: '',
    doctor: '',
    drugdose: '50',
    drugweek: '1',
    druglastdate: '',
    place: ''
  }

  state = {
    company: [],
    memberCompany: this.props?.member?.memberCompany || [],
    memberCompanygroup: [],
    companygroupList: [],
    image: '',
    selectCompany: '',
    loadingApi: false,
    taiyim: { ...this.taiyim },
    keywordName: '',
    keywordCompany: ''
  }

  componentDidMount() {
    this.init();
  }

  componentWillReceiveProps() {
    this.init();
  }

  datepickerRender = () => {
    var _this = this;
 
    $(`[name="birthday"]`)
    .on("dp.hide", function (e) {
      _this.setState({
        birthday : moment(e.date, "DD/MM/YYYY").subtract(543, 'years').format("YYYY-MM-DD")
      }, ()=> {
        console.log(_this.state)
      });
    })
    .datetimepicker({
      format: "DD/MM/YYYY",
      defaultDate: _this.state.birthday ? moment(_this.state.birthday, 'YYYY-MM-DD').add(543, 'years') : moment().add(543, 'years'),
      keepOpen: true,
      locale: 'th',
    });
  }

  init = async () => {
    
    this.setState((state) => {
      var stateInit = {};
      if (this.props.member) {
        this.field.map(data => { stateInit[data.key] = data.key === 'password' ? '' : this.props.member[data.key]; });
        stateInit.memberCompany = this.props.member.memberCompany || [];
        stateInit.image = this.props.member.image || '';
        stateInit.taiyim = this.props.member.taiyim || {...this.taiyim};
        
      } else {
        this.field.map(data => { stateInit[data.key] = ''; });
        stateInit.memberCompany = [];
        stateInit.image = '';
        stateInit.taiyim = {...this.taiyim};
      }

      return { ...stateInit };
    });

    var { list } = await Api.admin('CompanyList');

    if (!list[0]) list = [];

    this.setState({
      company: list || [],
      selectCompany: list[0].companyid
    });


    this.companygroupListByCompanyid();

    this.memberCompanygroupGet();

    if (this.props.show && UIkit && UIkit.modal('#modal-form')) {
      UIkit.modal('#modal-form').show();
    } else if( UIkit && UIkit.modal('#modal-form')) {
      UIkit.modal('#modal-form').hide();
    }

    
    this.datepickerRender();
  }




  companygroupListByCompanyid = async () => {
    var list = [];

    var { list = [] } = await Api.admin('CompanygroupListByCompanyid', { companyid: this.state.memberCompany || [] });

    this.setState({ companygroupList: list || [] });
  }


  memberCompanygroupGet = async () => {
    var list = [];
    if (this.props?.member?.id) {
      var { list = [] } = await Api.admin('MemberCompanygroupList', { id: this.props.member.id });
    }

    this.setState({ memberCompanygroup: list || [] });
  }

  memberAdd = async (e) => {
    if (this.state.loadingApi) return false;

    this.setState({ loadingApi: true });
    e.preventDefault();

    var data = this.state;
    if (this.props.member) {
      data.id = this.props.member.id;
    }

    data.memberCompany = this.state.memberCompany;
    data.memberCompanygroup = this.state.memberCompanygroup;

    if(!data.gender) data.gender = '1';

    var _error = false;
    this.field.forEach(f=>{

      if(f.require && !data[f.key]) {
        if(!(f.key === 'password' && this.props.member)) 
          _error = true;
          return false;
        
      }
    })
     
    if(_error) {
      
      UIkit.notification({ message: 'โปรดกรอกข้อมูลให้ครบ', status: 'danger' });
    } else {
console.log(data)

      var { result, responseText } = await Api.admin(this.props.member ? 'MemberUpdate' : 'MemberAdd', data);



      UIkit.notification({ message: responseText, status: result ? 'success' : 'danger' });
  
      if (result) {
        UIkit.modal('#modal-form').hide();
        this.props.callback();
      }
    }

    

    this.setState({ loadingApi: false });
  }

  updateValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  memberCompanyAdd = () => this.setState((state) => {

    var hasCompany = state.memberCompany.map(data => (data.companyid));

    if (hasCompany.indexOf($("#select-company option:selected").val()) < 0) {
      state.memberCompany.push({
        id: this.state.selectCompany,
        companyid: this.state.selectCompany,
        name: $("#select-company option:selected").attr('data-name'),
        level: '',
        position: '',
        active: '0'
      })
    }

    return { memberCompany: state.memberCompany };
  }, () => this.companygroupListByCompanyid());


  memberCompanygroupAdd = () => this.setState((state) => {

    var hasCompanyGroup = state.memberCompanygroup.map(data => (data.companyid));

    if (hasCompanyGroup.indexOf($("#select-companygroup option:selected").val()) < 0) {
      state.memberCompanygroup.push({
        companygroupid: $("#select-companygroup option:selected").val(),
        name: $("#select-companygroup option:selected").attr('data-name'),
        companyname: $("#select-companygroup option:selected").attr('data-company'),
      })
    }

    return { memberCompanygroup: state.memberCompanygroup };
  });

  memberCompanyRemove = (id) => this.setState((state) => {
    for (var i = state.memberCompany.length; i-- > 0;) {
      if (state.memberCompany[i].id == id) delete state.memberCompany[i];
    }

    return { memberCompany: state.memberCompany.filter(Boolean) };
  })

  memberCompanygroupRemove = (id) => this.setState((state) => {
    for (var i = state.memberCompanygroup.length; i-- > 0;) {
      if (state.memberCompanygroup[i].id == id) delete state.memberCompanygroup[i];
    }

    return { memberCompanygroup: state.memberCompanygroup.filter(Boolean) };
  })

  taiyimRender = () => {
    var _updateTY = (key, value) => {
      this.setState(state => {
        state.taiyim[key] = value;
        return { taiyim: state.taiyim };
      })
    }

    var _formType = [
      { label: 'ขื่อโรงพยาบาล', type: 'text', key: 'hospital' },
      { label: 'ชื่อหมอ', type: 'text', key: 'doctor' },
      { label: 'ขนาดยา', type: 'select', key: 'drugdose', option: ['50', '75', '100', '120', '150'] },
      { label: 'ความถี่ในการฉีดยา (สัปดาห์)', type: '', key: 'drugweek', option: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'] },
      { label: 'วันที่ได้รับการฉีดยาล่าสุด (ปปปป-ดด-วว)', type: 'text', key: 'druglastdate' },
      { label: 'สถานที่ฉีดยา', type: 'text', key: 'place' },

    ]

    var _form = [];

    _formType.forEach((f, index) => {
      _form.push(
        <div key={`ty`+index} className="uk-margin-small-top">
          <label>{f.label}</label>
          {
            f.type === 'text' ?
              <input className="uk-input" type="text" value={this.state.taiyim[f.key]} onChange={(e)=>_updateTY(f.key, e.target.value)} />
              :
              <select className="uk-select" value={this.state.taiyim[f.key]} onChange={(e)=>_updateTY(f.key, e.target.value)}>
                {
                  f.option.map(op=>(<option value={op}>{op}</option>))
                }
              </select>
          }
        </div>
      )
    })

    return <div className="uk-margin-small-top">
      {this.state.taiyim.use ?
        <div>
          <h4>ตั้งค่าไตยิ้ม <span uk-close="" className="uk-float-right" onClick={() => _updateTY('use', false)}></span></h4>
          {_form}
        </div>
        :
        <button className="uk-button uk-button-secondary uk-width-1-1" onClick={() => _updateTY('use', true)}>ตั้งค่าไตยิ้ม</button>
      }
    </div>
  }


  render() {
    return <div id="modal-form" className="uk-modal-full" uk-modal="bg-close: false; esc-close: false;">
      <div className="uk-modal-dialog uk-modal-body">
        <button className="uk-modal-close-default" type="button" uk-close=""></button>
        <h2 className="uk-modal-title">จัดการข้อมูลสมาชิก</h2>
        <form onSubmit={this.memberAdd}>
          <div>
            <label className="uk-button uk-button-default">
              เลือกรูปภาพ
              <UploadFirebase cb={(image) => {
                this.setState({ image })
              }} />
            </label>
            {this.state.image !== '' && <div className="uk-margin-small"><img src={ImageUri(this.state.image)} uk-img="" width="200" /></div>}
          </div>
          <div className="uk-grid-small uk-child-width-1-1 uk-child-width-1-3@s uk-margin-small-top" uk-grid="">
            {
              this.field.map(data => {
                var _input = null;
                switch(data.type) {
                  case 'select':
                    _input = <select className="uk-select" name={data.key} onChange={this.updateValue} value={this.state[data.key]}>
                      {data.option.map(o=><option value={o.value} key={o.key}>{o.label}</option>)}
                      
                    </select>
                    break;
                  default:
                    if(data.key === 'birthday') {

                      _input = <input type={data.type || 'text'} className="uk-input" name={data.key} />
                    }
                    else {

                      _input = <input type={data.type || 'text'} className="uk-input" name={data.key} onChange={this.updateValue} value={this.state[data.key]} />
                    }
                }

                return <div key={data.key}>
                  <label>{data.label} {data.require ? <span className="uk-text-danger">*</span> : null}</label>
                  {_input}
                </div>
              })
            }
          </div>
          <div className="uk-margin-small-top">
            <label>บริษัท</label>
            <div className="uk-button-group uk-width-1-1">
              <select className="uk-select" value={this.state.selectCompany} onChange={(e) => this.setState({ selectCompany: e.target.value })} id="select-company">
                {this.state.company.map((data, key) => {
                  return <option key={`company${key}`} value={data.companyid} data-name={data.name}>{data.name}</option>;
                })}
              </select>
              <button type="button" className="uk-button uk-button-secondary" onClick={this.memberCompanyAdd}><span uk-icon="icon: plus"></span></button>
            </div>
            <div className="uk-padding-small uk-background-muted uk-margin-top">
              {
                this.state.memberCompany && this.state.memberCompany.length > 0 ?
                  <table className="uk-table uk-table-small uk-table-divider">
                    <thead>
                      <tr className="uk-child-width-1-5">
                        <th>ชื่อบริษัท</th>
                        <th>แผนก</th>
                        <th>ตำแหน่ง</th>
                        <th>ระดับ (1=admin)</th>
                        <th>ลบ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.memberCompany.map((data, key) => (<tr key={`memberCompany${key}`}>
                          <td>
                            {data.name}
                          </td>
                          <td>
                            <input type="text" className="uk-input uk-form-small" value={data.position} onChange={e => {
                              data.position = e.target.value;
                              this.setState({ memberCompany: this.state.memberCompany });
                            }} />
                          </td>
                          <td>
                            <input type="text" className="uk-input uk-form-small" value={data.job} onChange={e => {
                              data.job = e.target.value;
                              this.setState({ memberCompany: this.state.memberCompany });
                            }} />
                          </td>
                          <td>
                            <select className="uk-select uk-form-small" value={data.level} onChange={e => {
                              data.level = e.target.value;
                              this.setState({ memberCompany: this.state.memberCompany });
                            }} >
                              <option value="0">User</option>
                              <option value="1">Admin</option>
                            </select>
                          </td>
                          <td>
                            <button type="button" className="uk-button uk-button-default uk-button-small" onClick={() => this.memberCompanyRemove(data.id)}>
                              <span uk-icon="icon: trash; ratio: 0.8"></span>
                            </button>
                          </td>
                        </tr>))
                      }
                    </tbody>
                  </table>
                  :
                  <div className="uk-text-center">ไม่มีรายการ โปรดเลือกบริษัท</div>
              }
            </div>
          </div>
          {
            this.state.companygroupList && this.state.companygroupList.length > 0 &&
            <div>
              <div className="uk-margin-small-top">
                <label>กลุ่ม</label>
                <div className="uk-button-group uk-width-1-1">
                  <select className="uk-select" id="select-companygroup">
                    {
                      this.state.companygroupList.map((data, index) => {
                        return <option key={`optionCompanyGroup${index}`} value={data.id} data-name={data.name} data-company={data.companyname}>{data.name}-{data.companyname}</option>
                      })
                    }
                  </select>
                  <button type="button" className="uk-button uk-button-secondary" onClick={() => this.memberCompanygroupAdd()}><span uk-icon="icon: plus"></span></button>
                </div>
              </div>
              <div className="uk-margin-small-top uk-padding-small uk-background-muted">
                {
                  this.state.memberCompanygroup.length > 0 ?
                    <table className="uk-table uk-table-small uk-table-divider">
                      <thead>
                        <tr className="uk-child-width-1-4">
                          <th>ลำดับ</th>
                          <th>กลุ่ม</th>
                          <th>บริษัท</th>
                          <th>ลบ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.memberCompanygroup.map((data, index) => {
                            return <tr key={`row${index}`}>
                              <td>{index + 1}</td>
                              <td>{data.name}</td>
                              <td>{data.companyname}</td>
                              <td>
                                <button type="button" className="uk-button uk-button-default uk-button-small" onClick={() => this.memberCompanygroupRemove(data.id)}>
                                  <span uk-icon="icon: trash; ratio: 0.8"></span>
                                </button>
                              </td>
                            </tr>
                          })
                        }
                      </tbody>
                    </table>
                    :
                    <div className="uk-text-center">ไม่มีรายการ</div>
                }
              </div>
            </div>
          }
          {
            this.taiyimRender()
          }
          <div className="uk-text-right  uk-margin-top">
            {
              this.state.loadingApi ?
                <span uk-spinner=""></span>
                :
                <div className="uk-width-1-1 uk-button-group">
                  <button className="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button">ยกเลิก</button>
                  <button className="uk-input uk-button-primary" type="submit">{this.props.member ? 'บันทึก' : 'เพิ่ม'}</button>
                </div>
            }
          </div>

        </form>
      </div>
    </div>
  }
}

export default class AdminMember extends Component {
  state = {
    memberList: [],
    formShow: false,
    member: false,
    pageCount: 0,
    pageNow: 1,
    dateStart: moment().subtract(2,'day').format('YYYY-MM-DD'),
    dateStop: moment().format('YYYY-MM-DD')
  }


  perPage = 40;

  componentDidMount() {
    this.init();
  }

  componentWillReceiveProps() {
    this.init();
    this.setState({pageNow: 1})
  }
  

  init() {
    this.memberListGet();
    this.UploadInit();
  }

  UploadInitV2 = () => {

    var bar = document.getElementById('js-progressbar');
    let list = [];

    UIkit.upload('.js-upload-2', {

      url: Api.serverUrl+'CsvUploadFile.php',
      multiple: false,
      name: 'file',
      beforeSend: function (environment) {
        environment.headers.authorization = window.localStorage.getItem('adminLogin') || '';
        environment.data.append('typeUpload', 'memberv2');
      },
      beforeAll: function () {
        bar.value = 0;
        list = [];
      },
      loadStart: function (e) {
        bar.removeAttribute('hidden');
        bar.max = e.total;
      },
      progress: function (e) {
        bar.max = e.total;
      },
      loadEnd: function (e) {
        if(arguments[0].currentTarget.responseText !== '') list.push(arguments[0].currentTarget.responseText.trim());

        bar.max = e.total;
      },
      completeAll:  () => {
        setTimeout(()=>{
          bar.setAttribute('hidden', 'hidden');
          this.memberListGet();
        }, 1000);
      }

    });
  }


  UploadInit = () => {

    var bar = document.getElementById('js-progressbar');
    let list = [];

    UIkit.upload('.js-upload', {

      url: Api.serverUrl+'CsvUploadFile.php',
      multiple: false,
      name: 'file',
      beforeSend: function (environment) {
        environment.headers.authorization = window.localStorage.getItem('adminLogin') || '';
        environment.data.append('typeUpload', 'member');
      },
      beforeAll: function () {
        bar.value = 0;
        list = [];
      },
      loadStart: function (e) {
        bar.removeAttribute('hidden');
        bar.max = e.total;
      },
      progress: function (e) {
        bar.max = e.total;
      },
      loadEnd: function (e) {
        if(arguments[0].currentTarget.responseText !== '') list.push(arguments[0].currentTarget.responseText.trim());

        bar.max = e.total;
      },
      completeAll:  () => {
        setTimeout(()=>{
          bar.setAttribute('hidden', 'hidden');
          this.memberListGet();
        }, 1000);
      }

    });
  }

  memberListGet = async () => {
    this.setState({loadingApi: true});

    var { list, amount } = await Api.admin('MemberList', {
      perPage: this.perPage,
      pageNow: this.state.pageNow,
      keywordCompany: this.state.keywordCompany,
      keywordName: this.state.keywordName,
      dateStart: this.state.dateStart,
      dateStop: this.state.dateStop
    });

    this.setState({ memberList: list || [], formShow: false, loadingApi: false, pageCount: +amount / this.perPage });
  }

  handlePageClick = (page) => {
    this.setState({pageNow: page.selected + 1}, ()=> this.memberListGet())
  }

  csvDownload = () => {
    var _csv = [
      [`"ลำดับ"`,
      `"วันที่สมัคร"`,
      `"เวลาที่สมัคร"`,
      `"เข้าสู่ระบบล่าสุด"`,
      `"จำนวนแชท"`,
      `"แชทล่าสุด"`,
      `"รหัสสมาชิก"`,
      `"เลขบัตรประชาชน"`,
      `"ชื่อผู้ใช้"`,
      `"ชื่อ-สกุล"`,
      `"บริษัท"`,
      `"แคมเปญ"`,
      `"กลุ่ม"`]
    ];

    if(this.state.memberList.length)
    this.state.memberList.forEach((data, key) => {
      _csv.push([
        `"${(key + (this.perPage * (this.state.pageNow - 1)) + 1)}"`,
        `"${moment(data.dateadd).format('YYYY-MM-DD')}"`,
        `"${moment(data.dateadd).format('HH:mm:ss')}"`,
        `"${moment(data.datelogin).format('DD/MM/YYYY HH:mm:ss')}"`,
        `"${numeral(data.chatAmount).format('0,0')}"`,
        `"${data.chatLastDate ? moment(data.chatLastDate).format('DD/MM/YYYY HH:mm:ss') : '-'}"`,
        `"${data.id}"`,
        `"${data.nid}"`,
        `"${data.username}"`,
        `"${data.nameprefix} ${data.name} ${data.lastname}"`,
        `"${data.memberCompany ? data.memberCompany.map(c=>c.name) : '-'}"`,
        `"${data.memberCampaign ? data.memberCampaign.map(c=>c.name) : '-'}"`,
        `"${data.memberCompanyGroup ? data.memberCompanyGroup.map(c=>c.name) : '-'}"`,

      ]);
    });

    _csv = "data:text/csv; charset=utf-8," + "\uFEFF" + encodeURIComponent(_csv.map(col=> col.join(",")).join("\n"));

    var _link = document.createElement("a");

    _link.setAttribute("href", _csv);

    _link.setAttribute("download", "รายชื่อสมาชิก.csv");

    _link.click();

    _link.remove();
  }

  paginateRender = () => {
    return <div className="uk-text-right">
      <a className="uk-float-left uk-margin-top" onClick={this.csvDownload}><i uk-icon="download"></i> ดาวน์โหลด CSV</a>
      <ReactPaginate
        className={'uk-padding-remove'}
        previousLabel={'previous'}
        nextLabel={'next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={this.state.pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={this.handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      />
    </div>
  }

  formHide = () => {
    this.setState({formShow:false});
  }

  render() {

    return <div>
    <div className="d-admin-header-row uk-text-right uk-margin-bottom">
      <div uk-grid="" className="uk-grid-small uk-child-width-1-2">
        <div>
          <div className="js-upload uk-placeholder uk-text-center">
            <span uk-icon="icon: cloud-upload"></span>
            <span className="uk-text-middle">เพิ่มสมาชิก V1 </span>
            <div uk-form-custom="">
                <input type="file" />
                <span className="uk-link">คลิก</span>
            </div>
          </div>
          <div><a href="/exmemberv1.csv" className="uk-margin-top">ดาวน์โหลดตัวอย่าง v1</a></div>
        </div>
        <div>
          {
            /*
            <div className="js-upload-2 uk-placeholder uk-text-center">
          <span uk-icon="icon: cloud-upload"></span>
          <span className="uk-text-middle">เพิ่มสมาชิก V2 </span>
          <div uk-form-custom="">
              <input type="file" />
              <span className="uk-link">คลิก</span>
          </div>
          </div>
            */
          }
          
        
        </div>
      </div>
      
      <progress id="js-progressbar" className="uk-progress" value="0" max="100" hidden></progress>
    </div>
      <div className="d-admin-header-row">
        <div className="uk-text-right uk-margin-small-bottom">
          <button className="uk-button uk-button-small uk-button-secondary" onClick={() => this.setState({ member: false }, () => this.setState({ formShow: true }))} >เพิ่มสมาชิก</button>
        </div>
        <div className='uk-margin-top-small'>
          <div className="uk-grid-small uk-flex-middle" uk-grid="">
            <div className="uk-width-auto">ค้นหาจาก ชื่อ-สกุล หรือ เลขบัตร</div>
            <div className="uk-width-auto@l uk-width-1-1">
              <input type="text" className="uk-input" value={this.state.keywordName} onChange={(e)=>this.setState({keywordName: e.target.value, formShow: false})} />
            </div>
            <div className="uk-width-auto">บริษัท</div>
            <div className="uk-width-auto@l uk-width-1-1">
              <input type="text" className="uk-input" value={this.state.keywordCompany} onChange={(e)=>this.setState({keywordCompany: e.target.value, formShow: false})} />
            </div>
            <div className="uk-width-auto">วันที่สมัคร เริ่ม</div>
            <div className="uk-width-auto uk-width-1-1">
              <input type="date" className="uk-input" value={this.state.dateStart} onChange = {(e)=>{
                this.setState({dateStart: e.target.value})
              }} />  
            </div>
            <div className="uk-width-auto">ถึงวันที่</div>
            <div className="uk-width-auto uk-width-1-1">
              <input type="date" className="uk-input" value={this.state.dateStop} onChange = {(e)=>{
                this.setState({dateStop: e.target.value})
              }} />  
            </div>
            <div className="uk-width-expand uk-text-right">
              <button className="uk-button uk-button-secondary" onClick={()=>{
                this.setState({pageNow: 1}, ()=>this.memberListGet());
                
              }}>ค้นหา</button>
            </div>
          </div>
        </div>
      </div>

      {
        this.state.formShow === true ?
          <div>
            <AdminMemberAddForm {...this.props} callback={()=>{
          
          this.formHide();
          this.memberListGet();
        }} show={this.state.formShow} member={this.member} />
        </div>
        :
        <></>
      }

      <div className="uk-overflow-auto">
      {this.paginateRender(this.state.pageNow)}

      <table className="uk-table uk-table-small uk-table-divider">
        <thead>
          <tr>
            <th>ลำดับ</th>
            <th>วันที่สมัคร</th>
            <th>เข้าสู่ระบบล่าสุด</th>
            <th>จำนวนแชท</th>
            <th>แชทล่าสุด</th>
            <th>รหัสสมาชิก</th>
            <th>เลขบัตรประชาชน</th>
            <th>ชื่อผู้ใช้</th>
            <th>ชื่อ-สกุล</th>
            <th>บริษัท</th>
            <th>แคมเปญ</th>
            <th>กลุ่ม</th>
            <th></th>
          </tr>
        </thead>
        <tbody>

          {
            this.state.loadingApi ? 
            <tr><td colSpan="100%">
              <div className="uk-padding uk-text-center"><div uk-spinner=""></div></div>  
            </td></tr>
            :
            this.state.memberList && this.state.memberList.length ? this.state.memberList.map((data, key) => {
              return <tr key={key}>
                <td>{(key + (this.perPage * (this.state.pageNow - 1)) + 1)}</td>
                <td>{moment(data.dateadd).format('DD/MM/YYYY HH:mm:ss')}</td>
                <td>{moment(data.datelogin).format('DD/MM/YYYY HH:mm:ss')}</td>
                <td>{numeral(data.chatAmount).format('0,0')}</td>
                <td>{data.chatLastDate ? moment(data.chatLastDate).format('DD/MM/YYYY HH:mm:ss') : '-'}</td>
                <td>{data.id}</td>
                <td>{data.nid}</td>
                <td>{data.username}</td>
                <td>
                  {data.nameprefix} {data.name} {data.lastname}
                </td>
                <td>{data.memberCompany && data.memberCompany.map(c=><div key={`companay${c.id}`}>{c.name}</div>)}</td>
                <td>{data.memberCampaign && data.memberCampaign.map(c=><div key={`campaign${c.id}`}>{c.name}</div>)}</td>
                <td>{data.memberCompanyGroup && data.memberCompanyGroup.map(c=><div key={`companaygrpi[${c.id}`}>{c.name}</div>)}</td>
                <td>
                  <a className="uk-button uk-button-small" onClick={() => {
                    this.member = data;
                    this.setState({ formShow: true });
                  }
                } >
                    <i uk-icon="icon: pencil"></i> แก้ไข
                  </a>
                </td>
              </tr>
            })
            :
            
            <tr><td colSpan="100%">
              <div className="uk-padding uk-text-center">ไม่มีรายการ</div>  
            </td></tr>
          }
        </tbody>
      </table>
      </div>
    </div>
  }
};
