var ScoreLabel = {
  '1' : 'ไม่เห็นด้วยอย่างยิ่ง',
  '2' : 'ไม่เห็นด้วย',
  '3' : 'เห็นด้วย',
  '4' : 'เห็นด้วยอย่างยิ่ง',
}

var FormData = [
  {
    title: '1.คนอื่นอาจจะเลิกคบกับฉัน ถ้ารู้ว่าฉันติดเชื้อเอชไอวี',
    key: 'break_up'
  },
  {
    title: '2.คนอื่นจะสมน้ำหน้า/ซ้ำเติม ถ้ารู้ฉันติดเชื้อเอชไอวี',
    key: 'aggravate'
  },
  {
    title: '3.ฉันรู้สึกอายที่ฉันติดเชื้อเอชไอวี',
    key: 'feel_ashamed'
  },
  {
    title: '4.ฉันรู้สึกว่าเป็นเวรเป็นกรรม ที่ฉันติดเชื้อเอชไอวี',
    key: 'feel_Fate'
  },
  {
    title: '5.ฉันรู้สึกท้อแท้/สินหวัง เนื่องจากฉันติดเชื้อเอชไอวี',
    key: 'feel_disheartened'
  },
  {
    title: '6.ฉันคิดว่าฉันไม่มีอนาคตแล้ว เนื่องจากฉันเป็นผู้ติดเชื้อเอชไอวี',
    key: 'no_future'
  },
  {
    title: '7.ฉันทำให้ครอบครัวอับอาย เนื่องจากติดเชื้อเอชไอวี',
    key: 'feel_family_ashamed'
  },
  {
    title: '8.ฉันไม่อยากไปรับบริการสุขภาพ เพราะกลัวคนอื่นจะรู้ว่าฉันติดเชื้อเอชไอวี',
    key: 'scary_other_know'
  },
];

var FormByKey = {}

for(var d of FormData) {
  FormByKey[d.key] = d.title.split('.')[1];
}

module.exports = { ScoreLabel, FormData, FormByKey }