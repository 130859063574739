import NovalueCheck from './NoValueCheck';

export default (value, age = 20) => {
  if(NovalueCheck(value)) return NovalueCheck(value);
  
  value = +value;
  age = +age;

  if(value > 10) {
    return { 
      title: 'น้ำตาลสูงวิกฤติ',
      color: 'red',
      caption: 'น้ำตาลสูงวิกฤติ',
      suggest: 'น้ำตาลสูงวิกฤติมีโอกาสเกิดภาวะแทรกซ้อนจากเบาหวานได้มาก'
    };
  } else if(value > 8.5) {
    return { 
      title: 'น้ำตาลสูงมาก',
      color: 'orange',
      caption: 'น้ำตาลสูงมาก',
      suggest: 'น้ำตาลสูงมากมีโอกาสเกิดภาวะแทรกซ้อนจากเบาหวานได้มาก'
    };
  } else if(value >= 7 && age >= 65 ) {
    return { 
      title: 'น้ำตาลสูง',
      color: 'yellow',
      caption: 'น้ำตาลสูง',
      suggest: 'น้ำตาลสูงมีโอกาสเกิดภาวะแทรกซ้อนจากเบาหวานได้มาก'
    };
  } else if(value < 7 && age >= 65 ) {
    return { 
      title: 'น้ำตาลปกติ ',
      color: 'green',
      caption: 'น้ำตาลปกติ ',
      suggest: 'น้ำตาลควบคุมได้'
    };
  } else if(value >= 6.5 && age < 65 ) {
    return { 
      title: 'น้ำตาลสูง',
      color: 'yellow',
      caption: 'น้ำตาลสูง',
      suggest: 'น้ำตาลสูงมีโอกาสเกิดภาวะแทรกซ้อนจากเบาหวานได้มาก'
    };
  } else if(value < 6.5 && age < 65 ) {
    return { 
      title: 'น้ำตาลปกติ ',
      color: 'green',
      caption: 'น้ำตาลปกติ ',
      suggest: 'น้ำตาลควบคุมได้'
    };
  } else {
    return {}
  }
}