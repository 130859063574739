import React, { Component } from 'react';
import SMCReport from '../../Components/Consult/SMCReport';
export default class ConsultStatistic extends Component {

  render() {
    return <div className="uk-padding">
      <div>
        <SMCReport {...this.props} />
      </div>
    </div>
  }
};
