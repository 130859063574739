import React, { Component } from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Api from '../../Components/Api';
import Formular from '../Formular';
import GroupReport from '../Formular/GroupReport';
import moment from 'moment';
import numeral from 'numeral';
import FullScreenElement from '../FullScreenElement';
import ReactEcharts from 'echarts-for-react';

UIkit.use(Icons);

var maxDataValue = (data) => {
  var maxValue = 0;
  var maxColor = '';

  for (var i in data) {
    if (+data[i] >= maxValue) {
      maxValue = +data[i];
      maxColor = i;
    }
  }

  return { color: maxColor, amount: maxValue };

}

var dataIcon = {};

export default class SmartHealthTrendingGraph extends Component {

  state = {
    loadingApi: true,
    selectAge: '',
    selectGender: '',
    selectDepartment: '',
    selectJob: '',
    selectRisk: 'red',
    optionDepartment: [
      {label: 'ทั้งหมด', value: ''}
    ],
    optionJob: [
      {label: 'ทั้งหมด', value: ''}
    ],
    data: null,
    yearGraph: [],
    dataGraph: [],
  }

  componentDidMount() {
    this.dataCacheGet();
  }

  dataRender = () => {
    var _member = {};

    var _dataTypeAmount = {};

    var _department = [];

    var _job = [];

    var data = this.state.data;

    /*Format data */
    for (var i in data) { /** Year */
      for (var i1 in data[i]) { /** Data name */
        _dataTypeAmount[i1] = data[i][i1].length;
        for (var i2 = data[i][i1].length; i2-- > 0;) {
          if (data[i][i1][i2]) {
            for (var i3 = data[i][i1][i2].length; i3-- > 0;) {
              var _useMemberData = false;
              var _memberData = data[i][i1][i2][i3];

              //if first call render option department and job
              if(this.state.optionDepartment.length === 1) {
                if(_memberData.position && _department.indexOf(_memberData.position) < 0) _department.push(_memberData.position);

                if(_memberData.job && _job.indexOf(_memberData.job) < 0) _job.push(_memberData.job);
              }

              if(this.state.selectAge !== '') {

                _memberData.age = +_memberData.age;

                //filter
                switch(this.state.selectAge) {
                  case 15:
                    if(_memberData.age < 15) {
                      _useMemberData = true;
                    }
                  break;
                  case 25:
                    if(_memberData.age >= 15 && _memberData.age <= 25) {
                      _useMemberData = true;
                    }
                  break;
                  case 61:
                    if(_memberData.age > 60) {
                      _useMemberData = true;
                    }
                  break;
                  default: 
                    if(_memberData.age > +this.state.selectAge - 5 && _memberData.age <= +this.state.selectAge) {
                      _useMemberData = true;
                    }
                  break;
                }
              } else {
                _useMemberData = true;
              }

              if(_useMemberData === true )
                if(this.state.selectGender !== '') {
                  if(this.state.selectGender == _memberData.gender) {
                    _useMemberData = true;
                  } else {
                    _useMemberData = false;
                  }
                }

              if(_useMemberData === true )
                if(this.state.selectDepartment !== '') {
                  if(this.state.selectDepartment == _memberData.position) {
                    _useMemberData = true;
                  } else {
                    _useMemberData = false;
                  }
                }

              if(_useMemberData === true )
                if(this.state.selectJob !== '') {
                  if(this.state.selectJob == _memberData.job) {
                    _useMemberData = true;
                  } else {
                    _useMemberData = false;
                  }
                }

              if(_useMemberData === true) {
                if (!_member[_memberData.memberid]) _member[_memberData.memberid] = {};
                if (!_member[_memberData.memberid][i]) _member[_memberData.memberid][i] = {};
  
                if (Formular[i1]) {
                  _member[_memberData.memberid][i][i1] = _memberData;
                  
                  _member[_memberData.memberid][i][i1].detail = Formular[i1](_memberData.value, _memberData.age, _memberData.gender);
                }
              }

              


            }
          }

        }
      }
    }

    /** find summary of risk */
    var _dataByYear = {};

    for (var i in _member) { /** member id */
      for (var i1 in _member[i]) { /** year */
        var _groupReport = GroupReport(_member[i][i1]);

        if (!_dataByYear[i1]) _dataByYear[i1] = {};

        for (var g = _groupReport.length; g-- > 0;) {
          if (!_dataByYear[i1][_groupReport[g].label]) _dataByYear[i1][_groupReport[g].label] = {
            green: 0,
            yellow: 0,
            orange: 0,
            red: 0
          };

          _dataByYear[i1][_groupReport[g].label][_groupReport[g].color]++;

          if (!dataIcon[_groupReport[g].label]) dataIcon[_groupReport[g].label] = _groupReport[g].icon;
        }

      }
    };

    var _dataByParameter = {};

    var _color = ['green', 'yellow', 'orange', 'red'];

    var _symbol = ['circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow'];

    var _index = 0;

    for(var year in _dataByYear) {
      for(var name in _dataByYear[year]) {
        
        if(_index > 6) _index = 0;


        if(!_dataByParameter[name]) _dataByParameter[name] = {};

        _color.forEach((color)=>{
          _dataByParameter[name][color] = {
            name: name.toUpperCase(),
            type: 'line',
            symbol: _symbol[_index],
            symbolSize: 14,
            itemStyle: {
              borderColor: '#FFFFFF',
              borderWidth: 2
            },
            data: []
          };
        })

        
        _index++;
      }
    };

    

    this.state.yearGraph.forEach((year)=>{
        for(var name in _dataByParameter) {
          for(var color in _dataByParameter[name] ) {
            
            if(_dataByYear[year] && _dataByYear[year][name]) {
              _dataByParameter[name][color].data.push(_dataByYear[year][name][color])
            } else {
              _dataByParameter[name][color].data.push(null);
            }
          }
        } 
      
    });

    
    var _dataGraph = [];
    for(var i in _dataByParameter) {
      
      _dataByParameter[i][this.state.selectRisk].data.reverse();

      _dataGraph.push(_dataByParameter[i][this.state.selectRisk])
    }

    if(this.state.optionDepartment.length === 1) {
      _department = _department.map((department) => ({label: department, value: department}));

      _department.splice(0,0, {label: 'ทั้งหมด', value: ''});

      _job = _job.map((job) => ({label: job, value: job}));

      _job.splice(0,0, {label: 'ทั้งหมด', value: ''});


      this.setState({
        optionDepartment: _department,
        optionJob: _job
      });
    }

    setTimeout(()=>{       
      this.setState({
        loadingApi: false,
        dataGraph: _dataGraph
      });
    } ,100);
  }
  
  dataCacheGet = async () => {
    this.setState({ loadingApi: true });

    try {
      var { data } = await Api.member("DataCache", {
        cachetype: 'SmartHealthTrendingGraph'
      });
  
      if(data) {
        
        var yearGraph = data.yearGraph;
  
        data = data.data;
  
        this.setState({data, yearGraph} , this.dataRender);
      } else {
        this.dataGet();
      }
    } catch(e) {

      this.dataGet();
    }

    
  }

  dataGet = async () => {
    this.setState({ loadingApi: true });
    var { yearGraph, data } = await Api.member('SmartHealthTrendingGraph');
    this.setState({data, yearGraph} , this.dataRender);

  }

  jobSelectRender = () => {
    return <select className="uk-select" onChange={e=>this.setState({selectJob: e.target.value}, this.dataRender)}>
      {this.state.optionJob.map((job)=>{
        return <option value={job.value}>{job.label}</option>
      })}
    </select>
  }

  departmentSelectRender = () => {
    return <select className="uk-select" onChange={e=>this.setState({selectDepartment: e.target.value}, this.dataRender)}>
      {this.state.optionDepartment.map((department)=>{
        return <option value={department.value}>{department.label}</option>
      })}
    </select>
  }

  ageSelectRender = () => {
    var _age = ['',15,25,30,35,40,45,50,55,60,61];

    var _ageLabel = [
      'ทั้งหมด',
      'น้อยกว่า 15 ปี',
      '15 - 25 ปี',
      '26 - 30 ปี',
      '31 - 35 ปี',
      '36 - 40 ปี',
      '41 - 45 ปี',
      '46 - 50 ปี',
      '51 - 55 ปี',
      '56 - 60 ปี',
      '61 ปี ขึ้นไป',
    ];
    
    return <select className="uk-select" onChange={e=>this.setState({selectAge: e.target.value}, ()=>this.dataRender())}>
      {_age.map((age, key)=>{
        return <option value={age}>{_ageLabel[key]}</option>
      })}
    </select>
  }

  riskSelectRender = () => {
    var _risk = [
      {
        label: 'Normal',
        value: 'green'
      },
      {
        label: 'Low risk',
        value: 'yellow'
      },
      {
        label: 'Risk',
        value: 'orange'
      },
      {
        label: 'High Risk',
        value: 'red'
      }
    ];
    
    return <select className="uk-select" value={this.state.selectRisk} onChange={e=>this.setState({selectRisk: e.target.value}, ()=>this.dataRender())}>
      {_risk.map((risk)=>{
        return <option value={risk.value}>{risk.label}</option>
      })}
    </select>
  }

  genderSelectRender = () => {
    var _gender = [
      {
        label: 'ทั้งหมด',
        value: ''
      },
      {
        label: 'ชาย',
        value: '1'
      },
      {
        label: 'หญิง',
        value: '0'
      }
    ];
    
    return <select className="uk-select" onChange={e=>this.setState({selectGender: e.target.value}, ()=>this.dataRender())}>
      {_gender.map((gender)=>{
        return <option value={gender.value}>{gender.label}</option>
      })}
    </select>
  }



  
  renderGraph = () => {
    var _dataName = [];
    this.state.dataGraph.forEach((data)=>{
      _dataName.push(data.name)
    })

    var option = {

      // Setup grid
      grid: {
        x: 40,
        x2: 20,
        y: 35,
        y2: 25
      },

      // Add tooltip
      tooltip: {
        trigger: 'axis'
      },

      // Add legend
      legend: {
        right: 10,
        data: _dataName,
      },

      // Add custom colors
      color: ['#F98E76', '#16D39A', '#2DCEE3', '#FF7588', '#626E82'],

      // Enable drag recalculate
      calculable: true,

      // Hirozontal axis
      xAxis: [{
        type: 'category',
        boundaryGap: false,
        data: this.state.yearGraph.reverse(),
        z: 10,
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      }],

      // Vertical axis
      yAxis: [{
        name : 'VALUE',
        type: 'value',
        minInterval: 1,
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      }],

      // Add series
      // Add series
      series: this.state.dataGraph
    };
    return <ReactEcharts
      option={option}
      notMerge={true}
      lazyUpdate={true}
      style={{height: 400}}
      theme={"theme_name"}
    />
  }

  render() {
    return <div className="uk-card uk-card-default uk-padding-small" id="d-smart-health-trending-graph">
      <div className="d-card-header">
        <div className="d-card-header-left">
          TRENDING GRAPH
        </div>
        <div className="d-card-header-right">
          <span className='icon-reload' onClick={this.dataGet}></span>
          <FullScreenElement element="#d-smart-health-trending-graph" />
        </div>
      </div>
      <div uk-grid="" className="uk-child-width-1-5@m uk-child-width-1-2 uk-grid-small uk-margin-small-top">
        <div>
          <div className="uk-text-meta">ตำแหน่ง</div>
          {this.jobSelectRender()}
        </div>
        <div>
          <div className="uk-text-meta">แผนก</div>
          {this.departmentSelectRender()}
        </div>
        <div>
          <div className="uk-text-meta">เพศ</div>
          {this.genderSelectRender()}
        </div>
        <div>
          <div className="uk-text-meta">อายุ</div>
          {this.ageSelectRender()}
        </div>
        <div>
          <div className="uk-text-meta">ความเสี่ยง</div>
          {this.riskSelectRender()}
        </div>
      </div>
      <div className="uk-margin-small-top">
      {
        this.state.loadingApi === true ? 
        <div className="uk-padding uk-text-center">
          <span uk-spinner=""></span>
        </div>
        :
        this.renderGraph()

      }
      </div>
    </div>
  }
};
