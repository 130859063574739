import firebase from "firebase";

 
const config = {
  apiKey: "AIzaSyBTPPSG4a62Z-LsARFRhTnWWyqbHjoVHpM",
  authDomain: "diet-z.firebaseapp.com",
  storageBucket: "diet-z.appspot.com",
  databaseURL: "https://diet-z.firebaseio.com",
  projectId: 'diet-z',
  appId: '1:387933989796:web:f49feeaed8b72636d22f31',
  messagingSenderId: "387933989796",
};

// const config = {
//   apiKey: "AIzaSyDDbkJQjPLfO2RQgo2iCjQTbiLnEEf9SjM",
//   authDomain: "tele-cmu.firebaseapp.com",
//   databaseURL: "https://tele-cmu-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "tele-cmu",
//   storageBucket: "tele-cmu.appspot.com",
//   messagingSenderId: "682985172743",
//   appId: "1:682985172743:web:4400fb725d133a1bb95fa2",
//   measurementId: "G-WS1PSY2QBG"
// };

firebase.initializeApp(config);

firebase.auth().signInAnonymously().then(()=>{
  
}).catch(function(error) {
  
});
