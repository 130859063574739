import NovalueCheck from './NoValueCheck';

export default (value) => {
  if(NovalueCheck(value)) return NovalueCheck(value);

  value = +value;

  if(value >= 40) {
    return { 
      title: 'อ้วนมากระดับวิกฤติ',
      color: 'red',
      caption: 'มีภาวะอ้วนระดับวิกฤต',
      suggest: 'จำเป็นต้องลดน้ำหนักภายใต้การดูแลของแพทย์'
    };
  } else if(value >= 30) {
    return { 
      title: 'อ้วนมากระดับวิกฤติ',
      color: 'red',
      caption: 'มีภาวะอ้วนระดับวิกฤต',
      suggest: 'จำเป็นต้องลดน้ำหนักภายใต้การดูแลของแพทย์'
    };
  } else if(value >= 30) {
    return { 
      title: 'อ้วนมาก',
      color: 'orange',
      caption: 'มีภาวะอ้วน',
      suggest: 'จำเป็นต้องลดน้ำหนักภายใต้การดูแลของแพทย์'
    };
  } else if(value >= 25) {
    return { 
      title: 'อ้วน',
      color: 'orange',
      caption: 'มีภาวะอ้วน',
      suggest: 'จำเป็นต้องลดน้ำหนักภายใต้การดูแลของแพทย์'
    };
  } else if(value >= 23) {
    return { 
      title: 'น้ำหนักเกิน',
      color: 'yellow',
      caption: 'เริ่มมีน้ำหนักเกินเกณฑ์ปกติ',
      suggest: 'เริ่มควบคุมอาหารและออกกำลังกาย'
    };
  } else if(value >= 18.5) {
    return { 
      title: 'น้ำหนักปกติ',
      color: 'green',
      caption: 'น้ำหนักอยู่ในเกณฑ์ปกติ',
      suggest: 'รับประทานอาหารและออกกำลังกายตามปกติ'
    };
  } else if(value >= 17) {
    return { 
      title: 'น้ำหนักน้อย',
      color: 'yellow',
      caption: 'น้ำหนักน้อยกว่าเกณฑ์ปกติ',
      suggest: 'ควรประเมินภาวะทุพโภชนาการและให้การรักษา'
    };
  } else if(value >= 16) {
    return { 
      title: 'น้ำหนักน้อยเกินไป',
      color: 'orange',
      caption: 'น้ำหนักน้อยกว่าเกณฑ์ปกติปานกลาง',
      suggest: 'ควรประเมินภาวะทุพโภชนาการและให้การรักษา'
    };
  } else {
    return { 
      title: 'น้ำหนักน้อยระดับวิกฤติ',
      color: 'red',
      caption: 'น้ำหนักน้อยกว่าเกณฑ์ปกติมาก',
      suggest: 'ควรประเมินภาวะทุพโภชนาการและให้การรักษา'
    };
  }
}