import React, { Component } from "react";
import Api from "../../Components/Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import DateTimePicker from "../../Components/DatePicker.js";
import moment from "moment";
import ReactPaginate from "react-paginate";
import firebase from "firebase";
UIkit.use(Icons);

class MemberAddForm extends Component {
  stateTemplate = {
    nameprefix: "",
    name: "",
    lastname: "",
    username: "",
    password: "",
    email: "",
    tel: "",
    gender: "",
    nid: "",
    campaignById: "",
    birthday: moment().format("YYYY-MM-DD"),
    dateStop: moment().add(1, 'years').format('YYYY-MM-DD'),
    campaignList: [],
    showDetail: false
  }

  state = JSON.parse(JSON.stringify(this.stateTemplate))

  campaginListGet = async () => {
    var _r = await Api.consult("CampaignList");

    this.setState({
      campaignList: _r.list || [],
      campaignById:  (_r.list.length ? _r.list[0].id : "")
    })
  }

  formSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    var _value = Object.fromEntries(new FormData(e.target));
    
    _value.dateStop = moment(this.state.dateStop).format('YYYY-MM-DD');

    _value.day = moment(this.state.dateStop).diff(moment(), 'days');

    _value.birthday = moment(this.state.birthday, 'YYYY-MM-DD').subtract(543, 'y').format('YYYY-MM-DD');

    var _r = null;

    if(this.props.memberId) {
      _value.id = this.props.memberId;
      _r = await Api.consult("MemberUpdate", _value);

    } else {
      
      _r = await Api.consult("MemberAdd", _value);
      this.props.closeCallback();
    }


    UIkit.notification({
      message: _r.responseText, 
      status: _r.result === true ? 'success' : 'danger' 
    });
  }

  
  memberGet = async () => {
    var _r = await Api.consult("MemberGet", {
      memberId: this.props.memberId
    });
 

    if(_r.data) {

      var _d = {};

      for(var k in _r.data) {
        if(this.stateTemplate[k] != null) {
          _d[k] = _r.data[k];
        }
      }
 

      this.setState({..._d, showDetail: true });
    } else {
      UIkit.notification({
        message: 'ไม่พบข้อมูล', 
        status: 'danger' 
      });

      this.props.closeCallback();
    }
  }

  componentDidMount() {
    this.setState(() => {
      return JSON.parse(JSON.stringify(this.stateTemplate))
    })
    
    if(this.props.memberId)
      this.memberGet();
    else
      this.setState({
        showDetail: true
      })
    this.campaginListGet();
  }

  render() {
    return this.state.showDetail === true ? <div className="uk-text-left">
      <a className="uk-float-right" uk-icon="close" onClick={this.props.closeCallback}></a>
      <form onSubmit={this.formSubmit}>
      <h2>เพิ่มคนไข้ใหม่</h2>
      {this.props.memberById}
      <div className="uk-grid uk-grid-margin uk-grid-small">
        {
          !this.props.memberId && <div className="uk-width-1-3@m uk-width-1-1 uk-margin-small-bottom">
          <label>แคมเปญเริ่มต้น <span class="uk-text-danger">*</span></label>
          <select name="campaignId" className="uk-select" defaultValue={this.state.campaignById}>
            {this.state.campaignList.map((c)=>{
              return <option value={c.id}>{c.name}</option>
            })}
          </select>
        </div>
        }
        
        <div className="uk-width-1-3@m uk-width-1-1">
          <label>วันที่สิ้นสุดแคมเปญ <span class="uk-text-danger">*</span></label>
            <DateTimePicker
              format={"DD/MM/YYYY"}
              id="CampaignDateStop"
              date={this.state.dateStop}
              callback={(value) => this.setState({ dateStop: value })}
            />
        </div>
        <div className="uk-width-1-3@m uk-width-1-1"></div>
      </div>
      <div className="uk-grid uk-grid-margin uk-grid-small">
        
        <div className="uk-width-1-3@m uk-width-1-1 uk-margin-small-bottom">
          <label>คำนำหน้าชื่อ <span class="uk-text-danger">*</span></label>
          <input required={true} className="uk-input" type="text" name="nameprefix" placeholder="นาย" defaultValue={this.state.nameprefix} />
        </div>
        <div className="uk-width-1-3@m uk-width-1-1 uk-margin-small-bottom">
          <label>ชื่อ <span class="uk-text-danger">*</span></label>
          <input required={true} className="uk-input" type="text" name="name" placeholder="ชื่อ" defaultValue={this.state.name} />
        </div>
        <div className="uk-width-1-3@m uk-width-1-1 uk-margin-small-bottom">
          <label>นามสกุล <span class="uk-text-danger">*</span></label>
          <input required={true} className="uk-input" type="text" name="lastname" placeholder="นามสกุล" defaultValue={this.state.lastname} />
        </div>
        <div className="uk-width-1-3@m uk-width-1-1 uk-margin-small-bottom">
          <label>ชื่อผู้ใช้ <span class="uk-text-danger">*</span></label>
          <input required={true} className="uk-input" type="text" name="username" placeholder="ชื่อผู้ใช้" defaultValue={this.state.username} />
        </div>
        <div className="uk-width-1-3@m uk-width-1-1 uk-margin-small-bottom">
          <label>รหัสผ่าน <span class="uk-text-danger">*</span></label>
          <input className="uk-input" type="text" name="password" placeholder="รหัสผ่าน" defaultValue={this.state.password} />
        </div>
        <div className="uk-width-1-3@m uk-width-1-1 uk-margin-small-bottom">
          <label>อีเมล</label>
          <input className="uk-input" type="text" name="email" placeholder="อีเมล" defaultValue={this.state.email} />
        </div>
        <div className="uk-width-1-3@m uk-width-1-1 uk-margin-small-bottom">
          <label>เบอร์โทรศัพท์มือถือ <span class="uk-text-danger">*</span></label>
          <input required={true} className="uk-input" type="tel" name="tel" placeholder="เบอร์โทรศัพท์มือถือ" defaultValue={this.state.tel} />
        </div>
        <div className="uk-width-1-3@m uk-width-1-1 uk-margin-small-bottom" defaultValue={this.state.gender}>
          <label>เพศ <span class="uk-text-danger">*</span></label>
          <select className="uk-select" name="gender">
            <option value="0">หญิง</option>
            <option value="1">ชาย</option>
          </select>
        </div>
        <div className="uk-width-1-3@m uk-width-1-1 uk-margin-small-bottom" defaultValue={this.state.gender}>
          <label>วัน เดือน ปี เกิด <span class="uk-text-danger">*</span></label>
          
          <DateTimePicker
                id="birthday"
                date={this.state.birthday ? moment(this.state.birthday, "YYYY-MM-DD").add(543, 'y') : moment().add(543, 'y')}
                format={"DD/MM/YYYY"}
                callback={(value) => { 

                  this.setState({ birthday: value })
                }}
              />
        </div>
        <div className="uk-width-1-3@m uk-width-1-1 uk-margin-small-bottom">
          <label>เลขบัตรประชาชน / เลขบัตรประกันสังคม / เลขพาสปอร์ต <span class="uk-text-danger">*</span></label>
          <input className="uk-input" type="text" name="nid" placeholder="เลขบัตรประชาชน / เลขบัตรประกันสังคม / เลขพาสปอร์ต" defaultValue={this.state.nid} />
        </div>
      </div>
      <div className="uk-text-right uk-margin-small-top">
        <button type="button" className="uk-button uk-button-default uk-margin-right" onClick={this.props.closeCallback}>ยกเลิก</button>
        <button type="submit" className="uk-button uk-button-primary">บันทึก</button>
      </div>
      </form>
      </div>
      :
      <div className="uk-text-center"><span uk-spinner=""></span></div>
  }
}

export default class ConsultMemberList extends Component {
  state = {
    list: [],
    listAll: [],
    dateStart: moment().startOf("day"),
    dateStop: moment().add(7, "day"),
    loadingApi: false,
    page: 1,
    pageAmount: 1,
    filterCampaign: "all",
    showMemberAddPopup: false,
    keywordName : ""
  };

  memberById = {};

  campaignById = {};

  campaignOption = [{ label: "แคมเปญทั้งหมด", value: "all" }];

  MemberAddMemberId = null;

  firebaseChatlistUpdate = (memberid, consultid) => {
    firebase.database().ref(`chatlist_m/${memberid}`).set(+new Date());
  };

  logVideoCallSend = async (consultId, memberId, campaignId) => {
    Api.member("LogVideoCallAdd", {
      consultId,
      memberId,
      campaignId,
      chatBy: "consult",
    });

    var result = await Api.consult("ChatMessageAdd", {
      consultId: consultId,
      memberId: memberId,
      campaignId: campaignId,
      typeMessage: "text",
      memberRead: 0,
      consultRead: 1,
      message: `มีการเรียกระบบวีดีโอคอล กดลิงค์ เพื่อเข้าสู่หน้าวีดีโอคอล
      https://con.defence-innovation.com/${memberId}/${consultId}?openExternalBrowser=1`,
      chatby: "c",
    });

    this.firebaseChatlistUpdate(memberId, consultId);
  };

  listGet = async () => {
    this.setState({ loadingApi: true });

    var _response = await Api.consult("MemberList", {
      dateStart: moment(this.state.dateStart).format("YYYY-MM-DD "),
      dateStop: moment(this.state.dateStop).format("YYYY-MM-DD"),
      page: this.state.page,
      keywordName: this.state.keywordName
    });
 
    if (Array.isArray(_response.member)) {
      _response.member.forEach((member) => {
        this.memberById[member.id] = member;
      });
    } else {
      this.memberById = {};
    }

    this.campaignOption = [{ label: "แคมเปญทั้งหมด", value: "all" }];

    if (Array.isArray(_response.campaign)) {
      _response.campaign.forEach((campaign) => {
        this.campaignOption.push({
          label: campaign.name,
          value: campaign.id,
        });

        this.campaignById[campaign.id] = campaign.name;
      });
    }

    if (Array.isArray(_response.list)) {
      _response.list.forEach((list) => {
        var _member = this.memberById[list.memberid];

        if (_member != null) {
          list.member = `${_member.nameprefix} ${_member.name} ${_member.lastname}`;

          list.dateadd = _member.dateadd;
        } else {
          list.member = "-";

          list.dateadd = "-";
        }

        list.campaignname = "";

        if (this.campaignById[list.campaignid] != null) {
          list.campaignname = this.campaignById[list.campaignid];
        }
      });
    }

    this.setState(
      {
        listAll: _response.list || [],
        loadingApi: false,
        pageAmount: _response.pageAmount,
      },
      () => {
        this.listFilterRender();
      }
    );
  };

  listFilterRender = () => {
    var _list = [];

    this.state.listAll.forEach((data) => {
      if (
        this.state.filterCampaign !== "all" &&
        this.state.filterCampaign != data.campaignid
      ) {
        return null;
      } else {
        _list.push(data);
      }
    });

    this.setState({ list: _list });
  };

  handlePageClick = (page) => {
    this.setState({ page: page.selected + 1 }, () => this.listGet());
  };

  paginateRender = () => {
    return (
      <div className="uk-text-right">
        <ReactPaginate
          className={"uk-padding-remove"}
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageAmount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    );
  };

  componentDidMount() {
    this.listGet();
  }

  render() {
    return (
      <div className="uk-padding">
        <div className="uk-card uk-card-default uk-padding-small">
          <div className="d-card-header">รายชื่อคนไข้</div>
          <div uk-grid="" className="uk-flex-middle">
            <div className="uk-width-1-5@m uk-width-1-1">
              <DateTimePicker
                id="dateStart"
                date={this.state.dateStart}
                callback={(value) => this.setState({ dateStart: value })}
              />
            </div>
            <div className="uk-width-1-5@m uk-width-1-1">
              <DateTimePicker
                id="dateStop"
                date={this.state.dateStop}
                callback={(value) => this.setState({ dateStop: value })}
              />
            </div>
            <div className="uk-width-1-5@m uk-width-1-1">
              <input type="text" className="uk-input" value={this.state.keywordName} placeholder="ค้นหาชื่อ-สกุล, เลขบัตร" onChange={(e)=>this.setState({keywordName: e.target.value})} />
            </div>
            <div className="uk-width-1-5@m uk-width-1-1">
              <button
                className="uk-button uk-button-primary"
                onClick={this.listGet}
              >
                ค้นหา
              </button>
            </div>
            <div className="uk-width-1-5@m uk-width-1-1 uk-text-right">
              <button className="uk-button uk-button-primary" onClick={()=>{
                this.MemberAddMemberId = null;

                this.setState({
                  showMemberAddPopup: true
                })
                
              }}>เพิ่มคนไข้ใหม่</button>
              {
                this.state.showMemberAddPopup === true && <div className="d-popup-background">
                <div className="d-popup-body">
                  <MemberAddForm memberId={this.MemberAddMemberId} closeCallback={()=>this.setState({showMemberAddPopup: false})} />
                </div>
              </div>
              }
              
            </div>
          </div>

          <hr />
              <select
                className="uk-select uk-width-small uk-margin-bottom"
                defaultValue={this.state.filterCampaign}
                onChange={(e) =>
                  this.setState({ filterCampaign: e.target.value }, () => {
                    this.listFilterRender();
                  })
                }
              >
                {this.campaignOption.map((option) => {
                  return <option value={option.value}>{option.label}</option>;
                })}
              </select>
          <div className="uk-overflow-auto">
            <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
              <thead>
                <tr>
                  <th>ที่</th>
                  <th>วันที่สมัคร</th>
                  <th>เวลา</th>
                  <th>ชื่อคนไข้</th>
                  <th>วีดีโอคอล/แชท</th>
                  <th>แคมเปญ</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.loadingApi === true ? (
                  <tr>
                    <td colSpan="100%">
                      <div className="uk-padding-small uk-text-center">
                        <span uk-spinner=""></span>
                      </div>
                    </td>
                  </tr>
                ) : this.state.list.length === 0 ? (
                  <tr>
                    <td colSpan="100%">
                      <div className="uk-padding-small uk-text-center">
                        ไม่พบข้อมูล
                      </div>
                    </td>
                  </tr>
                ) : (
                  this.state.list.map((data, key) => {

                    return (
                      <tr key={`row${data.id}`}>
                        <td>{key + 1}</td>
                        <td>
                          {data.dateadd == "-"
                            ? "-"
                            : moment(data.dateadd).format("DD/MM/YYYY")}
                        </td>
                        <td>
                          {data.dateadd == "-"
                            ? "-"
                            : moment(data.dateadd).format("HH:mm น.")}
                        </td>
                        <td>
                          {data.member == "-"
                            ? "ไม่พบข้อมูลสมาชิก สมาชิกถูกลบ"
                            : data.member}
                        </td>
                        <td>
                          {data.member == "-" ? (
                            ""
                          ) : (
                            <div>
                              <a
                                href={`https://con.defence-innovation.com/${data.memberid}/${this.props.consult.id}?openExternalBrowser=1`}
                                className="uk-margin-right"
                                target="_blank"
                                onClick={() => {
                                  this.logVideoCallSend(
                                    this.props.consult.id,
                                    data.memberid,
                                    data.campaignid
                                  );
                                }}
                              >
                                กดวีดีโอคอล
                              </a>
                              <a
                                href={`/consult/chat?messageSelectIndex=ca${data.campaignid}_co${this.props.consult.id}_m${data.memberid}`}
                              >
                                แชท
                              </a>
                            </div>
                          )}
                        </td>
                        <td>{data.campaignname}</td>
                        <td>{data.type}</td>
                        <td><a onClick={()=>{
                          this.MemberAddMemberId = data.memberid;
                          this.setState({showMemberAddPopup: true})
                        }}>แก้ไขข้อมูล</a></td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            <div>{this.paginateRender()}</div>
          </div>
        </div>
      </div>
    );
  }
}
