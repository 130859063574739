import React, { Component } from 'react';
import Api from '../../Components/Api';
import { Link } from "react-router-dom";
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import LanguageLoginSelect from '../../Components/LanguageLoginSelect';
import __ from '../../Components/Language';
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min.js";
import "eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css";
import moment from "moment";

UIkit.use(Icons);

export default class MemberPasswordRecovery extends Component {
  state = {
    step: 1,
    errorTextStep1: '',
    errorTextStep2: '',
    errorTextStep3: '',
    tel : '',
    nid: '',
    birthday: '',
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    password: "",
    passwordConfirm: "",
    resendOtp : false,
    isLoadingApi : false
  }
  

  datepickerGet = () => {
    $("#datetimepicker-birthday")
      .on("dp.change", (e) => {
        this.setState({
          birthday: moment(e.date).subtract(543, "y").format("YYYY-MM-DD"),
        });
      })
      .datetimepicker({
        format: "DD/MM/YYYY",
        defaultDate: moment().add(543, "y"),
      });
  };

  init = () => {
    this.datepickerGet();
  }

  otp = null

  otpConfirm = (e) => {
    e.preventDefault();


    if(this.otp && this.otp == `${this.state.otp1}${this.state.otp2}${this.state.otp3}${this.state.otp4}`) {
      this.setState({step: 3});
    } else {
      this.setState({errorTextStep2: __('รหัส OTP ผิด')})
    }
  }

  otpTextInputRender = (e, number) => {
    e.target.value = e.target.value.replace(/\D/, "");

    this.setState({ [`otp${number}`]: e.target.value });

    if (e.target.value && number !== "4")
      $("#otp-input-" + (+number + 1)).focus();

    if (number === "4") $("#otp-input-4").blur();
  };

  memberDataConfirm = async (e) => {
    e.preventDefault();

    if(this.state.isLoadingApi !== false) return;

    this.setState({errorTextStep1: '', isLoadingApi: true});

    var _response = await Api.member('MemberForgotPasswordDataGet', {
      nid: this.state.nid,
      tel: this.state.tel,
      birthday: this.state.birthday
    });

    if(_response.result === true) {
      this.otp = _response.otp;

      this.setState({errorTextStep1: '', step: 2, isLoadingApi: false});

    } else {
      this.setState({errorTextStep1: __(_response.responseText), isLoadingApi: false});
    }
  }
  
  memberPasswordRenew = async (e) => {
    e.preventDefault();

    if(this.state.isLoadingApi !== false) return;

    try {
      if(this.state.password.length < 8) {
        throw new Error(
          "รหัสผ่านควรตั้งให้มี 8 ตัวขึ้นไป ประกอบด้วยตัวเลขและตัวอักษรเพื่อความปลอดภัย"
        );
        
        
      } else if(this.state.password !== this.state.passwordConfirm) {
        throw new Error(
          "รหัสผ่านไม่ตรงกัน"
        );

      } else {
        this.setState({isLoadingApi: true});

        var _response = await Api.member('memberPasswordRenew', {
          nid: this.state.nid,
          tel: this.state.tel,
          birthday: this.state.birthday,
          otp: this.otp,
          password: this.state.password
        });

        if(_response.result === true) {
          this.otp = null;

          window.location.href = '/';

        } else {
          throw new Error(_response.responseText);
        }
        
        this.setState({isLoadingApi: false});
  
      }
    } catch(e) {
      this.setState({errorTextStep3: __(e.message), isLoadingApi: false});
    }

    

  }

  componentDidMount () {
    this.init();
  }

  render() {
    return <div>
      <div className="d-form-login-body uk-padding-small">
        <div className="uk-card uk-card-default uk-padding uk-margin-top uk-padding-remove-top d-form-login-container uk-width-1-1 uk-width-1-2@s uk-width-1-4@m">
          <LanguageLoginSelect />
          <div className="uk-text-center uk-padding-small">
            <img src="/logo_dietz.png" />
          </div>
          <h1 className="d-form-login-title uk-margin-bottom">{__('ลืมชื่อผู้ใช้หรือรหัสผ่าน')}</h1>
          {
            this.state.step === 1 && <form onSubmit={this.memberDataConfirm}>
            <div>
            {__("เลขบัตรประชาชน หรือ เลขบัตรประกันสังคม")}
              <span className="uk-text-danger">*</span>
            </div>
            <input
              type="number"
              pattern="[0-9]*"
              className="uk-input"
              value={this.state.nid}
              onChange={(e) =>
                this.setState({ nid: e.target.value.replace(/\D/, "") })
              }
            />
            <div>
              {__('หมายเลขโทรศัพท์มือถือ')}
              <span className="uk-text-danger">*</span>
            </div>
            <input
              type="number"
              pattern="[0-9]*"
              className="uk-input"
              value={this.state.tel}
              onChange={(e) =>
                this.setState({ tel: e.target.value.replace(/\D/, "") })
              }
            />
            <div>
              {__('วันเดือนปีเกิด')}
              <span className="uk-text-danger">*</span>
            </div>
            <div
              className="d-button-group input-group uk-button-group date uk-width-1-1"
              id="datetimepicker-birthday"
            >
              <input
                type="text"
                className="uk-input"
                placeholder={__("วันที่")}
                style={{ borderRight: 0 }}
              />
              <span
                className="input-group-addon"
                style={{
                  width: 45,
                  marginLeft: 0,
                  height: 30,
                  borderLeft: 0,
                  borderColor: "#DBDBDB",
                }}
              >
                <span className="glyphicon glyphicon-calendar"></span>
              </span>
            </div>

            {this.state.errorTextStep1 && (
              <div className="uk-margin-small-top uk-text-center uk-text-danger uk-padding-small uk-background-muted">
                {this.state.errorTextStep1}
              </div>
            )}
            
            <button
                className="uk-button uk-button-primary uk-width-1-1 uk-margin-top"
                type="submit"
              >{__('ส่งข้อความ')}</button>
          </form>
          }
          {
            this.state.step === 2 && <form
            className="uk-text-left uk-margin-small-top uk-text-center"
            onSubmit={this.otpConfirm}
          >
            <div className="uk-margin-small-bottom">
              <div className="d-text-title">{__("กรุณากรอกรหัส 4 หลัก")}</div>
              <div className="d-text-title">{__("ที่ท่านได้รับจาก SMS")}</div>
            </div>
            <div className="uk-margin-top">
              <input
                type="tel"
                id="otp-input-1"
                maxLength="1"
                pattern="[0-9]*"
                className="uk-input d-input-otp"
                value={this.state.otp1}
                onChange={(e) => {
                  this.otpTextInputRender(e, "1");
                }}
              />
              <input
                type="tel"
                id="otp-input-2"
                maxLength="1"
                pattern="[0-9]*"
                className="uk-input d-input-otp"
                value={this.state.otp2}
                onChange={(e) => {
                  this.otpTextInputRender(e, "2");
                }}
              />
              <input
                type="tel"
                id="otp-input-3"
                maxLength="1"
                pattern="[0-9]*"
                className="uk-input d-input-otp"
                value={this.state.otp3}
                onChange={(e) => {
                  this.otpTextInputRender(e, "3");
                }}
              />
              <input
                type="tel"
                id="otp-input-4"
                maxLength="1"
                pattern="[0-9]*"
                className="uk-input d-input-otp"
                value={this.state.otp4}
                onChange={(e) => {
                  this.otpTextInputRender(e, "4");
                }}
              />
            </div>
    
            {this.state.errorTextStep2 && (
              <div className="uk-margin-small-top uk-text-center uk-text-danger uk-padding-small uk-background-muted">
                {this.state.errorTextStep2}
              </div>
            )}
    
            <div className="uk-margin-top">
              <button
                className="uk-button uk-button-primary uk-width-1-1 uk-margin-top"
                type="submit"
              >
                {__("ตั้งรหัสผ่านใหม่")}
              </button>
            </div>
    
            {this.state.resendOtp !== true && <div className="uk-text-center uk-margin-top">
              {__("ยังไม่ได้รับ SMS")}{" "}
              <a className="uk-link-reset" onClick={(e)=>{
                  this.setState({resendOtp: true});
                  this.memberDataConfirm(e);
                }}>
                <u>{__("กดส่งใหม่")}</u>
              </a>
            </div>
            }
          </form>
          }
           {
            this.state.step === 3 && <form
            className="uk-text-left uk-margin-small-top uk-text-center"
            onSubmit={this.memberPasswordRenew}
          >
            <div className="uk-margin-small-bottom">
              <div className="d-text-title">{__("ตั้งรหัสผ่านใหม่")}</div>
            </div>
          <div>
            <div className="uk-text-left">
              {__("รหัสผ่าน")}
              <span className="uk-text-danger">*</span>
            </div>
            <input
              value={this.state.password}
              className="uk-input"
              type="password"
              name="password"
              onChange={(e) => {
                this.setState({
                  password: e.target.value.replace(/[^a-zA-Z0-9]/gi, ""),
                });
              }}
            />
            </div>
            <div className="uk-text-left">
              <ul >
                <li>{__("มีจำนวน 8 ตัว")}</li>
                <li>{__("เป็นตัวเลขหรือตัวอักษร")}</li>
              </ul>
            </div>
            <div>
              <div className="uk-text-left">
                {__("ยืนยันรหัสผ่าน")}
                <span className="uk-text-danger">*</span>
              </div>
              <input
                value={this.state.passwordConfirm}
                className="uk-input"
                type="password"
                name="password"
                onChange={(e) => {
                  this.setState({
                    passwordConfirm: e.target.value.replace(/[^a-zA-Z0-9]/gi, ""),
                  });
                }}
              />
            </div>
            {this.state.errorTextStep3 && (
              <div className="uk-margin-small-top uk-text-center uk-text-danger uk-padding-small uk-background-muted">
                {this.state.errorTextStep3}
              </div>
            )}
    
              <button
                className="uk-button uk-button-primary uk-width-1-1 uk-margin-top"
                type="submit"
              >
                {__("จัดส่งชื่อผู้ใช้และรหัสผ่าน")}
              </button>
          </form>
          }
        </div>
      </div>
    </div>
  }
};
