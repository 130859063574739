import React, { Component } from "react";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import HeaderSub from "./HeaderSub.js";
import IconCard from "./IconCard";
import Api from "../Api";
import $ from "jquery";
import Moment from "moment";
import Numeral from "numeral";
import ReactEcharts from 'echarts-for-react';
UIkit.use(Icons);

export default class WearableDevice extends Component {
  state = {
    campaign: [],
    timeStart: Moment().startOf('day').toDate().getTime() * 1000000,
    timeStop: new Date().getTime() * 1000000,
    campaignId: '0',
    step: 0,
    calories: 0,
    pulse: 0,
    popupShow: false,
    data_raw: []
  }

  dataByDate = {
    step: {},
    calories: {},
    pulse: {}
  }

  campaignListGet = async () => {
    var { list } = await Api.member("CampaignList");

    if (!list) list = [];

    list = list.map((data) => {
      return {
        value: data.id,
        label: data.name,
      };
    });

    this.setState({ campaign: list, campaignId: list[0] ? list[0].value : '0' });
  };

  dataGet = async () => {
    var _response = await Api.member('DataGet', {
      dateStart: Moment.unix(this.state.timeStart/1000000000).format('YYYY-MM-DD') + ' 00:00:00',
      dateStop : Moment.unix(this.state.timeStop/1000000000).format('YYYY-MM-DD') + ' 23:59:59'
    });
    
    this.setState({
      data_raw: _response.data || []
    });
  }

  dataUpdate = async () => {
    var _response = await Api.member('DataAddByGoogleFit', {
      campaignId: this.state.campaignId,
      data: this.dataByDate
    });

    // console.log(_response)

    this.setState({popupShow: false});

    this.dataGet();

    localStorage.setItem('googlefit-time', '');
  }

  googleFitTokenGet = async () => {

    var _response = await $.ajax({
      method: 'POST',
      url: 'https://accounts.google.com/o/oauth2/token',
      data: `code=${this.props.qs.code}&redirect_uri=${encodeURIComponent(window.location.origin + window.location.pathname)}&client_id=387933989796-qjimhe75d2arb48ll5a7tev1l30906qp.apps.googleusercontent.com&scope=&grant_type=authorization_code&client_secret=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`,
      beforeSend: function(request) {
        // request.setRequestHeader("Authorization",window.localStorage.getItem('adminLogin') || '' );
      }
    }).promise();

    if(_response.access_token) {
      this.googleFitDataGet(_response.access_token);
    }
  }
  
  googleFitDataGet = async (token) => {
    $.ajax({
      method: 'GET',
      url: `https://fitness.googleapis.com/fitness/v1/users/me/dataSources/derived:com.google.heart_rate.bpm:com.google.android.gms:merge_heart_rate_bpm/datasets/${this.state.timeStart}-${this.state.timeStop}`,
      beforeSend: function(request) {
        request.setRequestHeader("Authorization",`Bearer ${token}`);
      }
    }).done((data) => {

      // console.log("Heart Rate" ,  data);

      var _value = 0;
      data.point.forEach((point) => {


        point.value.forEach((value)=>{
          var _date = Moment(new Date(parseInt(point.modifiedTimeMillis))).format('YYYY-MM-DD');
          
          if(this.dataByDate.pulse[_date] == null) this.dataByDate.pulse[_date] = 0;
          this.dataByDate.pulse[_date] += value.fpVal;

          _value += value.fpVal;
        })
      })

      if(_value > 0) {
        //update

        this.setState({pulse: _value});

        // this.dataUpdate('pulse', _value, Moment().format('YYYY-MM-DD'));
      }
    });

    $.ajax({
      method: 'GET',
      url: `https://fitness.googleapis.com/fitness/v1/users/me/dataSources/derived:com.google.step_count.delta:com.google.android.gms:merge_step_deltas/datasets/${this.state.timeStart}-${this.state.timeStop}`,
      beforeSend: function(request) {
        request.setRequestHeader("Authorization",`Bearer ${token}`);
      }
    }).done((data) => {

      var _value = 0;
      data.point.forEach((point) => {
        point.value.forEach((value)=>{
          
          var _date = Moment(new Date(parseInt(point.modifiedTimeMillis))).format('YYYY-MM-DD');
          
          if(this.dataByDate.step[_date] == null) this.dataByDate.step[_date] = 0;

          this.dataByDate.step[_date] += value.intVal;

          _value += value.intVal;
        })
      });
      
      if(_value > 0) {
        //update
        this.setState({step: _value});
        // this.dataUpdate('step', _value, Moment().format('YYYY-MM-DD'));
      }
    });

  
    $.ajax({
      method: 'GET',
      url: `https://fitness.googleapis.com/fitness/v1/users/me/dataSources/derived:com.google.calories.expended:com.google.android.gms:merge_calories_expended/datasets/${this.state.timeStart}-${this.state.timeStop}`,
      beforeSend: function(request) {
        request.setRequestHeader("Authorization",`Bearer ${token}`);
      }
    }).done((data) => {

      var _value = 0;
      data.point.forEach((point) => {
        point.value.forEach((value)=>{
          var _date = Moment(new Date(parseInt(point.modifiedTimeMillis))).format('YYYY-MM-DD');
          
          if(this.dataByDate.calories[_date] == null) this.dataByDate.calories[_date] = 0;

          this.dataByDate.calories[_date] += value.fpVal;

          _value += value.fpVal;
        })
      });
      
      if(_value > 0) {
        //update

        this.setState({calories: _value});
      }
    });

    this.setState({popupShow: true});

    localStorage.setItem('googlefit-time', '');
  }

  graphWeekRender = () => {

    var _data = {
      step: {},
      calories: {},
      pulse: {}
    }

    var _date = [];
    
    var _date_start = Moment.unix(this.state.timeStart/1000000000);

    var _date_stop = Moment.unix(this.state.timeStop/1000000000);

    while(_date_start < _date_stop) {
      var _date_now = _date_start.format('YYYY-MM-DD');

      _date.push(_date_now);

      _data.step[_date_now] = 0;
      _data.calories[_date_now] = 0;
      _data.pulse[_date_now] = 0;


      _date_start.add(1, 'day');
    }

    


    if(Array.isArray(this.state.data_raw)) {
      this.state.data_raw.forEach(function(data) {
        if(_data[data.name] != null && _data[data.name][Moment(data.dateadd).format('YYYY-MM-DD')] != null) {
          _data[data.name][Moment(data.dateadd).format('YYYY-MM-DD')] += parseFloat(data.value);
        }
      });
    }

    var _option_render = (name) => {

      var _data_bar = [];
      for(var date in _data[name]) {
        _data_bar.push(_data[name][date]);
      }

      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: [
          {
            type: 'category',
            data: _date,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: name,
            type: 'bar',
            barWidth: '60%',
            data: name === 'step' ? _data_bar : _data_bar.map((d)=>d.toFixed(2)),
            itemStyle: {
              color: "#2e99d8"
            }
          }
        ]
      };
    }

    return <div>
      <ReactEcharts
        option={_option_render('step')}
        style={{height: 400}}
        notMerge={true}
        lazyUpdate={true}
      />
      <img src="/assets/ic_walk.jpg" width={200} />
      <div className="uk-margin-bottom uk-margin-small-top">ก้าวเดิน</div>
      <ReactEcharts
        option={_option_render('pulse')}
        style={{height: 400}}
        notMerge={true}
        lazyUpdate={true}
      />
      <img src="/assets/ic_pulse.jpg" width={200} />
      <div className="uk-margin-bottom uk-margin-small-top">ชีพจรเฉลี่ย</div>
      <ReactEcharts
        option={_option_render('calories')}
        style={{height: 400}}
        notMerge={true}
        lazyUpdate={true}
      />
      <img src="/assets/ic_meta.jpg" width={200} />
      <div className="uk-margin-small-top">อัตราการเผาผลาญพลังงาน</div>
    </div>
    
  }

  componentDidMount() {
    this.campaignListGet();

    this.dataGet();

    if(this.props.qs.code) {
      // 
      var _googlefit_time = localStorage.getItem('googlefit-time');

      if(_googlefit_time) {
        _googlefit_time = JSON.parse(_googlefit_time);

        this.setState({
          timeStart: _googlefit_time.timeStart,
          timeStop: _googlefit_time.timeStop
        }, ()=> {
          this.googleFitTokenGet();
        })
      }
      else {
        this.googleFitTokenGet();
      }

      
    }
  }

  timeInit() {
    localStorage.setItem('googlefit-time', JSON.stringify({
      timeStart: this.state.timeStart,
      timeStop: this.state.timeStop
    }))
  }

  todayRowRender = () => {

    var _data = {
      step: 0,
      calories: 0,
      pulse: 0
    }

    if(Array.isArray(this.state.data_raw)) {
      this.state.data_raw.forEach(function(data) {
        if(_data[data.name] != null) {
          _data[data.name] = data.value;
        }
      });
    }

    return <div uk-grid="">
      <div className="uk-width-1-3@m uk-width-1-1">
        <div>
          <h2 className="d-text-blue-light">{Numeral(_data.step).format('0,0')}</h2> <div>ก้าว</div>
        </div>
        <div className="uk-margin">
          <img src="/assets/ic_walk.jpg" width={200} />
        </div>
        <div className="uk-margin-small-top">ก้าวเดิน</div>
      </div>
      <div className="uk-width-1-3@m uk-width-1-1">
        <div>
        <h2 className="d-text-blue-light">{Numeral(_data.pulse).format('0,0')}</h2> <div className="uk-margin-bottom">ครั้งต่อนาที</div>
        </div>
        <div className="uk-margin">
          <img src="/assets/ic_pulse.jpg" width={200} />
        </div>
        <div className="uk-margin-bottom">ชีพจรเฉลี่ย</div>
      </div>
      <div className="uk-width-1-3@m uk-width-1-1">
        <div>
          <h2 className="d-text-blue-light">{Numeral(_data.calories).format('0,0')}</h2> <div>แคลอรี่</div>
        </div>
        <div className="uk-margin">
          <img src="/assets/ic_meta.jpg" width={200} />
        </div>
        <div className="uk-margin-bottom">อัตราการเผาผลาญพลังงาน</div>
      </div>
    </div>
  }

  render() {
    return (
      <div className="uk-padding">
        <HeaderSub title="Wearable device" />
        {this.state.popupShow === true && 
          <div className="d-popup-background">
            <div className="d-popup-body d-popup-body-small">
              <span uk-close="" className="uk-float-right" onClick={()=>this.setState({
                popupShow: false
              })}></span>
              <h4 className="uk-margin-top-remove">รายละเอียดข้อมูล</h4>
              <div>ค่าที่อ่านได้</div>
              <table className="uk-table uk-table-small uk-table-divider">
                <thead>
                  <tr>
                    <th>ประเภท</th>
                    <th>ค่ารวม</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>ก้าว</td>
                    <td>{Numeral(this.state.step).format('0,0')}</td>
                  </tr>
                  <tr>
                    <td>แคลอรี่</td>
                    <td>{Numeral(this.state.calories).format('0,0.00')}</td>
                  </tr>
                  <tr>
                    <td>อัตราการเต้นหัวใจ</td>
                    <td>{Numeral(this.state.pulse).format('0,0.00')}</td>
                  </tr>
                </tbody>
              </table>
              <div>แคมเปญ</div>
              <select value={this.state.campaignId} className="uk-select" onChange={(e)=>{
                this.setState({campaignId: e.target.value})
              
              }}>{this.state.campaign.map((campaign) => {
                return <option value={campaign.value}>{campaign.label}</option>
              })}</select>
              <div className="uk-margin-top">
                <button onClick={this.dataUpdate} className="uk-button uk-button-primary uk-width-1-1">อัพโหลดข้อมูล</button>
              </div>
            </div>
          </div>
        }
        <div className="uk-card uk-card-default uk-padding-small">
          <div className="uk-margin-bottom uk-text-right">
            <a className="uk-button uk-button-primary" onClick={()=>{ 
              this.timeInit();

              setTimeout(()=>{

                window.location.href = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?redirect_uri=${encodeURIComponent(window.location.origin + window.location.pathname )}&prompt=consent&response_type=code&client_id=387933989796-qjimhe75d2arb48ll5a7tev1l30906qp.apps.googleusercontent.com&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Ffitness.activity.read%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Ffitness.heart_rate.read&access_type=offline&service=lso&o2v=2&flowName=GeneralOAuthFlow`;

              }, 100);

            }}>Connect Google Fit</a>
          </div>
          <ul className="uk-subnav uk-subnav-pill uk-flex-center" uk-switcher="">
            <li>
              <a href="#" onClick={()=>this.setState({
                  timeStart: Moment().startOf('day').toDate().getTime() * 1000000,
                  timeStop: new Date().getTime() * 1000000
              }, ()=>{
                this.dataGet();
              })}>ข้อมูลวันนี้</a>
            </li>
            <li>
              <a href="#" onClick={()=>this.setState({
                timeStart: Moment().subtract(7, 'day').startOf('day').toDate().getTime() * 1000000,
                timeStop: new Date().getTime() * 1000000
              }, ()=>{
                this.dataGet();
              })}>รายสัปดาห์</a>
            </li>
            <li>
              <a href="#" onClick={()=>this.setState({
                
                timeStart: Moment().startOf('month').toDate().getTime() * 1000000,
                timeStop:  Moment().endOf('month').toDate().getTime() * 1000000
              }, ()=>{
                this.dataGet();
              })}>รายเดือน</a>
            </li>
          </ul>

          <ul className="uk-switcher uk-margin uk-text-center">
            <li>
              {this.todayRowRender()}
            </li>
            <li>
              <div className="uk-grid uk-text-left uk-flex-middle">
                <div className="uk-width-1-1 uk-width-auto@s">
                เลือกช่วงวันเริ่มต้น
                </div>
                <div className="uk-width-1-1 uk-width-auto@s">
                  <input className="uk-input" type="date" value={Moment.unix(this.state.timeStart/1000000).format('YYYY-MM-DD')} onChange={(e)=>{
                    this.setState({
                      timeStart: Moment(e.target.value, 'YYYY-MM-DD').unix() * 1000000000,
                      timeStop: Moment(e.target.value, 'YYYY-MM-DD').add(1, 'week').unix() * 1000000000
                    }, this.dataGet
                    );
                  }} />
                </div>
              </div>
              {
                this.graphWeekRender()
              }
            </li>
            <li>
              <div className="uk-grid uk-text-left uk-flex-middle">
                <div className="uk-width-1-1 uk-width-auto@s">
                เลือกเดือน
                </div>
                <div className="uk-width-1-1 uk-width-auto@s">
                  <select value={Moment.unix(this.state.timeStart/1000000000).format('MM')} className="uk-select" onChange={(e)=>{
                    this.setState({
                      timeStart: Moment(`${Moment().format('YYYY')}-${e.target.value}-${Moment().format('DD')}`, 'YYYY-MM-DD').startOf('month').unix() * 1000000000,
                      timeStop: Moment(`${Moment().format('YYYY')}-${e.target.value}-${Moment().format('DD')}`, 'YYYY-MM-DD').endOf('month').unix() * 1000000000
                    }, this.dataGet
                    );
                  }}>
                    <option value="01">มกราคม</option>
                    <option value="02">กุมภาพันธ์</option>
                    <option value="03">มีนาคม</option>
                    <option value="04">เมษายน</option>
                    <option value="05">พฤษภาคม</option>
                    <option value="06">มิถุนายน</option>
                    <option value="07">กรกฎาคม</option>
                    <option value="08">สิงหาคม</option>
                    <option value="09">กันยายน</option>
                    <option value="10">ตุลาคม</option>
                    <option value="11">พฤศจิกายน</option>
                    <option value="12">ธันวาคม</option>
                  </select>
                </div>
              </div>
            {
                this.graphWeekRender()
              }
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
