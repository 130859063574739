import React, { Component } from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import ReactEcharts from 'echarts-for-react';
import Api from '../../Components/Api';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import Formular from '../../Components/Formular.js';
import GroupReport from '../../Components/Formular/GroupReport.js';
import FullScreenElement from '../FullScreenElement';

UIkit.use(Icons);
var ExpanableComponent = ({ data }) => {
  var statusRender = {
    'yellow': <span className="uk-label d-background-yellow">Risk</span>,
    'orange': <span className="uk-label d-background-orange">Risk</span>,
    'green': <span className="uk-label d-background-green">Healthy</span>,
    'red': <span className="uk-label d-background-red">High Risk</span>
  };

  return <ul className="uk-list uk-list-divider uk-padding">
    {data.detail.map((row, index) => {
      return <li key={`row${index}`}>
        <h4 className="uk-text-uppercase uk-margin-remove">{row.name} ({row.value}) {row.detail && row.detail.color && statusRender[row.detail.color]}</h4>
    <div className="uk-text-small">{row.detail.title} {row.detail.caption} / {row.detail.suggest} { row.dateadd ? <span>/ Last Checked {moment(row.dateadd).add('y', 543).format('DD/MM/YY')}</span> : null} </div>
      </li>
    })}
  </ul>
};

export default class UserSmartHealthSmartHealthRecord extends Component {

  state = {
    data: GroupReport(this.props.data) || [],
    selectType: 'general'
  }

  componentDidMount() {
  }

  dateLastGet = (data) => {
    if(!data) data = [];

    var _date = null;
    data.forEach(d=>{
      if(!d.dateadd) return false;

      if(!_date) _date = moment(d.dateadd);

      if(moment(d.dateadd) > _date ) {
        _date = moment(d.dateadd);
      }
    })
    
    return _date ? _date.add('y', 543).format('DD/MM/YY') : '-';
  }


  columns = [
    {
      name: '',
      cell: row => <div>{row.icon === '' ? '' : <div className={`d-risk-logo-container d-risk-${row.color}`} ><img className="d-risk-logo" src={`/assets/${row.icon}.png`} /></div>}</div>,
      sortable: true
    },
    {
      name: 'Category',
      cell: row => <div>
        <div>{row.label}</div>
        <div>{row.color === 'green' ? 'ปกติ' : 'ผิดปกติ'}</div>
      </div>,
      sortable: true
    },
    {
      name: 'Last Checked',
      cell: row => <div>{this.dateLastGet(row.detail)}</div>,
      sortable: true
    }
  ];



  render() {
    return <div className="uk-card uk-card-default uk-padding-small" id="d-user-smarthealth-health-record">
      <div className="d-card-header">
        <div className="d-card-header-left">
          SMART HEALTH RECORDS
        </div>
        <div className="d-card-header-right">
          <span className='icon-reload' onClick={this.props.dataGet}></span>
          <FullScreenElement element="#d-user-smarthealth-health-record" />
        </div>
      </div>
      <div className="uk-margin-top">
        <DataTable
          striped={true}
          columns={this.columns}
          data={this.state.data}
          noHeader={true}
          expandableRows
          expandableRowsComponent={<ExpanableComponent />}
          expandableIcon={{
            collapsed: <span className="d-icon-table"><i uk-icon="icon: plus; ratio: 0.7;"></i></span>,
            expanded: <span className="d-icon-table d-icon-table-minus"><i uk-icon="icon: minus; ratio: 0.7;"></i></span>
          }}
          style={{
            border: '1px solid #E4E7ED'
          }}
        />
      </div>
    </div>
  }
};
