import React, { Component } from "react";
import { Link } from "react-router-dom";
import Api from "../Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import Autocomplete from "react-autocomplete";
import $ from "jquery";
UIkit.use(Icons);

export default class InspectionSchedule extends Component {
  state = {
    consultName: "",
    consultId: "",
    campaignId: "",
    campaignList: [],
    consultList: [],
    list: [],
    timeStartHour: "00",
    timeStartMinute: "00",
    timeStopHour: "00",
    timeStopMinute: "00",
    weekDay: "1",
    appointment_type: "offline"
  };

  campaignById = {};

  consultById = {};

  componentDidMount() {
    this.campaignListGet();

    this.consultListGet();

    this.consultInspectionListGet();
  }
  campaignListGet = async () => {
    var _response = await Api.consult("CampaignList", {campaignId: "all"});

    this.setState({
      campaignList: _response.list || [],
      campaignId: _response.list[0] ? _response.list[0].id : "",
    });
  };

  consultListGet = async () => {
    var _response = await Api.consult("ConsultInCompany");
    this.setState({
      consultList: _response.list || [],
    });
  };

  consultInspectionAdd = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!this.state.consultId) {
      UIkit.notification({
        message: "โปรดเลือกแพทย์",
        status: "danger",
      });

      return;
    }

    var _row = [];

    _row.push({
      weekday: this.state.weekDay,
      timestart: `${this.state.timeStartHour}:${this.state.timeStartMinute}:00`,
      timestop: `${this.state.timeStopHour}:${this.state.timeStopMinute}:00`,
      consultid: this.state.consultId,
      campaignid: this.state.campaignId,
      appointment_type: this.state.appointment_type
    });

    this.consultInspectionSave(_row);
  };

  consultInspectionSave = async (row) => {
    var _response = await Api.consult("M2ConsultInspectionAdd", {
      row,
    });

    UIkit.notification({
      message: _response.responseText,
      status: _response.result === true ? "success" : "danger",
    });

    if (_response.result === true) {
      this.consultInspectionListGet();
    }
  };

  consultInspectionListGet = async () => {
    var _response = await Api.consult("M2ConsultInspectionList");

    this.campaignById = _response.campaignById || {};

    this.consultById = _response.consultById || {};

    this.setState({
      list: _response.list || [],
    });
  };

  consultInspectionRemove = async (id) => {
    if (window.confirm("Confirm remove ?")) {
      var _response = await Api.consult("M2ConsultInspectionRemove", { id });

      UIkit.notification({
        message: _response.responseText,
        status: _response.result === true ? "success" : "danger",
      });

      if (_response.result === true) {
        this.consultInspectionListGet();
      }
    }
  };

  campaignSelectRender = () => {
    var _op = [
      // {label: 'All', value: 'all'},
    ];

    for (var campaign of this.state.campaignList) {
      _op.push({
        label: campaign.name + ` (UID : ${campaign.id})`,
        value: campaign.id,
      });
    }

    return (
      <select
        className="uk-select"
        value={this.state.campaignId}
        onChange={(e) => {
          this.setState({ campaignId: e.target.value });
        }}
      >
        {_op.map(function (o) {
          return (
            <option key={`campaign${o.value}`} value={o.value}>
              {o.label}
            </option>
          );
        })}
      </select>
    );
  };

  appointmentTypeSelectRender = () => {
    var _op = [
      {label: 'Online', value: 'online'},
      {label: 'Offline', value: 'offline'},
    ];
 

    return (
      <select
        className="uk-select"
        value={this.state.appointment_type}
        onChange={(e) => {
          this.setState({ appointment_type: e.target.value });
        }}
      >
        {_op.map(function (o) {
          return (
            <option key={`campaign${o.value}`} value={o.value}>
              {o.label}
            </option>
          );
        })}
      </select>
    );
  };

  weekday = {
    1: "วันจันทร์",
    2: "วันอังคาร",
    3: "วันพุธ",
    4: "วันพฤหัส",
    5: "วันศุกร์",
    6: "วันเสาร์",
    7: "วันอาทิตย์",
  };

  daySelectRender = () => {
    var _op = [];

    for (var key in this.weekday) {
      _op.push({
        label: this.weekday[key],
        value: key,
      });
    }

    return (
      <select
        className="uk-select"
        value={this.state.weekDay}
        onChange={(e) => {
          this.setState({ weekDay: e.target.value });
        }}
      >
        {_op.map(function (o) {
          return (
            <option key={`day${o.value}`} value={o.value}>
              {o.label}
            </option>
          );
        })}
      </select>
    );
  };

  timeSelectRender = (type) => {
    var _hour = [];
    var _minute = [];

    for (var h = 0; h < 24; h++) {
      var h_use = h < 10 ? "0" + h : h;

      _hour.push(
        <option key={`hour${h_use}`} value={h_use}>
          {h_use}
        </option>
      );
    }

    ["00", "15", "30", "45"].forEach(function (m) {
      _minute.push(
        <option key={`minute${m}`} value={m}>
          {m}
        </option>
      );
    });

    return (
      <div className="uk-flex uk-flex-middle">
        <select
          className="uk-select"
          onChange={(e) => {
            if (type === "start") {
              this.setState({ timeStartHour: e.target.value });
            } else {
              this.setState({ timeStopHour: e.target.value });
            }
          }}
        >
          {_hour}
        </select>{" "}
        :
        <select
          className="uk-select"
          onChange={(e) => {
            if (type === "start") {
              this.setState({ timeStartMinute: e.target.value });
            } else {
              this.setState({ timeStopMinute: e.target.value });
            }
          }}
        >
          {_minute}
        </select>{" "}
      </div>
    );
  };

  tableTimeFormar = (d) => {
    var _d = d.split(":");

    return `${_d[0]}:${_d[1]}`;
  };

  tableRender = () => {
    return (
      <table className="uk-table uk-table-small uk-table-divider">
        <thead>
          <tr>
            <th>ลำดับ</th>
            <th>แคมเปญ</th>
            <th>แพทย์</th>
            <th>วัน</th>
            <th>เวลาเริ่ม</th>
            <th>เวลาสิ้นสุด</th>
            <th>ประเภท</th>
            <th>ลบ</th>
          </tr>
        </thead>
        <tbody>
          {this.state.list.length === 0 ? (
            <tr>
              <td colSpan={7}>
                <div className="uk-padding-small uk-text-center">
                  ไม่พบรายการ
                </div>
              </td>
            </tr>
          ) : (
            this.state.list.map((list, index) => {
              console.log(list)
              return (
                <tr key={`row${index}`}>
                  <td>{index + 1}</td>
                  <td>
                    {this.campaignById[list.campaignid]
                      ? this.campaignById[list.campaignid]
                      : list.campaignid} (UID : {list.campaignid})
                  </td>
                  <td>
                    {this.consultById[list.consultid]
                      ? this.consultById[list.consultid]
                      : list.consultid} (UID: {list.consultid})
                  </td>
                  <td>{this.weekday[list.weekday]}</td>
                  <td>{this.tableTimeFormar(list.timestart)}</td>
                  <td>{this.tableTimeFormar(list.timestop)}</td>
                  <td>{list.appointment_type}</td>
                  <td>
                    <a
                      onClick={() => {
                        this.consultInspectionRemove(list.id);
                      }}
                    >
                      <span uk-icon="trash"></span>
                    </a>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    );
  };

  csvJSON(text, quoteChar = '"', delimiter = ",") {
    var rows = text.split("\n");
    var headers = rows[0].split(",");

    var _row = [];

    for (var i = 1; i < rows.length; i++) {
      var _r = rows[i].split(",");

      if (_r.length !== 5) {
        alert("ข้อมูลไฟล์ CSV ผิด");
        return;
      }

      _row.push({
        weekday: _r[0].replace(/(?:\\[rn])+/g, ""),
        timestart: `${_r[1].replace(/(?:\\[rn])+/g, "")}:00`,
        timestop: `${_r[2].replace(/(?:\\[rn])+/g, "")}:00`,
        consultid: _r[3].replace(/(?:\\[rn])+/g, ""),
        campaignid: _r[4].replace(/(?:\\[rn])+/g, ""),
      });
    }

    return _row;
  }

  csvUpload = (event) => {
    var reader = new FileReader();
    reader.readAsText($("#file-csv-upload")[0].files[0]);
    reader.onload = () => {
      var _text = reader.result;

      var _row = this.csvJSON(_text);

      if (_row.length > 0) {
        this.consultInspectionSave(_row);
      } else {
        
      UIkit.notification({
        message: "ไม่พบข้อมูลในไฟล์",
        status: "danger",
      });
      }
    };
  };

  render() {
    return (
      <div className={this.props.paddingRemove === true ? "" : "uk-padding"}>
        <div
          className={
            "uk-card uk-card-default uk-padding-small" +
            (this.props.paddingRemove === true ? "" : " uk-margin-top")
          }
        >
          <div className="d-card-header">
            <div className="d-card-header-left">ตารางออกตรวจแพทย์</div>
            <div className="d-card-header-right">
              <label className="uk-button uk-margin-right d-text-white">
                <input
                  type="file"
                  accept=".csv"
                  id="file-csv-upload"
                  onChange={this.csvUpload}
                  hidden
                />
                  <span uk-icon="cloud-upload" className="uk-margin-small-right"></span>Upload CSV
              </label>
              <button className="uk-button uk-button-primary">เพิ่ม</button>
            </div>
          </div>
          <div>
            <form onSubmit={this.consultInspectionAdd}>
              <div className="uk-grid">
                <div className="uk-width-1-1 uk-width-1-6@m">
                  <label>แพทย์</label>
                  <Autocomplete
                    wrapperStyle={{ width: "100%" }}
                    renderInput={(props) => {
                      return (
                        <input
                          {...props}
                          className="uk-input"
                          placeholder={"ชื่อแพทย์"}
                          value={this.state.consultName}
                          onChange={(e) => {
                            var _u = { consultName: e.target.value };

                            if (!e.target.value) {
                              _u.consultId = "";
                            }

                            this.setState(_u);
                          }}
                        />
                      );
                    }}
                    menuStyle={{
                      fontSize: "18px",
                    }}
                    onSelect={(value, data) => {
                      this.setState({
                        consultId: value,
                        consultName: `${data.nameprefix} ${data.name} ${data.lastname}`,
                      });
                    }}
                    getItemValue={(item) => item.id}
                    items={this.state.consultList.filter(
                      (d) =>
                        d.name.includes(this.state.consultName) ||
                        d.lastname.includes(this.state.consultName)
                    )}
                    onChange={(e) => (this.state.consultName = e.target.value)}
                    renderItem={(item, isHighlighted) => (
                      <div
                        key={item.id}
                        style={{
                          padding: "5px 10px",
                          background: isHighlighted ? "lightgray" : "white",
                          border: "1px solid #CCC"
                        }}
                      >
                        {item.nameprefix} {item.name} {item.lastname} (UID: {item.id})
                      </div>
                    )}
                  />
                </div>
                <div className="uk-width-1-1 uk-width-1-6@m">
                  <label>แคมเปญ</label>
                  {this.campaignSelectRender()}
                </div>
                <div className="uk-width-1-1 uk-width-1-6@m">
                  <label>วัน</label>
                  {this.daySelectRender()}
                </div>
                <div className="uk-width-1-1 uk-width-1-6@m">
                  <label>เวลาเริ่ม</label>
                  {this.timeSelectRender("start")}
                </div>
                <div className="uk-width-1-1 uk-width-1-6@m">
                  <label>เวลาสิ้นสุด</label>
                  {this.timeSelectRender("stop")}
                </div>
                <div className="uk-width-1-1 uk-width-1-6@m">
                  <label>ประเภท</label>
                  {this.appointmentTypeSelectRender()}
                </div>
              </div>
                <div className="uk-text-right uk-margin-top"> 
                  <button className="uk-button uk-button-primary">
                    เพิ่มข้อมูล
                  </button>
                </div>
            </form>
          </div>
          <div className="uk-margin-top">{this.tableRender()}</div>
        </div>
      </div>
    );
  }
}
