import React, { Component } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import HeaderSub from '../../Components/Member/HeaderSub';
import CampaignReportProfileCard from '../../Components/Member/CampaignReportProfileCard';
import CampaignList from '../../Components/Member/CampaignList';
import IconCard from '../../Components/Member/IconCard';
import CampaignActiveUser from '../../Components/Member/CampaignReportActiveUser';
import CampaignReportAvgLoss from '../../Components/Member/CampaignReportAvgLoss';
import CampaignReportActivity from '../../Components/Member/CampaignReportActivity';
import CampaignReportGroupOfUser from '../../Components/Member/CampaignReportGroupOfUser';
import CampaignReportDietzRate from '../../Components/Member/CampaignReportDietzRate';
import CampaignReportAvgBmi from '../../Components/Member/CampaignReportAvgBmi';
import CampaignReportUser from '../../Components/Member/CampaignReportUser';
import CampaignReportChat from '../../Components/Member/CampaignReportChat';
import CampaignReportChatView from '../../Components/Member/CampaignReportChatView';
import CampaignReportAvgBmiLossCard from '../../Components/Member/CampaignReportAvgBmiLossCard';
import Error404 from '../Error404';
UIkit.use(Icons);

export default class CampaignReport extends Component {
  state = {
    error404: false,
    loadingApi: true
  }

  componentDidMount() {
    this.campaignProfileGet();
  }

  componentWillReceiveProps() {
    this.campaignProfileGet();
  }

  campaignProfileGet = async () => {
    var { campaign } = await Api.member('CampaignReportProfileCard', { id: this.props.qs.id });

    if (!campaign && this.props.qs.id) {
      this.setState({ error404: true, loadingApi: false });
    } else {
      this.setState({loadingApi: false });
    }
  }



  render() {
    return <div className="uk-padding">
      {
        this.state.loadingApi ?
          <div className="uk-padding uk-text-center"><span uk-spinner=""></span></div>
          :
          this.state.error404 ?
            <Error404 />
            :
            <div>
              <HeaderSub title="Campaign Report" />
              {
                this.props.qs.id ?
                  <div className="uk-margin-small-top">
                    <CampaignReportProfileCard campaignId={this.props.qs.id} />
                    <div className="uk-margin-top">
                      <div className="uk-child-width-1-4@l uk-child-width-1-2@m uk-child-width-1-1" uk-grid="">
                        <div>
                          <CampaignReportUser id={this.props.qs.id} />
                        </div>
                        <div>
                          <CampaignReportAvgBmi id={this.props.qs.id} />
                        </div>
                        <div>
                          <CampaignReportAvgBmiLossCard id={this.props.qs.id} />
                        </div>
                        <div>
                          <CampaignReportChatView id={this.props.qs.id} />
                        </div>
                      </div>
                    </div>
                    <div className="uk-margin-top">
                      <div uk-grid="" className="uk-child-width-1-1 uk-child-width-1-2@l">
                        <div>
                          <CampaignActiveUser id={this.props.qs.id} />
                        </div>
                        <div>
                          <CampaignReportChat id={this.props.qs.id} />
                        </div>
                        <div>
                          <CampaignReportAvgLoss id={this.props.qs.id} />
                        </div>
                        <div>
                          <CampaignReportActivity id={this.props.qs.id} />
                        </div>
                      </div>
                    </div>
                    <div className="uk-margin-top">
                      <CampaignReportGroupOfUser id={this.props.qs.id} />
                    </div>
                    <div className="uk-margin-top">
                      <div uk-grid="">
                        <div className="uk-width-1-1 uk-width-3-4@l">
                          <CampaignReportDietzRate id={this.props.qs.id} />
                        </div>
                        <div className="uk-width-1-1 uk-width-1-4@l"></div>
                      </div>
                    </div>
                  </div>
                  :
                  <CampaignList />
              }
            </div>

      }



    </div>
  }
};
