var W = {
  'en': {
    'โปรดใส่ชื่อผู้ใช้และรหัสผ่าน': 'Please enter a username and password.',
    'โปรดยอมรับเงื่อนไขและนโยบาย': 'Please accept the terms and policies.',
    'ชื่อผู้ใช้': 'Username',
    'ชื่อผู้ใช้ (ภาษาอังกฤษหรือตัวเลข สามารถใช้เลขบัตรประชาชนได้)': 'Username (English letters or numbers, National ID available)',
    'สถานพยาบาล': 'Hospital',
    'ทั่วไป': 'general',
    'แคมเปญ': 'Campaign',
    'รหัสผ่าน': 'Password',
    'รหัสผ่าน (สามารถใช้วันเดือนปีเกิดเป็นรหัสผ่านได้ เช่น 10082524)': 'Password (Birthdate format available ex. 10081954)',
    'คุณได้อ่านและยอมรับ': 'You have read and agree',
    'เงื่อนไขการใช้บริการ': 'terms of service',
    'ประกาศนโยบายความเป็นส่วนตัว (Privacy Notice)': 'Privacy Notice',
    'และ': 'and',
    'นโยบายความเป็นส่วนตัว': 'privacy policy',
    'เข้าสู่ระบบ': 'Login',
    'ถ้ายังไม่ได้ลงทะเบียน': 'If not registered',
    'ลงทะเบียนที่นี่': 'register here',
    'รูปแบบของอีเมลไม่ถูกต้อง': 'The email format is incorrect.',
    'โปรดใส่ข้อมูลให้ครบ': 'Please complete the information.',
    'โปรดใส่รหัสผ่าน 8 ตัวอักษรหรือมากกว่า': 'Please enter a password of 8 characters or more.',
    'ส่งรหัส OTP ไปยัง ': 'Send OTP to',
    'มีจำนวน 8 ตัว': 'Has 8 characters',
    'เป็นตัวเลขหรือตัวอักษร': 'In numbers or letters',
    'เบอร์โทรศัพท์มือถือ': 'Mobile phone number',
    'สมัครใช้บริการสำหรับคนไข้': 'Register for patient',
    'สมัครสมาชิก': 'Register',
    'หากคุณมีบัญชีกับเราแล้ว': 'If you already have an account with us',
    'ล็อคอินเข้าสู่ระบบ': 'Login to the system',
    'กรุณากรอกรหัส 4 หลัก': 'Enter the 4 digit code.',
    'ที่ท่านได้รับจาก SMS': 'from SMS',
    'ยืนยันสมัครสมาชิก': 'Confirm Register',
    'ยังไม่ได้รับ SMS': "If haven't received the SMS",
    'กดส่งใหม่': 'press here to resend',
    'คำนำหน้าชื่อ': 'Title',
    'ชื่อ': 'Name',
    'นามสกุล': 'Surname',
    'อีเมล': 'Email',
    'เพศ': 'Gender',
    'หญิง': 'Woman',
    'ชาย': 'Man',
    'เลขบัตรประชาชน หรือ เลขบัตรประกันสังคม หรือหมายเลขพาสปอร์ต': 'ID card number or social security card number or passport number',
    'วันเดือนปีเกิด': 'Date of birth',
    'วันที่ เวลา': 'Date time',
    'รูปภาพ': 'Image',
    'เรียกดู': 'Browse',
    'lineID (ุถ้ามี)': 'lineID (If any)',
    'เราขอข้อมูลวันเดือนปีเกิดและเพศ เพื่อที่จะวิเคราะห์ความเสี่ยงสุขภาพจากอายุและเพศของคุณได้ เช่น ความเสี่ยงโรคไต หรือ โรคเบาหวาน เราขอข้อมูลเลขบัตรประชาชน หรือ เลขบัตรประกันสังคม หรือ หมายเลขพาสปอร์ต เพื่อกรณีเชื่อมโยงผลการตรวจสุขภาพในรูปแบบองค์กรโดยจะเก็บข้อมูลเป็นความลับ ไม่เปิดเผยยังหน่วยงานอื่นใด ที่ไม่ได้เกี่ยวข้องกับการรักษาพยาบาลของท่าน': 'We ask for date of birth and gender. In order to analyze your health risks based on your age and gender, such as your risk of kidney disease or diabetes, we ask for your ID number or Social Security number or passport number. In the case of linking the results of the health examination in an organizational form, the information will be kept confidential. not disclosed to any other agencies that are not related to your medical treatment',
    'เสร็จสิ้น': 'Completed',
    'กรณีที่ท่านลงทะเบียนเพื่อใช้งานในโรงพยาบาล หรือ องค์กร กรุณาใส่รหัสเพื่อเข้าใช้งาน(สามารถสอบถามได้ที่พยาบาลหรือฝ่ายทรัพยากรบุคคลของท่าน)': 'If you are registered for use in a hospital or organization, please enter the access code (you can ask your nurse or human resources department).',
    'คุณต้องยอมรับการใช้ข้อมูลส่วนบุคคลบุคคลที่มีความอ่อนไหวเป็นพิเศษ': 'You must agree to the use of particularly sensitive personal information.',
    'ท่านรับทราบและยอมรับ': 'You acknowledge and accept',
    'การใช้ข้อมูลส่วนบุคคลบุคคลที่มีความอ่อนไหวเป็นพิเศษ': 'Use of personal information that is particularly sensitive',
    'รหัสผ่านควรตั้งให้มี 8 ตัวขึ้นไป ประกอบด้วยตัวเลขและตัวอักษรเพื่อความปลอดภัย': 'Password should be set to 8 or more characters and contain numbers and letters for security purposes.',
    'โปรดติดต่อรพ.ของท่านเพื่อสอบถามรหัส': 'Please contact your hospital for the code.',
    'ระยะเวลาแคมเปญ กรณีคนไข้รายบุคคล': 'Campaign period In the case of individual patients',
    ' วัน': ' days',
    "วันที่สมัคร": "Register date",
    "วันที่หมดอายุ": "Expiration date",
    "ค่าใช้จ่าย": "Price",
    "กรุณาชำระค่าบริการก่อนกดสมัครสมาชิก วิธีใดวิธีหนึ่ง": "Please pay the service fee before clicking apply for membership. either way",
    "กรอกรหัสโปรโมชัน": "Enter the promo code",
    "ชำระเงิน": "Pay",
    "ไม่มีค่าใช้จ่าย": "Free",
    "ชำระเงินแล้ว": "Paid",
    "โปรดชำระเงินเพื่อดำเนินการต่อ": "Please pay to continue.",
    "ค.ศ.": "A.D.",
    "สมัครแคมเปญแล้ว": "Campaign registered",
    "กลับหน้าหลัก": "Back to homepage",
    "เลือกแคมเปญ": "Campaign select"
  },
  'jp': {
    'โปรดใส่ชื่อผู้ใช้และรหัสผ่าน' : 'あなたのユーザー名とパスワードを入力してください。',
    'โปรดยอมรับเงื่อนไขและนโยบาย' : '利用規約に同意してください。',
    'ชื่อผู้ใช้' : 'ユーザー名',
    'ชื่อผู้ใช้ (ภาษาอังกฤษหรือตัวเลข สามารถใช้เลขบัตรประชาชนได้)' : 'ユーザー名（ローマ字または数字パスポート番号利用可）',
    'สถานพยาบาล' : '医療機関名',
    'ทั่วไป' : '一般的な',
    'แคมเปญ' : 'キャンペーン',
    'รหัสผ่าน' : 'パスワード',
    'รหัสผ่าน (สามารถใช้วันเดือนปีเกิดเป็นรหัสผ่านได้ เช่น 10082524)' : 'パスワード (生年月日など例:10082524)',
    'คุณได้อ่านและยอมรับ' : '読んで同意しました',
    'เงื่อนไขการใช้บริการ' : '利用規約',
    'ประกาศนโยบายความเป็นส่วนตัว (Privacy Notice)' : 'プライバシーポリシーを確認し、同意する',
    'และ' : 'and',
    'นโยบายความเป็นส่วนตัว' : 'プライバシーポリシー',
    'เข้าสู่ระบบ' : 'ログイン',
    'ถ้ายังไม่ได้ลงทะเบียน': 'まだ登録していない場合',
    'ลงทะเบียนที่นี่' : 'ここに登録',
    'รูปแบบของอีเมลไม่ถูกต้อง' : 'メールの形式が間違っています。',
    'โปรดใส่ข้อมูลให้ครบ' : '完全な情報を入力してください。',
    'โปรดใส่รหัสผ่าน 8 ตัวอักษรหรือมากกว่า' : 'パスワードは8文字以上で入力してください。',
    'ส่งรหัส OTP ไปยัง ' : 'OTP認証番号は○○○○に送信されました',
    'มีจำนวน 8 ตัว' : '8文字',
    'เป็นตัวเลขหรือตัวอักษร' : '数字またはローマ字',
    'เบอร์โทรศัพท์มือถือ': '電話番号',
    'สมัครใช้บริการสำหรับคนไข้' : '患者様向けサービスお申し込みフォーム',
    'สมัครสมาชิก' : '登録する',
    'หากคุณมีบัญชีกับเราแล้ว' : 'すでにアカウントをお持ちの場合',
    'ล็อคอินเข้าสู่ระบบ' : 'システムにログインします',
    'กรุณากรอกรหัส 4 หลัก' : 'SMSで届いた4桁の',
    'ที่ท่านได้รับจาก SMS': '認証番号を入力して下さい',
    'ยืนยันสมัครสมาชิก' : '認証する',
    'ยังไม่ได้รับ SMS' : "認証番号が届かなかった方へ",
    'กดส่งใหม่' : '承認番号を再送する',
    'คำนำหน้าชื่อ' : '敬称',
    'ชื่อ' : '名',
    'นามสกุล' : '苗字',
    'อีเมล' : 'Eメール',
    'เพศ' : '性別',
    'หญิง' : '女性',
    'ชาย' : '男性',
    'รหัสบริษัท' : '会社番号',
    'รหัสแคมเปญ' : 'キャンペーン番号',
    'เลขบัตรประชาชน หรือ เลขบัตรประกันสังคม หรือหมายเลขพาสปอร์ต' : '国民 ID カード番号または社会保障カード番号 またはパスポート番号',
    'วันเดือนปีเกิด' : '生年月日（仏歴）',
    'วันที่ เวลา' : 'Date time',
    'รูปภาพ' : 'Image',
    'เรียกดู' : 'Browse',
    'lineID (ุถ้ามี)' : 'lineID (If any)',
    'เราขอข้อมูลวันเดือนปีเกิดและเพศ เพื่อที่จะวิเคราะห์ความเสี่ยงสุขภาพจากอายุและเพศของคุณได้ เช่น ความเสี่ยงโรคไต หรือ โรคเบาหวาน เราขอข้อมูลเลขบัตรประชาชน หรือ เลขบัตรประกันสังคม หรือ หมายเลขพาสปอร์ต เพื่อกรณีเชื่อมโยงผลการตรวจสุขภาพในรูปแบบองค์กรโดยจะเก็บข้อมูลเป็นความลับ ไม่เปิดเผยยังหน่วยงานอื่นใด ที่ไม่ได้เกี่ยวข้องกับการรักษาพยาบาลของท่าน' : ' 生年月日及び性別は年齢や性別によるリスク（腎臓病、糖尿病等）分析するためです。ご入力いただいた個人情報は医療サービスの提供のみご利用いたします。 ',
    'เสร็จสิ้น' : 'Completed',
    'กรณีที่ท่านลงทะเบียนเพื่อใช้งานในโรงพยาบาล หรือ องค์กร กรุณาใส่รหัสเพื่อเข้าใช้งาน(สามารถสอบถามได้ที่พยาบาลหรือฝ่ายทรัพยากรบุคคลของท่าน)': '*総合病院または社内利用の場合、担当看護師または人事担当と確認し、会社番号とキャンペーン番号をご入力ください*',
    'คุณต้องยอมรับการใช้ข้อมูลส่วนบุคคลบุคคลที่มีความอ่อนไหวเป็นพิเศษ': '個人情報の取扱いについて同意する',
    'ท่านรับทราบและยอมรับ' : 'You acknowledge and accept',
    'การใช้ข้อมูลส่วนบุคคลบุคคลที่มีความอ่อนไหวเป็นพิเศษ' : 'Use of personal information that is particularly sensitive',
    'รหัสผ่านควรตั้งให้มี 8 ตัวขึ้นไป ประกอบด้วยตัวเลขและตัวอักษรเพื่อความปลอดภัย' : 'Password should be set to 8 or more characters and contain numbers and letters for security purposes.',
    'โปรดติดต่อรพ.ของท่านเพื่อสอบถามรหัส' : 'Please contact your hospital for the code.',
    'ระยะเวลาแคมเปญ กรณีคนไข้รายบุคคล' : 'キャンペーン期間 個人の患者様の場合',
    'ระยะเวลาแคมเปญ' : 'キャンペーン期間',
    'วัน' : ' 日',
    "วันที่สมัคร" : "申込み日",
    "วันที่หมดอายุ" : "有効期限",
    "ค่าใช้จ่าย" : "Price",
    "กรุณาชำระค่าบริการก่อนกดสมัครสมาชิก วิธีใดวิธีหนึ่ง" : "Please pay the service fee before clicking apply for membership. either way",
    "กรอกรหัสโปรโมชัน" : "Enter the promo code",
    "ชำระเงิน" : "Pay",
    "ไม่มีค่าใช้จ่าย" : "Free",
    "ชำระเงินแล้ว" : "Paid",
    "โปรดชำระเงินเพื่อดำเนินการต่อ" : "Please pay to continue.",
    "ค.ศ." : "A.D.",
    "สมัครแคมเปญแล้ว" : "Campaign registered",
    "กลับหน้าหลัก" : "Back to homepage",
    "เลือกแคมเปญ" : "Campaign select",
    "รายการตรวจที่สำคัญ" : "重要なチェックリスト",
    "ค่าที่ตรวจ 3 ครั้งล่าสุด" : "最後にチェックした 3 つの値",
    "ส่งแบบประเมินวันที่" : "評価フォームを送信してください",
    "จบการรักษาแล้ว" : "治療終了",
    "เวลา" : "時間",
    "น." : "",
    "โปรดรอจนข้อความโหลดเสร็จสิ้นและลองอีกครั้ง" : "メッセージの読み込みが完了するまで待って、もう一度お試しください。",
    "ออนไลน์" : "online",
    "แพคเกจหมดอายุแล้ววันที่" : "パッケージの有効期限切れ日",
    "ยังไม่มีการสนทนา" : "まだ会話はありません",
    "คลิกที่นี่เพื่อเริ่มสนทนา" : "会話を始めるにはここをクリックしてください",
    "ไม่พบรายการ" : "アイテムが見つかりません",
    "ดูเพิ่มเติม" : "続きを見る",
    "วีดีโอคอล" : "ビデオ通話",
    "ไม่พบข้อความ" : "メッセージが見つかりません",
    "โปรดใส่ข้อมูลให้ครบ" : "完全な情報を入力してください。",
    "มีการเรียกระบบวีดีโอคอล" : "ビデオ通話システムあります。",
    "มีการเรียกระบบวีดีโอคอล กดลิงค์ เพื่อเข้าสู่หน้าวีดีโอคอล" : "ビデオ通話システムが呼び出されています。リンクを押してビデオ通話ページに入ります。",
    "ความดันโลหิต" : "血圧",
    "โปรดใส่ข้อมูลน้ำหนัก" : "体重情報を入力してください",
    "น้ำหนัก" : "重さ",
    "โปรดใส่ข้อมูลน้ำตาลในเลือด" : "血糖値情報を入力してください。",
    "น้ำตาลในเลือด" : "血糖値",
    "เวลาที่ตรวจ" : "検査時間",
    "การชำระเงิน" : "支払い",
    "อาการโควิด-19" : "COVID-19 症状",
    "ได้ไปในพื้นที่เสี่ยงโควิด 19" : "新型コロナウイルス感染症の危険地域に行ったことがある",
    "ได้ใกล้ชิดกลุ่มเสี่ยงโควิด 19" : "新型コロナウイルス感染症のリスクグループに近づく",
    "ผลตรวจ ATK" : "ATKテストの結果",
    "ออกซิเจนในเลือด" : "血中酸素",
    "อัตราการเต้นหัวใจ" : "心拍数",
    "อาการผิดปกติทางร่างกาย" : "身体症状",
    "โปรดใส่ข้อมูลให้ครบ" : "完全な情報を入力してください。",
    "ข้อมูลก่อนพบแพทย์" : "医師の診察を受ける前の情報",
    "อาการ" : "症状",
    "ระยะเวลาที่เป็น" : "どれくらいの",
    "ส่วนสูง" : "身長",
    "โรคประจำตัว" : "先天性疾患",
    "ประวัติแพ้ยา" : "お薬のアレルギー",
    "วิธีการจัดส่งยา" : "お薬送付方法",
    "ที่อยู่" : "住所",
    "เซนติเมตร" : " cm",
    "กิโลกรัม" : " kg",
    "ต้องการใบรับรองแพทย์" : "診断書が必要です",
    "ไม่ต้องการ" : "必要はありません",
    "ซอย" : "ソイ",
    "ถนน" : "通り番号",
    "ตำบล" : "町村",
    "อำเภอ" : "都",
    "จังหวัด" : "都県",
    "รหัสไปรษณีย์" : "郵便番号",
    "บ้านเลขที่" : "番地",
    "ที่อยู่จัดส่งยา" : "医薬品の配送先住所",
    "พิกัด" : "座標",
    "สับสน กระสับกระส่าย ที่เพิ่งเกิดขึ้น" : "混乱している、落ち着かない それはちょうど起こった",
    "ซึม แต่เรียกแล้วลืมตา" : "取り乱したが、電話して目を開けた。",
    "ซึมมาก ต้องกระตุ้น จึงลืมตา" : "私はとても落ち込んでいて、目を開けるには刺激が必要でした。",
    "ไม่รู้สึกตัว แม้กระตุ้นแล้ว" : "刺激を受けた後でも無意識",
    "หายใจ" : "息をする",
    "ใส่เครื่องช่วยหายใจ" : "人工呼吸器をつける",
    "ชีพจร" : "脈",
    "ครั้ง/นาที" : "回/分",
    "ความดันโลหิต (ค่าบน)" : "血圧（上限値）",
    "ใช้ยากระตุ้นความดันโลหิต" : "血圧を刺激する薬を使用する",
    "ความรู้สึกตัว" : "意識",
    "ข้อความ" : "メッセージ",
    "บันทึก" : "記録",
    "แนบรูป" : "添付画像",
    "ส่งไฟล์" : "ファイルを送信",
    "ส่งรูปอาหาร" : "食べ物の写真を送る",
    "ชื่ออาหาร" : "食品名",
    "อธิบายรายละเอียดอาหารโดยละเอียด เช่น ข้าวสวย 1 ถ้วย ทุเรียน 1 พู" : "ご飯 1 カップ、ドリアン 1 個など、食べ物を詳しく説明します。",
    "ตัวบน" : "上半身",
    "ตัวล่าง" : "下のほう",
    "ก่อนนอน" : "寝る前に",
    "ตื่นนอน" : "起きろ",
    "ไอ" : "咳",
    "เจ็บคอ" : "喉の痛み",
    "มีน้ำมูก" : "粘液がある",
    "มีเสมหะ" : "痰が出る",
    "หายใจเหนื่อยหรือลำบาก" : "疲れたり呼吸困難になったりする",
    "แสบคอ,คอแห้ง" : "喉の痛み、喉の乾燥",
    "ได้กลิ่นลดลง" : "臭いが減りました。",
    "หอบเหนื่อย" : "あえぐ",
    "ปวดกล้ามเนื้อ" : "筋肉痛",
    "ปวดศีรษะ" : "頭痛",
    "คลื่นไส้ อาเจียน" : "吐き気・嘔吐",
    "ส่งรูปผลตรวจ ATK (ถ้ามี)" : "ATK テスト結果の写真を送信します（ある場合）",
    "ผลตรวจ ATK" : "ATKテストの結果",
    "ปกติ" : "普通",
    "ผิดปกติ" : "異常な",
    "ตรวจเมื่อ" : "いつ確認するか",
    "ส่วนสูง" : "身長",
    "อาการผิดปกติทางร่างกาย" : "身体症状",
    "ไม่มีอาการ" : "症状なし",
    "มีอาการ" : "症状がある",
    "ไปในพื้นที่เสี่ยง" : "危険な場所に行く",
    "ใกล้ชิดกลุ่มเสี่ยง" : "リスクグループに近い",
    "ก่อนอาหาร" : "食事の前に",
    "หลังอาหาร" : "食事の後",
    "หลังอาหาร 1 ชั่วโมง" : "食後1時間",
    "หลังอาหาร 2 ชั่วโมง" : "食後2時間",
    "เช้า" : "朝",
    "อาหารว่างเช้า" : "朝のおやつ",
    "กลางวัน" : "昼間",
    "อาหารว่างบ่าย" : "午後のおやつ",
    "เย็น" : "いいね",
    "กลางคืน" : "夜間",
    "รายการตรวจที่สำคัญ" : "重要なチェックリスト",
    "ค่าที่ตรวจ 3 ครั้งล่าสุด" : "最後にチェックした 3 つの値",
    "ส่งแบบประเมินวันที่" : "評価フォームを送信してください",
    "แพคเกจหมดอายุแล้ววันที่" : "パッケージの有効期限切れ日",
    "เลือกเวลาตรวจ" : "検査時間を選択してください",
    "ไขมัน" : "脂肪",
    "วันที่/เวลา" : "日付時刻",
    "มื้ออาหาร" : "食事",
    "ค่าน้ำตาล" : "糖度",
    "ยาฉีดอินซูลิน" : "インスリン注射",
    "บันทึกอาหาร" : "食事記録",
    "มื้อหลัก" : "主食",
    "มื้อว่าง" : "スナック",
    "หมายเหตุ" : "注記",
    "ช่องทาง" : "チャネル",
    "นัดหมายเพื่อ" : "予約をする",
    "นัดหมายสำหรับ" : "予約をする",
    "ส่งคำร้องการจอง" : "予約リクエストを送信する",
    "ยอดเงิน(บาท)" : "金額(THB)",
    "ชื่อ-นามสกุล ผู้บันทึก" : "記録者の姓名",
    "เลขผู้ป่วย" : "患者番号",
    "ลบรูปภาพ" : "写真を削除する",
    "กรุณาบันทึกเลขใบสั่งยาของสถานพยาบาลหรือเลือกออกใบสั่งยาจากระบบไดเอทซ์ เลือกอย่างใดอย่างหนึ่ง" : "病院の処方箋番号を記録するか、Dietz システムから処方箋を発行することを選択してください。 1 つ選択してください",
    "ใช้ใบสั่งยาของสถานพยาบาล กรุณาระบุเลขใบสั่งยา กรณีใบสั่งยาหลายใบส่งในคราวเดียวกันให้คั่นด้วย" : "病院の処方箋を利用する 処方箋番号をご指定ください。 複数の処方箋を同時にお送りする場合は分けてお送りください。",
    "เลขที่ใบสั่งยาคั่นด้วย" : "処方箋番号は次のように区切られています。",
    "ออกใบสั่งยาจากระบบไดเอทซ์" : "食事療法システムから処方箋を発行する",
    "ถัดไป" : "次",
    "เหลืองใส" : "クリアイエロー",
    "เหลืองขุ่น" : "濁った黄色",
    "แดง" : "赤",
    "อื่นๆ" : "他の",
    "รอบที่" : "ラウンド",
    "ความเข้มข้นน้ำยา" : "溶液濃度",
    "เติมน้ำยาเข้า" : "溶液を加えます。",
    "ปล่อยน้ำยาออก" : "ソリューションをリリースする",
    "(น้ำเข้า-น้ำออก)" : "(水の流入 - 水の流出)",
    "ปริมาตรรวมสุทธิ" : "正味総量",
    "ลักษณะน้ำยา" : "液体の特性",
    "(ก.ก.)" : "(kg.)",
    "(ปัสสาวะ (ซีซี))" : "(尿(cc))",
    "เวลาเริ่ม" : "始まる時間",
    "เวลาหมด" : "時間がなくなりつつあります。",
    "ปริมาตร" : "音量",
    "เพิ่มข้อมูลใหม่" : "新しい情報を追加する",
    "ดูประวัติ" : "履歴を見る",
    "ส่งรูปภาพแผล" : "傷の写真を送る",
    "ดูภาพแผลเปรียบเทียบ" : "傷の比較写真をご覧ください。",
    "ส่งรูป" : "写真を送信する",
    "บริเวณที่เป็น" : "エリアは",
    "รายละเอียด" : "詳細",
    "มีอาการ ระบุ" : "特定の症状がある",
    "มีอาการปวดบริเวณแผล" : "傷の部分に痛みがあります。",
    "แผลบวมอักเสบ" : "腫れて炎症を起こした傷",
    "แผลซึมหรือแผลมีหนอง" : "傷口がにじみ出ているか、傷口が膿んでいます。",
    "แผลผ่าตัดแยกออก" : "分離された手術創",
    "อธิบายรายละเอียด:" : "詳細を説明する",
    "คำแนะนำ" : "アドバイス",
    "วันที่/เวลาที่แนะนำ" : "おすすめの日時",
    "ลักษณะอาการ" : "症状",
    "โรคประจำตัว (ถ้ามี)" : "持病・既往症（有れば）",
    "ประวัติการแพ้ยา (ถ้ามี)" : "薬物アレルギーの既往歴（ある場合）",
    "ใบรับรองแพทย์" : "診断書",
    "ต้องการ" : "必要",
    "ไม่ต้องการ" : "不要",
    "ผู้ป่วยโควิด" : "新型コロナウイルス患者",
    "ไม่ใช่ผู้ป่วยโควิด" : "新型コロナウイルス患者ではない",
    "ภาพบัตรประชาชนถ่ายคู่กับใบหน้าผู้ป่วย ใช้สำหรับยืนยันการเข้ารับบริการ" : "患者の顔が写ったIDカードの写真 サービスへの参加を確認するために使用されます。",
    "ภาพประกอบ" : "図",
    "แนบรูป ATK พร้อมเขียนชื่อกำกับบน ATK" : "ATKの写真を添付し​z​、ATKに名前を記入してください。",
    "(ถ้ามี)" : "(あれば)",
    "ข้าพเจ้ายืนยันว่าข้อมูลเอกสารที่แนบข้างต้นทั้งหมดเป็นความจริง" : "私は、上記の添付文書のすべての情報が真実であることを確認します。",
    "โปรดยืนยันว่าข้อมูลในเอกสารเป็นความจริง" : "文書内の情報が真実であることを確認してください。",
    "หายใจ" : "息をする",
    "ใส่เครื่องช่วยหายใจ" : "人工呼吸器をつける",
    "ตื่นดี พูดคุยรู้เรื่อง" : "起きて話してください。",
    "สับสน กระสับกระส่าย ที่เพิ่งเกิดขึ้น" : "混乱している、落ち着かない それはちょうど起こった",
    "ซึม แต่เรียกแล้วลืมตา" : "取り乱したが、電話して目を開けた。",
    "ซึมมาก ต้องกระตุ้น จึงลืมตา" : "私はとても落ち込んでいて、目を開けるには刺激が必要でした。",
    "ไม่รู้สึกตัว แม้กระตุ้นแล้ว" : "刺激後も意識不明",
    "ตารางน้ำตาลเบาหวานชนิดที่ 1" : "1型糖尿病の糖質表",
    "คาร์บ" : "炭水化物",
    "ยาฉีด" : "注射薬",
    "ตารางน้ำตาลเบาหวานชนิดที่ 2" : "2 型糖尿病の糖質表",
    "ตารางน้ำตาลเบาหวานตั้งครรภ์" : "妊娠中の糖尿病の糖質量表",
    "ทั้งหมด" : "全て",
    "ยังไม่ได้อ่าน" : "まだ読んでいません。",
    "ยังไม่มีการสนทนา" : "まだ会話はありません。",
    "ค้นหา" : "検索",
    "ใช้ภาษา" : "言語を使用する",
    "โรงพยาบาล" : "病院",
    "การศึกษา" : "勉強",
    "ความชำนาญเฉพะทาง" : "専門的な専門知識",
    "ประวัติ" : "記録",
    "เวลาและเงื่อนไขการให้บริการ" : "サービスの時間と条件",
    "ให้คำแนะนำผ่านแชท" : "チャットでアドバイスを与える",
    "วอยส์คอล" : "音声電話",
    "ไม่มีค่าใช้จ่าย" : "費用はかかりません。",
    "ค่าใช้จ่ายครั้งละ" : "1回あたりの費用",
    "บาท" : "THB",
    "รักษาแผล" : "傷を癒やす",
    "อาการโควิด-19" : "COVID-19 症状",
    "ค่าน้ำตาลปลายนิ้ว" : "指先の血糖値",
    "น้ำหนัก/รอบเอว" : "体重/胴囲",
    "เฉพาะผู้ป่วยเบาหวาน" : "糖尿病患者様限定",
    "น้ำยาล้างไต" : "腎臓透析液",
    "ข้อมูลก่อนพบแพทย์" : "医師の診察を受ける前の情報",
    "ข้อมูลสำหรับจัดส่งยา" : "医薬品の配送に関する情報",
    "สร้างเสริมสุขภาพ" : "健康増進",
    "คู่มือวีดีโอใช้งานสร้างเสริมสุขภาพ" : "健康増進のための動画マニュアル",
    "ดูข้อความก่อนหน้า" : "前のメッセージを表示",
    "รูปภาพ" : "写真",
    "วีดีโอคอล" : "ビデオ通話",
    "ไฟล์" : "ファイル",
    "สร้างนัด" : "予定を作成する",
    "ใบสั่งยา" : "処方箋",
    "บันทึกการดูแล" : "介護記録",
    "โปรดกรอกที่อยู่สำหรับจัดส่งยาเพื่อดำเนินการต่อ" : "続行するには、薬の配送先住所を入力してください。",
    "โปรดใส่ข้อมูลก่อนพบแพทย์" : "医師の診察を受ける前にこの情報を入力してください。",
    "โปรดใส่แบบประเมินตนเองก่อนพบแพทย์" : "医師の診察を受ける前に、自己評価フォームにご記入ください",
    "กดพบคนไข้" : "患者に会うために押してください。",
    "กดปุ่ม" : "ボタンを押してください",
    "กดพบหมอ" : "医師の診察を受けるには押してください。",
    "เพื่อเลือกบุคลากรทางการแพทย์ที่ท่านต้องการ" : "希望する医療従事者を選択するには",
    "รหัสผ่านควรตั้งให้มี 8 ตัวขึ้นไป ประกอบด้วยตัวเลขและตัวอักษรเพื่อความปลอดภัย" : "パスワードはセキュリティのため数字と文字を組み合わせた8文字以上にしてください。",
    "รหัสผ่านไม่ตรงกัน" : "パスワードが一致しません",
    "ลืมชื่อผู้ใช้หรือรหัสผ่าน" : "ユーザネームもしくはパスワードを忘れましたか？",
    "เลขบัตรประชาชน หรือ เลขบัตรประกันสังคม" : "国民 ID カード番号または社会保障カード番号 またはパスポート番号",
    "หมายเลขโทรศัพท์มือถือ" : "携帯電話番号",
    "วันเดือนปีเกิด" : "生年月日",
    "ส่งข้อความ" : "メッセージを送ります",
    "ตั้งรหัสผ่านใหม่" : "新しいパスワードを設定する",
    "จัดส่งชื่อผู้ใช้และรหัสผ่าน" : "ユーザー名とパスワードを送信する",
    "ใบสั่งยาของฉัน" : "処方箋",
    "ใบเสร็จของฉัน" : "領収書",
    "ตารางนัด" : "アポイント",
    "สรุปผลสุขภาพ" : "健康レポート",
    "สมุดสุขภาพ" : "健康記録手帳",
    "แบบสำรวจความพึงพอใจ" : "満足度調査",
    "แชท/วิดีโอคอล/บันทึกสุขภาพ" : "チャット / ビデオ通話 / 健康記録手帳",
    "ระดับน้ำตาลในเลือดขณะอดอาหาร (FBS)" : "空腹時血糖値(FBS)",
    "รูปแบบ" : "表記形式",
    "ตั้งแต่วันที่" : "いつから",
    "ถึงวันที่" : "いつまで",
    "RISK METRIC" : "リスク表示",
    "YOUR TRENDING RISK" : "あなたのリスク",
    "All" : "全て",
    "Healthy" : "健康",
    "Risk" : "リスクあり",
    "High Risk" : "高リスク",
    "Profile Overview" : "プロフィール",
    "Personal Informatiom" : "個人情報",
    "Account Information" : "アカウント情報",
    "Change Password" : "パスワード変更",
    "Privacy policy" : "プライバシーポリシー",
    "Logout" : "ログアウト",
    "โรคอ้วนลงพุง" : "肥満症",
    "โรคความดันโลหิตสูง" : "高血圧",
    "โรคไขมันในเลือดสูง" : "高脂血症",
    "โรคเบาหวาน" : "糖尿病",
    "โรคไต" : "腎臓病",
    "กรดยูริค" : "尿酸値",
    "การทำงานของตับ" : "肝機能",
    "เอกซ์เรย์ปอด" : "胸部レントゲン",
    "กลุ่มโรค" : "病気一覧",
    "คลิกที่นี่เพือเริ่มสนทนา" : "チャット開始",
    "ยังไม่ได้อ่าน" : "未読",
    "ยังไม่มีการสนทนา" : "チャット歴なし",
    "โปรดกรอกที่อยู่สำหรับจัดส่งยาเพื่อดำเนินการต่อ" : "お薬の送付先住所をご入力して「保存」を押してください。",
    "ละติจูด" : "緯度：",
    "ลองจิจูด" : "経度：",
    "โปรดใส่ข้อมูลก่อนพบแพทย์" : "受診する前に下記の内容をご入力ください。",
    "ลักษณะอาการ" : "症状：",
    "อาการที่ต้องการปรึกษา" : "相談内容（症状の詳細など）",
    "เป็นมาแล้วกี่วัน" : "症状が現れた日数",
    "ภาพบัตรประชาชนถ่ายคู่กับใบหน้าผู้ป่วย ใช้สำหรับยืนยันการเข้ารับบริการ" : "ご利用するために、パスポートをご本人の顔の横に持ち、写真を撮って添付してください。",
    "ภาพประกอบ 2 แนบรูป ATK พร้อมเขียนชื่อกำกับบน ATK (ถ้ามี)" : "ATK検査結果（ATKキットに名前付き）の写真（ある場合）",
    "ภาพประกอบ 3 (ถ้ามี)" : "その他の写真③",
    "ภาพประกอบ 4 (ถ้ามี)" : "その他の写真④",
    "ภาพประกอบ 5 (ถ้ามี)" : "その他の写真⑤",
    "ข้่พเจ้ายืนยันว่าข้อมูลเอกสารที่แนบข้างต้นทั้งหมดเป็นความจริง" : "上記の記載と写真は事実と相違ないことを証明します。",
    "แบบสำรวจความพึงพอใจ" : "アンケート",
    "ด้านแอพพลิเคชัน" : "アプリについて",
    "1.ความสะดวกในการล็อกอินใช้ระบบ" : "1.ログインの分かりやすさ",
    "2.ระบบออกแบบให้ใช้งานง่ายเมนูไม่ซับซ้อน" : "2.システムの分かりやすさ",
    "3.ความสะดวกในการสนทนาผ่านระบบ" : "3.アプリによる通話の快適さ",
    "4.ความมั่นใจเรื่องการรักษาความลับ" : "4.個人情報取り扱いの信頼性",
    "ด้านบริการ" : "サービス",
    "1.แบบประเมินก่อนพบแพทย์ครบถ้านเหมาะสมเข้าใจง่าย" : "1.受診前のアンケートの分かりやすさ",
    "2.ขั้นตอนการรับบริการเข้าใจง่าย" : "2.サービス利用手順の分かりやすさ",
    "3.การให้คำปรึกษาและคำแนะนำของเจ้าหน้าที่บุคลากร" : "3.スタッフのアドバイスの適切さ",
    "4.ประหยัดค่าใช้จ่าย/ค่าเดินทางการมาโรงพยาบาล" : "4.交通費による削減度合い",
    "5.การใช้งานระบบ Telehealth ในภาพรวม" : "5.オンライン診療の総合的な満足度",
    "หากท่านมีโอกาสได้ใช้งานระบบอีกครั้ง ท่านจะ" : "また、ご利用する機会がございましたら、",
    "ใช้" : "利用したい",
    "ไม่ใช้" : "分からない",
    "ไม่แน่ใจ" : "分からない",
    "ข้อเสนอแนะ/ข้อปรับปรุง" : "その他の意見・改善点",
    "เลือกช่วงวันที่เริ่มต้น" : "開始日を選択",
    "ก้าว" : "歩",
    "ครั้งต่อนาที" : "1分間",
    "แคลอรี่" : "カロリー",
    "ก้าวเดิน" : "歩数",
    "ชีพจรเฉลี่ย" : "平均心拍数",
    "อัตราการเผาผลาญพลังงาน" : "エネルギー代謝率",
    "ตารางสรุปผลสุขภาพ" : "健康レポート",
    "ตารางนัดหมาย" : "予約表",
    "ไม่พบข้อมูล" : "データがありません",
    "ที่" : "順番",
    "วันที่" : "日付",
    "ชื่อคนไข้" : "患者名",
    "ผู้นัดหมาย" : "予約担当",
    "นัดหมายเพื่อ" : "相談内容",
    "ช่องทาง" : "ルート（チャット？通話？）",
    "หมายเหตุ" : "備考",
    "ใบเสร็จของฉัน" : "私の領収書",
    "ประเภทใบเสร็จ" : "領収書の種類",
    "ยอดเงิน" : "金額",
    "ดาวน์โหลดใบเสร็จ" : "領収書をダウンロードする",
    "ลืมชื่อผู้ใช้หรือรหัสผ่าน กดที่นี่" : "ユーザー名またはパスワードを忘れた場合は、ここをクリックしてください。",
    "รหัสผ่านผิด" : "間違ったパスワード",
    "จำกัดจำนวนการแสดงผลแชทไว้ที่ 100 คน หากต้องการค้นหาคนไข้กรุณาพิมพ์ชื่อคนไข้แล้วกดปุ่ม ค้นหา" : "チャットは100件まで表示されます。 患者様を検索する場合は患者名を入力して「検索」ボタンを押してください",
    "ค้นหา" : "検索",
    "Chat" : "チャット",
    "Video call" : "ビデオ通話",
    "Video Call" : "ビデオ通話",
    "All" : "全て",
    "แพทย์" : "医師​",
    "ดูประวัติการแชท" : "チャット履歴",
    "Name" : "名前",
    "Company" : "会社",
    "Group" : "グループ",
    "Campaign" : "キャンペーン",
    "อายุ" : "年齢",
    "เพศ" : "性別",
    "โรคประจำตัว" : "持病",
    "เลขบัตรประชาชน" : "パスポート番号",
    "หมายเลขโทรศัพท์" : "電話番号",
    "ที่อยู่จัดส่งยา" : "お薬の配達先",
    "แพ้ยา" : "お薬のアレルギー​",
    "Chat History" : "チャット記録",
    "ดูประวัติการแชท" : "チャット履歴​",
    "ข้อความ" : "メッセージ",
    "มีการเรียกระบบวีดีโอคอล กดลิงค์ เพื่อเข้าสู่หน้าวีดีโอคอล" : "ビデオ通話システムが開始されました。長押ししてビデオ通話ページに入ります。"
  }
}

var LANGUSE = null;


export default (word) => {

  if(LANGUSE === null)
    LANGUSE = localStorage.getItem('language') || 'th';

  if (!W[LANGUSE] || !W[LANGUSE][word]) {
    return word;
  } else {

    if (LANGUSE === 'th') {
      return word
    } else {
      return W[LANGUSE][word];
    }

  }
}
