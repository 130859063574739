import React, { Component } from "react";
import moment from "moment";
import $ from "jquery";

import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min.js";
import "eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css";

export default class ChatBoxMessageSumbitDialysisType2Render extends Component {
  state = {
    date: moment().format("YYYY-MM-DD HH:mm"),
    morning_before : '',
    morning_med : '',
    lunch_before : '',
    lunch_med : '',
    dinner_before : '',
    dinner_med : '',
  }

  componentDidMount() {

    this.datepickerRender()
  }

  datepickerRender = () => {
    var _this = this;

    $("#d-dialysis-type2-datepicker")
    .on("dp.hide", function (e) {
      
      _this.setState({
        date : e.date.format("YYYY-MM-DD HH:mm")
      });
    })
    .datetimepicker({
      format: "DD/MM/YYYY HH:mm",
      defaultDate: moment(),
      keepOpen: true
    });
  }

  

  render() {
    return <div>
      <div>
      <div className="uk-text-small">วันที่</div>
      <div class='input-group date uk-margin-small-top uk-width-medium' id='d-dialysis-type2-datepicker'>
          <input type='text' class="form-control" />
          <span class="input-group-addon">
          <span class="glyphicon glyphicon-calendar"></span>
          </span>
        </div>
      </div>
      <div className="uk-text-small uk-margin-small-top">ตารางน้ำตาลเบาหวานชนิดที่ 2</div>
      <div className="uk-overflow-auto uk-margin-small-top">
        <table className="uk-table uk-table-small uk-table-divider d-table-diabates-data">
          <thead>
            <tr>
              <th colSpan={2}>มื้อเช้า</th>
              <th colSpan={2}>มื้อเที่ยง</th>
              <th colSpan={2}>มื้อเย็น</th>
            </tr>
            <tr>
              <th>ก่อน</th>
              <th>ยาฉีด</th>
              <th>ก่อน</th>
              <th>ยาฉีด</th>
              <th>ก่อน</th>
              <th>ยาฉีด</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><input type="text" className="uk-input uk-text-center" value={this.state.morning_before} onChange={(e)=>this.setState({morning_before: e.target.value})} /></td>
              <td><input type="text" className="uk-input uk-text-center" value={this.state.morning_med} onChange={(e)=>this.setState({morning_med: e.target.value})} /></td>
              <td><input type="text" className="uk-input uk-text-center" value={this.state.lunch_before} onChange={(e)=>this.setState({lunch_before: e.target.value})} /></td>
              <td><input type="text" className="uk-input uk-text-center" value={this.state.lunch_med} onChange={(e)=>this.setState({lunch_med: e.target.value})} /></td>
              <td><input type="text" className="uk-input uk-text-center" value={this.state.dinner_before} onChange={(e)=>this.setState({dinner_before: e.target.value})} /></td>
              <td><input type="text" className="uk-input uk-text-center" value={this.state.dinner_med} onChange={(e)=>this.setState({dinner_med: e.target.value})} /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="uk-text-right uk-margin-top">
      {
        this.props.messageListSending === true ?
        <div><span uk-spinner=""></span></div>
          :
        <button type="button" className="uk-button uk-button-primary" onClick={async ()=>{
          await this.props.callback(this.state);

          this.setState({
            morning_before : '',
            morning_med : '',
            lunch_before : '',
            lunch_med : '',
            dinner_before : '',
            dinner_med : '',
          })
        }}>
          บันทึก
        </button>
      }
      </div>
    </div>
  }
}