import React, { Component } from "react";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import { Link } from "react-router-dom";
import Api from "./Api.js";
import moment from "moment";
import numeral from "numeral";

export default class CampaignRenew extends Component {
  state = {
    showPopup: this.props.show || false,
    campaign: null,
    paymentType: '1',
    promocode: '',
  };

  qs = {}

  campaignId = null

  memberCampaignRenew = this.props.memberCampaignRenew

  memberCampaignId = this.props.memberCampaignId;

  dateStop = null

  componentDidMount() {
    this.init();

    this.qs = Api.queryString;

    if(this.qs && this.qs.refno) {
      this.campaignRenew();
    }
  }

  componentWillReceiveProps() {
    this.init();
  }

  init = () => {
    if(this.props.memberCampaignId == null || this.props.memberCampaignRenew == null) {
      this.memberCampaignGet();
    } else {
      this.campaignDetailGet();
    }

    
  };

  memberCampaignGet =  async () => {
    var _respone = await Api[this.props.apiType]("MemberCampaignId", {
      campaignId: this.props.campaignId,
    });

    if(_respone.data != null) {

      this.memberCampaignRenew = _respone.data.renew;
      this.memberCampaignId = _respone.data.id;
      this.dateStop = _respone.data.dateStop
      this.campaignDetailGet();
    }

  }

  campaignDetailGet = async () => {
    if(this.campaignId === this.props.campaignId) return;

    this.campaignId = this.props.campaignId;

    var _resposnse = await Api[this.props.apiType]("CampaignById", {
      id: this.props.campaignId,
    });
    

    this.setState({ campaign: _resposnse.data });
  };


  paymentForm = () => {
    var _refno = '5' + (this.memberCampaignRenew + '00' + this.memberCampaignId).toString().padStart(9, 0);

    return <form
      method="post"
      action="https://www.thaiepay.com/epaylink/payment.aspx"
      target="_self"
      id="d-register-pay-form"
    >
      <input
        type="hidden"
        name="refno"
        value={_refno}
      />
      <input type="hidden" name="merchantid" value="17332033" />
      <input
        type="hidden"
        name="customeremail"
        value="renew@dietz.asia"
      />
      <input type="hidden" name="cc" value="00" />
      <input
        type="hidden"
        name="productdetail"
        value={
          `ต่ออายุแคมเปญรหัส ${this.memberCampaignId} เลขที่อ้างอิง ${_refno}`
        }
      />
      <input
        type="hidden"
        name="total"
        value={numeral(this.state.campaign.price*1.07).format("0.00")}
      />
      <input type="hidden" name="returnurl" value={`https://smarthealth.dietz.asia/user?refno=${_refno}`}></input>
      <button className="uk-button uk-button-primary uk-margin-top uk-width-1-1" type="submit">ต่ออายุ</button>
    </form>
  }

  campaignRenew = async () => {
    var _response = await Api[this.props.apiType]("CampaignRenew", {
      id: this.memberCampaignId,
      refno: this.qs ? this.qs.refno : '',
      promocode: this.state.promocode
    });

    if(_response.result) {
      this.props.closePopup(true);

      if(this.qs.refno) {
        setTimeout(() => {
          if(this.props.apiType === 'member') {
            window.location.href = "/user";
          }

        }, 1000);
      }
    }

    UIkit.notification({message: _response.responseText});

  }

  campaignDetailCardRender = () => {
    return <div>
      
      <div className="uk-text-right">
            <a uk-icon="close" onClick={this.props.closePopup}></a>
          </div>
          {this.state.campaign == null ? (
            <div className="uk-padding-small uk-text-center">ไม่พบรายการ</div>
          ) : (
            <div>
              <h4>แพคเกจของท่านหมดอายุแล้ววันที {moment(this.dateStop).format('DD/MM/YYYY')}</h4>
              <label>ระยะเวลาแคมเปญ กรณีคนไข้รายบุคคล</label>
              <input type="text" disabled="disable" className="uk-input uk-text-center" value={this.state.campaign.day + ' วัน'} />
              <label className="uk-margin-small-top">วันที่สมัคร</label>
              <input type="text" disabled="disable" className="uk-input uk-text-center" value={moment().add(543, 'year').format('DD/MM/YYYY')} />
              <label className="uk-margin-small-top">วันที่แคมเปญหมดอายุ</label>
              <input type="text" disabled="disable" className="uk-input uk-text-center" value={moment().add(this.state.campaign.day , 'day').add(543, 'year').format('DD/MM/YYYY')} />
              <label className="uk-margin-small-top">ค่าใช้จ่าย (รวมภาษีมูลค่าเพิ่ม 7%)</label>
                <input
                  type="text"
                  className="uk-input uk-text-center"
                  disabled="disabled"
                  value={this.state.campaign.pay === "1" ? numeral(this.state.campaign.price*1.07).format('0,0.00') + ' ฿' : 'ไม่มีค่าใช้จ่าย'}
                />
              {
                this.state.campaign.pay === "1"  && <div>
                <div className="uk-margin-small-top">
                  <div className="uk-margin-top uk-text-small">
                    กรุณาชำระค่าบริการก่อนกดสมัครสมาชิก วิธีใดวิธีหนึ่ง
                  </div>
                  <div className=" uk-margin-small-top">
                    <label className="uk-width-1-1">
                      <input type="radio" name="payment-type" checked={this.state.paymentType === '0' ? true : false} onChange={()=>this.setState({paymentType: '0'})} /> กรอกรหัสโปรโมชัน{" "}
                      <input type="text" className="uk-input uk-text-center uk-margin-small-top"
                        value={this.state.promocode}
                        onChange={(e) =>
                          this.setState({ promocode: e.target.value })
                        } />
                    </label>
                  </div>
                  <div>
                    <label>
                      <input type="radio" name="payment-type" checked={this.state.paymentType === '1' ? true : false} onChange={()=>this.setState({paymentType: '1'})} /> ชำระเงิน
                    </label>
                  </div>
                </div>
              </div>
              }
              {
                this.state.paymentType === '1' && (+this.state.campaign.price*1.07) > 0 ? this.paymentForm() :
                <button className="uk-button uk-button-primary uk-margin-top uk-width-1-1" onClick={this.campaignRenew}>ต่ออายุ</button>
              }
              
            </div>
          )}
    </div>
  }

  render() {
    return ( this.props.show === true && (
      this.props.mode === 'form' ? 
      this.campaignDetailCardRender()
      :
      <div className="d-popup-background">
        <div className="d-popup-body d-popup-body-small"> {
          this.campaignDetailCardRender()
        }
        </div>
      </div>
    )
    
    );
  }
}
