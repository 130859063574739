import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import AdminRoute from './Components/Admin/Route';
import MemberRoute from './Components/Member/Route';
import ConsultRoute from './Components/Consult/Route';
import DashboardRoute from './DietzDashboard/Route.js';
import PrescriptionFile from './Components/PrescriptionFile.js';
import invoiceDetail from './Pages/Member/InvoiceDetail.js';
import ModeratorRoute from './Components/Moderator/Route.js';

if(!localStorage.getItem('language')) localStorage.setItem('language', 'th');

function App() {

  var routes = [
    {
      path: "/prescription-file",
      component: PrescriptionFile
    },
    {
      path: "/invoiceDetail",
      component: invoiceDetail
    },
    {
      path: "/admin",
      component: AdminRoute
    },
    {
      path: "/consult",
      component: ConsultRoute
    },
    {
      path: "/dietz-dashboard",
      component: DashboardRoute
    },
    {
      path: '/moderator',
      component : ModeratorRoute
    },
    {
      path: "/",
      component: window.location.hostname === 'consult.dietz.asia' ?  ConsultRoute : MemberRoute
    },
  ];


  return (
    <Router>
      <Switch>
        {routes.map((route, i) => {
          return <Route
            key={i}
            path={route.path}
            render={props => ( 
              <route.component {...props}  />
            )}
          />
        })}
      </Switch>
    </Router>
  );
}

export default App;
