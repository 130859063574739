import Alp from './Formular/Alp';
import BloodPressure from './Formular/BloodPressure';
import Bmi from './Formular/Bmi';
import Bun from './Formular/Bun';
import Chol from './Formular/Chol';
import Creatinine from './Formular/Creatinine';
import Egfr from './Formular/Egfr';
import FbsFpg from './Formular/FbsFpg';
import Hba1c from './Formular/Hba1c';
import Hdl from './Formular/Hdl';
import Ldl from './Formular/Ldl';
import Sgot from './Formular/Sgot';
import Sgpt from './Formular/Sgpt';
import Tg from './Formular/Tg';
import Uric from './Formular/Uric';
import WaistLine from './Formular/WaistLine';
import Lung from './Formular/Lung';

export default {
  'alp': Alp,
  'sys/dias': BloodPressure,
  'bmi': Bmi,
  'bun': Bun,
  'chol': Chol,
  'creatinine': Creatinine,
  'egfr': Egfr,
  'fbs/fpg': FbsFpg,
  'fbs': FbsFpg,
  'hba1c': Hba1c,
  'hdl': Hdl,
  'ldl': Ldl,
  'sgot': Sgot,
  'sgpt': Sgpt,
  'tg': Tg,
  'uric': Uric,
  'waistline': WaistLine,
  'lung': Lung
}