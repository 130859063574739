import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Api from '../../Components/Api.js';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import numeral from 'numeral';
import ReactPaginate from 'react-paginate';

UIkit.use(Icons);

export default class PackageList extends Component {
  state = {
    list: [],
    loadingApi: false,
    pageNow: 1,
    pageAmount: 1,
    perPage: 10
  }

  listGet = async  () => {
    this.setState({loadingApi: true});

    var _response = await Api.consult("M2PackageList", {
      page: this.state.pageNow
    });
    this.setState({
      list: _response.list || [],
      loadingApi: false,
      pageAmount: _response.pageAmount || 1
    });
  }

  
  handlePageClick = (page) => {
    this.setState({pageNow: page.selected + 1}, ()=> this.listGet())
  }

  paginateRender = () => {
    return <div className="uk-text-right">
      <ReactPaginate
        className={'uk-padding-remove'}
        previousLabel={'previous'}
        nextLabel={'next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={this.state.pageAmount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={this.handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      />
    </div>
  }

  componentDidMount() {
    this.listGet();
  } 

  render() {
    return <div className="uk-padding">
      <div className="uk-card uk-card-default uk-padding-small">
        <div className="d-card-header">
          <div uk-grid="">
            <div className='uk-width-1-2@m uk-width-1-1'>รายการแพ็คเกจ</div>
            <div className='uk-width-1-2@m uk-width-1-1 uk-text-right'>
              <a href={'/consult/packageAdd'} >
              <button onClick={()=>this.packageAdd} className='uk-button uk-button-primary'>เพิ่มแพ็คเกจ</button>
              </a>
            </div>
          </div>
        </div>
        <div className="uk-overflow-auto">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
            <thead>
              <tr>
                <th>ที่</th>
                <th>ชื่อแพ็คเกจ</th>
                <th>ราคา</th>
                <th>ใช้งาน</th>
                <th>แก้ไข</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.loadingApi === true ?
                <tr>
                  <td colSpan="100%">
                    <div className="uk-padding-small uk-text-center"><span uk-spinner=""></span></div>
                  </td>
                </tr>
                :
                this.state.list.length === 0 ? 
                <tr>
                  <td colSpan="100%">
                    <div className="uk-padding-small uk-text-center">ไม่พบข้อมูล</div>
                  </td>
                </tr>
                :

                this.state.list.map((data, key)=>{
 

                  var _price = numeral(data.price).format('0,0.00');

                  if(data.price_type === 'multi') {
                    try {
                      var _price_array = JSON.parse(decodeURIComponent(data.price_array));
   
                      var _price_low = Infinity;
                      var _price_high = 0;

                      for(var a of _price_array) {
                        if(parseFloat(a.price) < _price_low) {
                          _price_low = a.price;
                        }

                        if(parseFloat(a.price) > _price_high) {
                          _price_high = a.price;
                        }
                      }

                      if(_price_low === Infinity) {
                        _price_low = 0;
                      }
                      
                      _price = `${numeral(_price_low).format('0,0.00')} - ${numeral(_price_high).format('0,0.00')}`;
                    } catch(e) {
                      console.error(e);
                    }
                  }

                  return <tr key={`row${data.id}`}>
                    <td>{(this.state.perPage * (this.state.pageNow-1 ) + key + 1)}</td>
                    <td>
                      {data.title}
                    </td>
                    <td>
                    ฿ {_price}
                    </td>
                    <td>{data.publish === "1" ? <span uk-icon="icon:check"></span> : ''}
                    </td>
                    <td>
                      <a target="_blank" href={`/consult/packageAdd?id=${data.id}`}><span uk-icon="pencil"></span></a>
                    </td>
                  </tr>
                })
              }
            </tbody>
          </table>
          <div>
            {this.paginateRender()}
          </div>
          </div>
      </div>
    </div>
  }
};
