
import React, { Component } from 'react';
import ImageUri from '../ImageUri';
import Api from "../Api"
import firebase from "firebase";
import moment from 'moment';



export default class Diabetes extends Component {

  state = {
    loadingApi: false,
    list: [],
    dateStart: moment().subtract(3, 'month').format('YYYY-MM-DD'),
    dateStop: moment().format('YYYY-MM-DD'),
    keywordPatient: ''
  }

  componentDidMount() {
    this.diabetesGet();
  }

  diabetesGet = async () => {
    this.setState({loadingApi: true});

    var _response = await Api.consult("DiabetesList", {
      dateStart: this.state.dateStart,
      dateStop: this.state.dateStop,
      keywordPatient: this.state.keywordPatient
    });


    var _data = {};

    if(_response.data) {
      _response.data.forEach((member) => {
        if(member.lab) {
          member.lab.forEach((lab)=>{

            if(_data[lab.dateadd] == null) {
              _data[lab.dateadd] = {
                member: `${member.nameprefix} ${member.name} ${member.lastname}`
              };
            }
    
            _data[lab.dateadd][lab.note] = lab.value;
    
          })
        }
      })
    }

    //
    var _data_use = [];

    for(var datetime in _data) {
      if(_data[datetime]['หลังอาหารกลางวัน 2 ชั่วโมง'] != null) {

        _data[datetime].date = datetime;
        _data_use.push(_data[datetime])
      }
    }

    this.setState({
      list: _data_use,
      loadingApi: false
    });
  };

  render() {
    return <div className="uk-padding">
      <div className="uk-card uk-card-default uk-padding-small">
        <div className="d-card-header">
          <div className="d-card-header-left">ตารางน้ำตาลเบาหวานขณะตั้งครรภ์</div>
          <div className="d-card-header-right"></div>
        </div>
        <div className='d-card-body'>
          <div className='uk-grid'>
            <div className="uk-width-expand@s uk-width-1-1 uk-margin-small-bottom">
              <div className="uk-grid uk-grid-small uk-flex-middle">
                <div className="uk-width-1-6">
                ค้นหาจาก ชื่อ-สกุล
                </div>
                <div className="uk-width-expand">
                  <input type="text" className="uk-input" value={this.state.keywordPatient} onChange={(e)=>this.setState({keywordPatient: e.target.value})} placeholder="ชื่อ-สกุล" />
                </div>
              </div>
            </div>
            <div className="uk-width-expand@s uk-width-1-1 uk-margin-small-bottom">
              <div className="uk-grid uk-grid-small uk-flex-middle">
                <div className="uk-width-1-6">
                  วันที่
                </div>
                <div className="uk-width-expand">
                  <input type="date" className="uk-input" value={this.state.dateStart} onChange={(e)=>this.setState({dateStart: e.target.value})} />
                </div>
              </div>
            </div>
            <div className="uk-width-expand@s uk-width-1-1 uk-margin-small-bottom">
              <div className="uk-grid uk-grid-small uk-flex-middle">
                <div className="uk-width-1-6">
                  ถึง
                </div>
                <div className="uk-width-expand">
                  <input type="date" className="uk-input" value={this.state.dateStop} onChange={(e)=>this.setState({dateStop: e.target.value})} />
                </div>
              </div>
            </div>
            <div className="uk-width-auto@s uk-width-1-1 uk-margin-small-bottom uk-text-right">
              <button className="uk-button uk-button-primary" onClick={this.diabetesGet}>ค้นหา</button>
            </div>
          </div>
          <div className='uk-overflow-auto'>
            <table className='uk-table uk-table-divider uk-table-striped'>
              <thead>
                <tr>
                  <th rowSpan={2}>วันที่</th>
                  <th rowSpan={2}>ชื่อ-นามสกุล</th>
                  <th colSpan={3}>ก่อนรับประทานอาหาร</th>
                  <th colSpan={3}>หลังรับประทานอาหาร 1 ชั่วโมง</th>
                  <th colSpan={3}>หลังรับประทานอาหาร 2 ชั่วโมง</th>
                </tr>
                <tr>
                  <th>เช้า</th>
                  <th>เที่ยง</th>
                  <th>เย็น</th>
                  <th>เช้า</th>
                  <th>เที่ยง</th>
                  <th>เย็น</th>
                  <th>เช้า</th>
                  <th>เที่ยง</th>
                  <th>เย็น</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.loadingApi === true ?

                  <div className='uk-padding'><span uk-spinner=""></span></div>

                  :
                  this.state.list.length === 0 ?

                  <tr>
                    <td colSpan={'100%'}>
                      <div className='uk-padding uk-text-center'>ไม่พบข้อมูล</div>
                    </td>
                  </tr>
                :
                
                  this.state.list.map((data)=>{
                    return <tr>
                    <td>{moment().format('DD/MM/YY HH:mm')}</td>
                    <td>{data.member}</td>
                    <td>{data['ก่อนอาหาร,เช้า']}</td>
                    <td>{data['หลังอาหารเช้า 1 ชั่วโมง']}</td>
                    <td>{data['หลังอาหารเช้า 2 ชั่วโมง']}</td>
                    <td>{data['ก่อนอาหาร,กลางวัน']}</td>
                    <td>{data['หลังอาหารกลางวัน 1 ชั่วโมง']}</td>
                    <td>{data['หลังอาหารกลางวัน 2 ชั่วโมง']}</td>
                    <td>{data['ก่อนอาหาร,เย็น']}</td>
                    <td>{data['หลังอาหารเย็น 1 ชั่วโมง']}</td>
                    <td>{data['หลังอาหารเย็น 2 ชั่วโมง']}</td>
                  </tr>
                  })

                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  }
    

}
