import React, { Component } from "react";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import Api from "./Api";
import Prescription from "../Components/Consult/Prescription.js";
import moment from "moment";
import UploadFirebase from "./UploadFirebase.js";
import numeral from "numeral";
import $ from "jquery";
import ImageUri from "./ImageUri";

UIkit.use(Icons);

export default class PrescriptionList extends Component {
  state = {
    lodingList: false,
    list: [],
    memberId: null,
    campaignId: null,
    prescriptionShow: false,
    prescription_id: null,
    prescriptionSelect: {},
    prescription_status: null,
    shippingPopupShow: false,
    filePopupShow: false,
    fileIndex: null,
    file: {
      name: '',
      file: '',
      note: ''
    },

    filterData : {
      name: '',
      hn: '',
      no: '',
      prescription_type: '',
      status: '',
      date_start: moment().subtract(3, 'month').format("YYYY-MM-DD"),
      date_stop: moment().format("YYYY-MM-DD")
    },
    shippingData : {
      trackingcode: "",
      trackingurl: "",
      shipping: "ไปรษณีย์ลงทะเบียน",
      note: ""
    }
  };

  prescription_data = null;

  componentDidMount() {
    this.listGet();
  }

  dataUpdate = () => {
    this.setState({updateData: !this.state.updateData});
  }

  listGet = async () => {
    this.setState({loadingList: true});
    var _apiType = "member";

    if (window.location.pathname.indexOf("consult/") > 0) {
      _apiType = "consult";
    }

    var _result = await Api[_apiType]("PrescriptionList", this.state.filterData);
 

    if (!_result.data) _result.data = [];

    _result.data.forEach(function (data) {
      try {
        data.content = JSON.parse(data.content);
      } catch (e) {
        console.log(data)
        console.log(e)
      }
    });

    this.setState({
      list: _result.data,
      loadingList: false
    });
  };

  prescriptionSet = (data, callback) => {
    this.setState(
      {
        memberId: data.memberid,
        prescription_id: data.id,
        campaignId: data.campaignid,
      },
      () => {
        callback();
      }
    );
  };

  prescriptionSave = async (data, status, files) => {
    var _data = {
      content: JSON.stringify(data.content),
      memberId: this.state.memberId,
      id: this.state.prescription_id,
    };

    if (status) _data.status = status;

    if (files) _data.files = JSON.stringify(files);

    var _response = await Api.consult("PrescriptionAdd", _data);

    UIkit.notification({ message: _response.responseText });
  };

  shareToChat = async(data, message) => {

    var _status = data.content.option.status;
    
    var _option = '';
    if(_status === 'รอคนไข้ยืนยัน' || _status === 'คนไข้ยันยันแล้ว รอการชำระเงิน') {
      _option = `<div data-prescription="${_status}" data-prescription-id="${data.id}"></div>`;
    }

    if (!message)
      message = `ใบสั่งยาเลขที่ : <a target="_blank" rel="noopenner" href="/prescription-file?print=true&token=${data.token_file}"><b>${
        data.content.option.id
      }</b></a>\nสถานะใบสั่งยา : <b>${_status}</b> \nวันที่ : ${moment().format(
        "DD/MM/YYYY HH:mm"
      )}${_option}`;

    var _response = await Api.consult("ChatMessageAdd", {
      consultId: data.consultid,
      memberId: data.memberid,
      campaignId: data.campaignid,
      typeMessage: "text",
      memberRead: 0,
      consultRead: 1,
      message,
      chatby: "c",
    });

    if(_response.result === true)
    UIkit.notification({message: "แชร์ใบสั่งยาไปยังแชทแล้ว"})
    
  }

  status = [
    "ยกเลิกใบสั่งยา",
    "รอคนไข้ยืนยัน",
    "คนไข้ยันยันแล้ว รอการชำระเงิน",
    "ชำระเงินแล้ว รอยืนยันการชำระเงิน",
    "ตรวจสอบการชำระเงินแล้ว รอจัดส่งยา",
    "ชำระเงินแล้ว รอจัดส่งยา",
    "จัดส่งยาแล้ว",
    "ได้รับยาแล้ว ",
  ];

  statusUpdate = async (status, message) => {

    this.prescription_data.content.option.status = status;

    this.prescriptionSave(this.prescription_data, status);

    this.shareToChat(this.prescription_data, message);

    this.setState({ shippingPopupShow: false });
  };

  statusConfirmRender = async (status) => {
    switch (status) {
      case "จัดส่งยาแล้ว":
        this.setState({ shippingPopupShow: true });
        break;
      default:
        if (window.confirm("ยืนยันการเปลี่ยนสถานะ : " + status)) {
          this.statusUpdate(status);
        }
        break;
    }

    this.forceUpdate();
  };

  statusSelectRender = (data) => {
    return (
      <select
        className="uk-select"
        value={data.content.option.status}
        onChange={(e) => {
          var _value = e.target.value;

          this.prescription_data = data;

          this.setState(
            {
              memberId: data.memberid,
              prescription_id: data.id,
              campaignId: data.campaignid,
              prescriptionSelect : data,
            },
            () => {
              this.statusConfirmRender(_value);
            }
          );
        }}
      >
        {this.status.map((status) => {
          return (
            <option key={encodeURIComponent(status)} value={status}>
              {status}
            </option>
          );
        })}
      </select>
    );
  };

  shippingType = [
    "ไปรษณีย์ลงทะเบียน",
    "EMS",
    "พนักงานจัดส่ง",
    "รถควบคุมอุณหภูมิ",
    "อื่นๆ",
  ];

  shippingDataUpdate = (key, value) => {
    this.setState((state)=>{
      state.shippingData[key] = value;
      return {shippingData: state.shippingData}
    })
  }

  shippingPopupRender = () => {
    var _status = "จัดส่งยาแล้ว";


    return (
      <div className="d-popup-background">
        <div className="d-popup-body d-popup-body-small">
          <h1 className="uk-h4"><b>ใบสั่งยาเลขที่ : </b>{this.state.prescription_id}</h1>
          <div><b>สถานะ :</b> {_status}</div>
          <div className="uk-margin-small-top">
            <label>วิธีการจัดส่ง
            <select
              className="uk-select"
              value={this.state.shippingData.shipping}
              onChange={(e) => {
                this.shippingDataUpdate('shipping', e.target.value);
              }}
            >
              {this.shippingType.map((shipping) => {
                return (
                  <option key={shipping} value={shipping}>
                    {shipping}
                  </option>
                );
              })}
            </select>
            </label>
          </div>
          <div className="uk-margin-small-top">
            <label>เลข Tracking :{" "}
            <input
              type="text"
              placeholder="เลข Tracking"
              className="uk-input"
              value={this.state.shippingData.trackingcode}
              onChange={(e) => {
                this.shippingDataUpdate('trackingcode', e.target.value);
              }}
            /></label>
          </div>
          <div className="uk-margin-small-top">
            <label>ลิงค์ Tracking :{" "}
            <input
              type="text"
              placeholder="ลิงค์ Tracking"
              className="uk-input"
              value={this.state.shippingData.trackingurl}
              onChange={(e) => {
                this.shippingDataUpdate('trackingurl', e.target.value);
              }}
            />
            </label>
          </div>
          <div className="uk-margin-small-top">
          <label>หมายเหตุ :
            <textarea
              placeholder="หมายเหตุ"
              className="uk-textarea"
              value={this.state.shippingData.note}
              onChange={(e) => {
                this.shippingDataUpdate('note', e.target.value);
              }}
            /></label>
          </div>
          <div className="uk-margin-small-top uk-text-right">
            <button
              className="uk-button uk-button-default"
              onClick={() => {
                this.setState({ shippingPopupShow: false });
              }}
            >
              ยกเลิก
            </button>
            <button
              className="uk-button uk-button-primary uk-margin-left"
              onClick={() => {
                
                this.statusUpdate(
                  _status,
                  `ใบสั่งยาเลขที่ : <a target="_blank" rel="noopenner" href="/prescription-file?print=true&token=${this.state.prescriptionSelect.token || this.state.prescriptionSelect.token_file}"><b>${
                    this.state.prescriptionSelect.content.option.id
      }</b></a>\nสถานะใบสั่งยา : <b>${_status}</b> \nวันที่ : ${moment().format(
                    "DD/MM/YYYY HH:mm"
                  )} \nวิธีการจัดส่ง : ${this.state.shippingData.shipping}\nเลข Tracking : ${
                    this.state.shippingData.trackingcode || "-"
                  }\nลิงค์ Tracking : ${this.state.shippingData.trackingurl || "-"}\nหมายเหตุ : ${this.state.shippingData.note || "-"}`
                );
              }}
            >
              ยืนยัน
            </button>
          </div>
        </div>
      </div>
    );
  };

  fileSave = () => {
    if (!Array.isArray(this.prescription_data.files)) {
      this.prescription_data.files = [];
    }


    if(this.state.fileIndex == null) {
      this.prescription_data.files.push(JSON.parse(JSON.stringify(this.state.file)));
    } else {

      this.prescription_data.files[this.state.fileIndex] = JSON.parse(JSON.stringify(this.state.file));
    }

    this.prescriptionSave(
      this.prescription_data,
      false,
      this.prescription_data.files
    );

    this.setState({ filePopupShow: false });
  };

  fileValueUpdate = (key, value) => {
    this.setState((state)=>{
      state.file[key] = value;

      return {file: state.file};
    })
  }

  filePopupRender = () => {
    return (
      <div className="d-popup-background">
        <div className="d-popup-body d-popup-body-small">
          <h1 className="uk-h4">แนบเอกสาร</h1>
          <div>
            <label> ชื่อไฟล์
            <input
              className="uk-input"
              list="fileType"
              type="text"
              placeholder="ชื่อไฟล์"
              value={this.state.file.name}
              onChange={(e) => {
                this.fileValueUpdate('name', e.target.value);
              }}
            />
            <datalist id="fileType">
              <option value="ไฟล์หลักฐานการชำระเงิน"></option>
              <option value="ไฟล์ใบเคลมประกัน"></option>
              <option value="ไฟล์ใบเสร็จรับเงิน"></option>
              <option value="ไฟล์ใบรับรองแพทย์"></option>
              <option value="อื่นๆ"></option>
            </datalist>
            </label>
          </div>
          <div className="uk-margin-small-top">
            {this.state.file.file && <div uk-lightbox="">
              <a href={ImageUri(this.state.file.file)} target="_blank" rel="noopenner" ><img src={ImageUri(this.state.file.file)} uk-img className="uk-margin-small-bottom" /></a>
            </div>}
            <label className="d-submit-food-upload-button uk-button uk-button-default uk-width-1-1">
              แนบไฟล์ (รูปภาพ/pdf)
              <UploadFirebase
                maxWidth={1000}
                maxHeight={1000}
                accept={"application/pdf,image/*"}
                cb={(file) => {
                  console.log(file)
                  this.fileValueUpdate('file', file);
                }}
              />
            </label>
          </div>
          <div className="uk-margin-small-top">
            <label>
              หมายเหตุ
            <textarea
              className="uk-textarea"
              placeholder="หมายเหตุ"
              value={this.state.file.note}
              onChange={(e) => {
                this.fileValueUpdate('note', e.target.value);
              }}
            />
            </label>
          </div>
          <div className="uk-margin-small-top uk-text-right">
            <button
              className="uk-button uk-button-small uk-button-default"
              onClick={() => {
                this.setState({ filePopupShow: false });
              }}
            >
              ยกเลิก
            </button>
            <button className="uk-button uk-button-small uk-button-primary uk-margin-small-left" onClick={() => this.fileSave()}>บันทึก</button>
          </div>
        </div>
      </div>
    );
  };

  Duplicate = async (id) => {
    var _response = await Api.consult("PrescriptionDuplicate", {
      id
    });

    if(_response.result === true) {
      this.listGet();
    }

    UIkit.notification(_response.responseText);
  }

  Remove = async (data) => {
    if(window.confirm("ยืนยันการลบใบสั่งยา" + data.content.option.customer_name + " ?")) {
      var _response = await Api.consult("PrescriptionRemove", {
        id: data.id
      });
  
      if(_response.result === true) {
        this.listGet();
      }
  
      UIkit.notification(_response.responseText);

    }

  }

  PrintMail = (data) => {
    $("#d-print-container").remove();

    $("#root").prepend(
      $('<div id="d-print-container">').html(`<div style="border: 1px solid #000; padding: 20px; border-radius: 10px; display: inline-block;">
        <div style="font-size: 18px;"><strong>ถึง :</strong> ${data.content.option.customer_name}</div>
        <div style="font-size: 16px;">${data.content.option.customer_address}</div>
        <div style="font-size: 16px;">${data.content.option.customer_postcode}</div>
      </div>
      `)
    );

    window.print();

    $("#d-print-container").remove();
  }

  PrintShipping = async (data) => {
    if(data.purchase_dealer === 'shippop' && data.purchase_id && data.trackingcode) {
      var _response = await Api.consult("PrescriptionLabelGet", {
        purchaseId: data.purchase_id,
        trackingCode: data.trackingcode,
        dealer: data.purchase_dealer
      });

      if(_response.data && _response.data.status === true) {
        var win = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=297,height=210");

        win.document.body.innerHTML = _response.data.html.replace(/src="\/\//g, 'src="https://');
      } else {
        UIkit.notification("ไม่พบข้อมูลสำหรับพิมพ์ใบปะหน้าของรายการนี้")
        
      }

    } else {
      UIkit.notification("ไม่พบข้อมูลสำหรับพิมพ์ใบปะหน้าของรายการนี้")
    }
  }

  filterDataUpdate = (key, value) => {
    this.setState((state)=>{
      state.filterData[key] = value;
      return {filterData: state.filterData};
    })
  }

  render() {
    return (
      <div className="uk-padding">
        {this.state.shippingPopupShow && this.shippingPopupRender()}
        {this.state.prescriptionShow && (
          <Prescription
            memberId={this.state.memberId}
            id={this.state.prescription_id}
            campaignId={this.state.campaignId}
            closeCallback={() => {

              this.setState({ prescriptionShow: false }, ()=> {this.listGet()});
            }}
          />
        )}
        {this.state.filePopupShow && this.filePopupRender()}
        <div className="uk-card uk-card-default uk-padding-small">
          <div className="d-card-header">
            <div className="d-card-header-left">รายการใบสั่งยา</div>
            <div className="d-card-header-right"></div>
          </div>
          <div>
            <div className="uk-grid-small" uk-grid="">
              <div className="uk-width-1-1 uk-width-1-2@m">
                <label>ค้นหาจากชื่อหรือนามสกุลคนไข้</label>
                <input className="uk-input" type="text" placeholder="ค้นหาจากชื่อหรือนามสกุลคนไข้" value={this.state.filterData.name} onChange={(e)=>{
                  this.filterDataUpdate('name', e.target.value);
                }} />
              </div>
              <div className="uk-width-1-1 uk-width-1-2@m">
                <label>ค้นหาจากเลข HN</label>
                <input className="uk-input" type="text" placeholder="ค้นหาจากเลข HN" value={this.state.filterData.hn} onChange={(e)=>{
                  this.filterDataUpdate('hn', e.target.value);
                }} />
              </div>
              <div className="uk-width-1-1 uk-width-1-3@m">
                <label>ค้นหาจากเลขที่เอกสาร</label>
                <input className="uk-input" type="text" placeholder="ค้นหาจากเลขที่เอกสาร" value={this.state.filterData.no} onChange={(e)=>{
                  this.filterDataUpdate('no', e.target.value);
                }} />
              </div>
              <div className="uk-width-1-1 uk-width-1-3@m">
                <label>ค้นหาจากประเภทใบสั่งยา</label>
                <select className="uk-select" value={this.state.filterData.prescription_type} onChange={(e)=>{
                  this.filterDataUpdate('prescription_type', e.target.value);
                }}>
                  <option value="">ทั้งหมด</option>
                  <option value="1">ใบสั่งยาที่สร้างจากระบบ</option>
                  <option value="2">ใบรับยาต่อเนื่อง</option>
                </select>
              </div>
              <div className="uk-width-1-1 uk-width-1-3@m">
                <label>ค้นหาจากสถานะล่าสุด</label>
                <select className="uk-select" value={this.state.filterData.status} onChange={(e)=>{
                  this.filterDataUpdate('status', e.target.value);
                }}>
                  <option value="">ทั้งหมด</option>
                  {
                  this.status.map((data, index)=>{
                    return <option key={`filterStatus${index}`}>{data}</option>
                  })
                }
                </select>
              </div>
              <div className="uk-width-1-1 uk-width-1-2@m">
                <label>ค้นหาจากวันที่</label>
                <input className="uk-input" type="date" placeholder="ค้นหาจากวันที่" value={this.state.filterData.date_start} onChange={(e)=>{
                  this.filterDataUpdate('date_start', e.target.value);
                }} />
              </div>
              <div className="uk-width-1-1 uk-width-1-2@m">
                <label>ถึงวันที่</label>
                <input className="uk-input" type="date" placeholder="ถึงวันที่" value={this.state.filterData.date_stop} onChange={(e)=>{
                  this.filterDataUpdate('date_stop', e.target.value);
                }} />
              </div>
              <div className="uk-width-1-1 uk-text-right">
                <button className="uk-button uk-button-primary" onClick={this.listGet}>ค้นหา</button>
              </div>
            </div>
          </div>
          <div className="d-table-container uk-margin-top" style={{minHeight: 400}}>
            <table className="uk-table uk-table-divider uk-table-small uk-table-striped" id="d-prescription-table">
              <thead>
                <tr>
                  <th>วันที่</th>
                  <th>เลขที่เอกสาร</th>
                  <th>ใบสั่งยา</th>
                  <th>HN</th>
                  <th>VN</th>
                  <th>ยอดรวมสุทธิ</th>
                  <th>สถานะล่าสุด</th>
                  <th>เอกสารประกอบ</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                
                {
                
                this.state.loadingList ? 
                <tr>
                    <td colSpan="100%">
                      <div className="uk-text-center"><span uk-spinner=""></span></div>
                    </td>
                  </tr>
                :
                this.state.list.length === 0 ? (
                  <tr>
                    <td colSpan="100%">
                      <div className="uk-text-center">ไม่มีรายการ</div>
                    </td>
                  </tr>
                ) : (
                  this.state.list.map((data, index) => {
                    if (data.logView == null) {
                      data.logView = false;
                    }

                    if(data.content == null) {
                      data.content = {
                        option: {},
                        list: []
                      }
                    }


                    return (
                      <tr key={`list${index}`}>
                        <td>{moment(data.content.option.date).format("DD/MM/YYYY")}</td>
                        <td><a href={"/prescription-file?consult=true&token=" + data.token_file} target="_blank" rel="noopenner">{data.content.option.id}</a></td>
                        <td>ใบสั่งยาของ {data.content.option.customer_name}</td>
                        <td>{data.content.option.hospital_number}</td>
                        <td>{data.content.option.visit_number}</td>
                        <td>{numeral(data.content.option.price_summary).format('0,0.00')}</td>
                        <td>
                          <div className="d-prescription-list-status-container">
                          {this.statusSelectRender(data)}
                          <button
                           uk-icon="history"
                            className="d-prescription-link"
                            onClick={() => {
                              data.logView = !data.logView;

                              this.forceUpdate();
                            }}
                          >
                          </button>
                          </div>
                          {data.logView === true &&
                          Array.isArray(data.statuslog) === true ? (
                            <ul>
                              {data.statuslog.map((log) => {
                                return (
                                  <li>
                                    <strong>{log.status}</strong>
                                    <div className="uk-margin-small-left uk-text-meta">
                                      <div>
                                        วันที่{" "} : 
                                        {moment(
                                          log.date,
                                          "YYYY-MM-DD HH:mm:ss"
                                        ).format("DD/MM/YY")}
                                      </div>
                                      <div>
                                        เวลา{" "} : 
                                        {moment(
                                          log.date,
                                          "YYYY-MM-DD HH:mm:ss"
                                        ).format("HH:mm")}
                                      </div>
                                      <div>บันทึกโดย : {log.consult}</div>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          ) : null}
                        </td>
                        <td>
                          <button
                            className="uk-button uk-button-default uk-width-1-1 d-button-header-sub uk-button-small"
                            onClick={() => {
                              this.prescription_data = data;

                              this.prescriptionSet(data, () => {
                                this.setState((state)=>{
                                  state.file.name = '';
                                  state.file.note = '';
                                  state.file.file = '';
                            
                                  return {file: state.file, filePopupShow: true, fileIndex: null};
                                });
                              });
                            }}
                          >
                            <span uk-icon="plus"></span> <span className="uk-text-small">เพิ่มเอกสารประกอบ</span>
                          </button>
                          {
                            Array.isArray(data.files) && <ul>{data.files.map((file, file_index) => {

                              return <li key={`file${file_index}`}>
                                
                              <strong>
                                {file.file ? 
                                <span uk-lightbox="">
                                  <a href={ImageUri(file.file)} target="_blank" rel="noopenner" >{file.name}</a>
                                </span> : file.name}
                              </strong>
                              <button className="d-prescription-link uk-float-right" uk-icon="trash" onClick={()=>{
                                if(window.confirm("ยืนยันการลบไฟล์" + file.name + "?")) {
                                  delete data.files[file_index];
                                  data.files = data.files.filter(Boolean);
                                  this.prescription_data = data;

                                  this.prescriptionSet(data, ()=>{
                                    this.prescriptionSave(
                                      this.prescription_data,
                                      false,
                                      this.prescription_data.files
                                    );
                                  });
                                }
                                
                              }}></button>
                              <button className="d-prescription-link uk-float-right" uk-icon="pencil" onClick={()=>{
                                  this.prescription_data = data;
                                  
                                  this.setState({fileIndex: file_index}, ()=>{
                                    this.prescriptionSet(data, ()=>{

                                      this.setState((state)=>{
                                        state.file.name = file.name;
                                        state.file.note = file.note;
                                        state.file.file = file.file;
                                  
                                        return {file: state.file, filePopupShow: true};
                                      });
                                    })
                                  })
                              }} ></button>
                              </li>
                            })}</ul>
                          }
                        </td>
                        <td>
                        <div className="uk-inline" style={{display: "relative"}}>
                            <button type="button"
                            className="uk-button uk-button-default d-button-header-sub uk-button-small"><span uk-icon="more"></span></button>
                            <div className="d-prescription-table-more-dropdown" uk-dropdown="mode: click">
                              <button
                                  onClick={() => {
                                    this.prescriptionSet(data, () => {
                                      this.setState({ prescriptionShow: true });
                                    });
                                  }}
                                >
                                <span uk-icon="pencil"></span> แก้ไข
                              </button>
                              <a href={"/prescription-file?consult=true&print=true&token=" + data.token_file} target="_blank" rel="noopenner">
                                <span uk-icon="print"></span> พิมพ์
                              </a>
                              <button onClick={()=>{
                                this.shareToChat(data);
                              }}>
                                <span uk-icon="forward"></span> แชร์
                              </button>
                              <button onClick={()=>{
                                this.PrintMail(data);
                              }}> 
                                <span uk-icon="mail"></span> จ่าหน้าซอง
                              </button>
                              <button onClick={()=>{
                                this.PrintShipping(data);
                              }}> 
                                <span uk-icon="print"></span> พิมพ์ใบปะหน้าพัสดุ
                              </button>
                              <button onClick={()=>{
                                this.Duplicate(data.id);
                              }}>
                                <span uk-icon="copy"></span> สร้างซ้ำ
                              </button>
                              <hr />
                              <button onClick={()=>{
                                this.Remove(data);
                              }}>
                                <span uk-icon="trash"></span> ลบ
                              </button>
                            </div>
                        </div>

                          
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
