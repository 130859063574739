import React, { Component } from 'react';
import __ from './Language';
import UIkit from 'uikit';
import moment from "moment";
import Api from "./Api";
import $ from 'jquery';
import Hiv2Data from '../Components/Hiv2Data';

export default class HivFormV2 extends Component {

  state = {
    error_text: [],
    missing_medicine_value: null,
    q1: null,
    virus_check: null,
    loading_submit: false
  }

  componentDidMount() {
    this.init();
  }

  componentWillReceiveProps() {
    this.init();
  }

  init = () => {

  }

  radio = ({ name, label, value, className }) => {
    return <label className='uk-margin-small-bottom uk-flex uk-flex-middle uk-text-normal d-link'>
      <input type="radio" className={"uk-radio uk-margin-small-right " + (className || '')} name={name} value={value} onChange={(e) => {
        if (name === 'missing_medicine') {
          this.setState({ missing_medicine_value: value });
        }
        else if (className === 'q1') {
          if (value == '0') {
            $('.q1_1:checked').prop('checked', false);
            $('[name="symptom_other_abnormal_detail"]').val('');
          }

          this.setState({ q1: value });
        }
        else if (name === 'virus_check') {
          if (value !== 'ตรวจ') {
            $('[name="virus_check_amount"]:checked').prop('checked', false);
          }

          this.setState({ virus_check: value });
        }

        return;
      }} /> <span>{label || ''}</span>
    </label>
  }

  checkbox = ({ name, label, value, className }) => {
    return <label className='uk-margin-small-bottom uk-flex uk-flex-middle uk-text-normal d-link'>
      <input type="checkbox" className={"uk-checkbox uk-margin-small-right " + (className || "")} name={name} onChange={(e) => {

      }} style={{minWidth: 14}} /> <span>{label || ''}</span>
    </label>
  }

  formNextButtonRender = (index) => {
    return <div>
      {index > 0 && <button type="button" className='uk-button uk-button-small uk-button-default d-background-gray uk-margin-right' uk-switcher-item="previous">ข้อก่อนหน้า</button>}
      {index < this.form.length - 1 && <button type="button" className='uk-button uk-button-small uk-button-default d-background-gray ' uk-switcher-item="next">ข้อถัดไป</button>}
    </div>
  }

  form = [
    {
      label: 'Screening TB/ ADR/ STIs การคัดกรองเบื้องต้น หัวข้อ วัณโรค/ผลข้างเคียงจากยาต้านฯ/โรคติดต่อทางเพศสัมพันธ์',
      ui: () => {
        return <div>
          <div className='uk-margin-small-bottom'>1. ท่านมีอาการผิดปกติเหล่านี้ในช่วง 1 เดือนที่ผ่านมาหรือไม่ กรุณาเลือกทุกข้อที่ท่านมีอาการ</div>
          <div className='uk-margin-left'>
            <div className='uk-margin-small-bottom'>
              {this.radio({
                name: 'choose_abnormal',
                label: 'มีอาการผิดปกติ (เลือกได้มากกว่า 1 ข้อ)',
                value: '1',
                className: 'q1'
              })}
            </div>
            {
              this.state.q1 === '1' && <div className='uk-margin-left uk-grid uk-child-width-1-1 uk-child-width-1-4@m'>
                <div>
                  <div>
                    {this.checkbox({
                      name: 'continuous_cough',
                      label: 'ไอต่อเนื่อง',
                      className: 'q1_1'
                    })}
                  </div>
                  <div>
                    {this.checkbox({
                      name: 'blood_cough',
                      label: 'ไอเป็นเลือด',
                      className: 'q1_1'
                    })}
                  </div>
                  <div>
                    {this.checkbox({
                      name: 'weight_loss',
                      label: 'น้ำหนักลด',
                      className: 'q1_1'
                    })}
                  </div>
                  <div>
                    {this.checkbox({
                      name: 'fever',
                      label: 'ไข้',
                      className: 'q1_1'
                    })}
                  </div>
                  <div>
                    {this.checkbox({
                      name: 'unknow_sweating',
                      label: 'เหงื่อออกมากผิดปกติ',
                      className: 'q1_1'
                    })}
                  </div>
                  <div>
                    {this.checkbox({
                      name: 'rash',
                      label: 'ผื่น',
                      className: 'q1_1'
                    })}
                  </div>
                  <div>
                    {this.checkbox({
                      name: 'dizziness',
                      label: 'เวียนหัว',
                      className: 'q1_1'
                    })}
                  </div>
                </div>
                <div>
                  <div>
                    {this.checkbox({
                      name: 'tired',
                      label: 'อ่อนเพลีย',
                      className: 'q1_1'
                    })}
                  </div>
                  <div>
                    {this.checkbox({
                      name: 'squeamish',
                      label: 'คลื่นไส้ อาเจียน',
                      className: 'q1_1'
                    })}
                  </div>
                  <div>
                    {this.checkbox({
                      name: 'bored_food',
                      label: 'เบื่ออาหาร',
                      className: 'q1_1'
                    })}
                  </div>
                  <div>
                    {this.checkbox({
                      name: 'diarrhea',
                      label: 'ถ่ายเหลว',
                      className: 'q1_1'
                    })}
                  </div>
                  <div>
                    {this.checkbox({
                      name: 'stomach_ache',
                      label: 'ปวดท้อง',
                      className: 'q1_1'
                    })}
                  </div>
                  <div>
                    {this.checkbox({
                      name: 'yellow_body',
                      label: 'ตัวเหลือง ตาเหลือง',
                      className: 'q1_1'
                    })}
                  </div>
                </div>
                <div>
                  <div>
                    {this.checkbox({
                      name: 'sleepless',
                      label: 'นอนไม่หลับ',
                      className: 'q1_1'
                    })}
                  </div>
                  <div>
                    {this.checkbox({
                      name: 'urine_pain',
                      label: 'มีปัสสาวะแสบขัด',
                      className: 'q1_1'
                    })}
                  </div>
                  <div>
                    {this.checkbox({
                      name: 'urine_purulent',
                      label: 'มีหนองออกจากทางเดินปัสสาวะ',
                      className: 'q1_1'
                    })}
                  </div>
                  <div>
                    {this.checkbox({
                      name: 'abnormal_leucorrhoea',
                      label: 'มีตกขาวผิดปกติ',
                      className: 'q1_1'
                    })}
                  </div>
                  <div>
                    {this.checkbox({
                      name: 'genitalia_wound_itch_swell',
                      label: 'คัน บวม หรือเป็นแผลบริเวณอวัยวะเพศ',
                      className: 'q1_1'
                    })}
                  </div>
                  <div className='uk-margin-small-bottom uk-flex'>
                    {this.checkbox({
                      name: 'symptom_other_abnormal',
                      label: 'อื่นๆ',
                      className: 'q1_1'
                    })}
                    <input className='uk-input uk-margin-left' type="text" placeholder="ระบุ" name="symptom_other_abnormal_detail" />
                  </div>
                </div>
              </div>
            }

            <div>
              {this.radio({
                name: 'choose_abnormal',
                label: 'ไม่มีอาการ',
                value: '0',
                className: 'q1'
              })}
            </div>
          </div>
        </div>
      },
    },
    {
      label: 'Self-report Adherence แบบสอบถามความสม่ำเสมอการกินยาต้าน',
      ui: () => {
        return <div>
          <div className='uk-margin-small-bottom'>2. ในช่วง 30 วันที่ผ่านมา ท่านเคยขาดยาต้านไวรัสใช่หรือไม่</div>
          <div className='uk-margin-left'>
            <div>
              {this.radio({
                name: 'missing_medicine',
                label: 'ใช่ (โปรดตอบ ข้อที่ 2.1, 2.2 )',
                value: '1'
              })}
            </div>
            <div>
              {this.radio({
                name: 'missing_medicine',
                label: 'ไม่ใช่',
                value: '0'
              })}
            </div>
          </div>
          {
            this.state.missing_medicine_value === '1' && <div className='uk-margin-small-bottom'>
              <div className='uk-margin-small-bottom uk-text-bold'>ถ้าตอบ ใช่ โปรดตอบข้อต่อไปนี้</div>
              <div className='uk-margin-small-bottom'>2.1 จำนวนครั้งที่ท่านขาดยาต้านไวรัส</div>
              <div className='uk-margin-left'>
                <div>
                  {this.radio({
                    name: 'missing_take_medicine_once',
                    label: 'ขาดยาต้านไวรัส 1 ครั้ง',
                    value: '1'
                  })}
                </div>
                <div>
                  {this.radio({
                    name: 'missing_take_medicine_once',
                    label: 'ขาดยาต้านไวรัส มากกว่า 1 ครั้ง',
                    value: 'more'
                  })}
                </div>
              </div>
              <div className='uk-margin-small-bottom'>2.2 สาเหตุที่ขาดยาต้านไวรัส (เลือกได้มากกว่า 1 ข้อ)</div>
              <div className='uk-margin-left'>

                <div className='uk-grid uk-child-width-1-1 uk-child-width-1-3@m '>
                  <div>
                    <div>
                      {this.checkbox({
                        name: 'missing_because_bore',
                        label: 'เบื่อไม่อยากกินยาต้านไวรัส'
                      })}
                    </div>
                    <div>
                      {this.checkbox({
                        name: 'missing_because_no_time',
                        label: 'ไม่มีเวลากินยาที่เหมาะสม'
                      })}
                    </div>
                    <div>
                      {this.checkbox({
                        name: 'missing_because_busy_with_work',
                        label: 'เวลาทำงานไม่แน่นอน ยุ่งกับงาน'
                      })}
                    </div>
                    <div>
                      {this.checkbox({
                        name: 'missing_because_lost',
                        label: 'ทำยาหาย'
                      })}
                    </div>
                    <div>
                      {this.checkbox({
                        name: 'missing_because_share',
                        label: 'ยาหมดก่อนนัดเนื่องจากแบ่งให้คู่/เพื่อน/คนอื่น'
                      })}
                    </div>
                    <div>
                      {this.checkbox({
                        name: 'missing_because_no_inspiration',
                        label: 'ขาดแรงจูงใจในการกินยา'
                      })}
                    </div>
                  </div>
                  <div>
                    <div>
                      {this.checkbox({
                        name: 'missing_because_dispirited',
                        label: 'ท้อแท้ โดดเดี่ยว หมดอาลัยตายอยาก'
                      })}
                    </div>
                    <div>
                      {this.checkbox({
                        name: 'missing_because_strong',
                        label: 'ร่างกายแข็งแรงดี ยังไม่ป่วย/ไม่มีอาการผิดปกติมาก'
                      })}
                    </div>
                    <div>
                      {this.checkbox({
                        name: 'missing_because_too_often',
                        label: 'คลินิกนัดรับยาถี่เกินไป ไม่สะดวกรับยา'
                      })}
                    </div>
                    <div>
                    </div>
                    <div className='uk-margin-small-bottom uk-flex'>
                      {this.checkbox({
                        name: 'missing_because_other',
                        label: 'อื่นๆ'
                      })}
                      <input className='uk-input uk-margin-left' type="text" placeholder="ระบุ" name="missing_because_other_detail" />
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          }
          <div className='uk-flex uk-flex-middle'>
            <span>ท่านมียาต้านไวรัสคงเหลือ (โดยประมาณ) จำนวน </span><input className='uk-input uk-text-center uk-width-small uk-margin-small-left uk-margin-small-right' min={0} type="number" name="how_many_tablet_are_left" /> <span>เม็ด</span>
          </div>
        </div>
      }
    },
    {
      label: 'Alcohol and substance used แบบสอบถามการใช้เครื่องดื่มแอลกอฮอล์ และสารเสพติด ',
      ui: () => {
        return <div>
          <div className='uk-margin-small-bottom'>3.ท่านดื่มสุรา หรือใช้สารเสพติดอื่นๆ หรือไม่</div>
          <div className='uk-margin-left'>
            <div>
              {this.radio({
                name: 'check_drink_alcohol',
                label: 'ใช่',
                value: '1'
              })}
            </div>
            <div>
              {this.radio({
                name: 'check_drink_alcohol',
                label: 'ไม่ใช่',
                value: '0'
              })}
            </div>
          </div>
        </div>
      }
    },

    {
      label: 'Self-report VL  แบบสอบถามปริมาณไวรัสในเลือด',
      ui: () => {
        return <div>
          <div className='uk-margin-small-bottom'>4. ในช่วง 12 เดือนที่ผ่านมา ท่านได้ตรวจปริมาณไวรัสในเลือดหรือไม่</div>
          <div className='uk-margin-left'>
            <div>
              {this.radio({
                name: 'virus_check',
                label: 'ตรวจ',
                value: 'ตรวจ'
              })}
            </div>
            {
              this.state.virus_check === 'ตรวจ' && <div className='uk-margin-left'>
                <div>
                  {this.radio({
                    name: 'virus_check_amount',
                    label: 'ตรวจไม่พบปริมาณไวรัสหรือ ระดับไวรัสต่ำกว่า 50 copies/ml',
                    value: '<50'
                  })}
                </div>
                <div>
                  {this.radio({
                    name: 'virus_check_amount',
                    label: 'ปริมาณไวรัส 50-200 copies/ml',
                    value: '<200'
                  })}
                </div>
                <div>
                  {this.radio({
                    name: 'virus_check_amount',
                    label: 'ปริมาณไวรัส > 200 - <1000 copies/ml',
                    value: '<1000'
                  })}
                </div>
                <div>
                  {this.radio({
                    name: 'virus_check_amount',
                    label: 'ปริมาณไวรัส ≥ 1000 copies/ml',
                    value: '>1000'
                  })}
                </div>
                <div>
                  {this.radio({
                    name: 'virus_check_amount',
                    label: 'ไม่ทราบปริมาณไวรัส',
                    value: 'unknown'
                  })}
                </div>
              </div>
            }

            <div>
              {this.radio({
                name: 'virus_check',
                label: 'ไม่ตรวจ',
                value: 'ไม่ตรวจ'
              })}
            </div>
            <div>
              {this.radio({
                name: 'virus_check',
                label: 'จำไม่ได้',
                value: 'จำไม่ได้'
              })}
            </div>
          </div>
        </div>
      }
    },

    {
      label: '2Q การประเมินภาวะสุขภาพจิตด้วยแบบสอบถาม 2Q',
      ui: () => {
        return <div>
          <div className='uk-margin-small-bottom'>5.ในช่วง 2 สัปดาห์ที่ผ่านมา ท่านมีความรู้สึกหดหู่ เศร้า หรือ ท้อแท้สิ้นหวัง</div>
          <div className='uk-margin-left'>
            <div>
              {this.radio({
                name: 'feel_depress_in_2_week',
                label: 'ใช่',
                value: '1'
              })}
            </div>
            <div>
              {this.radio({
                name: 'feel_depress_in_2_week',
                label: 'ไม่ใช่',
                value: '0'
              })}
            </div>
          </div>
          <div className='uk-margin-small-bottom'>6.ในช่วง 2 สัปดาห์ที่ผ่านมา ท่านรู้สึกเบื่อ ทำอะไรก็ไม่เพลิดเพลิน</div>

          <div className='uk-margin-left'>
            <div>
              {this.radio({
                name: 'feel_bore_in_2_week',
                label: 'ใช่',
                value: '1'
              })}
            </div>
            <div>
              {this.radio({
                name: 'feel_bore_in_2_week',
                label: 'ไม่ใช่',
                value: '0'
              })}
            </div>
          </div>
        </div>
      }
    },

    {
      label: 'Sexual Risk Behavior การคัดกรองความเสี่ยงทางเพศสัมพันธ์ ',
      ui: () => {
        return <div>
          <div className='uk-margin-small-bottom'>7.การมีเพศสัมพันธ์ในช่วง 3 เดือนที่ผ่านมา (เลือกได้มากกว่า 1 ข้อ)
          </div>
          <div className='uk-margin-left'>
            <div>
              {this.checkbox({
                name: 'sex_more_than_1_person',
                label: 'มีเพศสัมพันธ์มากกว่า 1 คน',
                className: 'q7'
              })}
            </div>
            <div>
              {this.checkbox({
                name: 'sex_with_disease',
                label: 'มีคู่เพศสัมพันธ์ที่เป็นโรคติดต่อทางเพศสัมพันธ์',
                className: 'q7'
              })}
            </div>
            <div>
              {this.checkbox({
                name: 'sex_with_no_condom',
                label: 'มีเพศสัมพันธ์โดยไม่ใช้ถุงยางอนามัย หรือถุงยางอนามัย แตก รั่ว หลุด (ทุกช่องทางที่ใช้ในการมีเพศสัมพันธ์)',
                className: 'q7'
              })}
            </div>
            <div>
              {this.checkbox({
                name: 'sex_with_prostitute',
                label: 'มีเพศสัมพันธ์กับผู้ขายบริการทางเพศ',
                className: 'q7'
              })}
            </div>
            <div>
              {this.checkbox({
                name: 'sex_with_no_risk',
                label: 'ไม่มีความเสี่ยงเกี่ยวกับการมีเพศสัมพันธ์',
                className: 'q7'
              })}
            </div>
            <div>
              {this.checkbox({
                name: 'sex_not_answer',
                label: 'ไม่ขอตอบคำถามนี้',
                className: 'q7'
              })}
            </div>
          </div>
        </div>
      }
    },

    {
      label: 'Unscreened partner(s) for HIV แบบสอบถามการตรวจหาการติดเชื้อเอชไอวีในคู่  ',
      ui: () => {
        return <div>
          <div className='uk-margin-small-bottom'>8.คู่นอนของท่านเคยเข้ารับการตรวจเพื่อหาเชื้อ HIV หรือไม่
          </div>
          <div className='uk-margin-left'>
            <div>
              {this.radio({
                name: 'partner_check',
                label: 'เคย',
                value: 'เคย'
              })}
            </div>
            <div>
              {this.radio({
                name: 'partner_check',
                label: 'ไม่เคย',
                value: 'ไม่เคย'
              })}
            </div>
            <div>
              {this.radio({
                name: 'partner_check',
                label: 'ไม่มีคู่นอน',
                value: 'ไม่มีคู่นอน'
              })}
            </div>
            <div>
              {this.radio({
                name: 'partner_check',
                label: 'ไม่ขอตอบคำถามนี้',
                value: 'ไม่ขอตอบคำถามนี้'
              })}
            </div>
          </div>
        </div>
      }
    },

    {
      label: 'family planning แบบสอบถามการวางแผนตั้งครรภ ',
      ui: () => {
        return <div>
          <div className='uk-margin-small-bottom'>9.ท่านกำลังวางแผนจะมีบุตร หรือไม่
          </div>
          <div className='uk-margin-left'>
            <div>
              {this.radio({
                name: 'child_plan',
                label: 'ใช่',
                value: 'ใช่'
              })}
            </div>
            <div>
              {this.radio({
                name: 'child_plan',
                label: 'ไม่ใช่ / ไม่เกี่ยวข้อง',
                value: 'ไม่ใช่'
              })}
            </div>
            <div>
              {this.radio({
                name: 'child_plan',
                label: 'ไม่ขอตอบ',
                value: 'ไม่ขอตอบ'
              })}
            </div>
          </div>
        </div>
      }
    },

  ]

  form_validate = (d) => {
    var _error = [];

    //1
    if (d.choose_abnormal == null) {
      _error.push(`ตอนที่ 1 : โปรดตอบคำถามข้อที่ 1 ท่านมีอาการผิดปกติเหล่านี้ในช่วง 1 เดือนที่ผ่านมาหรือไม่`);
    }

    if (d.choose_abnormal == '1') {
      if ($('.q1_1:checked').length === 0) {
        _error.push(`ตอนที่ 1 : โปรดเลือกอาการผิดปกติ`);
      }

      if (d.symptom_other_abnormal == 'on') {

        if (!d.symptom_other_abnormal_detail) {
          _error.push(`ตอนที่ 1 : โปรดระบุรายละเอียด กรณีมีอาการผิดปกติอื่นๆ`);
        }

        if ($('.q1_1:checked').length < 1) {
          _error.push(`ตอนที่ 1 : โปรดตอบคำถามมีอาการผิดปกติอย่างไร`);
        }
      }

    }

    //2

    if (d.missing_medicine == null) {
      _error.push(`ตอนที่ 2 : โปรดตอบคำถามข้อที่ 2 ในช่วง 30 วันที่ผ่านมา ท่านเคยขาดยาต้านไวรัสใช่หรือไม่`);

    }

    if (d.missing_medicine == '1') {
      if (d.missing_take_medicine_once == null) {
        _error.push(`ตอนที่ 2 : โปรดตอบคำถามข้อที่ 2.1 จำนวนครั้งที่ขาดยาต้านไวรัส`);
      }

      if (d.missing_because_other && !d.missing_because_other_detail) {

        _error.push(`ตอนที่ 2 : ข้อที่ 2.2 โปรดระบุสาเหตุอื่นๆ`);
      }
    }

    if (d.how_many_tablet_are_left === '') {
      _error.push(`ตอนที่ 2 : โปรดระบุจำนวนยาต้านไวรัสคงเหลือ`);

    }

    //3

    if (d.check_drink_alcohol == null) {
      _error.push(`ตอนที่ 3 : โปรดตอบคำถามข้อที่ 3 ท่านดื่มสุรา หรือใช้สารเสพติดอื่นๆ หรือไม่`);

    }


    //4
    if (d.virus_check == null) {
      _error.push(`ตอนที่ 4 : ในช่วง 12 เดือนที่ผ่านมา ท่านได้ตรวจปริมาณไวรัสในเลือดหรือไม่`);

    }

    if (d.virus_check == 'ตรวจ') {
      if (d.virus_check_amount == null) {
        _error.push(`ตอนที่ 4 : โปรดเลือกปริมาณไวรัสที่ตรวจพบ`);

      }
    }

    //5
    if (d.feel_depress_in_2_week == null) {
      _error.push(`ตอนที่ 5 : โปรดตอบคำถามข้อที่ 5 ในช่วง 2 สัปดาห์ที่ผ่านมา ท่านมีความรู้สึกหดหู่ เศร้า หรือ ท้อแท้สิ้นหวัง`);
    }

    if (d.feel_bore_in_2_week == null) {
      _error.push(`ตอนที่ 5 : โปรดตอบคำถามข้อที่ 6 ในช่วง 2 สัปดาห์ที่ผ่านมา ท่านรู้สึกเบื่อ ทำอะไรก็ไม่เพลิดเพลิน`);
    }

    //6

    if ($('.q7:checked').length < 1) {
      _error.push(`ตอนที่ 6 : โปรดตอบคำถามข้อที่ 7 การมีเพศสัมพันธ์ในช่วง 3 เดือนที่ผ่านมา`);

    }

    //7
    if (d.partner_check == null) {
      _error.push(`ตอนที่ 7 : โปรดตอบคำถามข้อที่ 8  คู่นอนของท่านเคยเข้ารับการตรวจเพื่อหาเชื้อ HIV หรือไม่`);

    }

    //8
    if (d.child_plan == null) {
      _error.push(`ตอนที่ 8 : โปรดตอบคำถามข้อที่ 9  ท่านกำลังวางแผนจะมีบุตร หรือไม่`);

    }

    return _error;

  }

  tag_render = (data) => {
    var _tag = [];


    if (
      data.continuous_cough != null ||
      data.blood_cough != null ||
      data.weight_loss != null ||
      data.fever != null ||
      data.unknow_sweating != null
    ) {
      _tag.push('TB');
    }

    if (
      data.rash != null ||
      data.dizziness != null ||
      data.tired != null ||
      data.squeamish != null ||
      data.bored_food != null ||
      data.diarrhea != null ||
      data.stomach_ache != null ||
      data.sleepless != null
    ) {
      _tag.push('ADR');

    }

    if (
      data.urine_pain != null ||
      data.urine_purulent != null ||
      data.abnormal_leucorrhoea != null ||
      data.genitalia_wound_itch_swell != null
    ) {
      _tag.push('STIs');
    }

    if (data.missing_medicine == '1') {
      _tag.push('Adherence');
    }

    if (data.check_drink_alcohol == '1') {
      _tag.push('Alcohol and substance use');
    }

    if (data.virus_check === 'ตรวจ' && data.virus_check_amount != '<50') {
      _tag.push('VL');
    }

    if (
      data.feel_depress_in_2_week == '1' ||
      data.feel_bore_in_2_week == '1'
    ) {
      _tag.push('2Q');
    }

    if (
      data.sex_more_than_1_person != null ||
      data.sex_with_disease != null ||
      data.sex_with_no_condom != null ||
      data.sex_with_prostitute != null
    ) {
      _tag.push('Unsafe sex');
    }

    if (
      data.partner_check === 'ไม่เคย'
    ) {
      _tag.push('Unscreened partner for HIV');
    }

    if (
      data.child_plan === 'ใช่'
    ) {
      _tag.push('Family planning');
    }


    return _tag;

  }

  textRender = (d, depth, has_data) => {
    var _row = '';
    d.forEach((v) => {

      if (
        v.key == null
        ||
        has_data[v.key] === true
      ) {


        var _prefix = '';


        for (var i = 0; i < depth; i++) {
          _prefix += '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
        }

        if (depth !== 0) {
          _prefix = _prefix + '-';
        }


        _row += `${_prefix} ${v.title}\n`;
      }

      if (v.child != null) {
        _row += this.textRender(v.child, depth + 1, has_data);
      }
    });

    return _row;
  }

  summaryTextRender = (data) => {

    var _d = {};

    for (var k in data) {
      if (data[k] !== 'on') {
        _d[`${k}_${data[k]}`] = true;
      } else {
        _d[k] = true;
      }
    }

    return `<b>วันที่ทำแบบประเมินก่อนพบแพทย์ :</b> ${moment().add(543, 'years').format('DD/MM/YYYY')} เวลา ${moment().format('HH:mm น.')} \n` + this.textRender(Hiv2Data, 0, _d);
  }

  submit_loading = false;

  submit = async (e) => { 
    if(this.submit_loading !== false) return;

    this.submit_loading = true;
 
    this.setState({ error_text: [], loading_submit: true })


    var _data = $('#form-v2').serializeArray().reduce(function (obj, item) {
      obj[item.name] = item.value;
      return obj;
    }, {});

    // var formData = new FormData($('#form-v2'));

    // console.log(formData)
    // var _data = {}

    // for (var [key, value] of formData.entries()) {
    //   _data[key] = value;
    // }
    // console.log(_data)

    // return;

    var _noti = this.tag_render(_data);

    var _error = this.form_validate(_data);

    var _error_text = [];
 

    if (_error.length > 0) {
      for (var e of _error) {
        _error_text.push(<li key={'error-' + e}>{e}</li>);
      }

      this.setState({
        error_text: <div>
          <div><b><u>โปรดกรอกข้อมูล</u></b></div>
          <ul>
            {_error_text}
          </ul>
        </div>
      }); 
    }
    else {
      var _r = await Api.member("Hiv2DataAdd", {
        data: JSON.stringify(_data),
        dateadd: moment().format('YYYY-MM') + '-01',
        campaignId: this.props.campaignId,
        noti: _noti.join(','),
      });
 

      if (_r.result === true) {

        this.props.callback(this.summaryTextRender(_data), _noti)
      } else {
        UIkit.notification({ message: 'เกิดข้อผิดพลาด : ' + _r.responseText });
      }

    }

    this.submit_loading= false;

    setTimeout(() => {
      
    this.setState({loading_submit: false});
    }, 1000);

    return;
  }

  render() {
    return <div>
      <h4 className='uk-text-bold'>แบบประเมินตนเองก่อนพบแพทย์</h4>
      <div className='uk-text-danger uk-margin-bottom'><b>คําชี้แจง</b> คําตอบของท่านจะถูกเก็บเป็นความลับ และใช้ในการดูแลรักษาและพัฒนาระบบบริการเท่านั้น โปรดเลือกคําตอบที่ตรงกับท่านมากที่สุด</div>
      <form id="form-v2">
        <ul class="uk-subnav uk-subnav-pill" id="form-tab-container" uk-switcher="">
          {
            this.form.map((d, i) => {
              return <li key={`list-section-${i}`}><a href="#">ตอนที่ {i + 1}</a></li>
            })
          }
        </ul>

        <div class="uk-switcher uk-margin">
          {
            this.form.map((d, i) => {
              return <div>
                {d.ui()},
                {this.formNextButtonRender(i)}
              </div>
            })
          }
        </div>

        <div id="error-container" className='uk-text-danger'>{this.state.error_text || ''}</div>

        <hr />

        <div className='uk-text-right uk-margin-top'>

          <button type='button' className='uk-button uk-button-default uk-modal-close'>
            ยกเลิก
          </button>
          {
            this.state.loading_submit === true ? 
            <button type='button' className="uk-button uk-button-primary uk-margin-left">
              <span uk-spinner="ratio: 0.5"></span>
              </button>
            :
            <button type='button' className="uk-button uk-button-primary uk-margin-left" onClick={this.submit}>ส่งแบบประเมิน</button>
          }
          
        </div>
      </form>
    </div>
  }
};
