import NovalueCheck from './NoValueCheck';

export default (value, age, gender) => {
  if(gender == '1') gender = 'male';
  if(gender == '0') gender = 'female';
  
  if(NovalueCheck(value)) return NovalueCheck(value);

  value = +value;

  var midValue = 35;
  if(gender === 'male') {
    midValue = 45;
  }

  if(value > midValue) {
    return { 
      title: 'ปกติ',
      color: 'green',
      caption: 'ปกติ',
      suggest: ''
    };
  } else {
    return { 
      title: 'ไขมันดีต่ำ',
      color: 'orange',
      caption: 'ไขมันดีต่ำ',
      suggest: ''
    };
  }

}