import React, { Component } from "react";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import Api from "../../Components/Api";
import Formular from "../Formular";
import GroupReport from "../Formular/GroupReport";
import moment from "moment";
import numeral from "numeral";
import FullScreenElement from "../FullScreenElement";
import ReactEcharts from "echarts-for-react";

UIkit.use(Icons);

export default class SmartHealthHealthOverview2 extends Component {
  state = {
    loadingApi: false,
    selectYear: moment().subtract(1, 'year').format('YYYY'),
    selectAge: "",
    selectGender: "",
    selectDepartment: "",
    selectJob: "",
    selectGroupRisk: "",
    optionDepartment: [{ label: "ทั้งหมด", value: "" }],
    optionJob: [{ label: "ทั้งหมด", value: "" }],
    optionGroupRisk: [],
    groupRiskData: {},
    groupRiskDataInvolve: {},
  };

  componentDidMount() {
    this.dataCacheGet();
  }

  dataRender = () => {
    var _data = this.state.data;
    var _memberDataNameRisk = {};
    var _memberDataGroupRisk = {};
    var _groupRiskData = {
      green: 0,
      yellow: 0,
      orange: 0,
      red: 0,
    };
    var _department = [];
    var _job = [];
    var _groupRisk = [];
    var _riskInvolve = {};

    var _selectGroupRisk = this.state.selectGroupRisk;

    for (var name in _data) {
      for (var member in _data[name]) {
        var _memberData = _data[name][member];

        var _useMemberData = false;

        //if first call render option department and job
        if (this.state.optionDepartment.length < 1) {
          if (
            _memberData.position &&
            _department.indexOf(_memberData.position) < 0
          )
            _department.push(_memberData.position);

          if (_memberData.job && _job.indexOf(_memberData.job) < 0)
            _job.push(_memberData.job);
        }

        if (this.state.selectAge !== "") {
          _memberData.age = +_memberData.age;

          //filter
          switch (this.state.selectAge) {
            case 15:
              if (_memberData.age < 15) {
                _useMemberData = true;
              }
              break;
            case 25:
              if (_memberData.age >= 15 && _memberData.age <= 25) {
                _useMemberData = true;
              }
              break;
            case 61:
              if (_memberData.age > 60) {
                _useMemberData = true;
              }
              break;
            default:
              if (
                _memberData.age > +this.state.selectAge - 5 &&
                _memberData.age <= +this.state.selectAge
              ) {
                _useMemberData = true;
              }
              break;
          }
        } else {
          _useMemberData = true;
        }

        if (_useMemberData === true)
          if (this.state.selectGender !== "") {
            if (this.state.selectGender == _memberData.gender) {
              _useMemberData = true;
            } else {
              _useMemberData = false;
            }
          }

        if (_useMemberData === true)
          if (this.state.selectDepartment !== "") {
            if (this.state.selectDepartment == _memberData.position) {
              _useMemberData = true;
            } else {
              _useMemberData = false;
            }
          }

        if (_useMemberData === true)
          if (this.state.selectJob !== "") {
            if (this.state.selectJob == _memberData.job) {
              _useMemberData = true;
            } else {
              _useMemberData = false;
            }
          }

        if (_useMemberData === true) {
          if (_memberDataNameRisk[member] == null)
            _memberDataNameRisk[member] = {};

          _memberDataNameRisk[member][name] = _memberData;

          if (typeof Formular[name] === "function")
            _memberDataNameRisk[member][name].detail = Formular[name](
              _memberData.value,
              _memberData.age,
              _memberData.gender
            );
        }
      }
    }

    //Render group risk
    for (var member in _memberDataNameRisk) {
      if (_memberDataGroupRisk[member] === undefined)
        _memberDataGroupRisk[member] = {};

      _memberDataGroupRisk[member] = GroupReport(_memberDataNameRisk[member]);
    }

    for (var member in _memberDataGroupRisk) {
      if (_memberDataGroupRisk[member].length)
        _memberDataGroupRisk[member].forEach((group) => {
          if (_groupRisk.indexOf(group.label) < 0) {
            _groupRisk.push(group.label);
          }

          if (_selectGroupRisk === "") {
            _selectGroupRisk = _groupRisk[0];
          }

          if (group.label === _selectGroupRisk) {
            _groupRiskData[group.color]++;
          } else {
            if (group.color !== "green") {
              //add involve risk
              if (_riskInvolve[group.label] == undefined) {
                _riskInvolve[group.label] = 0;
              }

              _riskInvolve[group.label]++;
            }
          }
        });
    }

    if (this.state.optionDepartment.length < 1) {
      _department = _department.map((department) => ({
        label: department,
        value: department,
      }));

      _department.splice(0, 0, { label: "ทั้งหมด", value: "" });

      _job = _job.map((job) => ({ label: job, value: job }));

      _job.splice(0, 0, { label: "ทั้งหมด", value: "" });

      _groupRisk = _groupRisk.map((name) => ({ label: name, value: name }));

      this.setState({
        optionDepartment: _department,
        optionJob: _job,
        optionGroupRisk: _groupRisk,
        selectGroupRisk: _groupRisk[0] ? _groupRisk[0].value : "",
      });
    }

    this.setState({
      groupRiskDataInvolve: _riskInvolve,
      groupRiskData: _groupRiskData,
      loadingApi: false,
    });
  };

  dataCacheGet = async () => {
    this.setState({ loadingApi: true });
    try {
      var { data } = await Api.member("DataCache", {
        selectYear: this.state.selectYear,
        cachetype: 'SmartHealthHealthOverview2_'+this.state.selectYear
      });
  
      if(data) {
  
        this.setState(
          { data, optionDepartment: [], optionJob: [], selectGroupRisk: "" },
          this.dataRender
        );
      } else {
        this.dataGet();
      }
    } catch(e) {
      this.dataGet();

    }
    
  }

  dataGet = async () => {
    this.setState({ loadingApi: true });

    var { data } = await Api.member("SmartHealthHealthOverview2", {
      selectYear: this.state.selectYear,
    });

    this.setState(
      { data, optionDepartment: [], optionJob: [], selectGroupRisk: "" },
      this.dataRender
    );
  };

  jobSelectRender = () => {
    return (
      <select
        className="uk-select"
        onChange={(e) =>
          this.setState({ selectJob: e.target.value }, this.dataRender)
        }
      >
        {this.state.optionJob.map((job) => {
          return <option value={job.value}>{job.label}</option>;
        })}
      </select>
    );
  };

  departmentSelectRender = () => {
    return (
      <select
        className="uk-select"
        onChange={(e) =>
          this.setState({ selectDepartment: e.target.value }, this.dataRender)
        }
      >
        {this.state.optionDepartment.map((department) => {
          return <option value={department.value}>{department.label}</option>;
        })}
      </select>
    );
  };

  groupRiskSelectRender = () => {
    return (
      <select
        className="uk-select"
        value={this.state.selectGroupRisk}
        onChange={(e) =>
          this.setState({ selectGroupRisk: e.target.value }, this.dataRender)
        }
      >
        {this.state.optionGroupRisk.map((name) => {
          return <option value={name.value}>{name.label}</option>;
        })}
      </select>
    );
  };

  ageSelectRender = () => {
    var _age = ["", 15, 25, 30, 35, 40, 45, 50, 55, 60, 61];

    var _ageLabel = [
      "ทั้งหมด",
      "น้อยกว่า 15 ปี",
      "15 - 25 ปี",
      "26 - 30 ปี",
      "31 - 35 ปี",
      "36 - 40 ปี",
      "41 - 45 ปี",
      "46 - 50 ปี",
      "51 - 55 ปี",
      "56 - 60 ปี",
      "61 ปี ขึ้นไป",
    ];

    return (
      <select
        className="uk-select"
        onChange={(e) =>
          this.setState({ selectAge: e.target.value }, () => this.dataRender())
        }
      >
        {_age.map((age, key) => {
          return <option value={age}>{_ageLabel[key]}</option>;
        })}
      </select>
    );
  };

  genderSelectRender = () => {
    var _gender = [
      {
        label: "ทั้งหมด",
        value: "",
      },
      {
        label: "ชาย",
        value: "1",
      },
      {
        label: "หญิง",
        value: "0",
      },
    ];

    return (
      <select
        className="uk-select"
        onChange={(e) =>
          this.setState({ selectGender: e.target.value }, () =>
            this.dataRender()
          )
        }
      >
        {_gender.map((gender) => {
          return <option value={gender.value}>{gender.label}</option>;
        })}
      </select>
    );
  };

  yearSelectRender = () => {
    var _option = [];

    for (var i = 0; i <= 10; i++) {
      var _year = moment().subtract(i, "years").format("YYYY");
      _option.push(
        <option key={_year} value={_year}>
          {_year}
        </option>
      );
    }

    return (
      <select
        className="uk-select"
        value={this.state.selectYear}
        onChange={(e) =>
          this.setState({ selectYear: e.target.value }, this.dataGet)
        }
      >
        {_option}
      </select>
    );
  };

  riskName = {
    green: "Normal",
    yellow: "Low Risk",
    orange: "Risk",
    red: "High Risk",
  };

  amountRiskUserRender = () => {
    var _amount = 0;
    for (var i in this.state.groupRiskData) {
      if (i !== "green") {
        _amount += +this.state.groupRiskData[i];
      }
    }

    return _amount;
  };

  amountUserRender = () => {
    var _amount = 0;
    for (var i in this.state.groupRiskData) {
      _amount += +this.state.groupRiskData[i];
    }

    return _amount;
  };

  renderGraphInvolve = () => {
    var _data = [];

    for (var i in this.state.groupRiskDataInvolve) {
      _data.push({
        value: this.state.groupRiskDataInvolve[i],
        name: i,
      });
    }

    var option = {
      tooltip: {
        trigger: "item",
      },

      series: [
        {
          type: "pie",
          radius: "70%",
          data: _data,
          label: {
            fontSize: 11,
            formatter: function (e) {
              return `${e.name}\n${e.value} (${e.percent}%)`;
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    if (_data.length === 0) {
      return <div className="uk-padding uk-text-center">No data</div>;
    } else {
      return (
        <ReactEcharts
          option={option}
          notMerge={true}
          lazyUpdate={true}
          style={{ height: 300 }}
          theme={"theme_name"}
        />
      );
    }
  };

  renderGraph = () => {
    var _color = {
      green: "#16d39a",
      yellow: "#ffda7d",
      orange: "#ffa87d",
      red: "#ff7588",
    };

    var _noData = true;

    var _data = [];

    for (var i in this.state.groupRiskData) {
      if (+this.state.groupRiskData[i] > 0) _noData = false;

      _data.push({
        value: this.state.groupRiskData[i],
        name: this.riskName[i],
        itemStyle: {
          color: _color[i],
        },
      });
    }

    var option = {
      tooltip: {
        trigger: "item",
      },

      series: [
        {
          type: "pie",
          radius: "70%",
          data: _data,
          label: {
            formatter: function (e) {
              return `${e.name} : ${e.value} (${e.percent}%)`;
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    if (_noData === true) {
      return <div className="uk-padding uk-text-center">No data</div>;
    } else {
      return (
        <ReactEcharts
          option={option}
          notMerge={true}
          lazyUpdate={true}
          style={{ height: 300 }}
          theme={"theme_name"}
        />
      );
    }
  };

  render() {
    return (
      <div
        className="uk-card uk-card-default uk-padding-small"
        id="d-report-report"
      >
        <div className="d-card-header">
          <div className="d-card-header-left">HEALTH OVERVIEW</div>
          <div className="d-card-header-right">
            <span className="icon-reload" onClick={this.dataGet}></span>
            <FullScreenElement element="#d-report-report" />
          </div>
        </div>
        <div
          uk-grid=""
          className="uk-child-width-1-6@m uk-child-width-1-2 uk-grid-small uk-margin-small-top"
        >
          <div>
            <div className="uk-text-meta">กลุ่มโรค</div>
            {this.groupRiskSelectRender()}
          </div>
          <div>
            <div className="uk-text-meta">ตำแหน่ง</div>
            {this.jobSelectRender()}
          </div>
          <div>
            <div className="uk-text-meta">แผนก</div>
            {this.departmentSelectRender()}
          </div>
          <div>
            <div className="uk-text-meta">เพศ</div>
            {this.genderSelectRender()}
          </div>
          <div>
            <div className="uk-text-meta">อายุ</div>
            {this.ageSelectRender()}
          </div>
          <div>
            <div className="uk-text-meta">ปี</div>
            {this.yearSelectRender()}
          </div>
        </div>
        {this.state.loadingApi ? (
          <div className="uk-padding uk-text-center">
            <span uk-spinner=""></span>
          </div>
        ) : (
          <div
            className="uk-margin-small-top uk-child-width-1-1 uk-child-width-1-2@s"
            uk-grid=""
          >
            <div>
              <div className="uk-margin-top uk-text-center uk-text-meta">
                ระดับความเสี่ยง
              </div>
              {this.renderGraph()}
            </div>
            <div>
              <div className="uk-margin-top uk-text-center uk-text-meta">
                โรคร่วม
              </div>
              {this.renderGraphInvolve()}
            </div>
            <div className="uk-width-1-1 uk-text-center uk-text-bold">
              <hr />
              <span className="uk-margin-right uk-display-inline-block uk-text-center">
                จำนวนทั้งหมด {this.amountUserRender()} คน 
              </span>
              <span className="uk-display-inline-block uk-text-center">
                จำนวนผู้ที่มีความเสี่ยง {this.amountRiskUserRender()} คน (
                {numeral(
                  (this.amountRiskUserRender() * 100) / this.amountUserRender()
                ).format("0,0.00")}
                %)
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
