import NovalueCheck from './NoValueCheck';

export default (value, age, gender) => {
  if(gender == '1') gender = 'male';
  if(gender == '0') gender = 'female';
  
  if(NovalueCheck(value)) return NovalueCheck(value);

  value = +value;

  var midValue = 19;
  if(gender === 'male') {
    midValue = 30;
  }

  if(value <= midValue) {
    return { 
      title: 'การทำงานของตับปกติ',
      color: 'green',
      caption: 'การทำงานของตับปกติ',
      suggest: ''
    };
  } else {
    return { 
      title: 'การทำงานของตับผิดปกติ',
      color: 'orange',
      caption: 'ค่า SGPT/ALT สูงกว่าปกติ',
      suggest: 'ปรับพฤติกรรม พบนักโภชนาการ หรือ นักกำหนดอาหาร'
    };
  }

}