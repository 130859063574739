var _m2_campaign_ids = [185,302,514,515,516,517,518,519,520,521,522,523,524,541,542,543,544,545,546,547,548,549,550,551,552,553,554,555,556,557,558,559,560,561,562,563,564,565,566,567,568,569,570,571,572,573,574,575,576,577,578,579,580,581,582,583,584,585,586,587,588,589,590,591,592,593,594,595,596,597,598];

var api_route = {
  504: {
    prefix: "rtaf",
    version: "v1.0.1"
  },
  499: {
    prefix: "roiet",
    version: "v1.0.0"
  },
  492: {
    prefix: "ppk",
    version: "v1.0.0"
  },
  479: {
    prefix: "banpong",
    version: "v1.0.0",
    use_preview_box : true
  },
  335: {
    prefix: "smc",
    version: "v1.0.0",
    use_preview_box : true
  },
  540 : {
    prefix: "kku",
    version: "v1.0.1",
    use_preview_box : true
  },
  601 : {
    prefix: "pth",
    version: "v1.0.1",
    use_preview_box : true
  }
}

for(var k of _m2_campaign_ids) {
  api_route[k] = {
    prefix: "m2",
    version: "v1.0.0",
    use_preview_box : true
  };
}

export default api_route;