import NovalueCheck from './NoValueCheck';

export default (value) => {
  if(NovalueCheck(value)) return NovalueCheck(value);

  value = +value;

  if(value < 130) {
    return { 
      title: 'ปกติ',
      color: 'green',
      caption: 'ปกติ',
      suggest: ''
    };
  } else {
    return { 
      title: 'ไขมันเลวสูง',
      color: 'orange',
      caption: 'ไขมันเลวสูง',
      suggest: ''
    };
  }

}