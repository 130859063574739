import React, { Component } from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Api from '../../Components/Api';
import Formular from '../Formular';
import GroupReport from '../Formular/GroupReport';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import numeral from 'numeral';
import FullScreenElement from '../FullScreenElement';
import ReactEcharts from 'echarts-for-react';

UIkit.use(Icons);

var maxDataValue = (data) => {
  var maxValue = 0;
  var maxColor = '';

  for (var i in data) {
    if (+data[i] >= maxValue) {
      maxValue = +data[i];
      maxColor = i;
    }
  }

  return { color: maxColor, amount: maxValue };

}

var dataIcon = {};

export default class SmartHealthTrendingRisk extends Component {

  state = {
    data2Year: [],
    data5Year: [],
    data10Year: [],
    loadingApi: false,
    selectAge: '',
    selectGender: '',
    selectDepartment: '',
    selectJob: '',
    optionDepartment: [
      {label: 'ทั้งหมด', value: ''}
    ],
    optionJob: [
      {label: 'ทั้งหมด', value: ''}
    ],
    data: null,
    dataInGraph: {}
  }

  componentDidMount() {
    this.dataCacheGet();
  }

  dataRender = () => {
    var _member = {};

    var _dataTypeAmount = {};

    var _department = [];

    var _job = [];

    var data = this.state.data;

    /*Format data */
    for (var i in data) { /** Year */
      for (var i1 in data[i]) { /** Data name */
        _dataTypeAmount[i1] = data[i][i1].length;
        for (var i2 = data[i][i1].length; i2-- > 0;) {
          if (data[i][i1][i2]) {
            for (var i3 = data[i][i1][i2].length; i3-- > 0;) {
              var _useMemberData = false;
              var _memberData = data[i][i1][i2][i3];

              //if first call render option department and job
              if(this.state.optionDepartment.length === 1) {
                if(_memberData.position && _department.indexOf(_memberData.position) < 0) _department.push(_memberData.position);

                if(_memberData.job && _job.indexOf(_memberData.job) < 0) _job.push(_memberData.job);
              }

              if(this.state.selectAge !== '') {

                _memberData.age = +_memberData.age;

                //filter
                switch(this.state.selectAge) {
                  case 15:
                    if(_memberData.age < 15) {
                      _useMemberData = true;
                    }
                  break;
                  case 25:
                    if(_memberData.age >= 15 && _memberData.age <= 25) {
                      _useMemberData = true;
                    }
                  break;
                  case 61:
                    if(_memberData.age > 60) {
                      _useMemberData = true;
                    }
                  break;
                  default: 
                    if(_memberData.age > +this.state.selectAge - 5 && _memberData.age <= +this.state.selectAge) {
                      _useMemberData = true;
                    }
                  break;
                }
              } else {
                _useMemberData = true;
              }

              if(_useMemberData === true )
                if(this.state.selectGender !== '') {
                  if(this.state.selectGender == _memberData.gender) {
                    _useMemberData = true;
                  } else {
                    _useMemberData = false;
                  }
                }

              if(_useMemberData === true )
                if(this.state.selectDepartment !== '') {
                  if(this.state.selectDepartment == _memberData.position) {
                    _useMemberData = true;
                  } else {
                    _useMemberData = false;
                  }
                }

              if(_useMemberData === true )
                if(this.state.selectJob !== '') {
                  if(this.state.selectJob == _memberData.job) {
                    _useMemberData = true;
                  } else {
                    _useMemberData = false;
                  }
                }

              if(_useMemberData === true) {
                if (!_member[_memberData.memberid]) _member[_memberData.memberid] = {};
                if (!_member[_memberData.memberid][i]) _member[_memberData.memberid][i] = {};
  
                if (Formular[i1]) {
                  _member[_memberData.memberid][i][i1] = _memberData;
                  
                  _member[_memberData.memberid][i][i1].detail = Formular[i1](_memberData.value, _memberData.age, _memberData.gender);
                }
              }
            }
          }
        }
      }
    }

    /** find summary of risk */
    var _dataByYear = {};

    for (var i in _member) { /** member id */
      for (var i1 in _member[i]) { /** year */

        var _groupReport = GroupReport(_member[i][i1]);

        if (!_dataByYear[i1]) _dataByYear[i1] = {};

        for (var g = _groupReport.length; g-- > 0;) {
          if (!_dataByYear[i1][_groupReport[g].label]) _dataByYear[i1][_groupReport[g].label] = {
            green: 0,
            yellow: 0,
            orange: 0,
            red: 0
          };

          _dataByYear[i1][_groupReport[g].label][_groupReport[g].color]++;

          if (!dataIcon[_groupReport[g].label]) dataIcon[_groupReport[g].label] = _groupReport[g].icon;
        }

      }
    };

    /** return data of year */
    var _dataOnYear = (_year) => {
      return _dataByYear[moment().subtract(_year, 'y').format('YYYY')] || {};
    };

    var _0year = _dataOnYear(0);

    var _dataInGraph = {
      '2': {},
      '5' : {},
      '10' : {}
    };

    /*** renderRisk */
    var _percentRender = (_useYear, numberOfYear) => {
      var _pointCal = (_data) => {
        var _pointGreen = 1, _pointAll = 1; //start 1 to avoid infinity

        for (var i in _data) {
          if (i === 'green') {
            _pointGreen += _data[i];
          }

          _pointAll += _data[i];
        }


        return (_pointGreen / _pointAll);
      };

      var _type = {};
      for (var i in _useYear) {
        if (_0year[i]) {
          var _calUseYear = _pointCal(_useYear[i]);
          var _cal0Year = (_pointCal(_0year[i]));
          
          _dataInGraph[numberOfYear][i] = [_calUseYear, _cal0Year];

          _type[i] = ((_cal0Year - _calUseYear) * 100) / _calUseYear;
        }
      }

      return _type;
    }
    /*** setting for data in table */

    var _dataTableRender = (numberOfYear) => {
      var _data = _dataOnYear(numberOfYear);
      var _dataTable = [];
      var _percentChange = _percentRender(_data, numberOfYear);

      for (var i in _data) {
        _dataTable.push({
          label: i,
          icon: dataIcon[i],
          percent: _percentChange[i] || 0,
          year: numberOfYear,
          ...maxDataValue(_data[i])
        })
      }

      return _dataTable.reverse();
    }


    if(this.state.optionDepartment.length === 1) {
      _department = _department.map((department) => ({label: department, value: department}));

      _department.splice(0,0, {label: 'ทั้งหมด', value: ''});

      _job = _job.map((job) => ({label: job, value: job}));

      _job.splice(0,0, {label: 'ทั้งหมด', value: ''});


      this.setState({
        optionDepartment: _department,
        optionJob: _job
      });
    }
    

    setTimeout(()=>{
      this.setState({
        dataInGraph: _dataInGraph
      }, ()=>{
        this.setState({
          data2year: _dataTableRender(2),
          data5year: _dataTableRender(5),
          data10year: _dataTableRender(10),
          loadingApi: false,
        });
      })
      
    } ,100);

  }

  renderGraph = (data) => {
    var _dataGraph = this.state.dataInGraph[data.year] ? this.state.dataInGraph[data.year][data.label] : [];
    if(!_dataGraph) {

      _dataGraph = [1,1];
    }

    var option = {
      grids: {
        borderWidth: 0,
        backgroundColor: '#fff',
        shadowColor: 'rgba(0, 0, 0, 0.3)',
        shadowBlur: 2
      },
      grid: {
          left: '1px',
          right: '1px',
          bottom: '30px'
      },

      color: ['#3975AF'],
      // Hirozontal axis
      xAxis: [{
        show: false,
        boundaryGap: false,
        data: ['a', 'b'],
      }],

      // Vertical axis
      yAxis: [{
        show: false,
        type: 'value',
      }],

      // Add series
      // Add series
      series: {
        type: 'line',
        itemStyle: {
          borderColor: '#FFFFFF',
          borderWidth: 2
        },
        data: _dataGraph
      }
    };


    return <ReactEcharts
      option={option}
      notMerge={true}
      lazyUpdate={true}
      style={{height: 60, width: 100}}
      theme={"theme_name"}
    />
    
  }

  dataCacheGet = async () => {
    this.setState({ loadingApi: true });
    try {

      var { data } = await Api.member("DataCache", {
        cachetype: 'SmartHealthTrendingRisk'
      });
  
      if(data) {
        this.setState({data} , this.dataRender);
      } else {
        this.dataGet();
      }
    } catch(e) {

      this.dataGet();
    }
  }

  dataGet = async () => {
    this.setState({ loadingApi: true });
    var { data } = await Api.member('SmartHealthTrendingRisk', {
      selectAge: this.state.selectAge,
      selectGender: this.state.selectGender,
      selectDepartment: this.state.selectDepartment,
      selectJob: this.state.selectJob
    });
  
    this.setState({data} , this.dataRender);


  }

  jobSelectRender = () => {
    return <select className="uk-select" onChange={e=>this.setState({selectJob: e.target.value}, this.dataRender)}>
      {this.state.optionJob.map((job)=>{
        return <option value={job.value}>{job.label}</option>
      })}
    </select>
  }

  departmentSelectRender = () => {
    return <select className="uk-select" onChange={e=>this.setState({selectDepartment: e.target.value}, this.dataRender)}>
      {this.state.optionDepartment.map((department)=>{
        return <option value={department.value}>{department.label}</option>
      })}
    </select>
  }

  ageSelectRender = () => {
    var _age = ['',15,25,30,35,40,45,50,55,60,61];

    var _ageLabel = [
      'ทั้งหมด',
      'น้อยกว่า 15 ปี',
      '15 - 25 ปี',
      '26 - 30 ปี',
      '31 - 35 ปี',
      '36 - 40 ปี',
      '41 - 45 ปี',
      '46 - 50 ปี',
      '51 - 55 ปี',
      '56 - 60 ปี',
      '61 ปี ขึ้นไป',
    ];
    
    return <select className="uk-select" onChange={e=>this.setState({selectAge: e.target.value}, ()=>this.dataRender())}>
      {_age.map((age, key)=>{
        return <option value={age}>{_ageLabel[key]}</option>
      })}
    </select>
  }

  genderSelectRender = () => {
    var _gender = [
      {
        label: 'ทั้งหมด',
        value: ''
      },
      {
        label: 'ชาย',
        value: '1'
      },
      {
        label: 'หญิง',
        value: '0'
      }
    ];
    
    return <select className="uk-select" onChange={e=>this.setState({selectGender: e.target.value}, ()=>this.dataRender())}>
      {_gender.map((gender)=>{
        return <option value={gender.value}>{gender.label}</option>
      })}
    </select>
  }

  badge = (color) => {
    var _label = '';
    switch (color) {
      case 'green':
        _label = 'Normal';
        break;
      case 'yellow':
        _label = 'Low Risk';
        break;
      case 'orange':
        _label = 'Risk';
        break;
      case 'red':
        _label = 'High Risk';
        break;
    }

    return <span className={`d-risk-label d-risk-${color}`}>{_label}</span>
  }

  columns = [
    {
      name: '',
      cell: row => <div className="d-table-icon-container">{row.icon === '' ? '' : <div className={`d-risk-logo-container d-risk-${row.color}`} ><img className="d-risk-logo" src={`/assets/${row.icon}.png`} /></div>}</div>,
      sortable: false
    },
    {
      name: 'Health Risk',
      cell: row => <div>
        <div>{row.label}</div>
        <div>{row.color === 'green' ? 'ปกติ' : 'ผิดปกติ'}</div>
      </div>,
      sortable: false
    },
    {
      name: 'Status',
      cell: row => <div>{this.badge(row.color) || ''}</div>,
      sortable: false
    },
    {
      name: '+% -%',
      cell: row => <div>{numeral(row.percent).format('0')} %</div>,
      sortable: false
    },
    {
      name: 'Change',
      cell: row => <div>{this.renderGraph(row)}</div>,
      sortable: false
    }
  ];

  render() {
    return <div className="uk-card uk-card-default uk-padding-small" id="d-smarth-health-trending-risk">
      <div className="d-card-header">
        <div className="d-card-header-left">
          TRENDING RISK
        </div>
        <div className="d-card-header-right">
          <span className='icon-reload' onClick={this.dataGet}></span>
          <FullScreenElement element="#d-smarth-health-trending-risk" />
        </div>
      </div>
      <div uk-grid="" className="uk-child-width-1-4@m uk-child-width-1-2 uk-grid-small uk-margin-small-top">
        <div>
          <div className="uk-text-meta">ตำแหน่ง</div>
          {this.jobSelectRender()}
        </div>
        <div>
          <div className="uk-text-meta">แผนก</div>
          {this.departmentSelectRender()}
        </div>
        <div>
          <div className="uk-text-meta">เพศ</div>
          {this.genderSelectRender()}
        </div>
        <div>
          <div className="uk-text-meta">อายุ</div>
          {this.ageSelectRender()}
        </div>
      </div>
      {
        this.state.loadingApi ?
          <div className="uk-padding uk-text-center">
            <span uk-spinner=""></span>
          </div>
          :
          <div className="uk-margin-small-top">
            <ul uk-tab="">
              <li><a href="#">2 Year</a></li>
              <li><a href="#">5 Year</a></li>
              <li><a href="#">10 Year</a></li>
            </ul>
            <ul className="uk-switcher">
              <li>
                <DataTable
                  noHeader={true}
                  columns={this.columns}
                  data={this.state.data2year}
                />
              </li>
              <li>
                <DataTable
                  noHeader={true}
                  columns={this.columns}
                  data={this.state.data5year}
                />
              </li><li>
                <DataTable
                  noHeader={true}
                  columns={this.columns}
                  data={this.state.data10year}
                />
              </li>
            </ul>
          </div>
      }


    </div>
  }
};
