import React, { Component } from 'react';

export default class LanguageLoginSelect extends Component {

  langageSelect = (language) => {
    localStorage.setItem('language', language);
    window.location.reload();
  }

  render() {

    return <div className={"uk-text-right " + (this.props.headerMenu ? 'uk-inline uk-margin-right' : 'd-language-login-select-container') }>
      <a className="uk-link-reset uk-display-inline-block uk-margin-small-right" onClick={()=>this.langageSelect('th')}>
        <img src="/assets/th_flag.png" width="20" />
        {this.props.headerMenu !== true  && <div className="uk-text-center">TH</div>}
      </a>
      <a className="uk-link-reset uk-display-inline-block uk-margin-small-right" onClick={()=>this.langageSelect('en')}>
        <img src="/assets/en_flag.png" width="20" />
        {this.props.headerMenu !== true  && <div className="uk-text-center">ENG</div>}
      </a>
      <a className="uk-link-reset uk-display-inline-block" onClick={()=>this.langageSelect('jp')}>
        <img src="/assets/jp_flag.png" width="20" />
        {this.props.headerMenu !== true  && <div className="uk-text-center">JP</div>}
      </a>
    </div>
  }

}
