import React, { Component } from 'react';
import __ from './Language';
import UIkit from 'uikit';
import moment from "moment";
import Api from "./Api";
import $ from 'jquery';
import {ScoreLabel, FormData} from './HivSelfStigmaData';

export default class HivSelfStigmaForm extends Component {

  state = {
    error_text: [],
    missing_medicine_value: null,
    q1: null,
    virus_check: null
  }


  radio = ({ name, label, value, className }) => {
    return <label className='uk-margin-small-bottom uk-text-center uk-text-normal uk-width-1-1 d-link'>
      <input type="radio" className={"uk-radio uk-margin-small-right " + (className || '')} name={name} value={value}  /> 
    </label>
  }

  checkbox = ({ name, label, value, className }) => {
    return <label className='uk-margin-small-bottom uk-flex uk-flex-middle uk-text-normal d-link'>
      <input type="checkbox" className={"uk-checkbox uk-margin-small-right " + (className || "")} name={name} onChange={(e) => {

      }} /> <span>{label || ''}</span>
    </label>
  }


  form_validate = (d) => {
    var _error = [];

    for(var f of FormData) {

      if (d[f.key] == null) {
        _error.push(`โปรดตอบคำถามข้อที่ ` + f.title);
      }
    } 

    return _error;

  }

  tag_render = (d) => {
    var _tag = [];
    var _score = 0;

    for(var k in d) {
      _score += parseInt(d[k]);
    }

    if(_score > 16) {
      _tag.push('Self-Stigma');
    }

    return _tag;

  }
 

  summaryTextRender = (data) => {
    var _r = '';

    for(var f of FormData) {
      _r += f.title + ' : ' + (data[f.key] ? ((ScoreLabel[data[f.key]] + ` (${data[f.key]})`) || '-') : '-') + ` \n`;
    }

    return `<b>แบบประเมินตีตราตนเอง</b>\n<b>วันที่ทำแบบประเมิน :</b> ${moment().add(543, 'years').format('DD/MM/YYYY')} เวลา ${moment().format('HH:mm น.')} \n` + _r;
  }


  submit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ error_text: [] })

    var _formData =  $(e.target).serializeArray();

    var _data = {}

    for (var d of _formData) {
      _data[d.name] = d.value;
    }

    var _noti = this.tag_render(_data);

    var _error = this.form_validate(_data);

    var _error_text = [];

    var _d = this.summaryTextRender(_data)
 

    if (_error.length > 0) {
      for (var e of _error) {
        _error_text.push(<li key={'error-' + e}>{e}</li>);
      }

      this.setState({
        error_text: <div>
          <div><b><u>โปรดกรอกข้อมูล</u></b></div>
          <ul>
            {_error_text}
          </ul>
        </div>
      });

      return;
    }
    else { 
      var _r = await Api.member("SelfStigmaDataAdd", {
        data: JSON.stringify(_data),
        dateadd: moment().format('YYYY-MM') + '-01',
        campaignId: this.props.campaignId,
        noti: _noti.join(','),
      });
 

      if (_r.result === true) {

        this.props.callback(this.summaryTextRender(_data), _noti)
      } else {
        UIkit.notification({ message: 'เกิดข้อผิดพลาด : ' + _r.responseText });
      }

    }


    return;
  }

  render() {
    return <div id="hiv-stigma-modal-form" className="uk-modal-full" uk-modal="">
      <div className="uk-modal-dialog uk-modal-body">
        <div className="uk-text-right">
          <a className="uk-modal-close">
            <span uk-icon="close"></span>
          </a>

        </div>
        <h4 className='uk-text-bold'>แบบประเมินตีตราตนเอง</h4>
        <div className='uk-text-danger uk-margin-bottom'><b>คําชี้แจง</b> คําตอบของท่านจะถูกเก็บเป็นความลับ และใช้ในการดูแลรักษาและพัฒนาระบบบริการเท่านั้น โปรดเลือกคําตอบที่ตรงกับท่านมากที่สุด</div>
        <form onSubmit={this.submit}>
          <table className="uk-table uk-table-small uk-table-striped uk-table-divider">
            <thead>
              <tr>
                <th rowSpan={2}>
                  <div>คำถาม</div>
                  <div>ท่านเห็นด้วยกับข้อความต่อไปนี้ หรือไม่</div>
                </th>
                <th className="uk-text-center" colSpan={4}>ความคิดเห็น</th>
              </tr>
              <tr>
                <th className="uk-text-center">
                  <div>เห็นด้วยอย่างยิ่ง</div>
                  <div>(4)</div>
                </th>
                <th className="uk-text-center">
                  <div>เห็นด้วย</div>
                  <div>(3)</div>
                </th>
                <th className="uk-text-center">
                  <div>ไม่เห็นด้วย</div>
                  <div>(2)</div>
                </th>
                <th className="uk-text-center">
                  <div>ไม่เห็นด้วยอย่างยิ่ง</div>
                  <div>(1)</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {FormData.map((d)=>{
                return <tr>
                  <td>{d.title}</td>
                  {[4,3,2,1].map((value)=>{
                    return <td key={d.key+value} className="uk-text-center">{this.radio({
                      name: d.key,
                      value: value
                    })}</td>
                  })}
                </tr>
              })}
            </tbody>
          </table>

          <div id="error-container" className='uk-text-danger'>{this.state.error_text || ''}</div>

          <hr />

          <div className='uk-text-right uk-margin-top'>

            <button className='uk-button uk-button-default uk-modal-close'>
              ยกเลิก
            </button>
            <button className='uk-button uk-button-primary uk-margin-left' type="submit">ส่งแบบประเมิน</button>
          </div>
        </form>
      </div>
    </div>
  }
};
