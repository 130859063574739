import Rating from 'react-rating';
import React, { Component } from 'react';

export default class RatingDietz extends Component {
  render() {
    return <Rating readonly={this.props.readonly} initialRating={this.props.initialRating} stop={this.props.stop} 
      emptySymbol={<svg width="15" height="15" style={{ marginTop: -5, fill: '#edeef0' }}><g transform="scale(0.015 0.015)"><path d="M1024 397.050l-353.78-51.408-158.22-320.582-158.216 320.582-353.784 51.408 256 249.538-60.432 352.352 316.432-166.358 316.432 166.358-60.434-352.352 256.002-249.538z"></path></g></svg>}
      fullSymbol={<svg width="15" height="15" style={{ marginTop: -5, fill: '#16d39a' }}><g transform="scale(0.015 0.015)"><path d="M1024 397.050l-353.78-51.408-158.22-320.582-158.216 320.582-353.784 51.408 256 249.538-60.432 352.352 316.432-166.358 316.432 166.358-60.434-352.352 256.002-249.538z"></path></g></svg>}
    />
  }

}
