import React, { Component } from "react";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import Api from '../../Components/Api';
import $ from 'jquery';

UIkit.use(Icons);

export default class WorkHealthRecord extends Component {
  state = {
    company: {},
    profile: {},
    lab: {},
    campaign: [],
    campaignSelect: '',
    typeDataSelect: 'ตรวจสุขภาพก่อนเข้างาน'
  };

  typeData = [
    "ตรวจสุขภาพก่อนเข้างาน",
    "ตรวจสุขภาพประจำปี/ตรวจตามปัจจัยเสี่ยง",
    "ตรวจสุขภาพด้วยตนเองที่บ้าน",
    "ตรวจสุขภาพที่โรงพยาบาลเพื่อติดตามอาการ",
    "อื่นๆ"
 ]

  componentDidMount () {
    this.init();
  }


  init = () => {
    this.profileGet();

  }

  campaignList = async () => {
    var _response = await Api.member("CampaignList", {memberId: this.props.memberId});
    
    if(!_response.list) _response.list = [];

    this.setState({campaign: _response.list, campaignSelect: _response.list[0] ? _response.list[0].id : ''}, () => {

      this.labGet();
    });

  }

  labGet = async () => {
    if(this.props.qs && this.props.qs.dateadd) {
      var _response = await Api.member("WorkHealthRecordGet", {dateadd: this.props.qs.dateadd, memberId: this.props.memberId || false});
    
      // console.log(_response)

      if(_response.data) {
        var _lab = {};

        _response.data.forEach((data)=>{
          _lab[data.name] = data;
        })


        this.setState({
          lab: _lab,
          typeDataSelect: _response.data[0].datatype,
          campaignSelect: _response.data[0].campaignid
        }, ()=>{
          if(this.props.qs.print === '1') this.printPrompt();

        });
      }

    }

  }

  profileGet = async () => {
    var { profile, company, group } = await Api.member("UserProfileCard", {memberId: this.props.memberId || false});


    this.setState({
      bmi: profile.bmi ? profile.bmi : "-",
      profile: profile ? profile : {},
      company: company ? company : {},
      group: group || []
    }, () => {
      this.campaignList();
    });
  };

  saveForm = async () => {
    var _response = await Api.member("WorkHealthRecordSave", {campaignId: this.state.campaignSelect, dataType: this.state.typeDataSelect, lab: this.state.lab, dateadd: this.props.qs.dateadd || false});
    UIkit.notification(_response.responseText);

    if(!this.props.qs.dateadd && _response.result) window.location.href = "/work-health-record?dateadd=" + encodeURI(_response.dateadd);
  }

  setLabValue =(key, value) => {
    this.setState((state)=>{
      if(!state.lab[key]) state.lab[key] = {
        id: '',
        value: value,
        checkresult: '',
        checkplace: '',
        checknote: ''
      }

      state.lab[key].value = value;
      return {lab: state.lab};
    }, ()=> {
      console.log(this.state)
    })
  }

  getForm = (name, option) => {

    if(!option) option = {};

    if(!option.style) option.style ={}

    if(!option.type) option.type = "text";

    if(option.type === 'textarea') {
      return <textarea className="uk-textarea"  style={option.style} onChange={(e)=>this.setLabValue(name, e.target.value)} value={this.state.lab[name] ? this.state.lab[name].value :  ''}></textarea>
    }
    else if(option.type === 'radio') {
      return <input className="uk-radio" type="radio" name={option.name} checked={this.state.lab[option.name] && this.state.lab[option.name].value == option.value} onChange={(e)=>{
        this.setLabValue(name, option.value)
      }} />
    }
    else if(option.type === 'datepicker') {
      return <input className="uk-input" type="date" value={this.state.lab[name] ? this.state.lab[name].value :  ''} onChange={(e)=>{
        this.setLabValue(name, e.target.value);
      }} />
    }
    else {
      return <input className="uk-input" type={option.type} style={option.style} onChange={(e)=>this.setLabValue(name, e.target.value)} value={this.state.lab[name]  ? this.state.lab[name].value : ''} />;
    }
  };

  toTopNav =() => {
    return <div className="uk-text-right uk-margin-top uk-margin-bottom"><a href="#workbook-nav"><i className="icon-arrow-up-circle"></i> กลับด้านบน</a></div>
  }

  
  printPrompt = () => {
    
    $("#d-print-container").remove();

    $("#root").prepend(
      $('<div id="d-print-container">').html([
        `<style>.uk-input, .uk-textarea{ border-width: 0 !important; resize: none; color: inherit; padding: 0 !important; } body { background: none !important;}</style>`,
        $(".d-work-health-record-container").html()
     ])
    );
    
    
    $("#d-print-container").css({display: 'none'});
    window.print();

    $("#d-print-container").html("");

    if(this.props.printCallback) this.props.printCallback();
  };


  render() {
    return (
      <div className="uk-padding">
        <div className="uk-margin-small-bottom uk-text-right">
          <button onClick={this.printPrompt} className="uk-button uk-button-default d-button-header-sub uk-button-small"><span uk-icon="print"></span> พิมพ์</button>
        </div>
        <div className="uk-card uk-card-default uk-padding-small">
          <div className="d-card-header">
            <div className="d-card-header-left">บันทึกสุขภาพ</div>
            </div>
          <div className="uk-margin-small-bottom">
            <div className="uk-margin-small-bottom uk-text-bold">แคมเปญ</div>
            <select className="uk-select" value={this.state.campaignSelect} onChange={(e)=>this.setState({campaignSelect: e.target.value})}>{this.state.campaign.map((value, index)=> <option key={`campaign${value.id}`} value={value.id}>{value.name}</option>)}</select>
          </div>
          <div id="workbook-nav" className="uk-margin-small-bottom">
            <div className="uk-margin-small-bottom uk-text-bold">ประเภทการบันทึก</div>
            <select className="uk-select" value={this.state.typeDataSelect} onChange={(e)=>this.setState({typeDataSelect: e.target.value})}>{this.typeData.map((value, index)=> <option key={`typeData${index}`} value={value}>{value}</option>)}</select>
          </div>
          <div className="uk-margin-bottom">
            <a href="#nav-physical-examination" className="uk-margin-small-right">[รายงานผลการตรวจร่างกายเบื้องต้น (Physical Examination)]</a>
            <a href="#nav-chest-x-ray" className="uk-margin-small-right">[การตรวจภาพรังสีทรวงอก (Chest X-Ray)]</a>
            <a href="#nav-electrocardiogram-ekg" className="uk-margin-small-right">[ตรวจคลื่นไฟฟ้าหัวใจ (Electrocardiogram-EKG)]</a>
            <a href="#nav-occupation-vision-tests" className="uk-margin-small-right">[ตรวจสายตาอาชีวอนามัย (Occupation Vision Tests)]</a>
            <a href="#nav-urinalysis" className="uk-margin-small-right">[ผลการตรวจปัสสาวะ (Urinalysis)]</a>
            <a href="#nav-complete-blood-count" className="uk-margin-small-right">[ผลตรวจนับเม็ดเลือดแบบสมบูรณ์ (Complete Blood Count)]</a>
            <a href="#nav-audiometry" className="uk-margin-small-right">[ผลตรวจสมรรถภาพการได้ยิน (Audiometry)]</a> 
            <a href="#nav-spirometry" className="uk-margin-small-right">[ผลตรวจสมรรถภาพปอด (Spirometry)]</a>
            <a href="#nav-laboratory" className="uk-margin-small-right">[ผลตรวจทางห้องปฏิบัติการ (Laboratory)]</a>
            <a href="#nav-toxic" className="uk-margin-small-right">[ผลตรวจรายการอื่นๆ(Other) และสารพิเศษ (Toxic)]</a>
            <a href="#nav-stool-examination" className="uk-margin-small-right">[ผลตรวจอุจจาระ (Stool Examination)]</a> 
            <a href="#nav-stool-culture" className="uk-margin-small-right">[ผลตรวจเพาะเชื้อก่อโรคระบบทางเดินอาหาร ( Stool Culture )]</a>
            <a href="#nav-other" className="uk-margin-small-right">[ข้อมูลอื่นๆ]</a>
            <a href="#nav-2q" className="uk-margin-small-right">[แบบคัดกรองโรคซึมเศร้า 2 คำถาม (2Q)]</a>
            <a href="#nav-pv-pap-smear" className="uk-margin-small-right">[PV & Pap smear]</a>
            <a href="#nav-mamogram" className="uk-margin-small-right">[Mamogram]</a>
            <a href="#nav-ultrasound" className="uk-margin-small-right" >[Ultrasound]</a>
            <a href="#nav-exercise-stress" className="uk-margin-small-right">[Exercise stress]</a>
            <a href="#nav-vaccine" className="uk-margin-small-right">[ประวัติการได้รับวัคซีน]</a>
            <a href="#nav-pregnant" className="uk-margin-small-right">[ประวัติการฝากครรภ์]</a>

          </div>
          <div className="d-work-health-record-container">
            <div>
              {/**  */}
              <div
                className="d-user-health-book-container"
                style={{ minHeight: 740 }}
              >
                <div className="d-text-blue uk-text-center">
                  [สรุปผลการตรวจและข้อแนะนำในการปฏิบัติตนประจำปี{" "}
                  {this.getForm("yearLab", {
                          style: {
                            width: 60,
                            color: 'blue'
                          }
                        })}]
                </div>
                <div>- ผลการตรวจร่างกายทั่วไปโดยแพทย์ </div>
                <div>* ผลตรวจภาพรังสีทรวงอก </div>
                <div>* ผลตรวจนับเม็ดเลือดแบบสมบูรณ์ </div>
                <div>* ผลตรวจปัสสาวะ </div>
                <div>* ผลตรวจระดับน้ำตาลในเลือด </div>
                <div>* ผลตรวจระดับไขมันในเลือด </div>
                <div>* ผลตรวจระดับไขมันไตรกลีเซอไรด์ในเลือด </div>
                <div>* ผลตรวจไขมันชนิดดี(HDL) </div>
                <div>* ผลตรวจไขมันเลว(LDL) </div>
                <div>* ผลตรวจเอนไซม์ตับ </div>
                <div>* ผลตรวจการทํางานของไต </div>
                <div>* ผลตรวจระดับกรดยูริคในเลือด </div>
                <div>* ผลตรวจเลือดไม่พบเชื้อไวรัสตับอักเสบบี </div>
                <div>* ผลตรวจเลือดไม่พบเชื้อไวรัสตับอักเสบบี </div>
                <div>* ผลตรวจคลื่นไฟฟ้าหัวใจ (EKG)</div>
              </div>
              <div className="d-page-break"></div>

              {/**  */}
              <div className="d-user-health-book-container">
                <div id="nav-physical-examination" className="d-text-blue uk-text-center">
                  [รายงานผลการตรวจร่างกายเบื้องต้น (Physical Examination)]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td>
                      <span className="d-text-blue">นำ้หนัก (Weight)</span>
                      {" "}<span
                        className="uk-text-center"
                        style={{ display: "inline-block", width: 60 }}
                      >
                        {this.getForm("weight", {
                          style: {
                            width: 60
                          }
                        })}
                      </span>{" "}
                      <span className="d-text-blue">กิโลกรัม</span>
                    </td>
                    <td>
                      <span className="d-text-blue">ส่วนสูง (Height)</span>
                      {" "}<span
                        className="uk-text-center"
                        style={{ display: "inline-block", width: 60 }}
                      >
                        {this.getForm("height", {style: {width: 60}})}
                      </span>{" "}
                      <span className="d-text-blue">เซนติเมตร</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="d-text-blue">ดัชนีมวลกาย(BMI) </span>
                      <span
                        className="uk-text-center"
                        style={{ display: "inline-block", width: 65 }}
                      >
                        {this.getForm("bmi", {style: {width: 65}})}
                      </span>
                      <span className="d-text-blue">
                        กก./ม<sup>2</sup>.
                      </span>{" "}
                      <span className="d-text-pink">
                        [ค่าปกติ:18.5-24.9 กก./ม<sup>2</sup>.]
                      </span>
                    </td>
                    <td>
                      <span className="d-text-blue">หมู่โลหิต (ABO)</span>
                      <span
                          className="uk-text-center"
                          style={{ display: "inline-block", width: 65 }}
                        >
                        {this.getForm("abo", {style: {width: 65}})}
                      </span>
                    </td>
                  </tr>
                </table>
                <div>
                  {this.getForm("bmi-note", {type: 'textarea'})}
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td>
                      <span className="d-text-blue">ความดันโลหิต (BP) </span>
                      <span
                        className="uk-text-center"
                        style={{ display: "inline-block", width: 65}}
                      >
                        {this.getForm("sys/dias", {style: {width: 65}})}
                      </span>
                      <span className="d-text-blue">ม.ม.ปรอท</span>
                    </td>
                    <td>
                      <span className="d-text-blue">ชีพจร (Pulse) </span>
                      <span
                        className="uk-text-center"
                        style={{ display: "inline-block", width: 45 }}
                      >
                        {this.getForm("pulse", {style: {width: 45}})}
                      </span>
                      <span className="d-text-blue">ครั้ง/นาที</span>
                    </td>
                  </tr>
                </table>
                <div>
                  {this.getForm("pulse-note", {type: 'textarea'})}
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td>
                      <div className="d-text-blue uk-text-center">
                        โรคประจำตัว
                      </div>
                      <div>
                        {this.getForm("congenitaldisease", {type: 'textarea'})}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-text-blue uk-text-center">
                        ประวัติแพ้ยา,แพ้อาหาร
                      </div>
                      <div>
                        {this.getForm("drug-allergy", {type: 'textarea'})}
                      </div>
                    </td>
                  </tr>
                </table>
                <div>
                  <div className="uk-text-center">
                    <span className="d-text-blue">
                      <u>รายละเอียดของผู้ที่เข้ารับการตรวจ</u>
                    </span>
                  </div>
                  <div>
                    <span className="d-text-blue">บริษัท : </span>{" "}
                    {this.state.company.name}
                    <span className="d-text-purple">
                      <span
                        className="uk-text-center"
                        style={{ display: "inline-block", width: 65 }}
                      ></span>
                    </span>
                  </div>
                  <div>
                    <span className="d-text-blue">ชื่อ-นามสกุล : </span>{" "}
                    <span>
                      {this.state.profile.nameprefix} {this.state.profile.name}{" "}
                      {this.state.profile.lastname}
                    </span>
                  </div>
                  <div>
                    <span className="d-text-blue">แผนก/ตำแหน่ง : </span>{" "}
                    <span>{this.state.company.position}</span>
                  </div>
                  <div>
                    <span className="d-text-blue">รหัส : </span>
                    <span
                      className="uk-text-center"
                      style={{ display: "inline-block", width: 64 }}
                    >{this.getForm("no", {style: {width: 90}})}</span>
                    <span className="d-text-blue uk-margin-left">
                      ลำดับที่ :{" "}
                    </span>{this.getForm("order", {style: {width: 120}})}
                  </div>

                  <div>
                    <span className="d-text-blue">อายุ : </span>
                    <span
                      className="uk-text-center"
                      style={{ display: "inline-block", width: 65 }}
                    >
                      {this.getForm("age", {style: {width: 45}})}
                    </span>
                    <span className="d-text-blue">ปี</span>
                    <span className="d-text-blue uk-margin-left">
                      ตรวจวันที่ :{" "}
                    </span>{" "}
                    {this.getForm("dateLab", {style: {width: 120}})}
                    <span></span>
                  </div>
                </div>
                <div>
                  <div className="uk-text-center">
                    <span className="d-text-blue">
                      <u>
                        สรุปผลการตรวจร่างกายเบื้องต้นและตรวจร่างกายทั่วไปโดยแพทย์
                      </u>
                    </span>
                  </div>
                  <div className="d-note-border-bottom">
                    - ผลตรวจร่างกายทั่วไปโดยแพทย์
                  </div>
                  <div>
                    {this.getForm("conclusion", {type: 'textarea'})} {this.getForm("conclusion-more", {type: 'textarea'})}
                  </div>
                </div>
                {this.toTopNav()}
              </div>

              <div className="d-page-break"></div>

              {/** */}
              <div className="d-user-health-book-container">
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td>
                      <div id="nav-chest-x-ray" className="d-text-blue uk-text-center">
                        [สรุปผลการตรวจภาพรังสีทรวงอก (Chest X-Ray)]
                      </div>
                      <div className="d-note-border-bottom">
                        * ผลตรวจภาพรังสีทรวงอก{" "}
                      </div>
                      <div>
                        {this.getForm("chest-x-ray", {type: 'textarea'})}
                      </div>
                      {this.toTopNav()}
                    </td>
                  </tr>
                </table>
                <table
                  border="1"
                  cellPadding="10"
                  cellSpacing="0"
                  width="100%"
                  className="uk-margin-small-top"
                >
                  <tr>
                    <td>
                      <div id="nav-electrocardiogram-ekg" className="d-text-blue uk-text-center">
                        [สรุปผลตรวจคลื่นไฟฟ้าหัวใจ (Electrocardiogram-EKG)]
                      </div>
                      <div className="d-note-border-bottom">
                        * ผลตรวจคลื่นไฟฟ้าหัวใจ (EKG){" "}
                      </div>
                      <div>
                        {this.getForm("ekg", {type: 'textarea'})}
                      </div>
                      {this.toTopNav()}
                    </td>
                  </tr>
                </table>
                <table
                  border="1"
                  cellPadding="10"
                  cellSpacing="0"
                  width="100%"
                  className="uk-margin-small-top"
                >
                  <tr>
                    <td>
                      <div id="nav-occupation-vision-tests" className="d-text-blue uk-text-center">
                        [ผลตรวจสายตาอาชีวอนามัย (Occupation Vision Tests)]
                      </div>
                      <table
                        border="1"
                        cellPadding="10"
                        cellSpacing="0"
                        width="90%"
                        className="uk-margin-small-top"
                        style={{ margin: "auto" }}
                      >
                        <tr>
                          <td className="d-text-purple">ประเภทการทดสอบ</td>
                          <td
                            className="d-text-purple uk-text-center"
                            width="30%"
                          >
                            ผลตรวจ
                          </td>
                        </tr>
                        <tr>
                          <td className="d-text-blue">ทดสอบการมองระยะไกล</td>
                          <td>{this.getForm("visual-acuity")}</td>
                        </tr>
                        <tr>
                          <td className="d-text-blue">ทดสอบการมองระยะใกล้</td>
                          <td>
                            {" "}
                            L : {this.getForm("close-visual-left", {style: {width: 60}})} R :{" "}
                            {this.getForm("close-visual-right", {style: {width: 60}})}
                          </td>
                        </tr>
                        <tr>
                          <td className="d-text-blue">
                            ทดสอบการมองชัดลึก (ภาพ 3 มิติ)
                          </td>
                          <td>{this.getForm("stereo-depth")}</td>
                        </tr>
                        <tr>
                          <td className="d-text-blue">ทดสอบการแยกสี</td>
                          <td>{this.getForm("color-blindness")}</td>
                        </tr>
                        <tr>
                          <td className="d-text-blue">
                            ทดสอบความสมดุลกล้ามเนื้อตา
                          </td>
                          <td>{this.getForm("eye-muscle-balance")}</td>
                        </tr>
                        <tr>
                          <td className="d-text-blue">ทดสอบลานสายตา</td>
                          <td>
                            L : {this.getForm("horizontal-visual-field-left", {style: {width: 60}})} R :{" "}
                            {this.getForm("horizontal-visual-field-right", {style: {width: 60}})}
                          </td>
                        </tr>
                      </table>
                      <div>
                        {this.getForm("occupation-note", {type: 'textarea'})}
                      </div>
                      {this.toTopNav()}
                    </td>
                  </tr>
                </table>
                
              </div>

              <div className="d-page-break"></div>

              {/** */}

              <div className="d-user-health-book-container">
                <div id="nav-urinalysis" className="d-text-blue uk-text-center">
                  [ผลการตรวจปัสสาวะ (Urinalysis)]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td width="40%">สีปัสสาวะ (Color)</td>
                    <td colSpan="2">{this.getForm("ua-color")}</td>
                  </tr>
                  <tr>
                    <td>ความขุ่นใส (Appearance)</td>
                    <td colSpan="2">{this.getForm("ua-apperance")}</td>
                  </tr>
                  <tr>
                    <td className="d-text-blue">Urine Strip</td>
                    <td className="d-text-blue uk-text-center">ผลตรวจ</td>
                    <td className="d-text-blue uk-text-center" width="150">
                      ค่าปกติ
                    </td>
                  </tr>
                  <tr>
                    <td>ความถ่วงจําเพาะ (Sp.gr.)</td>
                    <td className="uk-text-center">{this.getForm("ua-spgr")}</td>
                    <td className="d-text-blue uk-text-center">1.005-1.030</td>
                  </tr>
                  <tr>
                    <td>ค่าความเป็นกรด, ด่าง (pH)</td>
                    <td className="uk-text-center">{this.getForm("ua-ph")}</td>
                    <td className="d-text-blue uk-text-center">5.0 - 8.0</td>
                  </tr>
                  <tr>
                    <td>น้ําตาลในปัสสาวะ (Glucose)</td>
                    <td className="uk-text-center">
                      {this.getForm("ua-glucose")}
                    </td>
                    <td className="d-text-blue uk-text-center">Negative</td>
                  </tr>
                  <tr>
                    <td>โปรตีนในปัสสาวะ (Protein)</td>
                    <td className="uk-text-center">
                      {this.getForm("ua-protein")}
                    </td>
                    <td className="d-text-blue uk-text-center">Negative</td>
                  </tr>
                  <tr>
                    <td>เลือดในปัสสาวะ (Blood)</td>
                    <td className="uk-text-center">
                      {this.getForm("ua-blood")}</td>
                    <td className="d-text-blue uk-text-center">Negative</td>
                  </tr>
                  <tr>
                    <td>แอมเฟตามีน (Amphetamine)</td>
                    <td className="uk-text-center">
                      {this.getForm("ua-amphetamine")}</td>
                    <td className="d-text-blue uk-text-center">Negative</td>
                  </tr>
                  <tr>
                    <td>ไซลีน (Xylene)</td>
                    <td className="uk-text-center">
                      {this.getForm("ua-xylene")}</td>
                    <td className="d-text-blue uk-text-center">&#60; 1.5 g/g. Cr</td>
                  </tr>
                  <tr>
                    <td>โครเมียม (Chromium)</td>
                    <td className="uk-text-center">
                      {this.getForm("ua-chromium")}</td>
                    <td className="d-text-blue uk-text-center">&#60; 30 ug/g. Cr</td>
                  </tr>
                  <tr>
                    <td>ฟอร์มาลดีไฮด์ (Formaldehyde)</td>
                    <td className="uk-text-center">
                      {this.getForm("ua-formaldehyde")}</td>
                    <td className="d-text-blue uk-text-center">&#60; 0.004 mg/m5</td>
                  </tr>
                  <tr>
                    <td className="d-text-blue" colSpan="3">
                      Urine Microscopy
                    </td>
                  </tr>
                  <tr>
                    <td>เม็ดเลือดขาว (WBC)</td>
                    <td className="uk-text-center">{this.getForm("ua-wbc")}</td>
                    <td className="d-text-blue uk-text-center">0 - 5 HPF</td>
                  </tr>
                  <tr>
                    <td>เม็ดเลือดแดง (RBC)</td>
                    <td className="uk-text-center">{this.getForm("ua-rbc")}</td>
                    <td className="d-text-blue uk-text-center">0 - 3 HPF</td>
                  </tr>
                  <tr>
                    <td>เซลล์เยื่อบุ (Epithelial Cell)</td>
                    <td className="uk-text-center">{this.getForm("ua-epithelial")}</td>
                    <td className="d-text-blue uk-text-center">0 - 5 HPF</td>
                  </tr>
                  <tr>
                    <td>แบคทีเรีย (Bacteria)</td>
                    <td className="uk-text-center" colSpan="2">{this.getForm("ua-bacteria")}</td>
                  </tr>
                </table>
                {this.toTopNav()}
              </div>

              <div className="d-page-break"></div>

              {/** */}
              <div className="d-user-health-book-container">
                <div id="nav-complete-blood-count" className="d-text-blue uk-text-center">
                  [ผลตรวจนับเม็ดเลือดแบบสมบูรณ์ (Complete Blood Count)]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td className="d-text-purple uk-text-center" width="40%">
                      รายละเอียดการตรวจ
                    </td>
                    <td className="d-text-purple uk-text-center" width="100">
                      ผลตรวจ
                    </td>
                    <td className="d-text-purple uk-text-center">ค่าปกติ</td>
                  </tr>
                  <tr>
                    <td>ฮีโมโกลบิน (Hb)</td>
                    <td className="uk-text-center">{this.getForm("hb")}</td>
                    <td className="d-text-blue uk-text-center">
                      M13-18,F 12-16 g/dl
                    </td>
                  </tr>
                  <tr>
                    <td>ฮีมาโตคริต (Hct)</td>
                    <td className="uk-text-center">{this.getForm("hct")}</td>
                    <td className="d-text-blue uk-text-center">
                      M 37-54 % , F 34-48 %
                    </td>
                  </tr>
                  <tr>
                    <td>จํานวนเม็ดเลือดแดง (RBC)</td>
                    <td className="uk-text-center">
                      {this.getForm("rbc-count")}
                    </td>
                    <td className="d-text-blue uk-text-center">
                      4-6x10<sup>6</sup> cells/mm<sup>3</sup>
                    </td>
                  </tr>
                  <tr>
                    <td>จํานวนเม็ดเลือดขาว (WBC)</td>
                    <td className="uk-text-center">{this.getForm("wbc")}</td>
                    <td className="d-text-blue uk-text-center">
                      4,500 - 11,000 cells/mm<sup>2</sup>
                    </td>
                  </tr>
                  <tr>
                    <td>- นิวโตรฟิล (Neutrophil) (NE)</td>
                    <td className="uk-text-center">
                      {this.getForm("neutrophil-ratio")}
                    </td>
                    <td className="d-text-blue uk-text-center">40 - 75 %</td>
                  </tr>
                  <tr>
                    <td>- ลิมโฟไซท์ (Lymphocyte) (LY)</td>
                    <td className="uk-text-center">
                      {this.getForm("lymphocyte-ratio")}
                    </td>
                    <td className="d-text-blue uk-text-center">20 - 45 %</td>
                  </tr>
                  <tr>
                    <td>- โมโนไซท์ (Monocyte) (MO)</td>
                    <td className="uk-text-center">
                      {this.getForm("monocyte-ratio")}
                    </td>
                    <td className="d-text-blue uk-text-center">2 - 10 %</td>
                  </tr>
                  <tr>
                    <td>- อีโอสิโนฟิล (Eosinophil) (EO)</td>
                    <td className="uk-text-center">
                      {this.getForm("eosinophil-ratio")}
                    </td>
                    <td className="d-text-blue uk-text-center">0 - 8 %</td>
                  </tr>
                  <tr>
                    <td>
                     - เบโซฟิล (Basophils) (BA)
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("ba-ratio")}
                    </td>
                    <td className="d-text-blue uk-text-center">0 - 1 %</td>
                  </tr>
                  <tr>
                    <td>จํานวนเกล็ดเลือด (Platelet)</td>
                    <td className="uk-text-center">
                      {this.getForm("platelets")}
                    </td>
                    <td className="d-text-blue uk-text-center">
                      140,000-450,000 cell/mm
                    </td>
                  </tr>
                  <tr>
                    <td>RBC Index</td>
                    <td colSpan="2">
                      <div>
                        <span className="d-text-blue" style={{ marginRight: 20 }}>
                          - MCV
                        </span>
                        <span style={{ display: "inline-block" }}>
                          {this.getForm("mcv", {style: {width: 100}})}
                        </span>{" "}
                        <span className="d-text-blue">fl. ( 80 - 100 fl. )</span>
                      </div>
                      <div>
                        <span className="d-text-blue" style={{ marginRight: 20 }}>
                          - MCH
                        </span>
                        <span style={{ display: "inline-block" }}>
                          {this.getForm("mch", {style: {width: 80}})}
                        </span>{" "}
                        <span className="d-text-blue">pg. ( 27 - 33 pg. )</span>
                      </div>
                      <div>
                        <span className="d-text-blue" style={{ marginRight: 20 }}>
                          - MCHC
                        </span>
                        <span style={{ display: "inline-block" }}>
                          {this.getForm("mchc", {style: {width: 80}})}
                        </span>{" "}
                        <span className="d-text-blue">g/dl ( 31 - 35 g/dl )</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>ลักษณะรูปร่างเม็ดเลือดแดง (RBC Morphology)</td>
                    <td colSpan="2">
                      <div style={{ minHeight: 50 }}>
                        {this.getForm("rbc-morph")}
                      </div>
                    </td>
                  </tr>
                </table>

                <div className="d-text-blue uk-text-center">
                  [สรุปผลตรวจนับเม็ดเลือดแบบสมบูรณ์ (Complete Blood Count)]
                </div>
                <div>
                  * ผลตรวจนับเม็ดเลือดแบบสมบูรณ์
                </div>
                <div>
                  {this.getForm("blood-summary", {type: 'textarea'})}

                </div>
                {this.toTopNav()}
              </div>
              <div className="d-page-break"></div>
              {/** */}
              <div className="d-user-health-book-container">
                <div id="nav-audiometry" className="d-text-blue uk-text-center">
                  [ผลตรวจสมรรถภาพการได้ยิน (Audiometry)]
                </div>
                <div className="uk-overflow-auto">
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td className="d-text-purple uk-text-center">
                      ความถี่ (เฮิร์ตซ์)
                    </td>
                    <td className="d-text-blue uk-text-center">500</td>
                    <td className="d-text-blue uk-text-center">1000</td>
                    <td className="d-text-blue uk-text-center">2000</td>
                    <td className="d-text-blue uk-text-center">3000</td>
                    <td className="d-text-blue uk-text-center">4000</td>
                    <td className="d-text-blue uk-text-center">6000</td>
                    <td className="d-text-blue uk-text-center">8000</td>
                  </tr>
                  <tr>
                    <td className="d-text-purple uk-text-center">หูขวา</td>
                    <td>{this.getForm("audiogram-r-500")}</td>
                    <td>{this.getForm("audiogram-r-1000")}</td>
                    <td>{this.getForm("audiogram-r-2000")}</td>
                    <td>{this.getForm("audiogram-r-3000")}</td>
                    <td>{this.getForm("audiogram-r-4000")}</td>
                    <td>{this.getForm("audiogram-r-6000")}</td>
                    <td>{this.getForm("audiogram-r-8000")}</td>
                  </tr>
                  <tr>
                    <td className="d-text-purple uk-text-center">หูซ้าย</td>
                    <td>{this.getForm("audiogram-l-500")}</td>
                    <td>{this.getForm("audiogram-l-1000")}</td>
                    <td>{this.getForm("audiogram-l-2000")}</td>
                    <td>{this.getForm("audiogram-l-3000")}</td>
                    <td>{this.getForm("audiogram-l-4000")}</td>
                    <td>{this.getForm("audiogram-l-6000")}</td>
                    <td>{this.getForm("audiogram-r-8000")}</td>
                  </tr>
                </table>
                </div>
                <div
                  className="d-text-purple uk-text-center"
                  style={{ padding: 10 }}
                >
                  ค่าปกติ : ระดับการได้ยินไม่เกิน 25 dB
                </div>
                <div className="d-text-blue uk-text-center">
                  <u className="d-text-blue">
                    สรุปผลตรวจสมรรถภาพการได้ยิน (Audiometry)
                  </u>
                </div>
                <div>
                  {this.getForm("audiogram", {type: 'textarea'})}
                </div>
                <div>
                  {this.getForm("audiogram-more", {type: 'textarea'})}
                </div>
                {this.toTopNav()}
              </div>
              <div className="d-user-health-book-container">
                <div id="nav-spirometry" className="d-text-blue uk-text-center">
                  [ผลตรวจสมรรถภาพปอด (Spirometry)]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td className="d-text-blue uk-text-center">FVC (L)</td>
                    <td className="d-text-blue uk-text-center">FEV1 (L)</td>
                    <td className="d-text-blue uk-text-center">FEV1/FVC (%)</td>
                    <td className="d-text-blue uk-text-center">ผลตรวจ</td>
                  </tr>
                  <tr>
                    <td>
                      <div>{this.getForm("fvc")}</div>
                    </td>
                    <td>{this.getForm("fev1")}</td>
                    <td>{this.getForm("fev1/fvc%")}</td>
                    <td>{this.getForm("spirometry-result")}</td>
                  </tr>
                </table>
                <div
                  className="d-text-pink uk-padding-small uk-text-center"
                  style={{ fontSize: 12 }}
                >{`หมายเหตุ : ค่า %FEV1/FVC อายุ < 50 ปี ใช้เกณฑ์ 75 % และ อายุ ≥ 50 ปี ใช้เกณฑ์ 70 %`}</div>

                <div className="d-text-blue uk-text-center">
                  [ผลตรวจสมรรถภาพปอด (Spirometry)]
                </div>
                <div className="d-note-border-bottom">{this.getForm("pft", {type:'textarea'})}</div>
                {this.toTopNav()}
              </div>

              <div className="d-page-break"></div>

              {/** */}
              <div className="d-user-health-book-container">
                <div id="nav-laboratory" className="d-text-blue uk-text-center">
                  [ผลตรวจทางห้องปฏิบัติการ (Laboratory)]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td className="d-text-blue uk-text-center">รายการตรวจ</td>
                    <td className="d-text-blue uk-text-center">ผลตรวจ</td>
                    <td className="d-text-blue uk-text-center">ค่าปกติ</td>
                    <td className="d-text-blue uk-text-center">สรุปผล</td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="d-text-purple">
                      ตรวจระดับน้ําตาลในเลือด
                    </td>
                  </tr>
                  <tr>
                    <td>Fasting Blood Sugar (FBS)</td>
                    <td className="uk-text-center">{this.getForm("fbs/fpg")}</td>
                    <td className="d-text-blue">70-99 mg/dl</td>
                    <td className="uk-text-center">
                      {this.getForm("fbs/fpg-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>HbA1C</td>
                    <td className="uk-text-center">{this.getForm("hba1c")}</td>
                    <td className="d-text-blue">&#60; 6.0 mg% </td>
                    <td className="uk-text-center">
                      {this.getForm("hba1c-result")}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="d-text-purple">
                      ตรวจหาระดับไขมันในเลือด
                    </td>
                  </tr>
                  <tr>
                    <td>Cholesterol</td>
                    <td className="uk-text-center">{this.getForm("chol")}</td>
                    <td className="d-text-blue">0-200 mg/dl</td>
                    <td className="uk-text-center">
                      {this.getForm("chol-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>Triglyceride</td>
                    <td className="uk-text-center">{this.getForm("tg")}</td>
                    <td className="d-text-blue">0-160 mg/dl</td>
                    <td className="uk-text-center">
                      {this.getForm("tg-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>HDL-Cholesterol</td>
                    <td className="uk-text-center">{this.getForm("hdl")}</td>
                    <td className="d-text-blue">{`>45 mg/dl`}</td>
                    <td className="uk-text-center">
                      {this.getForm("hdl-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>LDL-Cholesterol</td>
                    <td className="uk-text-center">{this.getForm("ldl")}</td>
                    <td className="d-text-blue">0-160 mg/dl</td>
                    <td className="uk-text-center">
                      {this.getForm("ldl-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>VLDL-Cholesterol</td>
                    <td className="uk-text-center">{this.getForm("vldl-chol")}</td>
                    <td className="d-text-blue">7-32 mg/dl</td>
                    <td className="uk-text-center">
                      {this.getForm("vldl-chol-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>VLDL-Cholesterol (cal)</td>
                    <td className="uk-text-center">{this.state.lab['tg'] ?  parseFloat(this.state.lab['tg'].value)/5 : ''}</td>
                    <td className="d-text-blue">7-32 mg/dl</td>
                    <td className="uk-text-center">
                    </td>
                  </tr>
                  <tr>
                    <td>Chylomicron</td>
                    <td className="uk-text-center">{this.getForm("chylomicron")}</td>
                    <td className="d-text-blue">{`< 200 mg/dl`}</td>
                    <td className="uk-text-center">
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="d-text-purple">
                      ตรวจการทํางานของตับ
                    </td>
                  </tr>
                  <tr>
                    <td>Total protein</td>
                    <td className="uk-text-center">{this.getForm("t.protein")}</td>
                    <td className="d-text-blue">6.6-8.7 g/dl</td>
                    <td className="uk-text-center">{this.getForm("t.protein-result")}</td>
                  </tr>
                  <tr>
                    <td>Total Bilirubin</td>
                    <td className="uk-text-center">
                      {this.getForm("t.bilirubin")}
                    </td>
                    <td className="d-text-blue">0.3-1.2 mg/dl</td>
                    <td className="uk-text-center">
                      {this.getForm("t.bilirubin-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>SGOT (AST)</td>
                    <td className="uk-text-center">{this.getForm("sgot")}</td>
                    <td className="d-text-blue">10-40 U/L</td>
                    <td className="uk-text-center">
                      {this.getForm("sgot-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>SGPT (ALT)</td>
                    <td className="uk-text-center">{this.getForm("sgpt")}</td>
                    <td className="d-text-blue">10-40 U/L</td>
                    <td className="uk-text-center">
                      {this.getForm("sgpt-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>Alk. Phosphatase</td>
                    <td className="uk-text-center">
                      {this.getForm("alk.phosphatase")}
                    </td>
                    <td className="d-text-blue">38-126 U/L</td>
                    <td className="uk-text-center">
                      {this.getForm("alk.phosphatase-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>Ammonia</td>
                    <td className="uk-text-center">
                      {this.getForm("ammonia")}
                    </td>
                    <td className="d-text-blue">25-94 mg%</td>
                    <td className="uk-text-center">
                      {this.getForm("ammonia-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>Albumin</td>
                    <td className="uk-text-center">
                      {this.getForm("albumin")}
                    </td>
                    <td className="d-text-blue">35-55 g/dl</td>
                    <td className="uk-text-center">
                      {this.getForm("albumin-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>Globulin</td>
                    <td className="uk-text-center">
                      {this.getForm("globulin")}
                    </td>
                    <td className="d-text-blue">3.5-5.0 g/dl</td>
                    <td className="uk-text-center">
                      {this.getForm("globulin-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>Globulin (cal)</td>
                    <td className="uk-text-center">
                      {this.state.lab['t.protein'] && this.state.lab.albumin ?  parseFloat(this.state.lab['t.protein'].value) - parseFloat(this.state.lab.albumin.value) : ''}
                    </td>
                    <td className="d-text-blue">1.3-3.2 g/dl</td>
                    <td className="uk-text-center">
                    </td>
                  </tr>
                  <tr>
                    <td>Direct Bilirubin</td>
                    <td className="uk-text-center">{this.getForm("direct-bilirubin")}</td>
                    <td className="d-text-blue">{`< 0.3 mg/dl`}</td>
                    <td className="uk-text-center">
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="d-text-purple">
                      ตรวจการทํางานของไต
                    </td>
                  </tr>
                  <tr>
                    <td>BUN</td>
                    <td className="uk-text-center">{this.getForm("bun")}</td>
                    <td className="d-text-blue">7.8-20.3 mg/dl</td>
                    <td className="uk-text-center">
                      {this.getForm("bun-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>Creatinine</td>
                    <td className="uk-text-center">
                      {this.getForm("creatinine")}
                    </td>
                    <td className="d-text-blue">0.7-1.5 mg/dl</td>
                    <td className="uk-text-center">
                      {this.getForm("creatinine-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>eGFR</td>
                    <td className="uk-text-center">
                      {this.getForm("egfr")}
                    </td>
                    <td className="d-text-blue">ml/min/1.73m²</td>
                    <td className="uk-text-center">
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="d-text-purple">
                      ตรวจระดับกรดยูริคในเลือด
                    </td>
                  </tr>
                  <tr>
                    <td>Uric Acid</td>
                    <td className="uk-text-center">{this.getForm("uric")}</td>
                    <td className="d-text-blue">2.3-8.2 mg/dl</td>
                    <td className="uk-text-center">
                      {this.getForm("uric-result")}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="d-text-purple">
                      ตรวจไวรัสตับอักเสบ บี
                    </td>
                  </tr>
                  <tr>
                    <td>HBs Ag (เชื้อไวรัสตับอักเสบบี)</td>
                    <td>{this.getForm("hbsag")}</td>
                    <td>{this.getForm("hbsag-normal")}</td>
                    <td>{this.getForm("hbsag-result")}</td>
                  </tr>
                  <tr>
                    <td>HBs Ab (ภูมิคุ้มกัน)</td>
                    <td>{this.getForm("hbsab")}</td>
                    <td>{this.getForm("hbsab-normal")}</td>
                    <td>{this.getForm("hbsab-result")}</td>
                  </tr>
                </table>
                {this.toTopNav()}
              </div>

              <div className="d-page-break"></div>

              {/** */}
              <div className="d-user-health-book-container">
                <div id="nav-toxic" className="d-text-blue uk-text-center">
                  [ผลตรวจรายการอื่นๆ(Other) และสารพิเศษ (Toxic)]
                </div>
                <table border="1"  cellSpacing="0" width="100%">
                  <tr>
                    <td className="d-text-blue uk-text-center">รายการตรวจ</td>
                    <td className="d-text-blue uk-text-center">ผลตรวจ</td>
                    <td className="d-text-blue uk-text-center">ค่าปกติ</td>
                    <td className="d-text-blue uk-text-center">สรุปผล</td>
                  </tr>
                  <tr>
                    <td>
                      <div>{this.getForm("other-list-1", {type: 'textarea'})}</div>
                    </td>
                    <td>{this.getForm("other-value-1", {type: 'textarea'})}</td>
                    <td>{this.getForm("other-normal-1", {type: 'textarea'})}</td>
                    <td>{this.getForm("other-result-1", {type: 'textarea'})}</td>
                  </tr>
                  <tr>
                    <td>
                      <div>{this.getForm("other-list-2", {type: 'textarea'})}</div>
                    </td>
                    <td>{this.getForm("other-value-2", {type: 'textarea'})}</td>
                    <td>{this.getForm("other-normal-2", {type: 'textarea'})}</td>
                    <td>{this.getForm("other-result-2", {type: 'textarea'})}</td>
                  </tr>
                  <tr>
                    <td>
                      <div>{this.getForm("other-list-3", {type: 'textarea'})}</div>
                    </td>
                    <td>{this.getForm("other-value-3", {type: 'textarea'})}</td>
                    <td>{this.getForm("other-normal-3", {type: 'textarea'})}</td>
                    <td>{this.getForm("other-result-3", {type: 'textarea'})}</td>
                  </tr>
                </table>
                {this.toTopNav()}
                <div id="nav-stool-examination" className="d-text-blue uk-text-center uk-margin-small-top">
                  [ผลตรวจอุจจาระ (Stool Examination)]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td className="d-text-blue uk-text-center">
                      รายละเอียดการตรวจ
                    </td>
                    <td className="d-text-blue uk-text-center">ผลตรวจ</td>
                    <td className="d-text-blue uk-text-center">ค่าปกติ</td>
                  </tr>
                  <tr>
                    <td>สีของอุจจาระ (Color)</td>
                    <td>{this.getForm("stool-color-result")}</td>
                    <td className="d-text-green uk-text-center">{this.getForm("stool-color-normal")}</td>
                  </tr>
                  <tr>
                    <td>ลักษณะทั่วไป (Appearance)</td>
                    <td>{this.getForm("stool-appearance-result")}</td>
                    <td className="d-text-green uk-text-center">{this.getForm("stool-appearance-normal")}</td>
                  </tr>
                  <tr>
                    <td>เม็ดเลือดขาว (WBC)</td>
                    <td>{this.getForm("stool-wbc-result")}</td>
                    <td className="d-text-green uk-text-center">0 - 5 HPF</td>
                  </tr>
                  <tr>
                    <td>เม็ดเลือดแดง (RBC)</td>
                    <td>{this.getForm("stool-rbc-result")}</td>
                    <td className="d-text-green uk-text-center">0 - 5 HPF</td>
                  </tr>
                  <tr>
                    <td>พยาธิ,ไข่พยาธิ (parasite,Ova)</td>
                    <td>{this.getForm("stool-parasite-result")}</td>
                    <td className="d-text-green uk-text-center">{this.getForm("stool-parasite-normal")}</td>
                  </tr>
                </table>
                <div>
                  {this.getForm("stool-examination-note", {type: 'textarea'})}
                </div>
                {this.toTopNav()}
              </div>

              <div className="d-user-health-book-container">
                <div id="nav-stool-culture" className="d-text-blue uk-text-center">
                  [ผลตรวจเพาะเชื้อก่อโรคระบบทางเดินอาหาร ( Stool Culture )]
                </div>
                <div>
                  <span className="d-text-blue">ผลตรวจ</span>
                </div>
                <div>
                  {this.getForm("stool-culture-value", {type: 'textarea'})}
                </div>
                <div>
                  <span className="d-text-blue">สรุปผลตรวจ</span>
                </div>
                <div>
                  {this.getForm("stool-culture-note", {type: 'textarea'})}
                </div>
                {this.toTopNav()}
              </div>

              <div className="d-user-health-book-container">
                <div id="nav-other" className="d-text-blue uk-text-center uk-margin-bottom">
                  [ข้อมูลอื่นๆ]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td className="d-text-blue uk-text-center">
                      รายละเอียดการตรวจ
                    </td>
                    <td className="d-text-blue uk-text-center">ผลตรวจ</td>
                    <td className="d-text-blue uk-text-center">หน่วย</td>
                  </tr>
                  <tr>
                    <td>ออกกำลังกาย</td>
                    <td>{this.getForm("exercise-per-minute")}</td>
                    <td className="d-text-green uk-text-center">นาที/วัน</td>
                  </tr>
                  <tr>
                    <td>การพักผ่อน</td>
                    <td>{this.getForm("sleep-per-hour")}</td>
                    <td className="d-text-green uk-text-center">ชั่วโมง/วัน</td>
                  </tr>
                  <tr>
                    <td>การสูบบุหรี่</td>
                    <td>{this.getForm("smoke-per-day")}</td>
                    <td className="d-text-green uk-text-center">มวน/วัน</td>
                  </tr>
                  <tr>
                    <td>การดื่มแอลกอฮอล์</td>
                    <td>{this.getForm("alcohol-per-day")}</td>
                    <td className="d-text-green uk-text-center">แก้ว/วัน</td>
                  </tr>
                  <tr>
                    <td>จำนวนก้าว</td>
                    <td>{this.getForm("walking-amount")}</td>
                    <td className="d-text-green uk-text-center">ก้าว/วัน</td>
                  </tr>
                  <tr>
                    <td>การรับประทานอาหาร</td>
                    <td>{this.getForm("food-amount")}</td>
                    <td className="d-text-green uk-text-center">ที่รับประทาน/วัน</td>
                  </tr>
                  <tr>
                    <td>การดื่มน้ำ</td>
                    <td>{this.getForm("drink-amount")}</td>
                    <td className="d-text-green uk-text-center">แก้ว/วัน</td>
                  </tr>
                </table>
                {this.toTopNav()}
              </div>

              
              <div className="d-user-health-book-container">
                <div id="nav-2q" className="d-text-blue uk-text-center uk-margin-bottom">
                  [แบบคัดกรองโรคซึมเศร้า 2 คำถาม (2Q)]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td className="d-text-blue uk-text-center">
                      คำถาม
                    </td>
                    <td className="d-text-blue uk-text-center">มี</td>
                    <td className="d-text-blue uk-text-center">ไม่มี</td>
                  </tr>
                  <tr>
                    <td>1. ใน 2 สัปดาห์ที่ผ่านมา รวมวันนี้ ท่านรู้สึก หดหู่ เศร้า หรือท้อแท้สิ้นหวัง หรือไม่</td>
                    <td className="uk-text-center">
                      {this.getForm("depress-2-week", {type: 'radio', name: 'depress-2-week', value: '1'})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("depress-2-week", {type: 'radio', name: 'depress-2-week', value: '0'})}
                    </td>
                  </tr>
                  <tr>
                    <td>2. ใน 2 สัปดาห์ที่ผ่านมา รวมวันนี้ท่านรู้สึก เบื่อ ทำอะไรก็ไม่เพลิดเพลิน หรือไม่</td>
                    <td className="uk-text-center">
                      {this.getForm("bored-2-week", {type: 'radio', name: 'bored-2-week', value: '1'})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("bored-2-week", {type: 'radio', name: 'bored-2-week', value: '0'})}
                    </td>
                  </tr>
                </table>
                {
                  this.state.lab['depress-2-week'] != null && this.state.lab['bored-2-week'] != null ?
                  (this.state.lab['depress-2-week'].value == '1' || this.state.lab['bored-2-week'].value == '1' ?
                  <div className="uk-margin-top">* ท่านมีแนวโน้มที่จะเป็นโรคซึมเศร้า</div>
                  :
                  '')

                  : ''
                }
                {this.toTopNav()}
              </div>


              {/* <div className="d-user-health-book-container">
                <div className="d-text-blue uk-text-center uk-margin-bottom">
                  [แบบประเมินโรคซึมเศร้า 9 คำถาม (9Q)]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td className="d-text-blue uk-text-center">
                      ในช่วง 2 สัปดาห์ที่ผ่านมารวมทั้งวันนี้ ท่านมีอาการเหล่านี้ บ่อยแค่ไหน
                    </td>
                    <td className="d-text-blue uk-text-center">ไม่มีเลย</td>
                    <td className="d-text-blue uk-text-center">เป็นบางวัน 1-7 วัน</td>
                    <td className="d-text-blue uk-text-center">เป็นบ่อย &#62; 7 วัน</td>
                    <td className="d-text-blue uk-text-center">เป็นทุกวัน</td>
                  </tr>
                  <tr>
                    <td>1. เบื่อ ไม่สนใจอยากทำอะไร</td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                  </tr>
                  <tr>
                    <td>2. ไม่สบายใจ ซึมเศร้า ท้อแท้</td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                  </tr>
                  <tr>
                    <td>3. หลับยากหรือหลับๆ ตื่นๆ หรือหลับมากไป</td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                  </tr>
                  <tr>
                    <td>4. เหนื่อยง่ายหรือไม่ค่อยมีแรง</td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                  </tr>
                  <tr>
                    <td>5. เบื่ออาหารหรือกินมากเกินไป</td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                  </tr>
                  <tr>
                    <td>6. รู้สึกไม่ดีกับตัวเอง คิดว่าตัวเองล้มเหลวหรือครอบครัวผิดหวัง</td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                  </tr>
                  <tr>
                    <td>7. สมาธิไม่ดี เวลาทำอะไร เช่น ดูโทรทัศน์ ฟังวิทยุ หรือทำงานที่ต้องใช้ความตั้งใจ</td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                  </tr>
                  <tr>
                    <td>8. พูดช้า ทำอะไรช้าลงจนคนอื่นสังเกตเห็นได้ หรือกระสับกระส่ายไม่สามารถอยู่นิ่งได้เหมือนคนที่เคยเป็น</td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                  </tr>
                  <tr>
                    <td>9. คิดทำร้ายตนเอง หรือคิดว่าถ้าตายไปคงจะดี</td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                  </tr>
                </table>
              </div>

              <div className="d-user-health-book-container">
                <div className="d-text-blue uk-text-center uk-margin-bottom">
                  [แบบประเมินการฆ่าตัวตาย 8 คำถาม (8Q)]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td className="d-text-blue uk-text-center">
                      ลำดับคำถาม
                    </td>
                    <td className="d-text-blue uk-text-center">ระยะเวลา</td>
                    <td className="d-text-blue uk-text-center">คำถาม</td>
                    <td className="d-text-blue uk-text-center">ไม่มี</td>
                    <td className="d-text-blue uk-text-center">มี</td>
                  </tr>
                  <tr>
                    <td>1.</td>
                    <td className="uk-text-center">
                    </td>
                    <td>
                      คิดอยากตาย หรือ คิดว่าตายไปจะดีกว่า
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td className="uk-text-center">
                    </td>
                    <td>
                      อยากทำร้ายตัวเอง หรือ ทำให้ตัวเองบาดเจ็บ
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                  </tr>
                  <tr>
                    <td>3.</td>
                    <td className="uk-text-center" rowSpan={3}>
                    ในช่วง 1 เดือนที่ผ่านมารวมวันนี้
                    </td>
                    <td>
                      คิดเกี่ยวกับการฆ่าตัวตาย
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                  </tr>
                  <tr>
                    <td>4.</td>
                    <td>
                      ท่านสามารถควบคุมความอยากฆ่าตัวตายที่ท่านคิดอยู่นั้นได้หรือไม่ หรือบอกได้ไหมว่าคงจะไม่ทำตามความคิดนั้นในขณะนี้
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                  </tr>
                  <tr>
                    <td>5.</td>
                    <td>
                      มีแผนการที่จะฆ่าตัวตาย
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td className="uk-text-center">
                    </td>
                    <td>
                      ได้เตรียมการที่จะทำร้ายตนเองหรือเตรียมการจะฆ่าตัวตายโดยตั้งใจว่าจะให้ตายจริง ๆ
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td className="uk-text-center">
                    </td>
                    <td>
                      ได้พยายามฆ่าตัวตายโดยคาดหวัง/ตั้งใจที่จะให้ตาย
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td className="uk-text-center">
                      ตลอดชีวิตที่ผ่านมา
                    </td>
                    <td>
                      ท่านเคยพยายามฆ่าตัวตาย
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("alcohol-per-day", {type: 'radio', name: ''})}
                    </td>
                  </tr>
                </table>
              </div> */}

              
              <div className="d-user-health-book-container">
                <div id="nav-pv-pap-smear" className="d-text-blue uk-text-center uk-margin-bottom">
                  [PV & Pap smear]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td className="d-text-blue uk-text-center">
                      ปกติ
                    </td>
                    <td className="d-text-blue uk-text-center">ผิดปกติ</td>
                    <td className="d-text-blue uk-text-center">หมายเหตุ</td>
                  </tr>
                  <tr>
                    <td className="uk-text-center">
                      {this.getForm("pv-pap-smear", {type: 'radio', name: 'pv-pap-smear', value: '0'})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("pv-pap-smear", {type: 'radio', name: 'pv-pap-smear', value: '1'})}
                    </td>
                    <td>
                      {this.getForm("pv-pap-smear-note", {type: 'textarea'})}
                    </td>
                  </tr>
                </table>
                {this.toTopNav()}
              </div>

              <div className="d-user-health-book-container">
                <div id="nav-mamogram" className="d-text-blue uk-text-center uk-margin-bottom">
                  [Mamogram]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td className="d-text-blue uk-text-center">
                      ปกติ
                    </td>
                    <td className="d-text-blue uk-text-center">ผิดปกติ</td>
                    <td className="d-text-blue uk-text-center">หมายเหตุ</td>
                  </tr>
                  <tr>
                    <td className="uk-text-center">
                      {this.getForm("mamogram", {type: 'radio', name: 'mamogram', value: '0'})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("mamogram", {type: 'radio', name: 'mamogram', value: '1'})}
                    </td>
                    <td>
                      {this.getForm("mamogram-note", {type: 'textarea'})}
                    </td>
                  </tr>
                </table>
                {this.toTopNav()}
              </div>

              <div className="d-user-health-book-container">
                <div id="nav-ultrasound" className="d-text-blue uk-text-center uk-margin-bottom">
                  [Ultrasound]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td className="d-text-blue uk-text-center">บริเวณที่ Ultrasound</td>
                    <td className="d-text-blue uk-text-center">
                      ปกติ
                    </td>
                    <td className="d-text-blue uk-text-center">ผิดปกติ</td>
                    <td className="d-text-blue uk-text-center">หมายเหตุ</td>
                  </tr>
                  <tr>
                    <td>
                      {this.getForm("ultrasound-place", {type: 'textarea'})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("ultrasound", {type: 'radio', name: 'ultrasound', value: '0'})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("ultrasound", {type: 'radio', name: 'ultrasound', value: '1'})}
                    </td>
                    <td>
                      {this.getForm("ultrasound-note", {type: 'textarea'})}
                    </td>
                  </tr>
                </table>
                {this.toTopNav()}
              </div>
              
              <div className="d-user-health-book-container">
                <div id="nav-exercise-stress" className="d-text-blue uk-text-center uk-margin-bottom">
                  [Exercise stress]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td className="d-text-blue uk-text-center">
                      ปกติ
                    </td>
                    <td className="d-text-blue uk-text-center">ผิดปกติ</td>
                    <td className="d-text-blue uk-text-center">หมายเหตุ</td>
                  </tr>
                  <tr>
                    <td className="uk-text-center">
                      {this.getForm("exercise-stress", {type: 'radio', name: 'exercise-stress', value: '0'})}
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("exercise-stress", {type: 'radio', name: 'exercise-stress', value: '1'})}
                    </td>
                    <td>
                      {this.getForm("exercise-stress-note", {type: 'textarea'})}
                    </td>
                  </tr>
                </table>
                {this.toTopNav()}
              </div>
              
              <div className="d-user-health-book-container">
                <div id="nav-vaccine" className="d-text-blue uk-text-center uk-margin-bottom">
                  [ประวัติการได้รับวัคซีน]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td className="d-text-blue uk-text-center">
                      ที่
                    </td>
                    <td className="d-text-blue uk-text-center">ชื่อวัคซีน</td>
                    <td className="d-text-blue uk-text-center">ได้รับเมื่อวันที่</td>
                  </tr>
                  <tr>
                    <td className="uk-text-center">1</td>
                    <td>{this.getForm("vaccine-name-1", {type: 'textarea'})}</td>
                    <td>{this.getForm("vaccine-date-1", {type: 'datepicker'})}</td>
                  </tr>
                  <tr>
                    <td className="uk-text-center">2</td>
                    <td>{this.getForm("vaccine-name-2", {type: 'textarea'})}</td>
                    <td>{this.getForm("vaccine-date-2", {type: 'datepicker'})}</td>
                  </tr>
                  <tr>
                    <td className="uk-text-center">3</td>
                    <td>{this.getForm("vaccine-name-3", {type: 'textarea'})}</td>
                    <td>{this.getForm("vaccine-date-3", {type: 'datepicker'})}</td>
                  </tr>
                  <tr>
                    <td className="uk-text-center">4</td>
                    <td>{this.getForm("vaccine-name-4", {type: 'textarea'})}</td>
                    <td>{this.getForm("vaccine-date-4", {type: 'datepicker'})}</td>
                  </tr>
                </table>
              </div>
              
              <div className="d-user-health-book-container">
                <div id="nav-pregnant" className="d-text-blue uk-text-center uk-margin-bottom">
                  [ประวัติการฝากครรภ์]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tr>
                    <td className="d-text-blue uk-text-center">
                      ที่
                    </td>
                    <td className="d-text-blue uk-text-center">อายุครรภ์ (สัปดาห์)</td>
                    <td className="d-text-blue uk-text-center">ฝากครรภ์ที่</td>
                    <td className="d-text-blue uk-text-center">ฝากครรภ์วันที่</td>
                  </tr>
                  <tr>
                    <td className="uk-text-center">1</td>
                    <td>{this.getForm("antenatal-age-1")}</td>
                    <td>{this.getForm("antenatal-place-1", {type: 'textarea'})}</td>
                    <td>{this.getForm("antenatal-date-1", {type: 'datepicker'})}</td>
                  </tr>
                  <tr>
                    <td className="uk-text-center">2</td>
                    <td>{this.getForm("antenatal-age-2")}</td>
                    <td>{this.getForm("antenatal-place-2", {type: 'textarea'})}</td>
                    <td>{this.getForm("antenatal-date-2", {type: 'datepicker'})}</td>
                  </tr>
                  <tr>
                    <td className="uk-text-center">3</td>
                    <td>{this.getForm("antenatal-age-3")}</td>
                    <td>{this.getForm("antenatal-place-3", {type: 'textarea'})}</td>
                    <td>{this.getForm("antenatal-date-3", {type: 'datepicker'})}</td>
                  </tr>
                  <tr>
                    <td className="uk-text-center">4</td>
                    <td>{this.getForm("antenatal-age-4")}</td>
                    <td>{this.getForm("antenatal-place-4", {type: 'textarea'})}</td>
                    <td>{this.getForm("antenatal-date-4", {type: 'datepicker'})}</td>
                  </tr>
                </table>
                {this.toTopNav()}
              </div>
            </div>
            {/* <div className="uk-margin-top uk-margin-bottom uk-text-right">
              <a href="https://siriraj.dietz.asia/memberlogin_new" rel="noopenner" target="_blank" ><button className="uk-button uk-button-secondary">เชื่อมต่อ Google Fit</button></a>
            </div> */}
          </div>
          <div>
            <button className="uk-button uk-button-primary uk-width-1-1" onClick={this.saveForm}>บันทึก</button>
          </div>
        </div>
      </div>
    );
  }
}
