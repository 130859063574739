import React, { Component } from 'react';
import Api from '../../Components/Api';
import LoginForm from '../../Components/LoginForm';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

UIkit.use(Icons);

export default class ModeratorLogin extends Component {
  login = async (data) => {
    var { result, responseText, session } = await Api.moderator('ModeratorLoginByForm', data);

    UIkit.notification(`<span uk-icon='icon: ${result ? 'check' : 'close'}'></span> ${responseText}`);

    if (result && session) {
      localStorage.setItem('moderatorLogin', session);
      window.location.href="/moderator";
    }
  }

  render() {
    return <div id="d-moderator-background-login" className="uk-background-cover">
      <div className="uk-position-center">
        <div className=" uk-card uk-card-default uk-padding-small uk-width-medium">
          <h4 className="uk-text-center">เข้าสู่ระบบ</h4>
          <LoginForm {...this.props} onSubmit={this.login} type="consult" />
        </div>
      </div>
    </div>
  }
};
