import React, { Component } from "react";
import Api from "../Components/Api.js";
import moment from "moment";
import numeral from "numeral";

var Row = [
    ['chat','Chat'], 
    ['videocall', 'Video Call'], 
    ['active_patient','Active Patient'], 
    ['active_healthcare_professional', 'Active Healthcare Professional'], 
    ['active_campaign', 'Active Campaign'], 
    ['active_hospital', 'Active Hospital']
];

export default class TelemonitoringTable extends Component {
  state = {
    loadingApi: true,
    active_campaign: [],
    active_healthcare_professional: [],
    active_hospital : [],
    active_patient: [],
    chat: [],
    top_company: [],
    videocall: [],
    
  };

  componentDidMount() {
    this.init();
  }


  dataTableGet = async () => {
    
    var _response = await Api.dietzdashboard("TelemonitoringTable", {
      dateStart: moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD'),
      dateStop: moment().endOf('month').format('YYYY-MM-DD')
    });

    this.setState({
      loadingApi: false,
      active_campaign: _response.active_campaign || [],
      active_healthcare_professional:  _response.active_healthcare_professional || [],
      active_hospital : _response.active_hospital || [],
      active_patient: _response.active_patient || [],
      chat: _response.chat || [],
      top_company: _response.top_company || [],
      videocall: _response.videocall || []

    })
  }

  async init() {
    this.dataTableGet();
  }

  render() {
    return (
      <div>
        <div className="dd-card dd-shadow">
          <div className="dd-card-header">
          Transaction
          </div>
          <div className="uk-padding">
            {
              this.state.loadingApi ? 
              <div><span uk-spinner=""></span></div>
              :
              <div className="uk-overflow-auto">
<table className="uk-table uk-table-divider uk-table-small uk-text-small">
              <thead>
                <tr>
                  <th></th>
                  {
                    this.state.chat.map((chat)=> {
                      return <th>{moment(chat.yearmonth,'YYYY-MM').format('MMM-YY')}</th>
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                  Row.map((row,index)=>{

                    return <tr key={`row-table-transaction${index}`}>
                      <td>{row[1]} {row[0] === 'active_patient' ? <i className="uk-text-meta" uk-icon="info" uk-tooltip="จำนวนผู้ใช้งานที่เข้าสู่ระบบในช่วงเวลานั้น โดยไม่นับซ้ำ"></i> : ''}</td>
                      {
                    this.state[row[0]].map((data)=> {
                      var _amount = data.amount;

                      if(row[0]=== 'chat') {
                        _amount = _amount;
                      } else if(row[0] === 'videocall') {
                        _amount = _amount;
                      } else if(row[0] === 'active_patient') {
                        _amount = _amount;
                      } else if(row[0] === 'active_campaign') {
                        _amount = _amount;
                      } else if(row[0] === 'active_hospital') {
                        _amount = _amount;
                      }
                      return <td>{numeral(_amount).format('0,0')}</td>
                    })
                  }
                    </tr>
                  })
                }
              </tbody>
            </table>
            </div>
            }
            
          </div>
        </div>
        <div className="dd-card dd-shadow uk-margin-top">
          <div className="dd-card-header">
          Top 5 transaction of hospital <i className="uk-text-meta" uk-icon="info" uk-tooltip="จำนวนการใช้งาน video call และ chat ทั้งหมดแยกตามแต่ละโรงพยาบาล"></i>
          </div>
          <div className="uk-padding">
            {
              this.state.loadingApi ? 
              <div><span uk-spinner=""></span></div>
              :
              <div className="uk-overflow-auto">
              <table className="uk-table uk-table-divider">
              <thead>
                <tr>
                  {
                    this.state.top_company.map((top)=> {
                      return <th>{moment(top.yearmonth,'YYYY-MM').format('MMM-YY')}</th>
                    })
                  }
                </tr>
              </thead>
              <tbody>
                <tr>
                  {
                    this.state.top_company.map((top)=> {
                      return <td><ol>{
                        top.data.map((data)=>{
                          return <li className="uk-margin-small-bottom">{data.name} ({numeral(data.amount).format('0,0')})</li>
                        })}</ol></td>
                    })
                  }
                  </tr>
              </tbody>
            </table>
            </div>
            }
            
          </div>
        </div>
      </div>
    );
  }
}
