
import React, { Component } from 'react';
import ImageUri from './ImageUri';
import Api from "./Api"
import firebase from "firebase";
import SoundAlertFile from '../alertcall.mp3';
import moment from 'moment';


var SoundAlert = new Audio(SoundAlertFile);

export default class VideoCallBox extends Component {
  state = {
    is_disable_temporary: false
  }

  componentDidMount() {
    
    SoundAlert.pause();
    
    SoundAlert.volume = 0.02;
          
    SoundAlert.currentTime = 0;

    
    var _is_disable_temporary = localStorage.getItem('disable_video_call_temporary');
    
    if(_is_disable_temporary && moment().diff(moment(_is_disable_temporary, 'YYYY-MM-DD HH:mm:ss'), 'minutes') < 10 ) {
      this.setState({is_disable_temporary: true})
    }
    else {

      localStorage.setItem('disable_video_call_temporary', '');

      SoundAlert.play();
    }
    
  }

  disable_temporary = () => {
    if(window.confirm("ยืนยันการปิดสายเรียกเข้า ?")) {
      localStorage.setItem('disable_video_call_temporary', moment().format('YYYY-MM-DD HH:mm:ss'));

      this.props.closeCallback();

      SoundAlert.pause();

      this.setState({is_disable_temporary: true});
    }
  }

  firebaseChatlistUpdate = () => {
    firebase.database().ref(`chatlist_c/${this.props.consultId}`).set(+new Date + 'vcb');

    firebase.database().ref(`chatlist_m/${this.props.memberId}`).set(+new Date + 'vcb');
  }
  
  logVideoCallSend = async (consultId, memberId, campaignId) => {
    Api.member("LogVideoCallAdd", {
      consultId,
      memberId,
      campaignId,
      chatBy: this.props.apiType
    }); 
  };

  render() {
    return  this.state.is_disable_temporary === true ?
      <a className='d-background-red' onClick={()=>{
        localStorage.setItem('disable_video_call_temporary', '');
  
        this.setState({is_disable_temporary: false});

      }} uk-tooltip="ยกเลิกการปิดกั้นสายเรียกเข้า" style={{
        display: 'inline-block',
        position: 'fixed',
        zIndex: 1005,
        borderRadius: '50%',
        width: '3vw',
        height: '3vw',
        right: '100px',
        top: '100px',
        textAlign: 'center',
        lineHeight: '3vw',
        color: "#FFF"
      }}>
        <span uk-icon="icon: receiver; ratio: 2"></span>
      </a>
      :
      <div className="d-popup-background">
      <div className="d-popup-body d-popup-body-small uk-text-center d-videocall-card">
        <div className='uk-text-right uk-margin-bottom'>
          <a uk-close="" onClick={()=>{
            this.props.closeCallback();

            SoundAlert.pause();
          
          }}></a>
        </div>
        <div>
          <div class="uk-background-muted uk-background-cover d-videocall-person-image" style={{"background-image": "url(" + ImageUri(this.props.image) + ")"} }></div>
        </div>
        <div className='uk-margin-top d-videocall-title'>สายเรียกเข้าจาก<div>{this.props.name}</div></div>
        <div className='uk-margin-small-bottom d-videocall-caption uk-text-meta'>มีการเรียกระบบวีดีโอคอล<div>กดปุ่มเพื่อเข้าสู่หน้าวีดีโอคอล</div></div>
        <div className='uk-margin-top'>
          <a href={`https://con.defence-innovation.com/${this.props.memberId}/${this.props.consultId}?openExternalBrowser=1`} target={"_blank"} className="d-videocall-button uk-display-block" onClick={async ()=>{

            SoundAlert.pause();

            var _r = await Api[this.props.apiType]("ChatMessageList", {
              consultId: this.props.consultId,
              memberId: this.props.memberId,
              campaignId: this.props.campaignId,
              page: 1,
              chatby: this.props.apiType === 'consult' ? 'c' : 'm',
              admin: false,
            });

            this.logVideoCallSend(this.props.consultId, this.props.memberId, this.props.campaignId);

            this.props.closeCallback();

            this.firebaseChatlistUpdate();
            
          }} >
            {this.props.buttonTitle} 
            <span className="d-videocall-button-icon icon-camrecorder"></span>
          </a>
          {
            this.props.apiType === 'consult' && <div className='uk-margin-top'>
            <a onClick={this.disable_temporary} className='uk-text-small'><u>คลิกเพื่อปิดกั้นสายเรียกเข้าทั้งหมดชั่วคราว</u></a>
          </div>
          }
        
        </div>
      </div>
    </div>
        
  }
    

}
