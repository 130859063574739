import React, {Component} from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import { Link } from "react-router-dom";

UIkit.use(Icons);

export default class MemberDashboard extends Component {
  state = {
  }

  componentDidMount() {
    this.breadcrumbRender();
  }

  breadcrumbRender() {
    var url = window.location.pathname.replace(/-/g, ' ').split('/');
    url[0] = <Link to="/">Home</Link>;
    if(!url[1]) url[1] = 'Dashboard';

    return <ul className="uk-breadcrumb">{url.map((data,key)=>{
      return <li className="uk-text-uppercase" key={`breadcrumb${key}`}>{data}</li>
    })}</ul>
  }

  settingMenuRender() {
    var menu = [
      {label: 'Profile Overview', link: '/user'},
      {label: 'Personal Information', link: '/user'},
      {label: 'Account Information', link: '/user'},
      {label: 'Change Password', link: '/user'},
      {label: 'Privacy policy', link: '/user'},
    ];

    return menu.map((data,key)=>{
      return <Link key={`link${key}`} className="uk-display-block d-link-header-sub" to={data.link}>{data.label}</Link>
    })
  }

  logout = () => {
    localStorage.setItem('memberLogin', '');
    window.location.href='/';
  }
  
  render() {
    return <div uk-grid="" className="uk-child-width-1-2@s uk-child-width-1- uk-grid-collapse">
      <div>
        <h1 className="uk-margin-remove uk-padding-remove d-header-sub-title">{this.props.title}</h1>
        <h6 className="uk-margin-remove uk-padding-remove d-header-sub-breadcrumb">{this.breadcrumbRender()}</h6>
      </div>
      <div className="uk-text-right@s uk-text-left">
        <div className="uk-button-group d-button-group">
          <div className="uk-inline">
              <button className="uk-button uk-button-default d-button-header-sub uk-button-small">
                <span className="icon-settings uk-margin-small-right"></span>
                Setting <span className="icon-arrow-down" style={{fontSize: '11px'}}></span>
              </button>
              <div className="uk-text-left d-dropdown-card" uk-dropdown="mode: click">
                {this.settingMenuRender()}
                <a className="uk-display-block" onClick={this.logout}>
                  Logout
                </a>
              </div>
          </div>
          
          <Link to={'/chat'} className="uk-button uk-button-default d-button-header-sub uk-button-small">
            <span className="icon-envelope"></span>
          </Link>
          <Link to={'/user'} className="uk-button uk-button-default d-button-header-sub uk-button-small">
            <span className="icon-pie-chart"></span>
          </Link>
        </div>
      </div>
    </div>
  }
};
