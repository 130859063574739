import React, { Component } from "react";
import moment from "moment";
import $ from "jquery";

import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min.js";
import "eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css";

export default class DiabetesPregnantForm extends Component {
  state = {
    lowsugar : '0',
    forgot: '0'
  }

  componentDidMount() {
  }
  

  render() {
    return <div className="uk-padding d-border">
      <h2 className="uk-text-center">ข้อมูลก่อนพบแพทย์สำหรับเบาหวานตั้งครรภ์</h2>
      <div>1. ใน 1 เดือนที่ผ่านมาท่านมีอาการน้ำตาลต่ำ ได้แก่ เหงื่อออกมาก กระสับกระส่าย ใจสั่น หิว อ่อนเพลีย เวียนหัว มึนงงหรือไม่</div>
      <div className="uk-margin-small-top">
        <label className="uk-margin-right">
          <input type="radio" className="uk-radio" checked={this.state.lowsugar == '1'} onChange={(e)=>this.setState({lowsugar: '1'})} /> ใช่
        </label>
        <label className="uk-margin-right">
          <input type="radio" className="uk-radio" checked={this.state.lowsugar == '0'} onChange={(e)=>this.setState({lowsugar: '0'})} /> ไม่ใช่
        </label>
      </div>

      
      <div className="uk-margin-top">2. ใน 1 เดือนที่ผ่านมา ท่านลืมฉีดยาเบาหวานหรือไม่</div>
      <div className="uk-margin-small-top">
        <label className="uk-margin-right">
          <input type="radio" className="uk-radio" checked={this.state.forgot == '0'} onChange={(e)=>this.setState({forgot: '0'})} /> ไม่ลืม
        </label>
        <label className="uk-margin-right">
          <input type="radio" className="uk-radio" checked={this.state.forgot == '1'} onChange={(e)=>this.setState({forgot: '1'})} /> ลืมเล็กน้อย (น้อยกว่า 2 ครั้ง)
        </label>
        <label className="uk-margin-right">
          <input type="radio" className="uk-radio" checked={this.state.forgot == '2'} onChange={(e)=>this.setState({forgot: '2'})} /> ลืมบ่อย (มากกว่า 3 ครั้ง)
        </label>
      </div>

      <div className="uk-margin-top">
        <button className="uk-button uk-button-primary uk-width-1-1" onClick={()=>{
          var _alert_tag = [];

          if(this.state.lowsugar == '1') {
            _alert_tag.push('symptomatic hypoglycemia');
          }

          if(this.state.forgot != '0') {
            _alert_tag.push('compliance');
          }

          this.props.callback({
            alert_tag: _alert_tag.join(','),
            text: `<div><b><u>ข้อมูลก่อนพบแพทย์สำหรับเบาหวานตั้งครรภ์</u></b></div><div><b>1. ใน 1 เดือนที่ผ่านมาท่านมีอาการน้ำตาลต่ำ ได้แก่ เหงื่อออกมาก กระสับกระส่าย ใจสั่น หิว อ่อนเพลีย เวียนหัว มึนงงหรือไม่</b></div><div> - ${this.state.lowsugar == '1' ? 'ใช่' : 'ไม่'} </div>
            <div><b>2. ใน 1 เดือนที่ผ่านมา ท่านลืมฉีดยาเบาหวานหรือไม่</b></div>
            <div> - ${this.state.forgot == '0' ? 'ไม่ลืม' : ( this.state.forgot == '1' ? 'ลืมเล็กน้อย (น้อยกว่า 2 ครั้ง)' : 'ลืมบ่อย (มากกว่า 3 ครั้ง)')} </div>
            `
          });
        }}>ส่งข้อมูล</button>
      </div>
    </div>
  }
}