import React, { Component } from "react";
import Api from "../../Components/Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import DateTimePicker from "../../Components/DatePicker.js";
import moment from 'moment';
import HeaderSub from '../../Components/Member/HeaderSub.js';

UIkit.use(Icons);

export default class AppointmentList extends Component {

  state = {
    list: [],
    group: 'self',
    dateStart: moment(),
    dateStop: moment().add(6, 'month'),
    loadingApi: false,
  }

  memberById = {} ;

  consultById ={} ;

  listGet = async  () => {
    this.setState({loadingApi: true});

    var {list, consult, member} = await Api.member("AppointmentList", {
      dateStart: moment(this.state.dateStart).format('YYYY-MM-DD HH:mm:ss'),
      dateStop: moment(this.state.dateStop).format('YYYY-MM-DD HH:mm:ss'),
      group: this.state.group
    });

    if(!list) list = [];
    if(!member) member = [];
    if(!consult) consult = [];

    member.forEach((data) => {
      this.memberById[data.id] = data;
    });

    consult.forEach((data) => {
      this.consultById[data.id] = data;
    });


    this.setState({
      list,
      loadingApi: false
    });
  }

  componentDidMount() {
    this.listGet(); 
  }

  companySelectRender = () =>{ 

  }


  render() {
    return (
      <div className="uk-padding">
        <HeaderSub title="ตารางนัดหมาย" />
        <div className="uk-card uk-card-default uk-padding-small">
          <div className="d-card-header">
            ตารางนัดหมาย
          </div>
          
          <div uk-grid="">
            <div className="uk-width-auto@m uk-width-1-1">
                <DateTimePicker id="dateStart" date={this.state.dateStart} callback={(value)=>this.setState({dateStart: value})}/>
            </div>
            <div className="uk-width-auto@m uk-width-1-1">
                <DateTimePicker id="dateStop" date={this.state.dateStop} callback={(value)=>this.setState({dateStop: value})} />
            </div>
            <div className="uk-width-expand@m uk-width-1-1">
              <button className="uk-button uk-button-primary uk-width-1-1 uk-width-auto@m" onClick={this.listGet}>ค้นหา</button>
            </div>
          </div>
          <hr />
          <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
            <thead>
              <tr>
                <th>ที่</th>
                <th>วันที่</th>
                <th>เวลา</th>
                <th>ชื่อคนไข้</th>
                <th>ผู้นัดหมาย</th>
                <th>นัดหมายเพื่อ</th>
                <th>ช่องทาง</th>
                <th>หมายเหตุ</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.loadingApi === true ?
                <tr>
                  <td colSpan="100%">
                    <div className="uk-padding-small uk-text-center"><span uk-spinner=""></span></div>
                  </td>
                </tr>
                :
                this.state.list.length === 0 ? 
                <tr>
                  <td colSpan="100%">
                    <div className="uk-padding-small uk-text-center">ไม่พบข้อมูล</div>
                  </td>
                </tr>
                :

                this.state.list.map((data, key)=>{

                  var _member = this.memberById[data.memberid];

                  if(_member) {
                    _member = `${_member.nameprefix} ${_member.name} ${_member.lastname}`;
                  } else {
                    _member = '-';
                  }

                  var _consult = this.consultById[data.consultid];
                  if(_consult) {
                    _consult = `${_consult.nameprefix} ${_consult.name} ${_consult.lastname}`;
                  } else {
                    _consult = '-';
                  }

                  return <tr key={`row${data.id}`}>
                    <td>{key+1}</td>
                    <td>
                      {moment(data.dateadd).format('DD/MM/YYYY')}
                    </td>
                    <td>
                      {moment(data.dateadd).format('HH:mm น.')}
                    </td>
                    <td>{_member}</td>
                    <td>
                      {_consult}
                    </td>
                    <td>
                      <a href={`/chat?messageSelectIndex=ca${data.campaignid}_co${data.consultid}_m${data.memberid}`}>{data.name}</a>
                    </td>
                    <td>{data.type}</td>
                    <td>
                      {data.note}
                    </td>
                  </tr>
                })
              }
            </tbody>
          </table>

        </div>
      </div>
    );
  }
}
