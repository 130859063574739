import React, {Component} from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import IconCard from '../../Components/Member/IconCard';
import Api from '../../Components/Api';
import numeral from 'numeral';

UIkit.use(Icons);

export default class CampaignReportAvgBmiLossCard extends Component {

  state = {
    bmi: <span uk-spinner=""></span>
  }

  componentDidMount() {
    this.userGet();
  }

  async userGet() {
    var {bmiMax, bmiMin} = await Api.member('CampaignReportAvgBmiLossCard', {campaignId: this.props.id});
    this.setState({bmi: (+bmiMax - bmiMin)})
  }

  render() {
    return <IconCard option={{
      backgroundColorLeft: '#008385',
      backgroundColorRight: '#00b5b8',
      icon: 'exclamation',
      title: 'AVG. BMI',
      caption: 'Loss',
      value: numeral(this.state.bmi).format('0.00') || 0
    }} />
  }
};
