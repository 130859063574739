import React, { Component } from 'react';
import ImageUri from './ImageUri';
import moment from 'moment';
import Rate from "./Rate";
import numeral from "numeral";
import { jsPDF } from "jspdf";
import Api from "./Api";
import HivCreateImageData from './HivCreateImageData';
import ChatBoxDiabates from './ChatBoxDiabates';
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import DataType from './DataType';
import { NotiData, KeyPage } from '../Pages/KnowledgeKey';
import Knowledge from '../Pages/Knowledge';
import KnowledgeLogAdd from '../Components/KnowledgeLogAdd';
import $ from 'jquery'

UIkit.use(Icons);


export default class ChatBoxMessageChat extends Component {
  diabetes = ChatBoxDiabates;
  
  apiType = this.props.chatby === "c" ? 'consult' : 'member'
  
  prescriptionStatusUpdate = async (data, status) => {
    var _response = await Api.member("PrescriptionStatusUpdate", {
      id: data.id,
      status: status,
    });

    UIkit.notification({ message: _response.responseText });

    this.props.chatListUpdate();
  };

  messageChat = {
    visitnote: (data) => {
      return <div>
        <div><b><u>บันทึกการดูแล (Visit Note)</u></b></div>
        <div><b>วันที่ :</b> {moment(data.cvn_dateadd).format('DD/MM/YYYY HH:mm')}</div>
        <div><b>เลขผู้ป่วย (HN) :</b> {data.cvn_hn}</div>
        <div><b>รายละเอียด :</b> </div>
        <div>{data.cvn_detail}</div>
        {data.cvn_image && <div className="uk-margin-small-top" uk-lightbox="">
              <a href={ImageUri(data.cvn_image)}>
                <img
                  uk-img=""
                  src={ImageUri(data.cvn_image)}
                  width="360"
                />
              </a>
            </div>}
            
      </div>
    },
    wound: (data) => {
      var _optionRating = [0.5, 1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0, 4.5, 5.0];
  
      if (typeof data.cw_symptomtype === "string") {
        try {
          data.cw_symptomtype = JSON.parse(data.cw_symptomtype);
        } catch (e) {
          data.cw_symptomtype = [];
        }
      }


      return (
        <div>
          <div>
            <div uk-lightbox="">
              <a href={ImageUri(data.cw_image)}>
                <img
                  uk-img=""
                  src={ImageUri(data.cw_image)}
                  width="360"
                  className="uk-margin-small-bottom"
                />
              </a>
            </div>
          </div>
          {this.props.chatby === "m" && +data.cw_rating > 0 && (
            <div>
              <Rate readonly={true} initialRating={data.cw_rating} stop={5} />{" "}
              <span>/ {numeral(data.cw_rating).format("0.0")}</span>
            </div>
          )}
  
          <div>
            <span className="d-chatwound-bold">บริเวณที่เป็น: </span>{" "}
            {data.cw_area}
          </div>
          <div>
            <span className="d-chatwound-bold">วันที่/เวลา: </span>{" "}
            {moment(data.cw_dateadd).format("DD/MM/YYYY เวลา HH:mm")}
          </div>
          <div>
            <span className="d-chatwound-bold">อธิบายรายละเอียด: </span>{" "}
            {data.cw_detail}
          </div>
          <div>
            <span className="d-chatwound-bold">อาการ: </span> {data.cw_symptom}
          </div>
          {Array.isArray(data.cw_symptomtype) === true &&
            data.cw_symptomtype.length > 0 && (
              <ul className="uk-margin-remove">
                {data.cw_symptomtype.map((symptomtype) => {
                  return <li>{symptomtype}</li>;
                })}
              </ul>
            )}
          <div></div>
          {this.props.chatby === "m" && +data.cw_rating > 0 && (
            <div>
              <div>
                <span className="d-chatwound-bold">คำแนะนำ: </span>{" "}
                {data.cw_suggest}
              </div>
              <div>
                <span className="d-chatwound-bold">วันที่/เวลาที่แนะนำ: </span>{" "}
                {moment(data.cw_suggestdate).format("DD/MM/YYYY เวลา HH:mm")}
              </div>
            </div>
          )}
          {this.props.chatby === "c" && (
            <div>
              <div>
                <span className="d-chatwound-bold">คำแนะนำ: </span>{" "}
                <textarea
                  className="uk-textarea"
                  placeholder="คำแนะนำ"
                  defaultValue={data.cw_suggest}
                  onChange={(e) => {
                    data.cw_suggest = e.target.value;
                  }}
                ></textarea>
              </div>
              <div className="d-button-group input-group uk-button-group d-chatmessage-wound-consult-rating-container uk-width-1-1 uk-margin-small-top uk-flex-middle">
                <span
                  className="uk-button input-group-addon"
                  style={{ width: 100, marginLeft: 0, fontSize: 12 }}
                >
                  ให้คะแนน
                </span>
                <select
                  className="uk-select uk-text-center"
                  defaultValue={data.cw_rating}
                  onChange={(e) => {
                    data.cw_rating = e.target.value;
                  }}
                >
                  <option value="0">เลือกคะแนน</option>
                  {_optionRating.map((data) => (
                    <option key={`option${data}`} value={data}>
                      {data}
                    </option>
                  ))}
                </select>
              </div>
              <button
                className="uk-button uk-button-primary uk-margin-small-top uk-width-1-1"
                onClick={() => {
                  this.props.woundUpdate(data);
                }}
              >
                บันทึกข้อมูล
              </button>
            </div>
          )}
        </div>
      );
    },
    appointment: (data) => {
      return (
        <div>
          <div>นัดหมายเพื่อ : {data.ca_name}</div>
          <div>
            วันที่/เวลา :{" "}
            {moment(data.ca_dateadd, "YYYY-MM-DD HH:mm:ss").format(
              "DD/MM/YY HH:mm น."
            )}
          </div>
          <div>ช่องทาง : {data.ca_type}</div>
          <div>หมายเหตุ : {data.ca_note || "-"}</div>
          <div>รหัสอ้างอิง : {data.ca_id}</div>
        </div>
      );
    },
    diabates: (data) => {
      var _detail = {};
      try {
        _detail = JSON.parse(data.cd_detail);
      } catch (e) {}
  
      var _body = [];
  
      this.diabetes.meal.forEach((meal) => {
        var _row = [
          <td>
            <div>{this.diabetes.meal_name[meal]}</div>
          </td>,
        ];
  
        this.diabetes.time.forEach((time) => {
          _row.push(<td>{_detail[`${meal}_${time}`]}</td>);
        });
  
        this.diabetes.drug.forEach((drug) => {
          _row.push(<td>{_detail[`${meal}_${drug}`]}</td>);
        });
  
        this.diabetes.food.forEach((food) => {
          _row.push(<td>{_detail[`${meal}_${food}`]}</td>);
        });
  
        _body.push(<tr>{_row}</tr>);
      });
  
      return (
        <div>
          <div>วันที่ {moment(data.cd_dateadd).format("DD/MM/YYYY HH:mm")}</div>
          <div className="uk-overflow-auto uk-margin-small-top">
            <table className="uk-table uk-table-small uk-table-divider d-table-diabates-data">
              <thead>
                <tr>
                  <th rowSpan="2">มื้ออาหาร</th>
                  <th colSpan="2">ค่าน้ำตาล</th>
                  <th rowSpan="2">ยาฉีดอินซูลิน</th>
                  <th colSpan="2">บันทึกอาหาร</th>
                </tr>
                <tr>
                  <th>ก่อนอาหาร</th>
                  <th>หลังอาหาร</th>
                  <th>มื้อหลัก</th>
                  <th>มื้อว่าง</th>
                </tr>
              </thead>
              <tbody>
                {_body}
                <tr>
                  <td>
                    <div>หมายเหตุ</div>
                  </td>
                  <td colSpan="6">{_detail.note}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    },
    dialysis: (data) => {
      var _detail = [];
      
      if(typeof data.cdi_detail === 'string'){
        try {
          _detail = JSON.parse(data.cdi_detail);
        } catch (e) {}
      } else {
        _detail = data.cdi_detail;
      }
  
      var _body = [];
  
      _detail.forEach((row, index) => {
        _body.push(
          <tr>
            <td className="uk-text-center">{index + 1}</td>
            {row.map((column, column_index) => {
              if (column_index === 8) {
                if (index === 0) {
                  return (
                    <td
                      className="d-tabledialysis-volume-summary-td"
                      rowSpan={5}
                    >
                      {column}
                    </td>
                  );
                } else {
                  return "";
                }
              } else if (column_index === 7) {
                var _style = {};
  
                if (column !== "") {
                  if (+column >= 1000) {
                    _style = {
                      background: "green",
                      color: "#FFFFFF",
                    };
                  } else if (+column >= 900) {
                    _style = {
                      background: "yellow",
                    };
                  } else {
                    _style = {
                      background: "red",
                      color: "#FFFFFF",
                    };
                  }
                }
  
                return <td style={_style}>{column}</td>;
              } else if (column_index === 11) {
                var _style = {};
  
                var _value = column.split("/");
  
                if (_value.length === 2) {
                  if (+_value[0] <= 140 && +_value[1] <= 90) {
                    _style = {
                      background: "green",
                      color: "#FFFFFF",
                    };
                  } else {
                    _style = {
                      background: "red",
                      color: "#FFFFFF",
                    };
                  }
                }
  
                return <td style={_style}>{column}</td>;
              } else {
                return <td>{column}</td>;
              }
            })}
          </tr>
        );
      });
  
      return (
        <div>
          <div>
            <b>วันที่ {moment(data.cdi_dateadd).format("DD/MM/YYYY HH:mm")}</b>
          </div>
          <div className="uk-overflow-auto uk-margin-small-top">
            <table className="uk-table uk-table-small uk-table-divider d-table-diabates-data">
              <thead>
                <tr>
                  <th rowSpan={2}>รอบที่</th>
                  <th rowSpan={2}>ความเข้มข้นน้ำยา</th>
                  <th colSpan={3}>เติมน้ำยาเข้า</th>
                  <th colSpan={3}>ปล่อยน้ำยาออก</th>
                  <th rowSpan={2}>
                    กำไรขาดทุน
                    <div>(น้ำเข้า-น้ำออก)</div>
                  </th>
                  <th rowSpan={2}>ปริมาตรรวมสุทธิ</th>
                  <th rowSpan={2}>ลักษณะน้ำยา</th>
                  <th rowSpan={2}>
                    น้ำหนัก
                    <div>(ก.ก.)</div>
                  </th>
                  <th rowSpan={2}>ความดันโลหิต</th>
                  <th rowSpan={2}>
                    หมายเหตุ<div>(ปัสสาวะ (ซีซี))</div>
                  </th>
                </tr>
                <tr>
                  <th>เวลาเริ่ม</th>
                  <th>เวลาหมด</th>
                  <th>ปริมาตร</th>
                  <th>เวลาเริ่ม</th>
                  <th>เวลาหมด</th>
                  <th>ปริมาตร</th>
                </tr>
              </thead>
              <tbody>{_body}</tbody>
            </table>
          </div>
        </div>
      );
    },
    file: (data) => (
      <div>
        <span uk-icon="file-pdf" className="uk-margin-small-right"></span>
        <a href={ImageUri(data.cfi_name)} target="_blank" rel="noopener">
          ไฟล์แนบ
        </a>
      </div>
    ),
    image: (data) => (
      <div uk-lightbox="">
        <a href={ImageUri(data.ci_name)}>
          <img uk-img="" src={ImageUri(data.ci_name)} width="360" />
        </a>
      </div>
    ),
    address: (data) => (
      <div>
        <div dangerouslySetInnerHTML={{ __html: data.ct_name }}></div>
        <a onClick={()=>this.setState({chatMessageAddressPopupShow: true})}>กดเพื่อแก้ไขข้อมูลที่อยู่จัดส่งยา</a>
      </div>
    ),
    hiv: (data) => {
      let _id = data.c_id;
      return <div>
        <div className="uk-text-right">
          <a className="uk-display-inline-block" onClick={()=>{
            var canvas = HivCreateImageData(_id);
            var doc = new jsPDF();
   
  
            var imgData = canvas.toDataURL('image/png');
            var pageHeight= doc.internal.pageSize.getHeight();
  
            var imageWidth = (canvas.width/canvas.height)*pageHeight;
  
            doc.addImage(imgData, 'PNG', 0, 0, imageWidth, pageHeight);
  
            doc.save("สร้างเสริมสุขภาพ.pdf");
  
          }}><span uk-icon="file-pdf"></span> ดาวน์โหลด PDF</a>
          <a  download="สร้างเสริมสุขภาพ.png" onClick={(e)=>{
            var _canvas = HivCreateImageData(_id);
  
            e.target.setAttribute("href", _canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
          }} className="uk-margin-small-left uk-display-inline-block"><span uk-icon="image"></span> ดาวน์โหลดภาพ</a>
        </div>
        <div dangerouslySetInnerHTML={{ __html: data.ct_name }} id={"d-hiv-data-" + _id} ></div>
        {
        this.props.chatby === "c" && 
        <div className="uk-text-right uk-margin-small-top">
          {
            data.hiv_finish == null ?
            (
              this.props.hivFinishLoading === true ? <div><span uk-spinner=""></span></div> 
              : 
              <button type="button" className="uk-button-primary uk-button" onClick={()=>{
                this.props.hivStatusFinishUpdate(_id);
              }}>กดเพื่อจบการรักษา</button>
            )
            :
            <b >จบการรักษาแล้ว</b>
          }
          
        </div>
        }
        {
        this.props.chatby === "m" && data.hiv_finish != null && <div class="uk-text-right"><a href="/survey" className="uk-button-danger uk-button uk-margin-top ">แบบประเมินความพึงพอใจ</a></div>
        }
      </div>
    },
    hiv2: (data) => { 
      let _id = data.c_id;
      let _html = data.ct_name
      var _noti_row = [];
      var _noti_text = null;
      
      try {
        var _d = JSON.parse(decodeURIComponent(data.ct_name));
        _html = decodeURIComponent(_d.text);
        if(_d.noti) {

          var _media = NotiData(_d.noti, 'media');

          for(let m in _media) {
            _noti_row.push( <div>
                <div className='knowledge-preview-container' uk-lightbox="">
                  <a href={_media[m].url} className='d-text-white' data-caption={_media[m].title} onClick={()=>{ 
                    if(this.apiType === "member") {
                      KnowledgeLogAdd(m, this.props.data.campaignid, 'auto-noti');
                    }
                  }}>
                    <div className='knowledge-preview' style={{
                      backgroundImage: `url(${_media[m].url})`,
                      }}>
                    </div>
                    <div className='uk-text-center uk-padding-small'>{_media[m].title}</div>
                  </a>
                </div>
              </div>
            )
          }

          if(_noti_row.length > 0) {
            _noti_text = <div>
              <hr className='uk-margin-top' />
              <div className='uk-margin-top uk-margin-small-bottom'><b><u>ชุดความรู้แนะนำ</u></b></div>
              <div className='uk-grid uk-grid-small uk-child-width-1-3@s uk-child-width-1-1 knowledge-container' uk-grid="">{_noti_row}</div>
            </div>;
          }

        }
      } catch(e) {
        console.error(e);
      }
  
      return <div>
        <div className="uk-text-right uk-margin-small-bottom">
          <a className="uk-display-inline-block" onClick={()=>{
            var canvas = HivCreateImageData(_id);
            var doc = new jsPDF();
   
  
            var imgData = canvas.toDataURL('image/png');

            var pageHeight= doc.internal.pageSize.getHeight();
  
            var imageWidth = (canvas.width/canvas.height)*pageHeight;
  
            doc.addImage(imgData, 'PNG', 0, 0, imageWidth, pageHeight);
  
            doc.save("สร้างเสริมสุขภาพ.pdf");
  
          }}><span uk-icon="file-pdf"></span> ดาวน์โหลด PDF</a>
          <a  download="สร้างเสริมสุขภาพ.png" onClick={(e)=>{
            var _canvas = HivCreateImageData(_id);
  
            e.target.setAttribute("href", _canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
          }} className="uk-margin-small-left uk-display-inline-block"><span uk-icon="image"></span> ดาวน์โหลดภาพ</a>
        </div>
        <div dangerouslySetInnerHTML={{ __html: _html }} id={"d-hiv-data-" + _id} ></div>
        {
          _noti_text
        }
        {
        this.props.chatby === "c" && 
        <div className="uk-text-right uk-margin-small-top">
          {
            data.hiv_finish == null ?
            (
              this.props.hivFinishLoading === true ? <div><span uk-spinner=""></span></div> 
              : 
              <button type="button" className="uk-button-primary uk-button" onClick={()=>{
                this.props.hivStatusFinishUpdate(_id);
              }}>รับทราบ</button>
            )
            :
            <b >รับทราบแล้ว</b>
          }
          
        </div>
        }
        {
        this.props.chatby === "m" && <div class="uk-text-right"><a href="/survey" className="uk-button-danger uk-button uk-margin-top ">ทำแบบประเมินความพึงพอใจ</a></div>
        }
      </div>
    },
    text: (data) => {
      if(typeof data.ct_name !== 'string') data.ct_name = '';

      if (
        data.ct_name.indexOf("http") > -1 &&
        data.ct_name.indexOf('data-word="link"') < 0
      ) {
        data.ct_name = data.ct_name
          .split("http")
          .map(function (word, index) {
            if (index % 2 == 1) {
              var _word_link = word.split(" ");
  
              _word_link[0] = `<a href="http${_word_link[0]}" target="_blank" data-word="link">http${_word_link[0]}</a>`;
  
              return _word_link.join(" ");
            } else {
              return word;
            }
          })
          .join("");
      }

      if (
        data.ct_name.includes("data-prescription-id") === true &&
        data.prescription_loaded !== true
      ) {
        data.footer = [];
  
        data.prescription_loaded = true;
  
        (async () => { 
          var _id = data.ct_name.split('data-prescription-id="')[1].split('"')[0];
  
          var _response = await Api[this.apiType]("PrescriptionFileGet", {
            id: _id,
          });

          try {
            data.prescription = _response.data;
  
            if(typeof data.prescription.content === 'string')
              data.prescription.content = JSON.parse(data.prescription.content);
  
            data.prescription.status_type = data.prescription.content.option.status


  
            this.forceUpdate();
  
            this.messageScrollBottom();
          } catch (e) {
            console.log(e)
          }
        })();
      }

      if(data.ct_name.includes("data-form-hiv-2=") === true) {
        if(this.apiType === 'consult') {
          data.ct_name = 'ส่งแบบประเมินตนเองก่อนพบแพทย์ให้คนไข้ (โปรดรอคนไข้ทำแบบประเมิน)';

        } else {
          data.footer = <button className="uk-button uk-button-primary uk-margin-small-top" onClick={()=>{
            UIkit.modal('#hiv-modal-form-v2').show();
          }}>กดเพื่อทำแบบประเมินตนเองก่อนพบแพทย์</button>
        }
      } else if(data.ct_name.includes("data-form-self-stigma=") === true) {
        if(this.apiType === 'consult') {
          data.ct_name = 'ส่งแบบประเมินตีตราตนเองให้คนไข้ (โปรดรอคนไข้ทำแบบประเมิน)';

        } else {
          data.footer = <button className="uk-button uk-button-blue uk-margin-small-top" onClick={()=>{
            UIkit.modal('#hiv-stigma-modal-form').show();
          }}>กดเพื่อทำแบบประเมินตีตราตนเอง</button>
        }
      } else if(data.ct_name.includes("data-knowledge=") === true) { 
        var _params = data.ct_name.split('data-knowledge=\"')[1].split('"')[0];
 
        data.footer = <div>
          <button className="uk-button uk-button-yellow uk-margin-small-top" uk-toggle={`target: #knowledge${data.c_id}`}>คลิกเพื่อดูความรู้แนะนำ</button>
          <div id={`knowledge${data.c_id}`} uk-modal="">
              <div className="uk-modal-dialog uk-modal-body">
                <div className='uk-grid uk-margin-small-bottom'>
                  <div className='uk-width-expand'><h2 class="uk-modal-title">ชุดความรู้แนะนำ</h2></div>
                  <div className='uk-width-auto'><i className='uk-modal-close uk-float-right' uk-icon="close"></i></div>
                </div>
                  <Knowledge 
                    campaignId={this.props.data.campaignid} 
                    tag={{
                      id: _params
                    }} 
                    channel={'consult-send'}
                  />
              </div>
          </div>
        </div>
      }
      
  
      if (this.apiType === "consult") {
        if (data.ct_name.indexOf("prescription-file?print=true&token") >= 0) {
          data.ct_name = data.ct_name
            .split("prescription-file?")
            .join("prescription-file?consult=true&");
            
        }
        if (data.prescription != null && data.prescription.content != null) {
          data.footer = (
            <div>สถานะล่าสุดจากคนไข้ : <b><u>{data.prescription.content.option.status}</u></b></div>
          );
        }
      } else if (this.apiType === "member") {
   
        if (data.prescription != null) {
          if (data.prescription.status_type === "รอคนไข้ยืนยัน") {
            if (data.prescription.content.option.status === "รอคนไข้ยืนยัน") {
              data.footer = (
                <div className="uk-margin-small-top">
                  <button
                    className="uk-button uk-button-primary uk-margin-small-right uk-width-1-1 uk-width-auto@s uk-margin-small-top"
                    onClick={() => {
                      this.prescriptionStatusUpdate(
                        data.prescription,
                        "คนไข้ยันยันแล้ว รอการชำระเงิน"
                      );
                    }}
                  >
                    ยืนยันใบสั่งยา
                  </button>
                  <button
                    className="uk-button uk-button-danger uk-width-1-1 uk-width-auto@s uk-margin-small-top"
                    onClick={() => {
                      this.prescriptionStatusUpdate(
                        data.prescription,
                        "ยกเลิกใบสั่งยา"
                      );
                    }}
                  >
                    ยกเลิกใบสั่งยา
                  </button>
                </div>
              );
            } else {
              data.footer = (
                <div>สถานะล่าสุดจากคนไข้ : <b><u>{data.prescription.content.option.status}</u></b></div>
              );
            }
          } else if (
            data.prescription.content.option.status ===
            "คนไข้ยันยันแล้ว รอการชำระเงิน"
          ) {
            data.footer = (
              <div>
                <form
                  method="post"
                  action="https://www.thaiepay.com/epaylink/payment.aspx"
                  target="_blank"
                >
                  <input
                    type="hidden"
                    name="refno"
                    value={data.prescription.id}
                  />
                  <input type="hidden" name="merchantid" value="17332033" />
                  <input
                    type="hidden"
                    name="customeremail"
                    value={this.props.profile != null && this.props.profile.email ? this.props.profile.email : 'noemail@dietz.asia'}
                  />
                  <input type="hidden" name="cc" value="00" />
                  <input
                    type="hidden"
                    name="productdetail"
                    value={
                      `ชำระเงินใบสั่งยาเลขที่ ${data.prescription.content.option.id } แคมเปญ ${data.prescription.campaignName} ${data.prescription.companyName}`
                    }
                  />
                  <input
                    type="hidden"
                    name="total"
                    value={numeral(
                      data.prescription.content.option.price_summary
                    ).format("0.00")}
                  />
                  <button
                    type="submit"
                    className="uk-button uk-button-primary uk-width-1-1 uk-margin-small-top"
                  >
                    ชำระเงิน
                  </button>
                </form>
              </div>
            );
          } else {
            data.footer = (
              <div>สถานะล่าสุด : <u><b>{data.prescription.content.option.status}</b></u></div>
            );
          }
        }
      }
  
      return (
        <div>
          <div dangerouslySetInnerHTML={{ __html: data.ct_name }}></div>
          {data.footer || null}
        </div>
      );
    },
    selfstigma: (data) => { 
      let _id = data.c_id;
      let _html = data.ct_name
      var _noti_row = [];
      
      try {
        var _d = JSON.parse(decodeURIComponent(data.ct_name));
        _html = decodeURIComponent(_d.text);
        if(_d.noti) {

          var _url = KeyPage['32'];
 
          _noti_row = <div>
            <hr className='uk-margin-top' />
            <div className='uk-margin-top uk-margin-small-bottom'><b><u>ชุดความรู้แนะนำ</u></b></div>
            <div className='uk-grid uk-grid-small uk-child-width-1-3@s uk-child-width-1-1 knowledge-container' uk-grid="">
              <div>
                <div className='knowledge-preview-container' uk-lightbox="">
                  <a href={_url.url} className='d-text-white'  data-caption={_url.title} onClick={()=>{ 
                    
                    if(this.apiType === "member") {
                      KnowledgeLogAdd('32', this.props.data.campaignid, 'auto-noti');
                    }
                  }}>
                    <div className='knowledge-preview' style={{
                      backgroundImage: `url(${_url.url})`,
                      }}>
                    </div>
                    <div className='uk-text-center uk-padding-small '>{_url.title}</div>
                  </a>
                </div>
              </div>
            </div>
            {
              this.props.chatby === "m" && <div class="uk-text-right"><a href="/survey" className="uk-button-danger uk-button uk-margin-top ">ทำแบบประเมินความพึงพอใจ</a></div>
            }
            
          </div>;

        }
      } catch(e) {
        console.error(e);
      }
  
      return <div>
        <div className="uk-text-right uk-margin-small-bottom">
          <a className="uk-display-inline-block" onClick={()=>{
            var canvas = HivCreateImageData(_id);
            var doc = new jsPDF();
   
  
            var imgData = canvas.toDataURL('image/png');

            var pageHeight = doc.internal.pageSize.getHeight();
  
            var imageWidth = (canvas.width/canvas.height)*pageHeight;
  
            doc.addImage(imgData, 'PNG', 0, 0, imageWidth, pageHeight);
  
            doc.save("แบบประเมินตีตราตนเอง.pdf");
  
          }}><span uk-icon="file-pdf"></span> ดาวน์โหลด PDF</a>
          <a  download="แบบประเมินตีตราตนเอง.png" onClick={(e)=>{
            var _canvas = HivCreateImageData(_id);
  
            e.target.setAttribute("href", _canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
          }} className="uk-margin-small-left uk-display-inline-block"><span uk-icon="image"></span> ดาวน์โหลดภาพ</a>
        </div>
        <div dangerouslySetInnerHTML={{ __html: _html }} id={"d-hiv-data-" + _id} ></div>
        {
          _noti_row
        }
      </div>
    },
    food: (data) => {
      var _optionRating = [0.5, 1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0, 4.5, 5.0];
  
      return (
        <div>
          <div>
            <div uk-lightbox="">
              <a href={ImageUri(data.cf_image)}>
                <img
                  uk-img=""
                  src={ImageUri(data.cf_image)}
                  width="360"
                  className="uk-margin-small-bottom"
                />
              </a>
            </div>
          </div>
          {this.props.chatby === "m" && +data.cf_rating > 0 && (
            <div>
              <Rate readonly={true} initialRating={data.cf_rating} stop={5} />{" "}
              <span>/ {numeral(data.cf_rating).format("0.0")}</span>
            </div>
          )}
  
          <div>
            <span className="d-chatfood-bold">ชื่ออาหาร: </span> {data.cf_name}
          </div>
          <div>
            <span className="d-chatfood-bold">วันที่/เวลา: </span>{" "}
            {moment(data.cf_dateadd).format("DD/MM/YYYY เวลา HH:mm")}
          </div>
          <div>
            <span className="d-chatfood-bold">คำอธิบาย: </span>{" "}
            {data.cf_caption}
          </div>
          {this.props.chatby === "c" && (
            <div className="d-button-group input-group uk-button-group d-chatmessage-food-consult-rating-container uk-width-1-1 uk-margin-small-top uk-flex-middle">
              <span
                className="uk-button input-group-addon"
                style={{ width: 100, marginLeft: 0, fontSize: 12 }}
              >
                ให้คะแนน
              </span>
              <select
                className="uk-select uk-text-center"
                defaultValue={data.cf_rating}
                onChange={(e) => {
                  data.cf_rating = e.target.value;

                  this.props.ratingUpdate(data.cf_id, e.target.value);
                }}
              >
                <option value="0">เลือกคะแนน</option>
                {_optionRating.map((data) => (
                  <option key={`option${data}`} value={data}>
                    {data}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      );
    }
  }
  


  render() {
    
    var _chat_type = this.props.data.c_type;

    //แปลงข้อมูลประเภทแชทเพื่อแสดงผล
    if(_chat_type === 'sos') _chat_type = 'text';

    return <div>{this.messageChat[_chat_type](this.props.data)}</div>
  }
}
