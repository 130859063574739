import React, { Component } from 'react';
import Api from '../Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
UIkit.use(Icons);

export default class ConsultMemberSummary extends Component {
  state = {
    amountMember: 0,
    amountMemberExpire: 0,
  }

   memberSummaryGet = async () => {
    var _response = await Api.consult('MemberSummary');

    this.setState({amountMember: _response.amount, amountMemberExpire: _response.amount_expire});

   }


   componentDidMount() {
    this.memberSummaryGet(); 
  }


  render() {
    return <div className="uk-card uk-card-default uk-margin-top">
    <table className="uk-table uk-table-divider uk-table-striped uk-width-1-1 uk-table-small uk-margin-remove-bottom">
      <tbody>
        <tr>
          <td>จำนวนคนไข้ที่ดูแลอยู่ปัจจุบัน</td>
          <td>{this.state.amountMember} คน</td>
        </tr>
        <tr>
          <td>คนไข้ที่แพคเกจหมดอายุแต่ยังไม่ได้ต่ออายุ</td>
          <td>{this.state.amountMemberExpire} คน</td>
        </tr>
      </tbody>
    </table>
    </div>
  }
};
